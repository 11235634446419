import { useEffect, useState } from "react";
import { getAssetByID } from "src/services/assets.services";

/**
 *
 * @param {string} id - Asset's unique identifier
 * @returns
 */
const useGetAssetByID = (id) => {
  const [asset, setAsset] = useState(null);

  useEffect(() => {
    if (id) {
      getAssetByID(id)
        .then((resp) => setAsset(resp.data))
        .catch((error) => console.log("useGetAssetByID: ", error.message));
    }
  }, [id]);

  return [asset, setAsset];
};
export default useGetAssetByID;
