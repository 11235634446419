import { Grid } from "@mui/material";
import MUICard from "src/components/MUICard/MUICard.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import MUIAutocomplete from "src/components/MUIAutocomplete/MUIAutocomplete.component";
import { useEffect, useState } from "react";
import configs from "src/configs/configs";
import { useFormik } from "formik";
import firstLetterCaps from "src/helpers/firstLetterCaps.helper";
import {
  createUser,
  getUserById,
  updateUserById,
} from "src/services/users.services";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import userTypes from "src/data/userTypes.data.json";
import { useNavigate, useParams } from "react-router-dom";
import MUISwitchButton from "src/components/MUISwitchButton/MUISwitchButton.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import FormActions from "src/pages/ManageOrganizations/RegisterOrganization/components/FormActions/FormActions.component";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import useApplicationRoles from "src/hooks/useApplicationRoles.hook";
import useGetOrganizations from "src/hooks/useGetOrganizations.hook";
import { getCustomers } from "src/services/customers.services";
import { getSuppliers } from "src/services/suppliers.services";
import { getInegrators } from "src/services/integrators.service";
import { parseOutgoingJSON } from "src/helpers/manageRequestJSON.helper";

const userRegistrationForm = [
  {
    id: "firstname",
    label: "Firstname",
    type: "text",
    isRequired: true,
    order: 1,
    col: { md: 6 },
  },
  {
    id: "lastname",
    label: "Lastname",
    type: "text",
    isRequired: true,
    order: 2,
    col: { md: 6 },
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    isRequired: true,
    order: 3,
    col: { md: 6 },
  },
  {
    id: "contact_number",
    label: "Contact Number",
    type: "text",
    order: 4,
    col: { md: 6 },
  },
  {
    id: "designation",
    label: "Designation",
    type: "text",
    order: 6,
    col: { md: 6 },
  },
  {
    id: "department",
    label: "Department",
    type: "text",
    order: 7,
    col: { md: 6 },
  },
  {
    id: "type",
    label: "Select Type",
    type: "select",
    order: 11,
    col: { md: 3 },
  },
  {
    id: "entity_id",
    label: "Select Entity",
    type: "autocomplete",
    order: 12,
    col: { md: 6 },
  },
];

//
const RegisterUser = () => {
  const applicationRoles = useApplicationRoles();
  const navigate = useNavigate();
  const params = useParams();
  const userId = params.id;

  const [isPageLoading, setIsPageLoading] = useState(userId ? true : false);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [isRegistering, setRegistering] = useState(false);
  const [incomingResponse, setIncomingResponse] = useState(null);
  const [searchTexts, setSearchTexts] = useState(null);

  // Access Current User:
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userRole = currentUser?.role || null;
  const organizationID = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions["users"] || [];
  const myOrganization = organizationID
    ? {
        id: organizationID,
        label: organizationID,
        value: organizationID,
        name: "organization_id",
      }
    : null;

  // initialize formik
  const formik = useFormik({
    initialValues: {
      organization_id: myOrganization || null,
      firstname: "",
      lastname: "",
      email: "",
      contact_number: "",
      designation: "",
      department: "",
      role: "",
      entity_id: null,
      type: "user",
      is_active: true,
    },

    // Implementing validations:
    validationSchema: Yup.object().shape({
      firstname: Yup.string().required("First Name is required"),
      lastname: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required")
        .matches(
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        ),
      organization_id: Yup.object().required("Organization is required!"),
      role: Yup.string().required("Role is required!"),
    }),

    onSubmit: async (values) => {
      const rawValues = values; // Un-filtered values
      const requestBody = parseOutgoingJSON(rawValues);
      setRegistering(true); // Submitting user details

      try {
        userId
          ? await updateUserById(userId, requestBody)
          : await createUser(requestBody);

        setIncomingResponse({
          severity: "success",
          message: `User ${userId ? "updated" : "created"} successfully.`,
        });

        if (!userId) formik.handleReset(); // Resetting formik values.
        setRegistering(false);
      } catch (error) {
        console.log(
          "Error while sumbitting user details: ",
          error?.response?.data?.data || error?.message
        );
        setIncomingResponse({
          severity: "error",
          message: error?.response?.data?.data || error?.message,
        });
        setRegistering(false);
      }
    },
    onReset: () => {
      if (userId) navigate(-1);
    },
  });

  const { roles } = applicationRoles.useGetRoles();
  const [organizations, isOrganizationsLoading] = useGetOrganizations(
    searchTexts?.organization_id
  );
  const [entities, isEntitiesLoading] = useGetEntitisByName(
    formik?.values?.type,
    searchTexts?.entity_id,
    formik?.values?.organization_id?.id
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (["firstname", "lastname"].includes(name)) {
      e.target.value = firstLetterCaps(value);
    }
    formik.handleChange(e);
  };

  // a function to handle change behaviour of autocomplete:
  const handleAutocompleteChange = (e, value) => {
    if (value) formik.handleChange({ target: { name: value.name, value } });
    else {
      const { name, value } = e.target;
      setSearchTexts((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    // console.log(rolesJson)
    if (roles.length) {
      const rolesMapping = roles?.map((rl) => ({
        id: rl.role_id,
        label: rl.role_name,
        value: rl.role_id,
      }));

      setRolesOptions(rolesMapping);
    }
  }, [roles]);

  // A function to fetch the user's details.
  const fetchUserDetails = async () => {
    try {
      const response = await getUserById(userId);

      formik.setValues({
        organization_id: {
          id: response.organization_id,
          label: response?.organization?.name,
          value: response.organization_id,
          name: "organization_id",
        },
        entity_id: response?.entity && {
          id: response.entity_id,
          label: response?.entity?.name,
          value: response.entity_id,
          name: "entity_id",
        },
        firstname: response.firstname,
        lastname: response.lastname,
        email: response.email,
        contact_number: response.contact_number,
        role: response.role,
        type: response?.type || "user",
        department: response.department,
        designation: response.designation,
        is_active: response.is_active,
      });
      setIsPageLoading(false);
    } catch (error) {
      console.log("Fetch User's Details: ", error.message);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  useEffect(() => {
    if (!entities.length) formik?.setFieldValue("entity_id", null);
  }, [entities]);

  // Set a variable for handling an update permission:
  const haveUpdatePermission =
    params?.id && !userPermissions.includes("UPDATE");

  const fieldOptions = {
    type: userTypes?.data || [],
    entity_id: entities || [],
  };

  const fieldsLoading = {
    entity_id: isEntitiesLoading,
  };

  const disabled = {
    entity_id: formik?.values?.type === "user",
  };

  if (isPageLoading) {
    return <PageLoader />;
  } else {
    return (
      <>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <HeaderChip
          icon={
            <configs.icons.PersonIcon sx={{ color: "#fff" + "!important" }} />
          }
          label={`Users > ${userId ? "Edit" : "Create"}`}
        />
        <div style={{ maxWidth: "800px", margin: "auto" }}>
          <MUICard
            title={
              <TitleComponent
                title={`${userId ? "Update" : "Register"} User`}
              />
            }
            action={
              userId ? (
                <MUISwitchButton
                  name={`is_active`}
                  is_active={formik.values.is_active}
                  onChange={formik.handleChange}
                  disabled={haveUpdatePermission}
                />
              ) : null
            }
          >
            <Grid spacing={2} container>
              {userRole === "asset_eye_owner" ? (
                <Grid md={12} item>
                  <>
                    <MUIInputLabel>
                      {<span style={{ color: "red" }}>*</span>}
                      {"Organization"}
                    </MUIInputLabel>
                    <MUIAutocomplete
                      name={"organization_id"}
                      value={formik?.values?.organization_id}
                      options={organizations}
                      onChange={handleAutocompleteChange}
                      loading={isOrganizationsLoading}
                      error={
                        formik.touched["organization_id"] &&
                        formik.errors["organization_id"]
                      }
                      helperText={
                        formik.touched["organization_id"] &&
                        formik.errors["organization_id"]
                      }
                      disabled={haveUpdatePermission}
                    />
                  </>
                </Grid>
              ) : null}

              {userRegistrationForm.map((field) => (
                <Grid md={field.col.md} order={field.order} key={field.id} item>
                  <MUIInputLabel>
                    {field.isRequired && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                    {field.label}
                  </MUIInputLabel>
                  {field?.type === "autocomplete" ? (
                    <MUIAutocomplete
                      name={field?.id}
                      value={formik?.values[field?.id]}
                      options={fieldOptions[field?.id]}
                      onChange={handleAutocompleteChange}
                      loading={fieldsLoading[field?.id]}
                      error={
                        formik.touched[field?.id] && formik.errors[field?.id]
                      }
                      helperText={
                        formik.touched[field?.id] && formik.errors[field?.id]
                      }
                      disabled={disabled[field?.id] || haveUpdatePermission}
                    />
                  ) : (
                    <MUITextField
                      Id={field.id}
                      Name={field.id}
                      Type={field.type}
                      Value={formik.values[field.id]}
                      OnChange={handleChange}
                      Error={
                        formik.touched[field.id] && formik.errors[field.id]
                      }
                      helperText={
                        formik.touched[field.id] && formik.errors[field.id]
                      }
                      SelectOptions={fieldOptions[field.id] || []}
                      Select={field?.type === "select"}
                      Disabled={haveUpdatePermission}
                    />
                  )}
                </Grid>
              ))}
              <Grid md={3} order={userRegistrationForm.length + 1} item>
                <MUIInputLabel>
                  {<span style={{ color: "red" }}>*</span>}
                  {"Select Role"}
                </MUIInputLabel>
                <MUITextField
                  Id={"role"}
                  Name={"role"}
                  Value={rolesOptions[0] && formik.values["role"]}
                  OnChange={handleChange}
                  Error={formik.touched["role"] && formik.errors["role"]}
                  helperText={formik.touched["role"] && formik.errors["role"]}
                  SelectOptions={rolesOptions}
                  Select
                  Disabled={haveUpdatePermission}
                />
              </Grid>
            </Grid>
          </MUICard>
          <AppSpacer vertical={"12px"} />
          <FormActions
            disabledSubmit={!userPermissions.includes("UPDATE")}
            formik={formik}
            isLoading={isRegistering}
          />
        </div>
      </>
    );
  }
};

export default RegisterUser;

// Title Component:
function TitleComponent({ title }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <configs.icons.PersonIcon sx={{ color: configs.colors.primary }} />
      &nbsp; {title}
    </div>
  );
}

const useGetEntitisByName = (entityName, searchText, organizationId) => {
  const [entities, setEntities] = useState([]);
  const [isEntitiesLoading, setIsEntitiesLoading] = useState(false);

  const fetchEntitesByName = async () => {
    setEntities([]);
    setIsEntitiesLoading(true);
    try {
      const response = await getEntitisByName(
        entityName,
        searchText,
        organizationId
      );
      setEntities(response);
      setIsEntitiesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (organizationId) {
      if (!searchText) fetchEntitesByName();
      if (searchText && searchText.length > 3) fetchEntitesByName();
    }
  }, [entityName, searchText, organizationId]);

  return [entities, isEntitiesLoading];
};

const getEntitisByName = async (entityName, searchKeyword, organizationId) => {
  try {
    switch (entityName) {
      case "customer": {
        const response = await getCustomers({ searchKeyword, organizationId });
        const autocompleteOptions = response?.data?.map((customer) => ({
          id: customer.id,
          label: customer.name,
          value: customer.name,
          name: "entity_id",
        }));
        return autocompleteOptions;
      }

      case "supplier": {
        const response = await getSuppliers({ searchKeyword, organizationId });
        const autocompleteOptions = response?.data?.map((supplier) => ({
          id: supplier.id,
          label: supplier.name,
          value: supplier.name,
          name: "entity_id",
        }));
        return autocompleteOptions;
      }

      case "integrator": {
        const response = await getInegrators({ searchKeyword, organizationId });
        const autocompleteOptions = response?.data?.map((integrator) => ({
          id: integrator.id,
          label: integrator.name,
          value: integrator.name,
          name: "entity_id",
        }));
        return autocompleteOptions;
      }

      default:
        return [];
    }
  } catch (error) {
    console.error(
      "Error while getting entities by name: ",
      error?.response?.data
    );
  }
};
