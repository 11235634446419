const registerOrganizationFormFields = [
  {
    id: "name",
    name: "name",
    label: "Organization Name",
    type: "text",
    isRequired: true,
    order: 1,
    col: {
      md: 6,
    },
  },

  {
    id: "description",
    name: "description",
    label: "Description",
    type: "text",
    order: 1,
    col: {
      md: 6,
    },
  },
  {
    id: "country",
    name: "country",
    label: "Country",
    type: "select",
    isRequired: true,
    order: 1,
    col: {
      md: 6,
    },
  },
  {
    id: "state",
    name: "state",
    label: "State",
    type: "select",
    isRequired: true,
    order: 1,
    col: {
      md: 6,
    },
  },
  {
    id: "city",
    name: "city",
    label: "City",
    type: "text",
    isRequired: true,
    order: 1,
    col: {
      md: 6,
    },
  },
  {
    id: "zipcode",
    name: "zipcode",
    label: "Zip code",
    isRequired: true,
    type: "text",
    order: 1,
    col: {
      md: 6,
    },
  },
];

export default registerOrganizationFormFields;
