import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  ListItemIcon,
  ButtonBase,
} from "@mui/material";
import "./Navbar.style.css";
import MUICard from "../MUICard/MUICard.component";
import MUITextField from "../MUITextField/MUITextField.component";
import ProfileThumbnail from "./components/ProfileThumbnail.component";
import Sidebar from "../Sidebar/Sidebar.component";
import { useSelector } from "react-redux";
import colors from "src/configs/colors.config";
import configs from "src/configs/configs";
import { useNavigate } from "react-router-dom";
import ActionsGroup from "./components/ActionsGroup.component";
import getRoleName from "src/helpers/getRoleName.helper";

const Navbar = () => {
  // Get Current User:
  const currentUser = useSelector((state) => state.auth.currentUser);
  const organizationID = currentUser?.organization_id || null;

  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const [activeUser, setActiveUser] = useState({
    displayName: "",
    role: "",
  });
  const [anchorID, setAnchorID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorID(event.currentTarget?.id);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle Menu Toggle:
  const handleToggleMenu = () => setShowMenu((e) => !e);

  // Set Current Active User:
  useEffect(() => {
    if (currentUser) {
      setActiveUser({
        displayName: currentUser?.firstname + " " + currentUser?.lastname,
        role: currentUser?.role,
      });
    }
  }, [currentUser]);

  const actionsGroup = {
    quickAdds: handleMenuOpen,
  };

  return (
    <MUICard removeDefaultPadding padding={0} style={{ borderRadius: 0 }}>
      <div className="navbar-main">
        <Grid columnSpacing={2} alignItems={"center"} container>
          {/* Logo */}
          <Grid xs={12} md={3} lg={2} item>
            <Grid columnSpacing={2} alignItems={"center"} container>
              <Grid xs={2} display={{ md: "none" }} item></Grid>
              <Grid xs={8} md={12} item>
                <div className="navbar-logo-container">
                  <img
                    src={configs.images.app_logo}
                    alt={configs.images.app_logo_alt_text}
                  />
                </div>
              </Grid>
              <Grid xs={2} display={{ md: "none" }} item>
                <IconButton onClick={handleToggleMenu}>
                  <configs.icons.MenuIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid xs={12} md={6} lg={8} item></Grid> */}
          <Grid md={2} display={{ xs: "none", md: "block" }} item></Grid>
          <Grid md={4} lg={3} display={{ xs: "none", md: "block" }} item>
            <MUITextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton style={{ color: configs.colors.primary }}>
                      <configs.icons.SearchIcon
                        sx={{
                          "&:hover": { color: configs.colors.primary },
                          color: configs.colors.secondary,
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              PlaceHolder="Search Anything..."
            />
          </Grid>
          <Grid md={1} display={{ xs: "none", md: "block" }} item></Grid>
          <Grid md={2} lg={2} display={{ xs: "none", md: "block" }} item>
            <ActionsGroup actions={actionsGroup} />
          </Grid>

          {/*  */}
          <Grid
            md={3}
            lg={2}
            justifyContent={"end"}
            display={{ xs: "none", md: "block" }}
            item
          >
            <ProfileThumbnail
              displayName={activeUser.displayName}
              role={getRoleName(activeUser?.role)}
              handleMenuOpen={handleMenuOpen}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        {showMenu ? (
          <Grid container>
            {/* Sidebar */}
            <Grid xs={12} display={{ md: "none" }} item>
              <Sidebar />
            </Grid>
          </Grid>
        ) : null}
      </div>
      {/* quick add menu */}
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "user_menu",
        }}
      >
        {getItems(anchorID).map((item) =>
          item.id === "create_organization" && organizationID ? null : (
            <MenuItem key={item.id} onClick={() => navigate(item?.path)}>
              {item?.icon ? <ListItemIcon>{item?.icon}</ListItemIcon> : null}
              {item?.label}
            </MenuItem>
          )
        )}
      </Menu>
    </MUICard>
  );
};

export default Navbar;

const quickAddItems = [
  {
    id: "create_organization",
    path: "/manage/organizations/register",
    icon: <configs.icons.BusinessIcon sx={{ color: colors.primary }} />,
    label: "Add Organization",
  },
  {
    id: "create_user",
    path: "/manage/users/register",
    icon: <configs.icons.PersonIcon sx={{ color: colors.primary }} />,
    label: "Add User",
  },
  {
    id: "create_customer",
    path: "/manage/customers/create",
    icon: <configs.icons.PeopleIcon sx={{ color: colors.primary }} />,
    label: "Add Customer",
  },
  {
    id: "create_supplier",
    path: "/manage/suppliers/create",
    icon: <configs.icons.ShoppingCartIcon sx={{ color: colors.primary }} />,
    label: "Add Supplier",
  },
  {
    id: "create_contract",
    path: "/manage/contracts/register",
    icon: <configs.icons.DescriptionIcon sx={{ color: colors.primary }} />,
    label: "Add Contract",
  },
  {
    id: "create_asset",
    path: "/manage/assets/register",
    icon: <configs.icons.LayersIcon sx={{ color: colors.primary }} />,
    label: "Add Assets",
  },
  {
    id: "create_custom_form",
    path: "/manage/custom-forms/create",
    icon: <configs.icons.CheckCircleIcon sx={{ color: colors.primary }} />,
    label: "Add Custom Form",
  },
]; // quick adds config

const userMenuItems = [
  {
    id: "view_user_profile",
    path: "/manage/users/profile",
    label: "Profile",
  },
  {
    id: "log_out",
    path: "/logout",
    label: "Log Out",
  },
]; // quick adds config

const getItems = (name) => {
  switch (name) {
    case "user_menu":
      return userMenuItems;
    default:
      return quickAddItems;
  }
};
