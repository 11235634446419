/* 
    Copyright 2024 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/

import { Alert, Snackbar } from "@mui/material";

//
const MUISnackbar = ({
  message,
  open,
  onClose,
  severity,
  vertical,
  horizontal,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MUISnackbar;
