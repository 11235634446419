import AppSpacer from "src/components/AppSpace/AppSpace.component";
import RegisterOrganizationForm from "./components/RegisterOrganization/RegisterOrganization.component";
import RegisterAdminForm from "./components/RegisterAdminForm/RegisterAdminForm.component";
import { useFormik } from "formik";
import {
  initializeOrganizationValues,
  initializeUserValues,
} from "src/helpers/formik.helpers";
import { useEffect, useState } from "react";
import firstLetterCaps from "src/helpers/firstLetterCaps.helper";
import { Country, State, City } from "country-state-city";
import * as Yup from "yup";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import organizationValidationsSchema from "./components/RegisterOrganization/organizationSchema.validations";
import adminValidationsSchema from "./components/RegisterAdminForm/adminSchema.validations";
import FormActions from "./components/FormActions/FormActions.component";
import handleRegisterOrganization from "./helpers/handleRegisterOrganization.helper";
import { useNavigate, useParams } from "react-router-dom";
import {
  getOrganizationByID,
  updateOrganizationDetails,
} from "src/services/organizations.services";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import configs from "src/configs/configs";
import { useSelector } from "react-redux";

const RegisterOrganization = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userPermission = currentUser.permissions["organizations"] || null;

  const navigate = useNavigate();
  const params = useParams();
  const organizationId = params?.id;

  const [isPageLoading, setIsPageLoading] = useState(
    organizationId ? true : false
  );
  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [primaryUser, setPrimaryUser] = useState(null);
  const [isRegistering, setRegistering] = useState(false);
  const [incomingResponse, setIncomingResponse] = useState(null);
  const [options, setOptions] = useState({
    country: [],
    state: [],
    city: [],
  });

  const [selectedOptions, setSelectedOptions] = useState({
    country: null,
    state: null,
    city: null,
  });

  const organization = initializeOrganizationValues();
  const user = initializeUserValues();

  //
  const formik = useFormik({
    initialValues: {
      organization,
      user,
    },
    validationSchema: Yup.object().shape({
      organization: Yup.object().shape(organizationValidationsSchema),
      user: organizationId ? null : Yup.object().shape(adminValidationsSchema),
    }),
    onSubmit: (values) => {
      setRegistering(true);

      if (organizationId) {
        updateOrganizationDetails(organizationId, values?.organization)
          .then((res) => {
            console.log(res);
            setIncomingResponse({
              severity: "success",
              message: "Updated successfully",
            });
            setRegistering(false); // Completing user registration.
          })
          .catch((error) => {
            setIncomingResponse({
              severity: "error",
              message: error.message,
            });
            setRegistering(false); // Completing user registration.
          });
      } else {
        handleRegisterOrganization(values)
          .then((res) => {
            console.log(res);
            setIncomingResponse({
              severity: "success",
              message: "Registered successfully",
            });
            setSelectedOptions({
              country: null,
              state: null,
              city: null,
            }); // Clearing selected country, state, city.
            formik.handleReset(); // Resetting formik values.
            setRegistering(false); // Completing user registration.
          })
          .catch((error) => {
            setIncomingResponse({
              severity: "error",
              message: error.message,
            });
            setRegistering(false); // Completing user registration.
          });
      }
    },
    onReset: () => {
      if (organizationId) navigate(-1);
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      ["user[firstname]", "user[lastname]", "organization[name]"].includes(name)
    ) {
      e.target.value = firstLetterCaps(value);
    }
    formik.handleChange(e);
  };

  //
  const handleAutocompleteChange = (e, value) => {
    const ids = e.target.id.split("-")[1];
    if (value) {
      setSelectedOptions((prev) => ({
        ...prev,
        [ids]: value,
      }));

      formik.handleChange({
        target: { name: `organization[${ids}]`, value: value.label },
      });
    }
  };

  // fetch all countries:
  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      country: Country.getAllCountries().map((c) => ({
        id: c.isoCode,
        label: c.name,
        value: c.isoCode,
      })),
    }));
  }, []);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      state: State.getStatesOfCountry(selectedOptions?.country?.id).map(
        (s) => ({
          id: s.isoCode,
          label: s.name,
          value: s.isoCode,
        })
      ),
    }));
  }, [selectedOptions.country]);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      city: City.getCitiesOfState(
        selectedOptions?.country?.id,
        selectedOptions?.state?.id
      ).map((s) => ({
        id: s.isoCode,
        label: s.name,
        value: s.isoCode,
      })),
    }));
  }, [selectedOptions.state]);

  useEffect(() => {
    if (organizationId) {
      getOrganizationByID(organizationId).then((response) => {
        setOrganizationDetails(response);
        setPrimaryUser(response.primaryUser);
        formik.setValues({
          organization: {
            name: response?.name,
            zipcode: response?.zipcode,
            description: response?.description,
            country: response?.country || null,
            city: response?.city || null,
            state: response?.state || null,
            is_active: response?.is_active,
          },
        });

        const addressOptions = {};

        const getAllCountries = Country.getAllCountries();
        const matchedCountry = getAllCountries.filter(
          (country) => country.name === response.country
        )[0];

        addressOptions.country = {
          id: matchedCountry.isoCode,
          label: matchedCountry.name,
          value: matchedCountry.isoCode,
        };

        const allStates = State.getStatesOfCountry(matchedCountry.isoCode);
        const matchedState = allStates.filter(
          (state) => state.name === response.state
        )[0];

        addressOptions.state = {
          id: matchedState.isoCode,
          label: matchedState.name,
          value: matchedState.isoCode,
        };

        const allCities = City.getCitiesOfState(
          matchedCountry.isoCode,
          matchedState.isoCode
        );
        const matchedCity = allCities.filter(
          (city) => city.name === response.city
        )[0];

        addressOptions.city = {
          id: matchedCity?.isoCode,
          label: matchedCity?.name,
          value: matchedCity?.isoCode,
        };

        setSelectedOptions(addressOptions);
        setIsPageLoading(false);
      });
    }
  }, [organizationId]);
  //

  // Set a variable for handling an update permission:
  const haveUpdatePermission = params?.id && !userPermission.includes("UPDATE");

  if (isPageLoading) {
    return <PageLoader />;
  } else {
    return (
      <>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <HeaderChip
          icon={
            <configs.icons.BusinessIcon sx={{ color: "#fff" + "!important" }} />
          }
          label={`Organization > ${organizationId ? "Edit" : "Create"}`}
        />
        <div style={{ width: "800px", margin: "auto" }}>
          {/* Register Organization */}
          <RegisterOrganizationForm
            disabled={haveUpdatePermission}
            formik={formik}
            isRegistering={isRegistering}
            options={options}
            autocompleteValues={selectedOptions}
            handleInputChange={handleChange}
            handleAutocompleteChange={handleAutocompleteChange}
          />
          <AppSpacer vertical={"12px"} />
          {organizationId ? null : (
            <RegisterAdminForm
              formik={formik}
              handleInputChange={handleChange}
              isRegistering={isRegistering}
            />
          )}
          <AppSpacer vertical={"12px"} />
          {/* Actions */}
          <FormActions
            disabledSubmit={haveUpdatePermission}
            formik={formik}
            isLoading={isRegistering}
          />
        </div>
      </>
    );
  }
};

export default RegisterOrganization;
