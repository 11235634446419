import { useSelector } from "react-redux";

const { useState, useEffect } = require("react");
const { getContracts } = require("src/services/contracts.services");

const useGetContracts = (searchText, organizationId) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  const organizationID = organizationId || null;
  // const userPermissions = currentUser?.permissions || [];

  const [contracts, setContracts] = useState([]);
  const [isContractsLoading, setIsContractsLoading] = useState(false);

  const searchKeyword = searchText ? searchText : "";

  // Function to fetch contracts:
  const fetchContracts = async () => {
    try {
      setIsContractsLoading(true);
      const resp = await getContracts({
        searchKeyword,
        organizationId: organizationID,
      });

      // Converting response into autocomplete options:
      const autocompleteOptions = resp?.data?.map((organization) => ({
        id: organization.id,
        label: organization.name,
        value: organization.id,
        name: "contract_id",
      }));

      setContracts(autocompleteOptions);
      setIsContractsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsContractsLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      if (!searchText) fetchContracts();
      if (searchText && searchText.length > 3) fetchContracts();
    }
  }, [searchText, organizationId]);

  return [contracts, isContractsLoading];
};

export default useGetContracts;
