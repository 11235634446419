import React, { useState } from "react";
import { Spin, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { PlusOutlined } from "@ant-design/icons";
import { GET_DASHBOARD_ITEMS } from "src/graphql/queries";
import ChartRenderer from "src/components/ChartRenderer";
import Dashboard from "src/components/Dashboard";
import DashboardItem from "src/components/DashboardItem";
import MUICard from "src/components/MUICard/MUICard.component";
import { CircularProgress, Grid, Modal, Typography } from "@mui/material";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import MUIAutocomplete from "src/components/MUIAutocomplete/MUIAutocomplete.component";
import useGetOrganizations from "src/hooks/useGetOrganizations.hook";
import useGetDashboards from "src/hooks/useGetDashboards.hook";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import { useSelector } from "react-redux";
import configs from "src/configs/configs";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import CreateDashboard from "./CreateDashboard/CreateDashboard.component";

const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  vizState: JSON.parse(i.vizState),
});

const defaultLayout = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 2,
  minH: 2,
});

const DashboardPage = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userPermissions = currentUser?.permissions["embedded_analytics"] || [];
  const organizationID = currentUser?.organization_id || null;
  const myOrganization = organizationID
    ? {
        id: organizationID,
        label: "",
        value: organizationID,
      }
    : null;
  // const userRole = currentUser?.role || null;
  // const userPermissions = currentUser?.permissions || [];

  const [searchTexts, setSearchTexts] = useState({
    organization_id: "",
    dashboard_id: "",
  });

  // Modals states:
  const [openModal, toggleOpenModal] = useState(false); // Toggle
  const [modalValue, setModalValue] = useState(null); // Storing modal states/values

  const [selectedOrganization, setSelectedOrganization] =
    useState(myOrganization); // User selected organization
  const [selectedDashboard, setSelectedDashboard] = useState(null); // User selected dashboard

  const [organizations, isOrganizationsLoading] = useGetOrganizations(
    searchTexts.organization_id
  ); // Get organizations
  const [dashboards, setDashboards, isDashboardsLoading] = useGetDashboards(
    searchTexts.dashboard_id,
    selectedOrganization?.id || null
  ); // Get dashboards as well as by organization id

  const modalOptions = {
    dashboard_id: { dashboards, setDashboards },
  }; // Options for modals

  const { loading, error, data } = useQuery(GET_DASHBOARD_ITEMS, {
    fetchPolicy: "cache-and-network",
    variables: {
      // dashboardId: "cad55f65-be7f-487a-8391-94a8b4b69d2f",
      dashboardId: selectedDashboard?.id || "",
    },
  });

  // functions:
  const handleAutocompleteChange = (e, selectValue) => {
    const { name, value } = e.target;

    switch (true) {
      case selectValue && selectValue.name === "organization_id":
        if (selectValue?.value) setSelectedOrganization(selectValue);
        break;
      case selectValue && selectValue.name === "dashboard_id":
        if (selectValue?.value) setSelectedDashboard(selectValue);
        break;
      default:
        setSearchTexts((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const handleClearAutocompletes = () => {
    setSelectedDashboard(null);
    setSelectedOrganization(null);
  };

  const dashboardItem = (item) => (
    <div key={item.id} data-grid={defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        item={item}
      >
        <ChartRenderer vizState={item.vizState} />
      </DashboardItem>
    </div>
  );

  const warningTexts = {
    organization_id: "Select an organization & the dashboard.",
    dashboard_id: "Now select the dashboard ...",
  };

  if (!userPermissions.includes("READ")) {
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">
          Oops! You don't have a permission to access dashboard.
        </Typography>
      </div>
    );
  } else
    return (
      <div>
        <MUICard>
          <Grid spacing={2} alignItems={"center"} container>
            {organizationID ? null : (
              <Grid sm={4} item>
                <MUIInputLabel>Select Organization</MUIInputLabel>
                <MUIAutocomplete
                  loading={isOrganizationsLoading}
                  name="organization_id"
                  value={selectedOrganization}
                  options={organizations}
                  onChange={handleAutocompleteChange}
                />
              </Grid>
            )}

            <Grid sm={4} item>
              <MUIInputLabel>Select Dashboard</MUIInputLabel>
              <MUIAutocomplete
                loading={isDashboardsLoading}
                name="dashboard_id"
                value={selectedDashboard}
                // setValue={setSelectedDashboard}
                options={dashboards}
                onChange={handleAutocompleteChange}
                toggleOpen={toggleOpenModal}
                setDialogValue={setModalValue}
                // creatable
              />
            </Grid>

            <Grid sm={1} item>
              <MUIInputLabel>&nbsp;</MUIInputLabel>
              <MUIButton onClick={handleClearAutocompletes} primary>
                Clear
              </MUIButton>
            </Grid>
            <Grid sm={2} item>
              <MUIInputLabel>&nbsp;</MUIInputLabel>
              <MUIButton
                onClick={() => navigate(`/manage/embedded_analytics`)}
                disabled={!userPermissions.includes("CREATE")}
                primary
              >
                Add Chart
              </MUIButton>
            </Grid>
          </Grid>
        </MUICard>

        <AppSpacer vertical={"12px"} />

        {loading ? (
          <div
            style={{
              width: "100%",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: configs.colors.primary }} />
          </div>
        ) : null}

        {error ? (
          <Alert
            message="Error occured while loading your query"
            description={error.toString()}
            type="error"
          />
        ) : null}

        {!loading && (!data || data.dashboardItems.length) ? (
          <Dashboard dashboardItems={data && data.dashboardItems}>
            {data &&
              data.dashboardItems.map(deserializeItem).map(dashboardItem)}
          </Dashboard>
        ) : (
          <div
            style={{
              width: "100%",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!loading && selectedOrganization && selectedDashboard ? (
              <Typography>
                No items available, Let's &nbsp;
                <Link to={"/manage/embedded_analytics"}>add items</Link>
              </Typography>
            ) : (
              <Typography>
                {!loading
                  ? !organizationID && !selectedOrganization?.id
                    ? warningTexts.organization_id
                    : warningTexts.dashboard_id
                  : null}
              </Typography>
            )}
          </div>
        )}

        <Modal open={openModal} onClose={() => toggleOpenModal((e) => !e)}>
          <div>
            <CreateDashboard
              organizationId={selectedOrganization?.id}
              creatorId={currentUser?.id}
              value={modalValue}
              setSelectedOption={setSelectedDashboard}
              setOptions={setDashboards}
              setValue={setModalValue}
              toggleOpen={toggleOpenModal}
            />
          </div>
        </Modal>
      </div>
    );
};

export default DashboardPage;
