/*
    Description: This file contains all the services related to the manage users.
*/
//

import { post, get, put, remove } from "../utils/axios/axios.utils";

const path = "/users";

export const createUser = async (data) => {
  try {
    const result = await post(path, data);

    return result;
  } catch (error) {
    throw error;
  }
};

export const getUsers = async (params) => {
  try {
    const response = await get(path, {
      params,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

//Users Details
export const getUserById = async (userId) => {
  try {
    const response = await get(path + `/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// updating user details
export const updateUserById = async (userId, data) => {
  try {
    const result = await put(`${path}/${userId}`, data); // Assuming 'put' is used for updates
    return result;
  } catch (error) {
    throw error;
  }
};

// // Fetch current/active user:
export const getCurrentUser = async (token) => {
  try {
    const response = await get(`${path}/current-user`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchUsersData = async (params) => {
  try {
    const response = await get("/users/data/meta-data", {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// Deleting an Users
export const deleteUser = async (userId) => {
  try {
    const result = await remove(`${path}/${userId}`);
    return result;
  } catch (error) {
    console.error("deleteUsers:", error.message);
    throw error;
  }
};
