import React, { useEffect, useState } from "react";
import { Grid, IconButton, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import DashboardCard from "src/components/DashboardCard/DashboardCard.component";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUIGridTable from "src/components/MUIGridTable/MUIGridTable.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import configs from "src/configs/configs";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import {
  getOrganizations,
  getOrgnizationsMetaData,
  deleteOrganization,
} from "src/services/organizations.services";
import { exportToCSV, exportToXLSX } from "src/helpers/exportData.helper";
import Searchbar from "src/components/Searchbar/Searchbar.component";
import DialogsGroup from "src/components/DialogsGroup/DialogsGroup.component";
import { useSelector } from "react-redux";

//Organizations Dashboard
const OrgnizationsDashboard = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userPermission = currentUser.permissions;

  const navigate = useNavigate();

  const [isPageLoading, setIsPageLoading] = useState({
    meta_data: true,
    list: true,
  });
  const [isListFetched, setIsListFetched] = useState(false);
  const [summaries, setSummaries] = useState({
    all_count: 0,
    active_count: 0,
    in_active_count: 0,
    listed_in_7_days: 0,
  });
  const [orgnizations, setOrgnizations] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [incomingResponse, setIncomingResponse] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [searchString, setSearchString] = useState(null);

  const [openDialog, toggleDialog] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [dialogType, setDialogType] = useState(null);

  // Fetch Organizations MetaData
  const fetchOrgnizationsMetaData = async () => {
    try {
      const response = await getOrgnizationsMetaData();
      setSummaries({
        all_count: response.count,
        active_count: response.active,
        in_active_count: response.in_active,
        listed_in_7_days: response.registered_within_7_Days,
      });
      setIsPageLoading((prev) => ({ ...prev, meta_data: false }));
    } catch (error) {
      console.error("fetchOrgnizationsMetaData error:", error.message);
    }
  };

  useEffect(() => {
    fetchOrgnizationsMetaData();
  }, []);

  useEffect(() => {
    if (!searchString || searchString.length > 3) fetchOrgnizations();
  }, [paginationModel, searchString]);

  // Get Organizations
  const fetchOrgnizations = async () => {
    try {
      setIsListFetched(true);
      const response = await getOrganizations({
        pageNumber: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        searchKeyword: searchString,
      });

      if (response && response.data && response.meta_data) {
        setOrgnizations(response.data);
        setTotalRows(response.meta_data.count);
        setIsPageLoading((prev) => ({ ...prev, list: false }));
      } else {
        console.error("Invalid organizations response:", response);
      }
      setIsListFetched(false);
    } catch (error) {
      console.error("fetchOrgnizations error:", error.message);
    }
  };

  // Handle Row Click
  const handleOnRowClick = (params) =>
    navigate(`/manage/organizations/edit/${params.id}`);

  const onClickRowDelete = (e, data) => {
    e.stopPropagation(); // Remove row click effect
    setDialogType("DELETE");
    toggleDialog(true); // Open confirmation dialog
    setDeleteID(data?.id); // Set ID to delete
  };

  const handleOnDelete = async () => {
    setIsDeleting(true);

    try {
      await deleteOrganization(deleteID); // Call delete organization service

      setIncomingResponse({
        severity: "success",
        message: "Organization is deleted successfully",
      });

      setIsDeleting(false);
      toggleDialog(false);
      setDeleteID(null);
    } catch (error) {
      setIncomingResponse({
        severity: "error",
        message: error?.message,
      });

      setIsDeleting(false);
    }
  };

  // Handled export functionality
  const onClickExport = (e, data) => {
    setDialogType("EXPORT");
    toggleDialog(true);
  };

  const handleOnExport = (value) => {
    const type = value.id;
    const filename = `organizations-${new Date().toISOString()}`;
    setIsExporting(true);

    switch (type) {
      case "csv":
        exportToCSV(orgnizations, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      case "xlsx":
        exportToXLSX(orgnizations, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      default:
        console.log("Invalid export format/type");
        setIsExporting(false);
        break;
    }
  };

  const dialogOptions = {
    EXPORT: {
      onConfirm: handleOnExport,
      isLoading: isExporting,
    },
    DELETE: {
      onConfirm: handleOnDelete,
      isLoading: isDeleting,
    },
  };

  // Defining Columns
  const columns = [
    {
      field: "is_active",
      headerName: "",
      width: 40,
      align: "center",
      renderCell: (params) => (
        <span>
          {params.value ? (
            <configs.icons.CheckCircleIcon color="success" />
          ) : (
            <configs.icons.UnpublishedIcon color="error" />
          )}
        </span>
      ),
    },
    { field: "name", headerName: "Name", width: 150 },
    { field: "description", headerName: "Description", width: 250 },
    { field: "city", headerName: "City", width: 150 },
    { field: "state", headerName: "State", width: 150 },
    { field: "country", headerName: "Country", width: 150 },
    { field: "zipcode", headerName: "Zip Code", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          disabled={!userPermission?.organizations?.includes("DELETE")}
          onClick={(e) => onClickRowDelete(e, params)}
        >
          <configs.icons.Trash
            color={
              userPermission?.organizations?.includes("DELETE")
                ? "error"
                : "disabled"
            }
          />
        </IconButton>
      ),
    },
  ];

  if (isPageLoading.meta_data || isPageLoading.list) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <HeaderChip
              label={"Orgnizations | Summaries"}
              icon={
                <configs.icons.BusinessIcon sx={{ color: "#fff !important" }} />
              }
            />
          </Grid>

          <Grid container item xs={12} spacing={2}>
            {cardsList.map((card) => (
              <Grid sm={3} key={card.id} item>
                <DashboardCard
                  icon={card.icon}
                  label={card.label}
                  count={summaries && summaries[card.id]}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <AppSpacer vertical={"20px"} />

        <Grid container item spacing={2} alignItems="center">
          <Grid item xs={6}>
            <HeaderChip
              label={"Orgnizations | List"}
              icon={<configs.icons.List sx={{ color: "#fff !important" }} />}
            />
          </Grid>
          <Grid item xs={4}>
            <Searchbar
              placeholder={"Search..."}
              value={searchString}
              onChange={({ target: { value } }) => setSearchString(value)}
              onClear={() => setSearchString(null)}
            />
          </Grid>
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermission?.organizations?.includes("EXPORT")}
              onClick={onClickExport}
              primary
            >
              Export
            </MUIButton>
          </Grid>
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermission?.organizations?.includes("CREATE")}
              onClick={() => navigate("/manage/organizations/register")}
              primary
            >
              Create
            </MUIButton>
          </Grid>
          <Grid xs={12} item>
            <MUIGridTable
              loading={isListFetched}
              columns={columns}
              rows={orgnizations}
              rowCount={totalRows}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              onRowClick={
                userPermission?.organizations?.includes("READ")
                  ? handleOnRowClick
                  : null
              }
            />
          </Grid>
        </Grid>

        {/* Confirmation Dialog */}
        <Modal
          open={openDialog}
          onClose={() => (isDeleting ? {} : toggleDialog((e) => !e))}
        >
          <div className="dialog-container">
            <DialogsGroup
              type={dialogType}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={dialogOptions[dialogType]?.onConfirm}
              isLoading={dialogOptions[dialogType]?.isLoading}
            />
            {/* <ConfirmationDialog
              primaryLabel={"Delete"}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={handleOnDelete}
              isLoading={isDeleting}
            /> */}
          </div>
        </Modal>
      </div>
    );
  }
};

export default OrgnizationsDashboard;

//List of Cards
const cardsList = [
  {
    id: "all_count",
    icon: <configs.icons.BusinessIcon />,
    count: 0,
    label: "Orgnizations",
  },
  {
    id: "active_count",
    icon: <configs.icons.CheckCircleIcon />,
    count: 0,
    label: "Active ",
  },
  {
    id: "in_active_count",
    icon: <configs.icons.UnpublishedIcon />,
    count: 0,
    label: "In-Active ",
  },
  {
    id: "listed_in_7_days",
    icon: <configs.icons.SyncIcon />,
    count: 0,
    label: "Listed In Last 7 Days",
  },
];

// const DialogsGroup = ({ type, ...props }) => {
//   switch (type) {
//     case "EXPORT":
//       return <ExportDialog {...props} />;
//     case "DELETE":
//       return <ConfirmationDialog {...props} primaryLabel={"Delete"} />;
//     default:
//       return null;
//   }
// };
