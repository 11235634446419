import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUICard from "src/components/MUICard/MUICard.component";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import firstLetterCaps from "src/helpers/firstLetterCaps.helper";
import { createRealmsRole } from "src/services/roles.service";
import * as Yup from "yup";

/**
 *
 * @param {Object} props - Component props
 * @param {void} props.toggleOpen - Toggle modal
 * @param {void} props.onSuccessCallback - Modal success callback
 * @param {void} props.onErrorCallback - Modal error callback
 * @returns
 */
const CreateRole = (props) => {
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: schemaValidations,

    onSubmit: async (values) => {
      setIsCreating(true);
      const rawValues = values;
      const keycloakRole = convertToKeycloakRole(rawValues.name);

      const requestJSON = {};
      requestJSON.name = keycloakRole;
      requestJSON.description = rawValues.description;

      try {
        const response = await createRealmsRole(requestJSON);
        const roleId = response?.data?.name; // This gives created role's id
        navigate(`/configurations/roles/${roleId}`); // Redirect to role's details
        props?.onSuccessCallback({
          severity: "success",
          message: "Role is created successfully",
        });
        props?.toggleOpen(false);
      } catch (error) {
        const data = error?.response?.data || null;
        let message = "";

        if (data?.message === "Conflict") {
          message = "This role is already exists!";
        } else {
          message = error?.message;
        }

        props?.onErrorCallback({
          severity: "error",
          message,
        });
        console.error("Create realms role: ", error?.message);
      }

      setIsCreating(false);
    },
  });

  const requiredFields = {};
  Object.keys(schemaValidations.fields).forEach((field) => {
    requiredFields[field] = formik.touched[field] && formik.errors[field];
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      const namesArr = value.split(" ");
      const newNameArr = namesArr.map((n) => firstLetterCaps(n));
      const newValue = newNameArr.join(" ");
      formik.setFieldValue(name, newValue);
    } else {
      formik.handleChange(e);
    }
  };

  const inputComponentProps = {
    values: {
      name: formik?.values?.name,
      description: formik?.values?.description,
    },
    disabled: {
      name: isCreating,
      description: isCreating,
    },
    errors: {
      ...requiredFields,
    },
    helperTexts: {
      ...requiredFields,
    },
    onChanges: {
      onInputChange: handleInputChange,
    },
  };

  return (
    <div style={containerStyle}>
      <MUICard title={"Add Role"} style={{ width: "500px" }}>
        <Grid spacing={2} container>
          <InputComponent {...inputComponentProps} />
          <Grid xs={9} item></Grid>
          <Grid xs={3} item>
            <MUIButton
              onClick={formik.handleSubmit}
              isLoading={isCreating}
              isLoadingButton
              primary
            >
              Add
            </MUIButton>
          </Grid>
        </Grid>
      </MUICard>
    </div>
  );
};

export default CreateRole;

const convertToKeycloakRole = (roleName) => {
  const strings = roleName.split(" ");
  const lowercaseStrings = strings.map((s) => s.toLowerCase());
  return `asset_eye_${lowercaseStrings.join("_")}`;
};

const InputComponent = ({
  values,
  onChanges,
  errors,
  helperTexts,
  disabled,
  options,
}) => {
  return (
    <Grid xs={12} spacing={2} item container>
      {fields.map((field) => {
        const textfieldProps = {};

        textfieldProps.Type = field?.type || "text";
        textfieldProps.Name = field?.id || field?.name || "default";
        textfieldProps.Value = values && values[field?.id];
        textfieldProps.SelectOptions = options && options[field?.id];
        textfieldProps.OnChange = onChanges && onChanges?.onInputChange;
        textfieldProps.Error = errors && errors[field?.id];
        textfieldProps.helperText = helperTexts && helperTexts[field?.id];
        textfieldProps.Disabled = disabled && disabled[field?.id];
        textfieldProps.Select = field.type === "select" ? true : false;

        return (
          <Grid xs={12} item>
            <MUIInputLabel>{field.label}</MUIInputLabel>
            <MUITextField {...textfieldProps} />
          </Grid>
        );
      })}
    </Grid>
  );
};

const containerStyle = {
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}; // Main container style

const fields = [
  {
    id: "name",
    name: "name",
    label: "Role Name",
    isRequired: true,
    type: "text",
    isMultiline: false,
  },
  {
    id: "description",
    name: "description",
    label: "Description",
    isRequired: false,
    type: "text",
    isMultiline: true,
  },
];

const schemaValidations = Yup.object().shape({
  //   organization_id: Yup.object().required("Organization is required!"),
  name: Yup.string().required("Role name is required!"),
});
