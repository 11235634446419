/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/
//
import PublishIcon from "@mui/icons-material/Publish";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import PeopleIcon from "@mui/icons-material/People";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import ListIcon from "@mui/icons-material/List";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DiamondIcon from "@mui/icons-material/Diamond";
import SettingsIcon from "@mui/icons-material/Settings";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import SyncIcon from "@mui/icons-material/Sync";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BusinessIcon from "@mui/icons-material/Business";
import DescriptionIcon from "@mui/icons-material/Description";
import LayersIcon from "@mui/icons-material/Layers";
import PersonIcon from "@mui/icons-material/Person";
import { PersonPinCircle } from "@mui/icons-material";
import HandshakeIcon from "@mui/icons-material/Handshake";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EditIcon from "@mui/icons-material/Edit";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WarningIcon from "@mui/icons-material/Warning";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import SystemUpdateAltRoundedIcon from "@mui/icons-material/SystemUpdateAltRounded";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BuildSharpIcon from "@mui/icons-material/BuildSharp";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import AddIcon from "@mui/icons-material/Add";
import HandshakeRoundedIcon from "@mui/icons-material/HandshakeRounded";

const icons = {
  UpArrow: PublishIcon,
  DownArrow: DownloadIcon,
  ViewActivities: VisibilityIcon,
  Close: CloseIcon,
  CustomersIcon: PeopleIcon,
  RupeeIcon: CurrencyRupeeIcon,
  InterestIcon: AutorenewIcon,
  Trash: DeleteIcon,
  Cross: CancelIcon,
  Invoice: ReceiptIcon,
  AddCustomer: PersonAddIcon,
  AddLoanApplication: RequestQuoteIcon,
  Dashboard: SpaceDashboardIcon,
  List: ListIcon,
  PDFIcon: PictureAsPdfIcon,
  Products: DiamondIcon,
  SettingsIcon,
  MoveToInboxIcon,
  NotificationsIcon,
  SearchIcon,
  MenuIcon,
  PersonIcon,
  BulletMenuIcon: MoreHorizIcon,
  SyncDisabledIcon,
  SyncIcon,
  ExpandMoreIcon,
  BusinessIcon,
  PeopleIcon,
  DescriptionIcon,
  LayersIcon,
  PersonPinCircle,
  HandshakeIcon,
  CheckCircleIcon,
  UnpublishedIcon,
  LocationOnIcon,
  EditIcon,
  DomainDisabledIcon,
  PersonOffIcon,
  SummarizeIcon,
  ShoppingCartIcon,
  AddShoppingCartIcon,
  RemoveShoppingCartIcon,
  PhotoFilterIcon,
  AddCircleIcon,
  WarningIcon,
  WarningRoundedIcon,
  ExportRoundedIcon: SystemUpdateAltRoundedIcon,
  UploadFileRoundedIcon,
  NoteAddRoundedIcon,
  CloudUploadIcon,
  CloudDownloadIcon,
  AttachFileRoundedIcon,
  ScheduleIcon,
  BuildSharpIcon,
  AnalyticsIcon,
  SettingsAccessibilityIcon,
  AddIcon,
  HandshakeRoundedIcon,
};

export default icons;
