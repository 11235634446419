import axios from "axios";
import authInstance from "../keycloak/keycloak.utils";

const api_version = process.env.REACT_APP_API_VERSION;
const API_URL = `${process.env.REACT_APP_API_URL}/${api_version}`;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use(async (config) => {
  try {
    if (authInstance.isTokenExpired(30)) {
      console.log("Token is expired!");
      console.log("Refreshing token ...");
      await authInstance.updateToken(30);
      console.log("Token is refreshed.");

      config.headers.Authorization = `Bearer ${authInstance?.token}`;
      return config;
    } else {
      console.log("Token is still valid.");

      config.headers.Authorization = `Bearer ${authInstance.token}`;
      return config;
    }
  } catch (error) {
    console.error("Interceptor's request error: ", error.message);
  }
});

axiosApi.interceptors.response.use(
  (response) => response,
  async (err) => {
    const { config, response } = err;
    const { status, statusText } = response;
    if (status === (401 || 403) && statusText === "Unauthorized") {
      console.log("Refreshing token ...");

      await authInstance.updateToken(30);
      console.log("Token is refreshed.");

      config.headers.Authorization = `Bearer ${authInstance?.token}`;

      return axios(config);
    } else {
      return Promise.reject(err);
    }
  }
);

// http GET
/**
 *
 * @param {string} url - End URLs (exclude Base URL)
 * @param {object} config - Axios Configuration
 * @returns
 */
export const get = async (url, config = {}) => {
  try {
    const res = await axiosApi.get(url, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

// http POST
/**
 *
 * @param {string} url - End URLs (exclude Base URL)
 * @param {object} body - Request Body, Data.
 * @param {object} config - Axios Configuration
 * @returns
 */
export const post = async (url, body, config = {}) => {
  try {
    const res = await axiosApi.post(url, body, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

// http PUT
/**
 *
 * @param {string} url - End URLs (exclude Base URL)
 * @param {object} body - Request Body, Data.
 * @param {object} config - Axios Configuration
 * @returns
 */
export const put = async (url, body, config = {}) => {
  try {
    const res = await axiosApi.put(url, body, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

// http DELETE
/**
 *
 * @param {string} url - End URLs (exclude Base URL)
 * @param {object} config - Axios Configuration
 * @returns
 */
export const remove = async (url, config = {}) => {
  try {
    const res = await axiosApi.delete(url, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};
