import moment from "moment";

export const parseOutgoingJSON = (data) => {
  const incomingJson = data;
  const incomingJsonkeys = Object.keys(incomingJson);
  const incomingJsonValues = Object.values(incomingJson);
  const outgoingJSON = {};

  const parseIncomingJsonValues = incomingJsonValues.map((value, index) => {
    if (value && typeof value === "object" && !Array.isArray(value)) {
      if (["country", "state"].includes(incomingJsonkeys[index]))
        return value.value;
      else return value.id;
    } else return value;
  });

  incomingJsonkeys.forEach((key, index) => {
    outgoingJSON[key] = parseIncomingJsonValues[index];
  });

  return outgoingJSON;
};

export const parseIncomingJSON = (JSON) => {
  const incomingJSON = JSON;
  const incomingJSONkeys = Object.keys(incomingJSON);
  const incomingJSONValues = Object.values(incomingJSON);
  const outgoingJSON = {};

  const parseIncomingJsonValues = incomingJSONValues.map((value, index) => {
    if (value && typeof value === "object" && !Array.isArray(value))
      return {
        id: value.id,
        label: value.name,
        value: value.id,
        name: `${incomingJSONkeys[index]}_id`,
      };
    else return value;
  });

  incomingJSONkeys.forEach((key, index) => {
    let value = parseIncomingJsonValues[index];

    if (value && typeof value === "object" && !Array.isArray(value))
      outgoingJSON[value?.name] = value;
    else {
      if (value && isIsoDate(value)) {
        outgoingJSON[key] = moment(value).format("YYYY-MM-DD");
      } else outgoingJSON[key] = value;
    }
  });

  // return outgoingJSON;
  // console.log(outgoingJSON);

  return outgoingJSON;
};

function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
}
