import MUICard from "src/components/MUICard/MUICard.component";
import "./CreateCustomForm.style.css";
import CardTitle from "src/components/CardTitle/CardTitle.component";
import configs from "src/configs/configs";
import * as Yup from "yup";

import {
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import MUIAutocomplete from "src/components/MUIAutocomplete/MUIAutocomplete.component";
import { useFormik } from "formik";
import firstLetterCaps from "src/helpers/firstLetterCaps.helper";
import { useEffect, useState } from "react";
import FormActions from "src/pages/ManageOrganizations/RegisterOrganization/components/FormActions/FormActions.component";
import useGetOrganizations from "src/hooks/useGetOrganizations.hook";
import useConstants from "src/hooks/useConstants.hook";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUISwitchButton from "src/components/MUISwitchButton/MUISwitchButton.component";
import {
  parseIncomingJSON,
  parseOutgoingJSON,
} from "src/helpers/manageRequestJSON.helper";
import {
  createCustomForm,
  updateCustomForm,
} from "src/services/customForms.services";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import { useParams } from "react-router-dom";
import useGetCustomFormByID from "src/hooks/manageCustomForms/useGetCustomFormByID.hook";
import { useSelector } from "react-redux";

const CreateCustomForm = () => {
  const givenOrganization = {
    organization_id: null,
  }; // Provides given initial value

  const currentUser = useSelector((state) => state.auth.currentUser);
  const userRole = currentUser?.role || null;
  const organizationID = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions || [];

  if (organizationID) {
    givenOrganization.organization_id = {
      id: organizationID,
      label: "",
      value: organizationID,
    };
  } // Implementing pre-defined organization value

  const params = useParams();
  const customFormId = params.id;

  const constants = useConstants();
  const [flags, setFlags] = useState(null);
  const [searchStrings, setSearchStrings] = useState(null);
  const [openModal, toggleOpenModal] = useState(false);
  const [incomingResponse, setIncomingResponse] = useState(null);

  const [organizations, isOrganizationsLoading] = useGetOrganizations(
    searchStrings?.organization_id
  );
  const [customForm] = useGetCustomFormByID(customFormId);

  updateFieldsConfig("organization_id", {
    options: organizations,
    isLoading: isOrganizationsLoading,
  });

  updateFieldsConfig("type", {
    options: constants["form_type"],
  });

  const formik = useFormik({
    initialValues: { ...initialValues, ...givenOrganization },
    validationSchema,
    onSubmit: async (values) => {
      setFlags((prev) => ({ ...prev, isFormSubmit: true }));
      const rawJSON = values;
      const parseRawJSON = parseOutgoingJSON(rawJSON);
      const filterFields = parseRawJSON.fields.map(({ id, ...field }) => field);
      const filterNewFields = parseRawJSON.newFields.map(
        ({ id, ...field }) => field
      );

      // parseRawJSON.type = parseRawJSON.type;
      parseRawJSON.fields = filterFields;
      parseRawJSON.newFields = filterNewFields;

      try {
        customFormId
          ? await updateCustomForm(customFormId, parseRawJSON)
          : await createCustomForm(parseRawJSON);

        setIncomingResponse({
          severity: "success",
          message: `Custom form created successfully!`,
        });
        setFlags((prev) => ({ ...prev, isFormSubmit: false }));
        if (!customFormId) formik.handleReset();
      } catch (error) {
        const message = error?.response?.data?.message || error.message;
        setIncomingResponse({
          severity: "error",
          message,
        });
        setFlags((prev) => ({ ...prev, isFormSubmit: false }));
      }
    },
  });
  //handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);

    if (name === "name") {
      const namesArr = value.split(" ");
      const newNameArr = namesArr.map((n) => firstLetterCaps(n));

      e.target.value = newNameArr.join(" ");
    }

    formik.handleChange(e);
  };

  const handleAutocompleteChange = (e, value) => {
    if (value) {
      formik.handleChange({ target: { name: value.name, value } });
    } else {
      const { name, value } = e.target;
      setSearchStrings((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleModalToggle = () => toggleOpenModal((e) => !e);

  const handleCallback = (value) => {
    formik.setValues((prev) => ({
      ...prev,
      fields: [...prev.fields, value],
      newFields: [...prev?.newFields, value],
    }));

    handleModalToggle();
  };

  useEffect(() => {
    if (customForm) {
      const rawJSON = parseIncomingJSON(customForm);
      formik.setValues((prev) => ({
        ...prev,
        ...rawJSON,
      }));
    }
  }, [customForm]);

  return (
    <div className="create-custom-form-container">
      <MUISnackbar
        severity={incomingResponse?.severity}
        message={incomingResponse?.message}
        open={incomingResponse ? true : false}
        onClose={() => setIncomingResponse(null)}
        vertical={"top"}
        horizontal={"right"}
      />
      <MUICard title={<TitleComponent />}>
        <Grid spacing={2} container>
          {fields.map((field) => (
            <Grid md={field.col.md} key={field.id} item>
              {field.id === "organization_id" && userRole !== "owner" ? null : (
                <MUIInputLabel>
                  {field.isRequired && <span style={{ color: "red" }}>*</span>}
                  {field?.label}
                </MUIInputLabel>
              )}
              {field?.type === "autocomplete" ? (
                field.id === "organization_id" &&
                userRole !== "asset_eye_owner" ? null : (
                  <MUIAutocomplete
                    loading={field?.isLoading}
                    name={field.id}
                    value={formik.values[field.id]}
                    options={field?.options}
                    onChange={handleAutocompleteChange}
                    error={
                      formik?.touched &&
                      formik?.touched[field.id] &&
                      formik?.errors &&
                      formik?.errors[field.id]
                    }
                    helperText={
                      formik?.touched &&
                      formik?.touched[field.id] &&
                      formik?.errors &&
                      formik?.errors[field.id]
                    }
                    disabled={flags?.isFormSubmit}
                    fullWidth
                  />
                )
              ) : (
                <MUITextField
                  Name={field.id}
                  Type={field.type}
                  Value={formik.values[field.id]}
                  OnChange={handleInputChange}
                  Error={
                    formik?.touched &&
                    formik?.touched[field.id] &&
                    formik?.errors &&
                    formik?.errors[field.id]
                  }
                  helperText={
                    formik?.touched &&
                    formik?.touched[field.id] &&
                    formik?.errors &&
                    formik?.errors[field.id]
                  }
                  Select={field.type === "select"}
                  SelectOptions={field?.options}
                  Disabled={
                    customFormId
                      ? field?.id === "type"
                        ? true
                        : flags?.isFormSubmit
                      : flags?.isFormSubmit
                  }
                  fullWidth
                />
              )}
            </Grid>
          ))}
        </Grid>
      </MUICard>
      <MUICard
        padding={0}
        title={"Fields"}
        action={
          <MUIButton onClick={handleModalToggle} primary>
            Add Field
          </MUIButton>
        }
      >
        {formik?.errors && formik?.errors["fields"] ? (
          <Typography variant="body1" sx={{ padding: 2, color: "red" }}>
            {formik?.errors["fields"]}
          </Typography>
        ) : null}

        {formik?.values?.fields.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "60%" }}>Labels</TableCell>
                <TableCell style={{ width: "20%" }}>Types</TableCell>
                <TableCell style={{ width: "20%" }}>Is Required?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formik?.values?.fields?.map((field) => (
                <TableRow key={field?.id}>
                  <TableCell style={{ width: "60%" }}>{field?.label}</TableCell>
                  <TableCell style={{ width: "20%" }}>{field?.type}</TableCell>
                  <TableCell style={{ width: "20%" }}>
                    {field?.is_required ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </MUICard>
      <FormActions
        formik={formik}
        isLoading={flags?.isFormSubmit}
        submitLabel={"Create"}
      />
      <Modal open={openModal} onClose={handleModalToggle}>
        <div className="create-field-container">
          <CreateField
            callback={handleCallback}
            types={constants["input_type"]}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateCustomForm;

// Components:
const TitleComponent = ({ isUpdate }) => (
  <CardTitle
    title={`${isUpdate ? "Edit" : "Create"}  Custom Form`}
    icon={
      <configs.icons.SummarizeIcon sx={{ color: configs.colors.primary }} />
    }
  />
);

var fields = [
  {
    id: "organization_id",
    name: "organization_id",
    label: "Organization",
    type: "autocomplete",
    isRequired: true,
    col: {
      md: 12,
    },
  },
  {
    id: "name",
    name: "name",
    label: "Name",
    isRequired: true,
    type: "text",
    col: {
      md: 8,
    },
  },
  {
    id: "type",
    name: "type",
    label: "Type",
    isRequired: true,
    type: "select",
    col: {
      md: 4,
    },
  },
  {
    id: "description",
    name: "description",
    label: "Description",
    type: "text",
    col: {
      md: 12,
    },
  },
];

const initialValues = {
  organization_id: null,
  name: "",
  type: "",
  description: "",
  fields: [],
  newFields: [],
};

const validationSchema = Yup.object().shape({
  organization_id: Yup.object().required("Organization is required!"),
  name: Yup.string().required("Name is required!"),
  type: Yup.string().required("Type is required!"),
  fields: Yup.array()
    .min(1, "Please add atleast 1 field!")
    .required("Fields are required!"),
});

const updateFieldsConfig = (id, config) => {
  const prev = fields;

  fields = prev?.map((field) => {
    if (field?.id === id)
      return {
        ...field,
        ...config,
      };
    else return field;
  });
};

const CreateField = ({ types, callback = () => {} }) => {
  const formik = useFormik({
    initialValues: {
      id: new Date().getTime(),
      label: "",
      type: "",
      is_required: false,
    },
    validationSchema: Yup.object().shape({
      label: Yup.string().required("Label is required!"),
      type: Yup.string().required("Type is required!"),
    }),
    onSubmit: (values) => {
      callback(values);
      formik.handleReset();
    },
  });

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "label") {
      const namesArr = value.split(" ");
      const newNameArr = namesArr.map((n) => firstLetterCaps(n));

      e.target.value = newNameArr.join(" ");
    }

    formik.handleChange({
      target: {
        name,
        value: name === "is_required" ? checked : e.target.value,
      },
    });
  };

  return (
    <div className="create-field-body">
      <MUICard title={"Create Field"}>
        <Grid spacing={2} container>
          <Grid sm={12} item>
            <MUIInputLabel>Label</MUIInputLabel>
            <MUITextField
              Name="label"
              Value={formik.values["label"]}
              OnChange={handleChange}
              Error={formik?.errors && formik?.errors["label"]}
              helperText={formik?.errors && formik?.errors["label"]}
            />
          </Grid>
          <Grid sm={6} item>
            <MUIInputLabel>Type</MUIInputLabel>
            <MUITextField
              Name="type"
              Value={formik.values["type"]}
              OnChange={handleChange}
              SelectOptions={types}
              Select
              Error={formik?.errors && formik?.errors["type"]}
              helperText={formik?.errors && formik?.errors["type"]}
            />
          </Grid>
          <Grid sm={6} item>
            <MUIInputLabel>Is Required?</MUIInputLabel>
            <MUISwitchButton
              name="is_required"
              is_active={formik.values["is_required"]}
              onChange={handleChange}
            />
          </Grid>
          <Grid sm={8} item></Grid>
          <Grid sm={4} item>
            <MUIButton onClick={formik.handleSubmit} primary>
              Create
            </MUIButton>
          </Grid>
        </Grid>
      </MUICard>
    </div>
  );
};
