import { useSelector } from "react-redux";

const { useState, useEffect } = require("react");
const { getCustomers } = require("src/services/customers.services");

const useGetCustomers = (searchText, organizationId) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationId = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [customers, setCustomers] = useState([]);
  const [isCustomersLoading, setIsCustomersLoading] = useState(false);

  const searchKeyword = searchText ? searchText : "";

  // Function to fetch customer:
  const fetchCustomers = async () => {
    try {
      setIsCustomersLoading(true);
      const resp = await getCustomers({ searchKeyword, organizationId });

      // Converting response into autocomplete options:
      const autocompleteOptions = resp?.data?.map((customer) => ({
        id: customer.id,
        label: customer.name,
        value: customer.name,
        name: "customer_id",
      }));

      setCustomers(autocompleteOptions);
      setIsCustomersLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsCustomersLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      if (!searchText) fetchCustomers();
      if (searchText && searchText.length > 3) fetchCustomers();
    }
  }, [searchText, organizationId]);

  return [customers, isCustomersLoading];
};

export default useGetCustomers;
