import React from "react";
import { Grid, Typography } from "@mui/material";
import MUIInputLabel from "../MUIInputLabel/MUIInputLabel.component";
import MUICard from "src/components/MUICard/MUICard.component";

const CustomFields = ({ customForms }) => {
  return (
    <>
      {customForms.map((form) => (
        <MUICard
          key={form.id}
          title={
            <TitleComponent title={form.title || "Additional Information"} />
          }
        >
          {form.fields.map((field) => (
            <Grid container spacing={2} key={field.id}>
              <Grid item xs={12} sm={4} mt={2}>
                <Typography variant="body1">
                  <Grid>
                    <MUIInputLabel>{field.label}</MUIInputLabel>{" "}
                    {field.value || "-"}
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
          ))}
        </MUICard>
      ))}
    </>
  );
};

export default CustomFields;

function TitleComponent({ title }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {title}
    </div>
  );
}
