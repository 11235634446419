import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAssetOwners } from "src/services/assetOwner.services";

const useGetAssetOwners = (organizationId) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationID = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [assetOwners, setAssetOwners] = useState([]);
  const [isAssetOwnersLoading, setIsAssetOwnersLoading] = useState(false);

  useEffect(() => {
    if (organizationId) {
      setIsAssetOwnersLoading(true);
      getAssetOwners({ organizationId })
        .then((res) => {
          const { data } = res;
          // Converting response into autocomplete options:
          const autocompleteOptions = data?.map((asset_owner) => ({
            id: asset_owner.id,
            label: asset_owner.name,
            value: asset_owner.id,
            name: "asset_owner_id",
          }));
          setAssetOwners(autocompleteOptions);
          setIsAssetOwnersLoading(false);
        })
        .catch((error) => {
          console.log("useGetAssetOwners: ", error.message);
          setIsAssetOwnersLoading(false);
        });
    }
  }, [organizationId]);

  return [
    assetOwners,
    setAssetOwners,
    isAssetOwnersLoading,
    setIsAssetOwnersLoading,
  ];
};
export default useGetAssetOwners;
