import "./DashboardCardV2.style.css";
import { Avatar, Grid, Typography } from "@mui/material";
import MUICard from "../MUICard/MUICard.component";
import configs from "src/configs/configs";

const DashboardCardV2 = ({ label, icon, value }) => {
  return (
    <div>
      <MUICard className={"parent-card-container-v2"} >
        <Grid
          className="card-container-v2"
          alignContent={"space-between"}
          container
        >
          <Grid sm={12} item>
            <Typography variant="body1" fontWeight={"bold"}>
              {label}
            </Typography>
          </Grid>
          <Grid sm={12} alignItems={"center"} item container>
            <Grid sm={4} item>
              <div className="icon-container-v2">
                <Avatar
                  variant="rounded"
                  style={{ backgroundColor: configs.colors.primary }}
                >
                  {icon}
                </Avatar>
              </div>
            </Grid>
            <Grid sm={8} item>
              <div className="value-container-v2">
                <Typography variant="h5" fontWeight={"bold"}>
                  {value}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </MUICard>
    </div>
  );
};

export default DashboardCardV2;
