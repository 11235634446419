import { get, post, put } from "../utils/axios/axios.utils";
const path = "/hardware-models";

export const getHardwareModels = async (params) => {
  try {
    const response = await get(`${path}`, {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

/**
 *
 * @param {object} data
 * @returns
 */
export const createHardwareModel = async (data) => {
  try {
    const response = await post(`${path}`, data);
    return response;
  } catch (error) {
    console.error("createHardwareModel:", error);
    throw error;
  }
};
