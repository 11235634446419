import { Grid, IconButton, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import DashboardCard from "src/components/DashboardCard/DashboardCard.component";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUIGridTable from "src/components/MUIGridTable/MUIGridTable.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import configs from "src/configs/configs";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import ExportToolbar from "src/components/MUIExport/ExportToolbar";
import ConfirmationDialog from "src/components/ConfirmationDialog/ConfirmationDialog.component";
import {
  getCustomers,
  getCustomersMetaData,
  deleteCustomer,
} from "src/services/customers.services";
import Searchbar from "src/components/Searchbar/Searchbar.component";
import DialogsGroup from "src/components/DialogsGroup/DialogsGroup.component";
import { exportToCSV, exportToXLSX } from "src/helpers/exportData.helper";
const CustomersDashboard = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const organizationId = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions["customers"] || [];

  const navigate = useNavigate();

  const [isPageLoading, setIsPageLoading] = useState({
    meta_data: true,
    list: true,
  });
  const [isListFetched, setIsListFetched] = useState(false);
  const [summaries, setSummaries] = useState({
    all_count: 0,
    active_count: 0,
    in_active_count: 0,
    listed_in_7_days: 0,
  });
  const [customers, setCustomers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [incomingResponse, setIncomingResponse] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [searchString, setSearchString] = useState(null);

  const [openDialog, toggleDialog] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [dialogType, setDialogType] = useState(null);

  // get customers meta data:
  const fetchCustomersMetaData = async () => {
    try {
      const response = await getCustomersMetaData({ organizationId });
      if (response && response.count !== undefined) {
        setSummaries({
          all_count: response.count,
          active_count: response.active,
          in_active_count: response.in_active,
          listed_in_7_days: response.registered_within_7_Days,
        });
        setIsPageLoading((prev) => ({ ...prev, meta_data: false }));
      } else {
        // Handle unexpected response format
        console.log("Invalid metadata response:", response);
      }
    } catch (error) {
      console.log("fetchCustomersMetaData error: ", error.message);
    }
  };

  useEffect(() => {
    if (!isDeleting) fetchCustomersMetaData();
  }, [isDeleting]);

  useEffect(() => {
    if (!isDeleting) {
      if (!searchString) fetchCustomers();
      if (searchString && searchString.length > 3) fetchCustomers();
    }
  }, [paginationModel, searchString, isDeleting]);

  //
  const fetchCustomers = async () => {
    try {
      setIsListFetched(true);
      const response = await getCustomers({
        pageNumber: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        searchKeyword: searchString,
        organizationId,
      });

      const { data, meta_data } = response;

      setCustomers(data);
      setTotalRows(meta_data.count);
      setIsPageLoading((prev) => ({ ...prev, list: false }));
      setIsListFetched(false);
    } catch (error) {
      console.log("fetchCustomers error: ", error.message);
    }
  };

  // A function to handle table row click:
  const handleOnRowClick = (params) => {
    // if (userPermissions.includes("read_customers")) // Removing temp.
    navigate(`/manage/customers/edit/${params.id}`);
  };

  const onClickRowDelete = (e, data) => {
    e.stopPropagation(); // Remove row click effect
    setDialogType("DELETE");
    toggleDialog(true); // Open confirmation dialog
    setDeleteID(data?.id); // Set ID to delete
  };

  const handleOnDelete = async () => {
    setIsDeleting(true);

    try {
      await deleteCustomer(deleteID); // Call delete organization service

      setIncomingResponse({
        severity: "success",
        message: "Customer is deleted successfully",
      });

      setIsDeleting(false);
      toggleDialog(false);
      setDeleteID(null);
    } catch (error) {
      setIncomingResponse({
        severity: "error",
        message: error?.message,
      });

      setIsDeleting(false);
    }
  };

  // Handled export functionality
  const onClickExport = (e, data) => {
    setDialogType("EXPORT");
    toggleDialog(true);
  };

  const handleOnExport = (value) => {
    const type = value.id;
    const filename = `customers-${new Date().toISOString()}`;
    setIsExporting(true);

    switch (type) {
      case "csv":
        exportToCSV(customers, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      case "xlsx":
        exportToXLSX(customers, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      default:
        console.log("Invalid export format/type");
        setIsExporting(false);
        break;
    }
  };

  const dialogOptions = {
    EXPORT: {
      onConfirm: handleOnExport,
      isLoading: isExporting,
    },
    DELETE: {
      onConfirm: handleOnDelete,
      isLoading: isDeleting,
    },
  };

  //Defining columns
  const columns = [
    {
      field: "is_active",
      headerName: "",
      width: 40,
      align: "center",
      renderCell: (params) => (
        <span>
          {params.value ? (
            <configs.icons.CheckCircleIcon color="success" />
          ) : (
            <configs.icons.UnpublishedIcon color="error" />
          )}
        </span>
      ),
    },
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "organization",
      headerName: "Organization",
      width: 300,
      renderCell: (params) => {
        return <span>{params.value.name}</span>;
      },
    },
    { field: "description", headerName: "Description", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "address",
      headerName: "Address",
      width: 300,

      renderCell: (params) => (
        <span style={{ whiteSpace: "pre-wrap" }}>
          {params.row.street_address_1}, {params.row.street_address_2},{" "}
          {params.row.city}, {params.row.state}, {params.row.country},{" "}
          {params.row.zipcode}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          disabled={!userPermissions?.includes("DELETE")}
          onClick={(e) => onClickRowDelete(e, params)}
        >
          <configs.icons.Trash
            color={userPermissions?.includes("DELETE") ? "error" : "disabled"}
          />
        </IconButton>
      ),
    },
  ];

  if (isPageLoading.meta_data || isPageLoading.list) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <Grid alignItems={"center"} spacing={2} container>
          <Grid xs={12} item>
            <HeaderChip
              label={"Customers | Summaries"}
              icon={
                <configs.icons.PeopleIcon
                  sx={{ color: "#fff" + "!important" }}
                />
              }
            />
          </Grid>
          <Grid xs={12} item>
            <Grid spacing={2} container>
              {cardsList.map((card) => (
                <Grid sm={3} key={card.id} item>
                  <DashboardCard
                    icon={card.icon}
                    label={card.label}
                    count={summaries && summaries[card.id]}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <AppSpacer vertical={"20px"} />

        <Grid alignItems={"center"} spacing={2} container>
          <Grid xs={6} item>
            <HeaderChip
              label={"Customers| List"}
              icon={
                <configs.icons.List sx={{ color: "#fff" + "!important" }} />
              }
            />
          </Grid>
          {/* {userPermissions.includes("create_customers") ? null : (
            <Grid item xs={2} />
          )} */}
          <Grid xs={4} item>
            <Searchbar
              placeholder={"Search..."}
              value={searchString}
              onChange={({ target: { value } }) => setSearchString(value)}
              onClear={() => setSearchString(null)}
            />
          </Grid>
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermissions?.includes("EXPORT")}
              onClick={onClickExport}
              primary
            >
              Export
            </MUIButton>
          </Grid>
          {/* {userPermissions.includes("create_customers") ? (
            <Grid xs={1} item>
              <MUIButton
                onClick={() => navigate("/manage/customers/create")}
                primary
              >
                Create
              </MUIButton>
            </Grid>
          ) : null} */}
          <Grid xs={1} item>
            <MUIButton
              onClick={() => navigate("/manage/customers/create")}
              primary
              disabled={!userPermissions?.includes("CREATE")}
            >
              Create
            </MUIButton>
          </Grid>
          <Grid xs={12} item>
            <MUIGridTable
              loading={isListFetched}
              columns={columns}
              rows={customers}
              rowCount={totalRows}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              onRowClick={
                userPermissions.includes("READ") ? handleOnRowClick : null
              }
            />
          </Grid>
        </Grid>

        {/* Confirmation Dialog */}
        <Modal
          open={openDialog}
          onClose={() => (isDeleting ? {} : toggleDialog((e) => !e))}
        >
          <div className="dialog-container">
            <DialogsGroup
              type={dialogType}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={dialogOptions[dialogType]?.onConfirm}
              isLoading={dialogOptions[dialogType]?.isLoading}
            />
            {/* <ConfirmationDialog
              primaryLabel={"Delete"}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={handleOnDelete}
              isLoading={isDeleting}
            /> */}
          </div>
        </Modal>
      </div>
    );
  }
};

export default CustomersDashboard;

const cardsList = [
  {
    id: "all_count",
    icon: <configs.icons.PeopleIcon />,
    count: 0,
    label: "Customers",
  },
  {
    id: "active_count",
    icon: <configs.icons.CheckCircleIcon />,
    count: 0,
    label: "Active Customers",
  },
  {
    id: "in_active_count",
    icon: <configs.icons.UnpublishedIcon />,
    count: 0,
    label: "In-Active Customers",
  },
  {
    id: "listed_in_7_days",
    icon: <configs.icons.SyncIcon />,
    count: 0,
    label: "Listed In Last 7 Days",
  },
];
