/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/
//
import { MenuItem, TextField } from "@mui/material";
import configs from "src/configs/configs";

const MUITextField = ({
  OnChange,
  Type,
  Label,
  PlaceHolder,
  Size,
  Value,
  Id,
  Error,
  OnBlur,
  IsMultiLine,
  Name,
  helperText,
  MaxRows,
  Disabled,
  Select,
  style,
  InputProps,
  SelectOptions = [],
  autoFocus,
}) => {
  const defaultInputProps = {
    sx: {
      borderRadius: 2,
      "&.MuiOutlinedInput-root": {
        " &.Mui-focused fieldset": {
          borderColor: Error ? "red" : configs.colors.primary,
        },
      },
    },
  };
  return (
    <div>
      {!Select ? (
        <TextField
          name={Name}
          InputProps={
            InputProps
              ? { ...defaultInputProps, ...InputProps }
              : defaultInputProps
          }
          autoComplete="off"
          type={Type}
          label={Label}
          placeholder={PlaceHolder}
          id={Id ? Id : "my-id"}
          size={Size ? Size : "small"}
          value={Value ? Value : ""}
          fullWidth
          disabled={Disabled ? Disabled : false}
          onChange={OnChange}
          onBlur={OnBlur}
          error={Error ? true : false}
          multiline={IsMultiLine}
          maxRows={MaxRows ? MaxRows : 4}
          helperText={helperText}
          style={style}
          autoFocus={autoFocus}
        />
      ) : (
        <TextField
          InputProps={InputProps ? InputProps : defaultInputProps}
          select={Select}
          name={Name}
          type={Type}
          label={Label}
          placeholder={PlaceHolder}
          id={Id ? Id : "my-id"}
          size={Size ? Size : "small"}
          value={Value ? Value : ""}
          fullWidth
          disabled={Disabled ? Disabled : false}
          onChange={OnChange}
          onBlur={OnBlur}
          error={Error ? true : false}
          multiline={IsMultiLine}
          maxRows={MaxRows ? MaxRows : 4}
          helperText={helperText}
          style={style}
        >
          {SelectOptions.map((type, index) => (
            <MenuItem key={index} value={type.value} id={type.id}>
              {type.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </div>
  );
};

export default MUITextField;
