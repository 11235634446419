import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Modal,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import DashboardCard from "src/components/DashboardCard/DashboardCard.component";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import { DataGrid } from "@mui/x-data-grid";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import configs from "src/configs/configs";
import colors from "src/configs/colors.config";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";

import {
  getAssets,
  getAssetsMetaData,
  deleteAsset,
  importAssets,
} from "src/services/assets.services";
import ExportToolbar from "src/components/MUIExport/ExportToolbar";
import MUIGridTable from "src/components/MUIGridTable/MUIGridTable.component";
import { useSelector } from "react-redux";
import ConfirmationDialog from "src/components/ConfirmationDialog/ConfirmationDialog.component";
import Searchbar from "src/components/Searchbar/Searchbar.component";
import DialogsGroup from "src/components/DialogsGroup/DialogsGroup.component";
import { exportToCSV, exportToXLSX } from "src/helpers/exportData.helper";

const AssetsDashboard = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const organizationId = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions["assets"] || [];

  const [isPageLoading, setIsPageLoading] = useState({
    meta_data: true,
    list: true,
  });
  const [isListFetched, setIsListFetched] = useState(false);
  const [summaries, setSummaries] = useState({
    all_count: 0,
    active_count: 0,
    in_active_count: 0,
    listed_in_7_days: 0,
  });
  const [assets, setAssets] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [incomingResponse, setIncomingResponse] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [searchString, setSearchString] = useState(null);

  /* Define states to handle delete dialog */
  const [openDialog, toggleDialog] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const [dialogType, setDialogType] = useState(null);

  // get customers meta data:
  const fetchAssetsMetaData = async () => {
    try {
      // console.log("Fetching metadata...");
      const response = await getAssetsMetaData({ organizationId });
      // console.log("Metadata response:", response);
      setSummaries({
        all_count: response.count,
        active_count: response.active,
        in_active_count: response.in_active,
        listed_in_7_days: response.registered_within_7_Days,
      });
      setIsPageLoading((prev) => ({ ...prev, meta_data: false }));
    } catch (error) {
      console.error("fetchAssetsMetaData error:", error.message);
    }
  };

  useEffect(() => {
    if (!isDeleting || !isImporting) fetchAssetsMetaData();
  }, [isDeleting, isImporting]);

  useEffect(() => {
    if (!isDeleting || !isImporting) {
      if (!searchString) fetchAssets();
      if (searchString && searchString.length > 3) fetchAssets();
    }
  }, [paginationModel, searchString, isDeleting, isImporting]);

  const fetchAssets = async () => {
    try {
      setIsListFetched(true);
      const response = await getAssets({
        pageNumber: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        searchKeyword: searchString,
        organizationId,
      });
      // console.log("Assets response:", response);

      if (response && response.data && response.meta_data) {
        setAssets(response.data);
        setTotalRows(response.meta_data.count);
        setIsPageLoading((prev) => ({ ...prev, list: false }));
      } else {
        console.error("Invalid assets response:", response);
      }
      setIsListFetched(false);
    } catch (error) {
      console.error("fetchAssets error:", error.message);
    }
  };

  // A function to handle table row click:
  const handleOnRowClick = (params) =>
    navigate(`/manage/assets/view/${params.id}`);

  // Handle Delete
  const onClickRowDelete = (e, data) => {
    e.stopPropagation(); // Remove row click effect
    setDialogType("DELETE");
    toggleDialog(true); // Open confirmation dialog
    setDeleteID(data?.id); // Set ID to delete
  };

  const handleOnDelete = async () => {
    setIsDeleting(true);

    try {
      await deleteAsset(deleteID); // Call delete organization service

      setIncomingResponse({
        severity: "success",
        message: "Asset is deleted successfully",
      });

      setIsDeleting(false);
      toggleDialog(false);
      setDeleteID(null);
    } catch (error) {
      setIncomingResponse({
        severity: "error",
        message: error?.message,
      });

      setIsDeleting(false);
    }
  };

  // Handled export functionality
  const onClickExport = (e, data) => {
    setDialogType("EXPORT");
    toggleDialog(true);
  };

  const handleOnExport = (value) => {
    const type = value.id;
    const filename = `assets-${new Date().toISOString()}`;
    setIsExporting(true);

    switch (type) {
      case "csv":
        exportToCSV(assets, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      case "xlsx":
        exportToXLSX(assets, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      default:
        console.log("Invalid export format/type");
        setIsExporting(false);
        break;
    }
  };

  // Import contracts functionality:
  const onClickImport = () => {
    setDialogType("IMPORT_ASSET");
    toggleDialog(true);
  }; // function to handle event trigger.

  /* Implement a function to handle import */
  const handleOnImport = async (value) => {
    setIsImporting(true);

    try {
      await importAssets(value); // Call import contracts service

      setIncomingResponse({
        severity: "success",
        message: "Contracts are uploaded successfully",
      });

      setIsImporting(false);
      toggleDialog(false);
      // setDeleteID(null);
    } catch (error) {
      setIncomingResponse({
        severity: "error",
        message: error?.message,
      });

      setIsImporting(false);
    }
  }; // Import contracts function

  const dialogOptions = {
    EXPORT: {
      onConfirm: handleOnExport,
      isLoading: isExporting,
    },
    DELETE: {
      onConfirm: handleOnDelete,
      isLoading: isDeleting,
    },
    IMPORT_ASSET: {
      onConfirm: handleOnImport,
      isLoading: isImporting,
    },
  };

  const columns = [
    {
      field: "is_active",
      headerName: "",
      width: 40,
      align: "center",
      renderCell: (params) => (
        <span>
          {params.value ? (
            <configs.icons.CheckCircleIcon color="success" />
          ) : (
            <configs.icons.UnpublishedIcon color="error" />
          )}
        </span>
      ),
    },
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "organization",
      headerName: "Organization",
      width: 260,
      renderCell: (params) => {
        return <span>{params?.value?.name}</span>;
      },
    },
    { field: "serial_number", headerName: "Serial Number ", width: 150 },
    // { field: "reseller_id:", headerName: "Reseller", width: 110 },
    // { field: "usage_status", headerName: "usage_status", width: 200 },
    // { field: "WarrantyStatus.daWa", headerName: "Status", width: 130 },
    {
      field: "warranty_start_date",
      headerName: "Warranty Start Date",
      width: 175,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: "warranty_end_date",
      headerName: "Warranty End Date",
      width: 175,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          disabled={!userPermissions?.includes("DELETE")}
          onClick={(e) => onClickRowDelete(e, params)}
        >
          <configs.icons.Trash
            color={userPermissions?.includes("DELETE") ? "error" : "disabled"}
          />
        </IconButton>
      ),
    },
  ];

  const formatDate = (date) => {
    if (!date) return "N.A.";
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime())
      ? "N.A."
      : moment(parsedDate.toISOString()).format("DD/MMM/YYYY");
  };

  if (isPageLoading.meta_data || isPageLoading.list) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <HeaderChip
              label={"Assets | Summaries"}
              icon={
                <configs.icons.LayersIcon
                  sx={{ color: "#fff" + "!important" }}
                />
              }
            />
          </Grid>

          <Grid container item xs={12} spacing={2}>
            {cardsList.map((card) => (
              <Grid sm={3} key={card.id} item>
                <DashboardCard
                  icon={card.icon}
                  label={card.label}
                  count={summaries && summaries[card.id]}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <AppSpacer vertical={"20px"} />

        <Grid container item spacing={2} alignItems="center">
          <Grid item xs={6}>
            <HeaderChip
              label={"Assets | List"}
              icon={
                <configs.icons.List sx={{ color: "#fff" + "!important" }} />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Searchbar
              placeholder={"Search..."}
              value={searchString}
              onChange={({ target: { value } }) => setSearchString(value)}
              onClear={() => setSearchString(null)}
            />
          </Grid>
          {/* <Grid item xs={1}></Grid> */}
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermissions.includes("IMPORT")}
              onClick={onClickImport}
              primary
            >
              Import
            </MUIButton>
          </Grid>
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermissions.includes("EXPORT")}
              onClick={onClickExport}
              primary
            >
              Export
            </MUIButton>
          </Grid>
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermissions.includes("CREATE")}
              onClick={() => navigate("/manage/assets/register")}
              primary
            >
              Create
            </MUIButton>
          </Grid>
          <Grid xs={12} item>
            <MUIGridTable
              loading={isListFetched}
              columns={columns}
              rows={assets}
              rowCount={totalRows}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              onRowClick={
                userPermissions.includes("READ") ? handleOnRowClick : null
              }
            />
          </Grid>
        </Grid>

        {/* Confirmation Dialog */}
        <Modal
          open={openDialog}
          onClose={() => (isDeleting ? {} : toggleDialog((e) => !e))}
        >
          <div className="dialog-container">
            <DialogsGroup
              type={dialogType}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={dialogOptions[dialogType]?.onConfirm}
              isLoading={dialogOptions[dialogType]?.isLoading}
            />
            {/* <ConfirmationDialog
              primaryLabel={"Delete"}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={handleOnDelete}
              isLoading={isDeleting}
            /> */}
          </div>
        </Modal>
      </div>
    );
  }
};

export default AssetsDashboard;

const cardsList = [
  {
    id: "all_count",
    icon: <configs.icons.LayersIcon />,
    count: 0,
    label: "Assets",
  },
  {
    id: "active_count",
    icon: <configs.icons.CheckCircleIcon />,
    count: 0,
    label: "Active Assets",
  },
  {
    id: "in_active_count",
    icon: <configs.icons.UnpublishedIcon />,
    count: 0,
    label: "In-Active Assets",
  },
  {
    id: "listed_in_7_days",
    icon: <configs.icons.SyncIcon />,
    count: 0,
    label: "Listed In Last 7 Days",
  },
];
