import "./ConfirmationDialog.style.css";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import "./ConfirmationDialog.style.css";
import { Grid, Typography } from "@mui/material";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUICard from "src/components/MUICard/MUICard.component";
import configs from "src/configs/configs";

const ConfirmationDialog = ({
  headerText,
  captionText,
  icon,
  primaryLabel,
  secondaryLabel,
  onCancel,
  onConfirm,
  isLoading,
  children,
  disabledConfirm,
  disabledCancel,
}) => {
  return (
    <div className="dialog-container">
      <MUICard style={{ width: "320px" }}>
        <Grid textAlign={"center"} container>
          <Grid sm={12} item>
            {icon || (
              <configs.icons.WarningRoundedIcon
                className="default-size-icon"
                color="warning"
              />
            )}
          </Grid>
          <Grid sm={12} item>
            <Typography variant="h5" fontWeight={"bold"}>
              {headerText || "Are you sure?"}
            </Typography>
            <Typography variant="caption" color={"GrayText"}>
              {captionText ||
                "Do you really want to delete? This action cannot be undone."}
            </Typography>
            <AppSpacer vertical={"16px"} />
          </Grid>
          <Grid sm={12} justifyContent={"center"} item spacing={2} container>
            <Grid sm={12} item>
              {children}
            </Grid>
            <Grid sm={6} item>
              <MUIButton
                onClick={onCancel}
                variant="outlined"
                disabled={disabledCancel || isLoading}
                primary
              >
                {secondaryLabel || "Cancel"}
              </MUIButton>
            </Grid>
            <Grid sm={6} item>
              <MUIButton
                onClick={ onConfirm}
                isLoadingButton
                isLoading={isLoading}
                disabled={disabledConfirm}
                primary
              >
                {primaryLabel || "Confirm"}
              </MUIButton>
            </Grid>
          </Grid>
        </Grid>
      </MUICard>
    </div>
  );
};

export default ConfirmationDialog;
