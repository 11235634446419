import { Grid, IconButton, Modal } from "@mui/material";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import useApplicationRoles from "src/hooks/useApplicationRoles.hook";
import configs from "src/configs/configs";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUIGridTable from "src/components/MUIGridTable/MUIGridTable.component";
import { useCallback, useState } from "react";
import MUICard from "src/components/MUICard/MUICard.component";
import CreateRole from "../CreateRole/CreateRole.component";
import { useNavigate } from "react-router-dom";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import DialogsGroup from "src/components/DialogsGroup/DialogsGroup.component";
import { deleteRealmsRoleByName } from "src/services/roles.service";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import getRoleName from "src/helpers/getRoleName.helper";

const ViewRoles = () => {
  const navigate = useNavigate();
  const [open, toggleOpen] = useState(false);

  /* Define states to handle delete dialog */
  const [openDialog, toggleDialog] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [incomingResponse, setIncomingResponse] = useState(null);

  const onSuccessCallback = useCallback((res) => setIncomingResponse(res));
  const onErrorCallback = useCallback((res) => setIncomingResponse(res));

  const applicationRoles = useApplicationRoles();
  const { roles, isRolesLoading } = applicationRoles.useGetRoles(isDeleting);

  const handleToggleOpen = () => toggleOpen((e) => !e);
  const handleOnRowClick = (e) => {
    if (e?.row?.role_id !== "asset_eye_owner")
      navigate(`/configurations/roles/${e?.row?.role_id}`);
  };
  const onClickRowDelete = (e, row) => {
    e.stopPropagation(); // Remove row click effect
    setDialogType("DELETE");
    toggleDialog(true); // Open confirmation dialog
    setDeleteID(row?.row?.role_id); // Set ID to delete
  };

  const handleOnDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteRealmsRoleByName(deleteID); // Call delete organization service
      setIncomingResponse({
        severity: "success",
        message: "Role is deleted successfully",
      });
      setIsDeleting(false);
      toggleDialog(false);
      setDeleteID(null);
    } catch (error) {
      setIncomingResponse({
        severity: "error",
        message: error?.message,
      });
      setIsDeleting(false);
    }
  };

  const dialogOptions = {
    DELETE: {
      onConfirm: handleOnDelete,
      isLoading: isDeleting,
    },
  };

  const columns = [
    {
      field: " ",
      headerName: "",
      width: 40,
      align: "center",
      disableSort: true,
    },
    { field: "name", headerName: "Role Name", width: 300, padding: 4 },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (row) => (
        <div>
          {["asset_eye_owner", "asset_eye_admin", "asset_eye_user"].includes(
            row.row.role_id
          ) ? null : (
            <IconButton onClick={(e) => onClickRowDelete(e, row)}>
              <configs.icons.Trash color={"error"} />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  const rows = roles?.map((role) => ({
    ...role,
    name: getRoleName(role.name),
  }));

  if (isRolesLoading) return <PageLoader />;
  else
    return (
      <div>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <Grid spacing={2} container>
          <Grid sm={6} item>
            <HeaderChip
              label={"Roles"}
              icon={
                <configs.icons.SettingsAccessibilityIcon
                  sx={{ color: "#fff" + "!important" }}
                />
              }
            />
          </Grid>
          <Grid sm={4} item></Grid>
          <Grid sm={2} item>
            <MUIButton
              onClick={handleToggleOpen}
              startIcon={<configs.icons.AddIcon />}
              primary
            >
              Add Role
            </MUIButton>
          </Grid>
          <Grid sm={12} item>
            <MUIGridTable
              columns={columns}
              rows={rows}
              rowCount={rows.length}
              disableColumnMenu
              onRowClick={handleOnRowClick}
            />
          </Grid>
        </Grid>

        <Modal open={open} onClose={handleToggleOpen}>
          <div>
            <CreateRole
              toggleOpen={toggleOpen}
              onSuccessCallback={onSuccessCallback}
              onErrorCallback={onErrorCallback}
            />
          </div>
        </Modal>

        {/* Confirmation Dialog */}
        <Modal
          open={openDialog}
          onClose={() => (isDeleting ? {} : toggleDialog((e) => !e))}
        >
          <div className="dialog-container">
            <DialogsGroup
              type={dialogType}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={dialogOptions[dialogType]?.onConfirm}
              isLoading={dialogOptions[dialogType]?.isLoading}
            />
          </div>
        </Modal>
      </div>
    );
};

export default ViewRoles;
