import React from 'react';
import * as PropTypes from 'prop-types';
import { Menu } from 'antd';
import {
  LineChartOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  PieChartOutlined,
  TableOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import ButtonDropdown from './ButtonDropdown';

const ChartIcons = {
  line: LineChartOutlined,
  area: AreaChartOutlined,
  bar: BarChartOutlined,
  pie: PieChartOutlined,
  table: TableOutlined,
  number: InfoCircleOutlined,
};

const ChartTypes = [
  { name: 'line', title: 'Line' },
  { name: 'area', title: 'Area' },
  { name: 'bar', title: 'Bar' },
  { name: 'pie', title: 'Pie' },
  { name: 'table', title: 'Table' },
  { name: 'number', title: 'Number' },
];
/* 
const SelectChartType = ({ chartType, updateChartType }) => {
  const menu = (
    <Menu>
      {ChartTypes.map((m) => {
        const IconComponent = ChartIcons[m.name]
        console.log('IconComponent', IconComponent);

        return (
          <Menu.Item key={m.title} onClick={() => updateChartType(m.name)}>
            <IconComponent />
            {m.title}
          </Menu.Item>
        )
      }
      )}
    </Menu>
  ); */

const SelectChartType = ({ chartType, updateChartType }) => {
  const menuItems = ChartTypes.map((m) => {
    const IconComponent = ChartIcons[m.name];
    return {
      key: m.name,
      label: (
        <span onClick={() => updateChartType(m.name)}>
          <IconComponent />
          {m.title}
        </span>
      ),
    };
  });

  const menu = { items: menuItems };


  const foundChartType = ChartTypes.find((t) => t.name === chartType);
  const FoundIconComponent = ChartIcons[foundChartType.name];

  return (
    <ButtonDropdown /* overlay={menu} */ menu={menu} icon={<FoundIconComponent />}>
      {foundChartType.title}
    </ButtonDropdown>
  );
};

SelectChartType.propTypes = {
  chartType: PropTypes.string.isRequired,
  updateChartType: PropTypes.func.isRequired,
};

export default SelectChartType;
