import { Avatar, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MUICard from "src/components/MUICard/MUICard.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import { getCustomersByID } from "src/services/customers.services";
import configs from "src/configs/configs";
import moment from "moment/moment";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";

const ViewCustomerDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const customerId = params.id;

  const [customer, setCustomer] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const handleGetCustomerById = async () => {
    try {
      setIsPageLoading(true);
      const response = await getCustomersByID(customerId);

      setCustomer(response.data);
      setIsPageLoading(false);
    } catch (error) {
      console.log("handleGetCustomerById: ", error.message);
    }
  };
  useEffect(() => {
    handleGetCustomerById();
  }, [customerId]);

  if (isPageLoading) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <HeaderChip
          icon={
            <configs.icons.PeopleIcon
              sx={{ color: "#fff" + "!important" }}
            />
          }
          label={`Customers > View > ${customer?.name}`}
        />

        <CustomerCard
          data={customer}
          onEdit={() => navigate(`/manage/customers/edit/${customerId}`)}
        />

        <OrganizationCard
          data={customer?.organization}
          onView={() =>
            navigate(`/manage/organizations/view/${customer?.organization?.id}`)
          }
        />
      </div>
    );
  }
};

export default ViewCustomerDetails;

// components:
const CustomerCard = ({ data, onEdit }) => (
  <MUICard style={{ position: "relative", width: "540px", margin: "auto" }}>
    <Grid spacing={2} alignItems={"center"} container>
      <Grid sm={4} item>
        <Avatar
          sx={{
            bgcolor: data?.is_active ? configs.colors.primary : "",
            width: "80px",
            height: "80px",
            margin: "auto",
          }}
        >
          {data?.is_active ? (
            <configs.icons.PeopleIcon sx={{ fontSize: "42px" }} />
          ) : (
            <configs.icons.RemovePeopleIcon sx={{ fontSize: "42px" }} />
          )}
        </Avatar>
        <div style={{ textAlign: "center", padding: "4px" }}>
          <Typography variant="caption">
            Created by {data["created_by"].firstname}{" "}
            {data["created_by"].lastname}
          </Typography>
        </div>
      </Grid>

      <Grid sm={7} item>
        <Typography variant="h5">{data?.name}</Typography>
        <Typography textTransform={"capitalize"} variant="body2">
          {data?.description}
        </Typography>
        <Typography variant="caption">
          {data?.email}
          <br />
          {data?.city}, {data?.state}, {data?.country} - {data?.zipcode}
        </Typography>
      </Grid>
      <Grid sm={1} item>
        <div style={{ position: "absolute", top: 10, right: 10 }}>
          <Tooltip title="Edit">
            <IconButton onClick={onEdit}>
              <configs.icons.EditIcon
                sx={{
                  color: configs.colors.primary,
                  fontSize: 18,
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  </MUICard>
);

const OrganizationCard = ({ data, onView }) => (
  <MUICard style={{ position: "relative", width: "540px", margin: "auto" }}>
    <Grid alignItems={"center"} container>
      <Grid sm={4} item>
        <Avatar
          sx={{
            bgcolor: data?.is_active ? configs.colors.primary : "",
            width: "80px",
            height: "80px",
            margin: "auto",
          }}
        >
          {data?.is_active ? (
            <configs.icons.BusinessIcon sx={{ fontSize: "42px" }} />
          ) : (
            <configs.icons.DomainDisabledIcon sx={{ fontSize: "42px" }} />
          )}
        </Avatar>
        <div style={{ textAlign: "center", padding: "4px" }}>
          <Typography variant="caption">
            Created on {moment(data?.createdAt).format("DD MMM YYYY")}
          </Typography>
        </div>
      </Grid>
      <Grid sm={7} item>
        <Typography variant="h5">{data?.name}</Typography>
        <Typography textTransform={"capitalize"} variant="body2">
          {data?.description}
        </Typography>
        <Typography variant="caption">
          {data?.city}, {data?.state}, {data?.country} - {data?.zipcode}
        </Typography>
      </Grid>
      <Grid sm={1} item>
        <div style={{ position: "absolute", top: 10, right: 10 }}>
          <Tooltip title="View">
            <IconButton onClick={onView}>
              <configs.icons.ViewActivities
                sx={{
                  color: configs.colors.primary,
                  fontSize: 18,
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  </MUICard>
);
