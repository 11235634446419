import { get, post, put, remove } from "src/utils/axios/axios.utils";

const baseUrl = "/suppliers";

export const getSuppliersMetaData = async (params) => {
  try {
    const response = await get(baseUrl + "/data/meta-data", { params });

    return response.data;
  } catch (error) {
    console.log("getSuppliersMetaData: ", error.message);
    throw error;
  }
};

/**
 *
 * @param {Object} params - A Query object.
 * @param {number} params.pageNumber - Page Number.
 * @param {number} params.limit - Numbers of rows per request/page
 * @param {string} params.searchKeyword - Search string.
 * @returns
 */
export const getSuppliers = async (params) => {
  try {
    const response = await get(baseUrl, { params });

    return response;
  } catch (error) {
    console.log("getSuppliers: ", error.message);
    throw error;
  }
};

/**
 *
 * @param {Object} requestBody - The Param to create supplier.
 * @returns
 */
export const createSupplier = async (requestBody) => {
  try {
    const response = await post(baseUrl, requestBody);

    return response;
  } catch (error) {
    console.log("createSupplier: ", error.message);
    throw error;
  }
};

/**
 *
 * @param {string} id - Supplier's ID (UID4)
 * @returns
 */
export const getSupplierById = async (id) => {
  try {
    const response = await get(`${baseUrl}/${id}`);

    return response;
  } catch (error) {
    console.log("getSupplierById: ", error.message);
    throw error;
  }
};

/**
 *
 * @param {string} id - Supplier's ID (UID4)
 * @param {Object} data - Parameter to update supplier's details
 * @returns
 */
export const updateSupplierById = async (id, data) => {
  try {
    const response = await put(`${baseUrl}/${id}`, data);

    return response;
  } catch (error) {
    console.log("getSupplierById: ", error.message);
    throw error;
  }
};

// Deleting an Supplier
export const deleteSupplier = async (supplierId) => {
  try {
    const result = await remove(`${baseUrl}/${supplierId}`);
    return result;
  } catch (error) {
    console.error("deleteSuppliers:", error.message);
    throw error;
  }
};
