import React, { useState } from "react";
import { Card, Menu, Button, Dropdown, Modal, Space } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { GET_DASHBOARD_ITEMS } from "../graphql/queries";
import { DELETE_DASHBOARD_ITEM } from "../graphql/mutations";
import { useSelector } from "react-redux";

const DashboardItemDropdown = ({ itemId, item }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userPermissions = currentUser?.permissions["embedded_analytics"] || [];

  const [removeDashboardItem] = useMutation(DELETE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS,
      },
    ],
  });

  // Define the items for the dashboard item dropdown menu
  const dashboardItemDropdownMenu = [
    {
      key: `dashboard-dropdown-${itemId}-edit`,
      access: "UPDATE",
      label: (
        <Link to={`/manage/embedded_analytics?itemId=${itemId}`}>Edit</Link>
      ),
    },
    {
      key: `dashboard-dropdown-${itemId}-delete`,
      access: "DELETE",
      label: "Delete",
      onClick: () => {
        // Show a confirmation dialog before removing the dashboard item
        Modal.confirm({
          title: "Are you sure you want to delete this item?",
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk() {
            console.log(item);
            // Function to remove a dashboard item when the user confirms the action
            removeDashboardItem({
              variables: {
                id: itemId, // Pass the itemId as a variable to the removeDashboardItem mutation
                input: {
                  dashboard_id: item.dashboard_id,
                },
              },
            });
          },
        });
      },
    },
  ].filter((item) => userPermissions.includes(item.access));

  return (
    <Dropdown
      key={`dashboard-dropdown-${itemId}`}
      menu={{ items: dashboardItemDropdownMenu }} // Attach the dropdown menu to the Dropdown component
      placement="bottomLeft" // Position the dropdown menu at the bottom left of the button
    >
      <Space>
        <Button
          key={`menu-button-${itemId}`}
          shape="circle" // Shape the button as a circle
          icon={<MenuOutlined />} // Use the MenuOutlined icon for the button
        />
      </Space>
    </Dropdown>
  );
};

const DashboardItem = ({ itemId, children, title, item }) => (
  <Card
    title={title} // Set the title of the card
    style={{
      height: "100%",
      width: "100%",
    }}
    extra={<DashboardItemDropdown itemId={itemId} item={item} />}
  >
    {children}
  </Card>
);

export default DashboardItem;
