// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/

.navbar-main {
  padding: 0px 12px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}

.navbar-logo-container {
  margin: 0 auto;
  text-align: center;
}

.navbar-logo-container img {
  height: 60px;
}

.icon-button {
  transition: all 0.2s ease-in-out;
  /* color: #000 !important; */
}

.icon-button:hover {
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
  /* color: #317bff !important; */
  /* color: rgba(239, 130, 33, 1) !important; */
}

@media only screen and (max-width: 900px) {
  .navbar-logo-container {
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.style.css"],"names":[],"mappings":"AAAA;;;CAGC;;AAED;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gCAAgC;EAChC,4BAA4B;AAC9B;;AAEA;EACE,qBAAqB;EACrB,gCAAgC;EAChC,+BAA+B;EAC/B,6CAA6C;AAC/C;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["/* \n    Copyright 2023-24 Rahi Systems Inc\n    Author: Pratik Sonar <pratik.sonar@rahisystems.com>\n*/\n\n.navbar-main {\n  padding: 0px 12px;\n  width: 100%;\n  height: 60px;\n  display: flex;\n  align-items: center;\n}\n\n.navbar-logo-container {\n  margin: 0 auto;\n  text-align: center;\n}\n\n.navbar-logo-container img {\n  height: 60px;\n}\n\n.icon-button {\n  transition: all 0.2s ease-in-out;\n  /* color: #000 !important; */\n}\n\n.icon-button:hover {\n  transform: scale(1.2);\n  transition: all 0.2s ease-in-out;\n  /* color: #317bff !important; */\n  /* color: rgba(239, 130, 33, 1) !important; */\n}\n\n@media only screen and (max-width: 900px) {\n  .navbar-logo-container {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
