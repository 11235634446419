import { Grid, Modal, Tooltip } from "@mui/material";
import MUICard from "src/components/MUICard/MUICard.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import { useEffect, useState } from "react";
import configs from "src/configs/configs";
import { useFormik } from "formik";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import FormActions from "src/pages/ManageOrganizations/RegisterOrganization/components/FormActions/FormActions.component";
import firstLetterCaps from "src/helpers/firstLetterCaps.helper";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import MUIAutocomplete from "src/components/MUIAutocomplete/MUIAutocomplete.component";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import useGetOrganizations from "src/hooks/useGetOrganizations.hook";
import useGetContracts from "src/hooks/manageContracts/useGetContracts.hook";
import useGetSuppliers from "src/hooks/useGetSuppliers.hook";
import useGetManufacturers from "src/hooks/useGetManufacturers.hook";
import useGetSupportProviders from "src/hooks/useGetSupportProviders.hook";
import CreateCategory from "src/pages/ManageCategories/CreateCategory/CreateCategory.component";
import useGetCategories from "src/hooks/useGetCategories.hook";
import useGetBusinessUnits from "src/hooks/useGetBusinessUnits.hook";
import CreateBusinessUnit from "src/pages/ManageBusinessUnit/CreateBusinessUnit/CreateBusinessUnit.component";
import useGetAssetOwners from "src/hooks/useGetAssetOwners.hook";
import CreateAssetOwner from "src/pages/ManageAssetOwners/CreateAssetOwner/CreateAssetOwner.component";
import useGetCountryStateCity from "src/hooks/useGetCountryStateCity.hook";
import useConstants from "src/hooks/useConstants.hook";
import useGetWarrantyProviders from "src/hooks/useGetWarrantyProviders.hook";
import useGetHardwareModels from "src/hooks/useGetHardwareModels.hook";
import CreateHardwareModel from "src/pages/ManageHardwareModels/CreateHardwareModel/CreateHardwareModel.component";
import CustomFormSection from "src/components/CustomFormSection/CustomFormSection.component";
import useGetCustomForms from "src/hooks/useGetCustomForms.hooks";
import { getCustomFormById } from "src/services/customForms.services";
import CustomForm from "src/components/CustomForm/CustomForm.component";
import {
  parseIncomingJSON,
  parseOutgoingJSON,
} from "src/helpers/manageRequestJSON.helper";
import { createAsset, updateAssetById } from "src/services/assets.services";
import useGetAssetByID from "src/hooks/manageAssets/useGetAssetByID.hook";
import { City, Country, State } from "country-state-city";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import { useSelector } from "react-redux";
import AttachmentsComponent from "src/components/AttachmentsComponent/AttachmentsComponent";
import CreateFiles from "src/pages/ManageFiles/CreateFiles/CreateFiles.page";
import useGetProviders from "src/hooks/useGetProviders.hook";
import useGetCustomers from "src/hooks/useGetCustomers.hook";
import useGetIntegrators from "src/hooks/useGetIntegrators.hook";

// main component:
const CreateAsset = () => {
  const givenOrganization = {
    organization_id: null,
  }; // Provides given initial value

  const currentUser = useSelector((state) => state.auth.currentUser);
  const userRole = currentUser?.role || null;
  const organizationID = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions || [];

  if (organizationID) {
    givenOrganization.organization_id = {
      id: organizationID,
      label: "",
      value: organizationID,
    };
  } // Implementing pre-defined organization value

  const navigate = useNavigate();
  const params = useParams();
  const countryStateCity = useGetCountryStateCity();
  const optionsList = useConstants();
  const assetId = params.id;
  const [flags, setFlags] = useState({
    isFormSubmit: false,
    organization: false,
    contract_id: false,
    category_id: false,
    isPageLoading: assetId ? true : false,
    openCustomFormModal: false,
    isCustomFormFetched: false,
  }); // Flag for handling loading button on submit.
  const [searchStrings, setSearchStrings] = useState({
    organization_id: "",
    contract_id: "",
    category_id: "",
    supplier_id: "",
    provider_id: "",
    manufacturer_id: "",
    support_provider_id: "",
    warranty_provider_id: "",
    hardware_model_id: "",
  });
  const [open, toggleOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalValue, setModalValue] = useState(null);
  const [incomingResponse, setIncomingResponse] = useState(null);

  // Formik integration:
  const formik = useFormik({
    initialValues: { ...createAssetInitialValues, ...givenOrganization },
    validationSchema: conditionalValidationSchema,
    onSubmit: async (values) => {
      setFlags((prev) => ({ ...prev, isFormSubmit: true }));

      const rawValues = values;

      const requestBody = parseOutgoingJSON(rawValues);
      requestBody.attachments = attachments;

      try {
        assetId
          ? await updateAssetById(assetId, requestBody)
          : await createAsset(requestBody);

        setIncomingResponse({
          severity: "success",
          message: `Asset ${assetId ? "Updated" : "created"} successfully!`,
        });
        if (!assetId) formik.handleReset();
        setFlags((prev) => ({ ...prev, isFormSubmit: false }));
      } catch (error) {
        const message = error?.response?.data?.message || error.message;
        setIncomingResponse({
          severity: "error",
          message,
        });
        setFlags((prev) => ({ ...prev, isFormSubmit: false }));
      }
    },
    onReset: () => {
      if (assetId) navigate(-1);
    },
  });

  const [asset, setAsset] = useGetAssetByID(assetId);

  const [organizations, isOrganizationsLoading] = useGetOrganizations(
    searchStrings?.organization_id
  );
  const [contracts, isContractsLoading] = useGetContracts(
    searchStrings?.contract_id,
    formik?.values?.organization_id?.id
  );
  const [suppliers, isSuppliersLoading] = useGetSuppliers(
    searchStrings?.supplier_id,
    formik?.values?.organization_id?.id
  );
  const [customers, isCustomersLoading] = useGetCustomers(
    searchStrings?.customer_id,
    formik?.values?.organization_id?.id
  );
  const [integrators, isIntegratorsLoading] = useGetIntegrators(
    searchStrings?.integrator_id,
    formik?.values?.organization_id?.id
  );
  const [manufacturers, isManufacturersLoading] = useGetManufacturers(
    searchStrings?.manufacturer_id,
    formik?.values?.organization_id?.id,
    {
      data: suppliers,
    }
  );
  const [supportProviders, isSupportProvidersLoading] = useGetSupportProviders(
    searchStrings?.support_provider_id,
    formik?.values?.organization_id?.id,
    {
      data: suppliers,
    }
  );
  const [warrantyProviders, isWarrantyProvidersLoading] =
    useGetWarrantyProviders(
      searchStrings?.warranty_provider_id,
      formik?.values?.organization_id?.id,
      {
        data: suppliers,
      }
    );
  const [providers, isProvidersLoading] = useGetProviders(
    searchStrings?.provider_id,
    formik?.values?.organization_id?.id,
    {
      data: suppliers,
    }
  );

  const [categories, setCategories, isCategoriesLoading] = useGetCategories(
    formik?.values?.organization_id?.id
  );
  const [assetOwners, setAssetOwners, isAssetOwnersLoading] = useGetAssetOwners(
    formik?.values?.organization_id?.id
  );
  const [businessUnits, setBusinessUnits, isBusinessUnitsLoading] =
    useGetBusinessUnits(formik?.values?.organization_id?.id);

  const [hardwareModels, setHardwareModels, isHardwareModelsLoading] =
    useGetHardwareModels(searchStrings?.hardware_model_id);

  const [customForms, setCustomForms] = useGetCustomForms(
    "asset",
    formik?.values?.organization_id?.id
  );
  const [selectedCustomForm, setSelectedCustomForm] = useState(null);
  const [selectedCustomForms, setSelectedCustomForms] = useState([]);

  // Attachmets states:
  const [attachments, setAttachments] = useState([]);

  const [formDisable, setFormDisable] = useState(true);

  const modalOptions = {
    category_id: { categories, setCategories },
    business_unit_id: { businessUnits, setBusinessUnits },
    asset_owner_id: { assetOwners, setAssetOwners },
    hardware_model_id: { hardwareModels, setHardwareModels },
    upload_files: { attachments, setAttachments },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState(null);

  const [countries] = countryStateCity.useGetCountries();
  const [states] = countryStateCity.useGetStatesByCountryCode(
    formik.values.country?.id
  );
  const [cities] = countryStateCity.useGetCityByStateCountryCode(
    formik.values.country?.id,
    formik.values.state?.id
  );

  //handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);

    if (name === "name") {
      const namesArr = value.split(" ");
      const newNameArr = namesArr.map((n) => firstLetterCaps(n));

      e.target.value = newNameArr.join(" ");
    }

    formik.handleChange(e);
  };

  //
  const handleAutocompleteChange = (e, value) => {
    if (value) {
      formik.handleChange({ target: { name: value.name, value } });
    } else {
      const { name, value } = e.target;
      setSearchStrings((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Function to handle dialog values
  const handleDialogValue = (type, newValue) => {
    setModalType(type);
    setModalValue(newValue);
  };

  const customHookConfigs = {
    organization_id: {
      options: organizations,
      flag: isOrganizationsLoading,
    },
    contract_id: {
      options: contracts,
      flag: isContractsLoading,
    },
    category_id: {
      options: categories,
      flag: isCategoriesLoading,
    },
    manufacturer_id: {
      options: manufacturers,
      flag: isManufacturersLoading,
    },
    supplier_id: {
      options: suppliers,
      flag: isSuppliersLoading,
    },
    customer_id: {
      options: customers,
      flag: isCustomersLoading,
    },
    integrator_id: {
      options: integrators,
      flag: isIntegratorsLoading,
    },
    provider_id: {
      options: providers,
      flag: isProvidersLoading,
    },
    asset_owner_id: {
      options: assetOwners,
      flag: isAssetOwnersLoading,
    },
    business_unit_id: {
      options: businessUnits,
      flag: isBusinessUnitsLoading,
    },
    warranty_provider_id: {
      options: warrantyProviders,
      flag: isWarrantyProvidersLoading,
    },
    support_provider_id: {
      options: supportProviders,
      flag: isSupportProvidersLoading,
    },
    hardware_model_id: {
      options: hardwareModels,
      flag: isHardwareModelsLoading,
    },
    country: {
      options: countries,
    },
    state: {
      options: states,
    },
    city: {
      options: cities,
    },
  };

  const fetchCustomFormById = async (id) => {
    try {
      setFlags((prev) => ({
        ...prev,
        isCustomFormFetched: true,
      }));
      const response = await getCustomFormById(id);
      const { data } = response;
      const parsedCustomForm = [data].map((cForm) => ({
        id: cForm.id,
        name: cForm.name,
        fields: cForm.fields,
      }))[0];

      const parsedCustomForm_2 = [data].map((cForm) => {
        const parsedObj = {};
        parsedObj.id = cForm.id;

        parsedObj.fields = cForm?.fields?.map((cField) => ({
          id: cField.id,
          value: "",
        }));

        return parsedObj;
      })[0];

      formik.setValues((values) => ({
        ...values,
        custom_forms: [...values.custom_forms, parsedCustomForm_2],
      }));

      setSelectedCustomForms((prev) => [...prev, parsedCustomForm]);
      setSelectedCustomForm(null);
      setFlags((prev) => ({
        ...prev,
        openCustomFormModal: !prev.openCustomFormModal,
        isCustomFormFetched: false,
      }));
    } catch (error) {
      setFlags((prev) => ({
        ...prev,
        isCustomFormFetched: false,
      }));
      console.log("fetchCustomFormById: ", error.message);
    }
  };

  const handleAddCustomForms = () => {
    const customFormId = selectedCustomForm;

    fetchCustomFormById(customFormId);
  };

  useEffect(() => {
    if (asset) {
      const incomingJSON = asset;
      const rawIncomingJSON = parseIncomingJSON(incomingJSON);
      setAttachments(rawIncomingJSON?.attachments);
      const parseCountryStateCityObj = getCountryStateAndCity(rawIncomingJSON);

      formik.setValues({
        ...rawIncomingJSON,
        ...parseCountryStateCityObj,
      });
      setSelectedCustomForms(rawIncomingJSON.custom_forms);

      setFlags((prev) => ({ ...prev, isPageLoading: false }));
    }
  }, [asset]);

  useEffect(() => {
    if (selectedCustomForms.length && customForms.length) {
      const selectedCustomFormsIds = selectedCustomForms.map((form) => form.id);
      setCustomForms((prevState) => {
        return prevState.filter(
          (form) => !selectedCustomFormsIds.includes(form.id)
        );
      });
    }
  }, [selectedCustomForms, customForms]);

  // Attachments functionality:
  const handleAddAttachments = () => {
    toggleOpen(true);
    handleDialogValue("upload_files", null);
  };

  useEffect(() => {
    if (formik.values.organization_id) setFormDisable(false);
    else setFormDisable(true);
  }, [formik.values.organization_id]);

  conditionalValidationSchema =
    formik.values.asset_type === "service"
      ? createServiceValidationSchema
      : createAssetValidationSchema;

  assetRegistrationForm[0].fields =
    formik.values.asset_type === "service"
      ? serviceGeneralInformationFields
      : assetGeneralInformationFields;

  if (flags.isPageLoading) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <Grid spacing={2} container>
          <Grid sm={12} item>
            <HeaderChip
              icon={
                <configs.icons.DescriptionIcon
                  sx={{ color: "#fff" + "!important" }}
                />
              }
              label={`Assets > ${assetId ? "Edit" : "Create"}`}
            />
          </Grid>
          <Grid sm={9} item>
            {/* <div style={{ width: "800px", margin: "auto" }}> */}
            <Grid container>
              {assetRegistrationForm.map((section) => (
                <Grid key={section.title} item xs={12}>
                  <MUICard title={<TitleComponent title={section.title} />}>
                    <Grid container spacing={2}>
                      {section.fields.map((field) => (
                        <Grid
                          key={field.id}
                          item
                          xs={12}
                          md={field.col && field.col.md ? field.col.md : 6}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div>
                            <Tooltip
                              title={
                                formik.values.organization_id
                                  ? null
                                  : "Select an organization first!"
                              }
                              placement="top"
                            >
                              {field.id === "organization_id" &&
                              userRole !== "asset_eye_owner" ? null : (
                                <MUIInputLabel>
                                  {field.required && (
                                    <span style={{ color: "red" }}>*</span>
                                  )}
                                  {field.label}
                                </MUIInputLabel>
                              )}
                              {field.type !== "autocomplete" ? (
                                <MUITextField
                                  Name={field.id}
                                  Type={field.type}
                                  Value={formik.values[field.id]}
                                  OnChange={handleInputChange}
                                  Error={
                                    formik?.touched[field.id] &&
                                    formik?.errors &&
                                    formik?.errors[field.id]
                                  }
                                  helperText={
                                    formik?.touched[field.id] &&
                                    formik?.errors &&
                                    formik?.errors[field.id]
                                  }
                                  Select={field.type === "select"}
                                  SelectOptions={optionsList[field.id]}
                                  Disabled={formDisable || flags.isFormSubmit}
                                  fullWidth // Added fullWidth to occupy the entire width
                                />
                              ) : field.id === "organization_id" &&
                                userRole !== "asset_eye_owner" ? null : (
                                <MUIAutocomplete
                                  loading={customHookConfigs[field.id]?.flag}
                                  name={field.id}
                                  value={formik.values[field.id]}
                                  options={customHookConfigs[field.id]?.options}
                                  onChange={handleAutocompleteChange}
                                  error={
                                    formik?.touched[field.id] &&
                                    formik?.errors &&
                                    formik?.errors[field.id]
                                  }
                                  helperText={
                                    formik?.touched[field.id] &&
                                    formik?.errors &&
                                    formik?.errors[field.id]
                                  }
                                  disabled={
                                    (field.id === "organization_id"
                                      ? false
                                      : formDisable) || flags.isFormSubmit
                                  }
                                  creatable={
                                    field.isCreatable &&
                                    formik.values?.organization_id?.id
                                  }
                                  toggleOpen={toggleOpen}
                                  setDialogValue={(e) =>
                                    handleDialogValue(field.id, e)
                                  }
                                  fullWidth // Added fullWidth to occupy the entire width
                                />
                              )}
                            </Tooltip>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </MUICard>
                  <AppSpacer vertical={"12px"} />
                </Grid>
              ))}
            </Grid>
            {/* Attachments component */}
            <AttachmentsComponent
              onAdd={handleAddAttachments}
              data={attachments}
              setData={setAttachments}
              disabled={formDisable}
            />

            {selectedCustomForms.length
              ? selectedCustomForms.map((cform, index) => (
                  <MUICard key={cform.id} title={cform.name}>
                    <CustomForm
                      index={index}
                      cForm={cform}
                      formik={formik}
                      handleInputChange={handleInputChange}
                      flags={flags}
                    />
                  </MUICard>
                ))
              : null}
            <CustomFormSection
              open={flags.openCustomFormModal}
              toggleOpen={() =>
                setFlags((prev) => ({
                  ...prev,
                  openCustomFormModal: !prev.openCustomFormModal,
                }))
              }
              options={customForms}
              value={selectedCustomForm}
              onChange={(e) => setSelectedCustomForm(e.target.value)}
              onAdd={handleAddCustomForms}
              loading={flags.isCustomFormFetched}
            />
            <FormActions
              formik={formik}
              isLoading={flags.isFormSubmit}
              disabledSubmit={formDisable}
            />
            {/* </div> */}
          </Grid>
          <Grid item xs={3}>
            <MUICard title={<TitleComponent title="Asset Types" />}>
              <Grid spacing={2} container>
                <Grid item xs={12}>
                  {/* <MUIInputLabel>Asset Type</MUIInputLabel> */}
                  <>
                    <MUITextField
                      Name={"asset_type"}
                      Value={
                        optionsList["asset_type"].length
                          ? formik.values["asset_type"]
                          : ""
                      }
                      SelectOptions={optionsList["asset_type"]}
                      OnChange={handleInputChange}
                      Select
                    />
                  </>
                </Grid>
              </Grid>
            </MUICard>
          </Grid>
        </Grid>

        <Modal open={open} onClose={() => toggleOpen((e) => !e)}>
          <div>
            <ModalsGroup
              organizationId={formik.values?.organization_id?.id}
              creatorId={currentUser?.id}
              type={modalType}
              value={modalValue}
              onAutocompleteChange={handleAutocompleteChange}
              toggleOpen={toggleOpen}
              setValue={setModalValue}
              setOptions={modalOptions}
            />
          </div>
        </Modal>
      </div>
    );
  }
};
export default CreateAsset;

// Title Component:
function TitleComponent({ title }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {title}
    </div>
  );
}

const ModalsGroup = ({
  organizationId,
  creatorId,
  type,
  value,
  onAutocompleteChange,
  toggleOpen,
  setValue,
  setOptions,
}) => {
  // Collecting common props:
  const props = {
    organizationId,
    creatorId,
    value: value,
    setSelectedOption: (selectedValue) =>
      onAutocompleteChange(null, selectedValue),
    toggleOpen: toggleOpen,
    setValue: setValue,
  };

  switch (type) {
    case "category_id":
      return (
        <CreateCategory
          {...props}
          setOptions={setOptions[type].setCategories}
        />
      );
    case "business_unit_id":
      return (
        <CreateBusinessUnit
          {...props}
          setOptions={setOptions[type].setBusinessUnits}
        />
      );
    case "asset_owner_id":
      return (
        <CreateAssetOwner
          {...props}
          setOptions={setOptions[type].setAssetOwners}
        />
      );
    case "hardware_model_id":
      return (
        <CreateHardwareModel
          {...props}
          setOptions={setOptions[type].setHardwareModels}
        />
      );
    case "upload_files":
      return (
        <CreateFiles {...props} setOptions={setOptions[type].setAttachments} />
      );
    default:
      return null;
  }
};

var conditionalValidationSchema = [];

// Configurations:
const assetRegistrationForm = [
  //General Information
  {
    title: "General Information ",
    fields: [
      {
        id: "organization_id",
        name: "organization_id",
        label: "Organization",
        type: "autocomplete",
        required: true,
        col: {
          md: 12,
        },
      },
      {
        id: "name",
        name: "name",
        label: "Name",
        type: "text",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "contract_id",
        name: "contract",
        label: "Contract",
        type: "autocomplete",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "category_id",
        name: "category",
        label: "Category",
        type: "autocomplete",
        isCreatable: true,
        col: {
          md: 6,
        },
      },
      {
        id: "manufacturer_id",
        name: "manufacturer",
        label: "Manufacturer",
        type: "autocomplete",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "customer_part_number",
        name: "Customer Part Number",
        label: "Customer Part Number",
        type: "text",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "serial_number",
        name: "Serial Number",
        label: "Serial Number",
        type: "text",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "manufacturer_part_number",
        name: "Manufacturer Part Number",
        label: "Manufacturer Part Number",
        type: "text",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "hardware_model_id",
        name: "Hardware Model",
        label: "Hardware Model",
        type: "autocomplete",
        isCreatable: true,
        col: {
          md: 6,
        },
      },
      {
        id: "supplier_id",
        name: "supplier_id",
        label: "Supplier",
        type: "autocomplete",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "sales_manager_name",
        name: "Sales Manager Name",
        label: "Sales Manager Name",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "sales_manager_email",
        name: "Sales Manager Email",
        label: "Sales Manager Email",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "sales_manager_address",
        name: "Sales Manager Address",
        label: "Sales Manager Address",
        type: "text",
        col: {
          md: 6,
        },
      },

      {
        id: "asset_notes",
        name: "Asset Notes",
        label: "Asset Notes",
        type: "text",
        col: {
          md: 6,
        },
      },
    ],
  },

  //Location
  {
    title: "Location",
    fields: [
      {
        id: "country",
        name: "country",
        label: "Country",
        type: "autocomplete",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "state",
        name: "state",
        label: "State",
        type: "autocomplete",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "city",
        name: "city",
        label: "City",
        type: "text",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "address",
        name: "address",
        label: "Address",
        required: true,
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "asset_location_address",
        name: "Asset Location Address",
        label: "Asset Location Address",
        required: true,
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "location_description",
        name: "Location Description",
        label: "Location Description",
        required: true,
        type: "text",
        col: {
          md: 6,
        },
      },
    ],
  },

  //Business Unit
  {
    title: "Business Unit",
    fields: [
      {
        id: "asset_owner_id",
        name: "Asset Owner",
        label: "Asset Owner",
        type: "autocomplete",
        isCreatable: true,
        col: {
          md: 6,
        },
      },
      {
        id: "business_unit_id",
        name: "business_unit_id",
        label: "Business Unit",
        type: "autocomplete",
        isCreatable: true,
        col: {
          md: 6,
        },
      },
    ],
  },

  //Warranty Information
  {
    title: "Warranty Information",
    fields: [
      {
        id: "warranty_type",
        name: "Warranty Type",
        label: "Warranty Type",
        type: "select",
        col: {
          md: 6,
        },
      },
      {
        id: "warranty_status",
        name: "Warranty Status",
        label: "Warranty Status",
        type: "select",
        col: {
          md: 6,
        },
      },
      {
        id: "warranty_provider_id",
        name: "Warranty Provider",
        label: "Warranty Provider",
        type: "autocomplete",
        col: {
          md: 6,
        },
      },
      {
        id: "end_of_life_date",
        name: "End of Life Date",
        label: "End of Life Date",
        type: "date",
        col: {
          md: 6,
        },
      },
      {
        id: "warranty_start_date",
        name: "Start Dater",
        label: "Start Date",
        type: "date",
        col: {
          md: 6,
        },
      },
      {
        id: "warranty_end_date",
        name: "End Date",
        label: "End Date",
        type: "date",
        col: {
          md: 6,
        },
      },
      {
        id: "warranty_contact_phone",
        name: "Warranty Contact Phone",
        label: "Warranty Contact Phone",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "warranty_contact_email",
        name: "Warranty Contact Email",
        label: "Warranty Contact Email",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "warranty_notes",
        name: "Warranty Notes",
        label: "Warranty Notes",
        type: "text",
        col: {
          md: 6,
        },
      },
    ],
  },

  //Support Information
  {
    title: "Support Information",
    fields: [
      {
        id: "support_type",
        name: "Support Type",
        label: "Support Type",
        type: "select",
        col: {
          md: 6,
        },
      },
      {
        id: "support_status",
        name: "Support Status",
        label: "Support Status",
        type: "select",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "support_provider_id",
        name: "Support Provider",
        label: "Support Provider",
        type: "autocomplete",
        required: true,
        col: {
          md: 6,
        },
      },
      {
        id: "end_of_support_date",
        name: "End of Support Date",
        label: "End of Support Date",
        type: "date",
        col: {
          md: 6,
        },
      },
      {
        id: "support_start_date",
        name: "Start Date",
        label: "Start Date",
        type: "date",
        col: {
          md: 6,
        },
      },
      {
        id: "support_end_date",
        name: "End Date",
        label: "End Date",
        type: "date",
        col: {
          md: 6,
        },
      },
      {
        id: "support_contact_phone",
        name: "Support Contact Phone",
        label: "Support Contact Phone",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "support_contact_email",
        name: "Support Contact Email",
        label: "Support Contact Email",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "support_notes",
        name: "Support Notes",
        label: "Support Notes",
        type: "text",
        col: {
          md: 6,
        },
      },
    ],
  },

  //Usage Information
  {
    title: "Usage Information",
    fields: [
      {
        id: "usage_status",
        name: "Usage Status",
        label: "Usage Status",
        type: "select",
        col: {
          md: 6,
        },
      },
      {
        id: "asset_tag",
        name: "Asset Tag",
        label: "Asset Tag",
        type: "text",
        col: {
          md: 6,
        },
      },
    ],
  },

  //Financial Information
];

const createAssetInitialValues = {
  organization_id: null,
  name: "",
  asset_type: "asset",
  contract_id: null,
  category_id: null,
  manufacturer_id: null,
  customer_part_number: "",
  serial_number: "",
  provider_id: null,
  supplier_id: null,
  customer_id: null,
  integrator_id: null,
  manufacturer_part_number: "",
  hardware_model_id: null,
  sales_manager_name: "",
  sales_manager_email: "",
  sales_manager_address: "",
  asset_notes: "",
  country: null,
  state: null,
  city: "",
  address: "",
  asset_location_address: "",
  location_description: "",
  asset_owner_id: null,
  business_unit_id: null,
  warranty_type: "",
  warranty_status: "",
  warranty_provider_id: null,
  end_of_life_date: null,
  warranty_start_date: null,
  warranty_end_date: null,
  warranty_contact_phone: "",
  warranty_contact_email: "",
  warranty_notes: "",
  support_type: "",
  support_status: "",
  support_provider_id: null,
  end_of_support_date: null,
  support_start_date: null,
  support_end_date: null,
  support_contact_phone: "",
  support_contact_email: "",
  support_notes: "",
  usage_status: "",
  asset_tag: "",
  custom_forms: [],
};

const createAssetValidationSchema = Yup.object().shape({
  organization_id: Yup.object().required("Organization is required!"),
  name: Yup.string().required("Name is required!"),
  contract_id: Yup.object().required("Contract is required!"),
  serial_number: Yup.string().required("Serial number is required!"),
  supplier_id: Yup.object().required("Supplier is required!"),
  manufacturer_id: Yup.object().required("Manufacturer is required!"),
  address: Yup.string().required("Address is required!"),
  country: Yup.object().required("Country is required!"),
  state: Yup.object().required("State is required!"),
  city: Yup.string().required("City is required!"),
  asset_location_address: Yup.string().required(
    "Asset location address is required!"
  ),
  location_description: Yup.string().required(
    "Location description is required!"
  ),
  support_status: Yup.string().required("Support status is required!"),
  support_provider_id: Yup.object().required("Support provider is required!"),
  customer_part_number: Yup.string().required(
    "Customer part number is required!"
  ),
  manufacturer_part_number: Yup.string().required(
    "Manufacturer part number is required!"
  ),
});

const createServiceValidationSchema = Yup.object().shape({
  organization_id: Yup.object().required("Organization is required!"),
  name: Yup.string().required("Name is required!"),
  contract_id: Yup.object().required("Contract is required!"),
  provider_id: Yup.object().required("Provider is required!"),
  address: Yup.string().required("Address is required!"),
  country: Yup.object().required("Country is required!"),
  state: Yup.object().required("State is required!"),
  city: Yup.string().required("City is required!"),
  asset_location_address: Yup.string().required(
    "Asset location address is required!"
  ),
  location_description: Yup.string().required(
    "Location description is required!"
  ),
  support_status: Yup.string().required("Support status is required!"),
  support_provider_id: Yup.object().required("Support provider is required!"),
});

const getCountryStateAndCity = (data) => {
  const countryStateCity = { country: null, state: null };
  const allCountries = Country.getAllCountries();

  const matchedCountries = allCountries.filter(
    (c) =>
      c.name.toLowerCase() === (data?.country).toLowerCase() ||
      c.isoCode === data?.country
  );

  if (matchedCountries?.length) {
    const country = matchedCountries[0]; // Country

    countryStateCity.country = {
      id: country.isoCode,
      label: country.name,
      value: country.name,
      name: "country",
    }; // Setting country

    const statesByCountryCode = State.getStatesOfCountry(country?.isoCode);
    const matchedStates = statesByCountryCode.filter(
      (s) => s.name.toLowerCase() === (data?.state).toLowerCase()
    );
    if (matchedStates?.length) {
      const state = matchedStates[0]; // State

      countryStateCity.state = {
        id: state.isoCode,
        label: state.name,
        value: state.name,
        name: "state",
      };

      const citiesOfState = City.getCitiesOfState(
        country.isoCode,
        state.isoCode
      );
      const matchedCities = citiesOfState.filter(
        (c) => c.name.toLowerCase() === (data?.city).toLowerCase()
      );

      if (matchedCities.length) {
        const city = matchedCities[0]; // City
        countryStateCity.city = data?.city;

        // countryStateCity.city = {
        //   id: `${country.isoCode}_${state.isoCode}`,
        //   label: city?.name,
        //   value: city?.name,
        //   name: "city",
        // };

        return countryStateCity;
      } else return countryStateCity;
    } else return countryStateCity;
  } else return countryStateCity;
};

const serviceGeneralInformationFields = [
  {
    id: "organization_id",
    name: "organization_id",
    label: "Organization",
    type: "autocomplete",
    required: true,
    col: {
      md: 12,
    },
  },
  {
    id: "name",
    name: "name",
    label: "Name",
    type: "text",
    required: true,
    col: {
      md: 6,
    },
  },
  {
    id: "contract_id",
    name: "contract",
    label: "Contract",
    type: "autocomplete",
    required: true,
    col: {
      md: 6,
    },
  },
  {
    id: "category_id",
    name: "category",
    label: "Category",
    type: "autocomplete",
    isCreatable: true,
    col: {
      md: 6,
    },
  },
  {
    id: "provider_id",
    name: "provider",
    label: "Provider",
    type: "autocomplete",
    required: true,
    col: {
      md: 6,
    },
  },
  {
    id: "customer_id",
    name: "customer_id",
    label: "Customer",
    type: "autocomplete",
    required: false,
    col: {
      md: 6,
    },
  },
  {
    id: "integrator_id",
    name: "integrator_id",
    label: "Integrator",
    type: "autocomplete",
    required: false,
    col: {
      md: 6,
    },
  },
  {
    id: "asset_notes",
    name: "Asset Notes",
    label: "Asset Notes",
    type: "text",
    col: {
      md: 6,
    },
  },
];

const assetGeneralInformationFields = [
  {
    id: "organization_id",
    name: "organization_id",
    label: "Organization",
    type: "autocomplete",
    required: true,
    col: {
      md: 12,
    },
  },
  {
    id: "name",
    name: "name",
    label: "Name",
    type: "text",
    required: true,
    col: {
      md: 6,
    },
  },
  {
    id: "contract_id",
    name: "contract",
    label: "Contract",
    type: "autocomplete",
    required: true,
    col: {
      md: 6,
    },
  },
  {
    id: "category_id",
    name: "category",
    label: "Category",
    type: "autocomplete",
    isCreatable: true,
    col: {
      md: 6,
    },
  },
  {
    id: "manufacturer_id",
    name: "manufacturer",
    label: "Manufacturer",
    type: "autocomplete",
    required: true,
    col: {
      md: 6,
    },
  },
  {
    id: "customer_part_number",
    name: "Customer Part Number",
    label: "Customer Part Number",
    type: "text",
    required: true,
    col: {
      md: 6,
    },
  },
  {
    id: "serial_number",
    name: "Serial Number",
    label: "Serial Number",
    type: "text",
    required: true,
    col: {
      md: 6,
    },
  },
  {
    id: "manufacturer_part_number",
    name: "Manufacturer Part Number",
    label: "Manufacturer Part Number",
    type: "text",
    required: true,
    col: {
      md: 6,
    },
  },
  {
    id: "hardware_model_id",
    name: "Hardware Model",
    label: "Hardware Model",
    type: "autocomplete",
    isCreatable: true,
    col: {
      md: 6,
    },
  },
  {
    id: "supplier_id",
    name: "supplier_id",
    label: "Supplier",
    type: "autocomplete",
    required: true,
    col: {
      md: 6,
    },
  },
  {
    id: "customer_id",
    name: "customer_id",
    label: "Customer",
    type: "autocomplete",
    required: false,
    col: {
      md: 6,
    },
  },
  {
    id: "integrator_id",
    name: "integrator_id",
    label: "Integrator",
    type: "autocomplete",
    required: false,
    col: {
      md: 6,
    },
  },
  {
    id: "sales_manager_name",
    name: "Sales Manager Name",
    label: "Sales Manager Name",
    type: "text",
    col: {
      md: 6,
    },
  },
  {
    id: "sales_manager_email",
    name: "Sales Manager Email",
    label: "Sales Manager Email",
    type: "text",
    col: {
      md: 6,
    },
  },
  {
    id: "sales_manager_address",
    name: "Sales Manager Address",
    label: "Sales Manager Address",
    type: "text",
    col: {
      md: 6,
    },
  },

  {
    id: "asset_notes",
    name: "Asset Notes",
    label: "Asset Notes",
    type: "text",
    col: {
      md: 6,
    },
  },
];
