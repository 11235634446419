import { get, post, remove } from "src/utils/axios/axios.utils";

/**
 *
 * @param {Array} data - List of files to be upload
 * @returns
 */
export const uploadFiles = async (data) => {
  const formData = new FormData();
  data.forEach((file) => {
    formData.append("files", file);
  });

  try {
    const response = await post("/files", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param {string} id - File UUID
 * @param {string} filename - File name for download
 * @returns
 */
export const downloadFile = async (id, filename) => {
  try {
    const response = await get(`/files/${id}`, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param {string} id - File UUID
 * @returns
 */
export const deleteFile = async (id) => {
  try {
    const response = await remove(`/files/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
