/* globals window */
/* import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { SchemaLink } from 'apollo-link-schema'; */
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { SchemaLink } from "@apollo/client/link/schema";

//import { makeExecutableSchema } from 'graphql-tools';
import { makeExecutableSchema } from "@graphql-tools/schema";
import {
  createDashboardItem,
  deleteDashboardItem,
  getDashboardItemById,
  updateDashboardItem,
} from "src/services/dashboardItems.service";
import { getDashboardByID } from "src/services/dashboards.services";

const cache = new InMemoryCache();
const defaultDashboardItems = [];

const getDashboardItems = async (dashboardId) => {
  try {
    const dashboards = await getDashboardByID(dashboardId);

    const newItems = dashboards.items.map((item) => ({
      ...item,
      vizState: item.viz_state,
    }));

    return newItems || defaultDashboardItems;
  } catch (error) {
    throw error;
  }
  // JSON.parse(window.localStorage.getItem("dashboardItems")) ||
  // defaultDashboardItems;
};

const setDashboardItems = (items) =>
  window.localStorage.setItem("dashboardItems", JSON.stringify(items));

const nextId = () => {
  const currentId =
    parseInt(window.localStorage.getItem("dashboardIdCounter"), 10) || 1;
  window.localStorage.setItem("dashboardIdCounter", currentId + 1);
  return currentId.toString();
};

const toApolloItem = (i) => ({
  ...i,
  __typename: "DashboardItem",
});

const typeDefs = `
  type DashboardItem {
    id: String!
    layout: String
    vizState: String
    name: String
    dashboard_id: String
  }

  input DashboardItemInput {
    layout: String
    vizState: String
    name: String
    organization_id: String
    dashboard_id: String
  }

  type Query {
    dashboardItems(dashboardId: String!): [DashboardItem]
    dashboardItem(id: String!): DashboardItem
  }

  type Mutation {
    createDashboardItem(input: DashboardItemInput): DashboardItem
    updateDashboardItem(id: String!, input: DashboardItemInput): DashboardItem
    deleteDashboardItem(id: String!, input: DashboardItemInput): DashboardItem
  }
`;

const schema = makeExecutableSchema({
  typeDefs,
  resolvers: {
    Query: {
      async dashboardItems(_, { dashboardId }) {
        if (dashboardId) {
          const dashboardItems = await getDashboardItems(dashboardId);
          return dashboardItems.map(toApolloItem);
        } else {
          return [];
        }
      },
      async dashboardItem(_, { id }) {
        const dashboardItems = await getDashboardItemById(id);
        const incomingItem = dashboardItems.data;
        incomingItem.vizState = incomingItem.viz_state;
        // return toApolloItem(dashboardItems.find((i) => i.id.toString() === id));
        return toApolloItem(incomingItem);
      },
    },
    Mutation: {
      createDashboardItem: async (_, { input: { ...item } }) => {
        try {
          item.viz_state = item.vizState;
          item.layout = JSON.stringify({});

          const newItems = await createDashboardItem(item);
          item.id = newItems?.data?.id;

          return toApolloItem(item);
        } catch (error) {
          throw error;
        }
        // const dashboardItems = getDashboardItems();
        // item = { ...item, id: nextId(), layout: JSON.stringify({}) };
        // dashboardItems.push(item);
        // setDashboardItems(dashboardItems);
      },
      updateDashboardItem: async (_, { id, input: { ...item } }) => {
        // item.viz_data = item.vizData;
        item.viz_state = item.vizState;
        await updateDashboardItem(id, item);
        const dashboardItems = await getDashboardItems(item.dashboard_id);

        item = Object.keys(item)
          .filter((k) => !!item[k])
          .map((k) => ({
            [k]: item[k],
          }))
          .reduce((a, b) => ({ ...a, ...b }), {});
        const index = dashboardItems.findIndex((i) => i.id.toString() === id);
        dashboardItems[index] = { ...dashboardItems[index], ...item };
        // setDashboardItems(dashboardItems);
        return toApolloItem(dashboardItems[index]);

      },
      deleteDashboardItem: async (_, { id, input: { ...item } }) => {
        try {
          await deleteDashboardItem(id);
          const dashboardItems = await getDashboardItems(item.dashboard_id);

          // return toApolloItem(dashboardItems);
        } catch (error) {
          throw error;
        }
        // const dashboardItems = await getDashboardItems(id);
        // const index = dashboardItems.findIndex((i) => i.id.toString() === id);
        // const [removedItem] = dashboardItems.splice(index, 1);
        // setDashboardItems(dashboardItems);
      },
    },
  },
});

export default new ApolloClient({
  cache,
  link: new SchemaLink({ schema }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network", // Ensure this is not 'standby'
    },
  },
});
