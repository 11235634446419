import { useSelector } from "react-redux";
import "./Login.style.css";
import { Typography } from "@mui/material";
import PageLoader from "src/components/PageLoader/PageLoader.component";

const Login = () => {
  const loggedInError = useSelector((state) => state.auth.error);
  if (loggedInError)
    return (
      <div className="login-container">
        <Typography variant="h6">Oops! Something went wrong.</Typography>
      </div>
    );
  else
    return (
      <div className="login-container">
        <PageLoader />
      </div>
    );
};

export default Login;
