import "./CreateContract.style.css";
import { Grid, Modal } from "@mui/material";
import configs from "src/configs/configs";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import { useNavigate, useParams } from "react-router-dom";
import MUICard from "src/components/MUICard/MUICard.component";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import useConstants from "src/hooks/useConstants.hook";
import { useFormik } from "formik";
import MUIAutocomplete from "src/components/MUIAutocomplete/MUIAutocomplete.component";
import firstLetterCaps from "src/helpers/firstLetterCaps.helper";
import { useEffect, useState } from "react";
import useGetOrganizations from "src/hooks/useGetOrganizations.hook";
import useGetCategories from "src/hooks/useGetCategories.hook";
import useGetCustomers from "src/hooks/useGetCustomers.hook";
import useGetSuppliers from "src/hooks/useGetSuppliers.hook";
import useGetBusinessUnits from "src/hooks/useGetBusinessUnits.hook";
import useGetPaymentTerms from "src/hooks/useGetPaymentTerms.hook";
import useGetStatuses from "src/hooks/useGetStatuses.hook";
import FormActions from "src/pages/ManageOrganizations/RegisterOrganization/components/FormActions/FormActions.component";
import AttachmentsComponent from "src/components/AttachmentsComponent/AttachmentsComponent";
import CustomFormSection from "src/components/CustomFormSection/CustomFormSection.component";
import CreateCategory from "src/pages/ManageCategories/CreateCategory/CreateCategory.component";
import CreateStatus from "src/pages/ManageStatuses/CreateStatus/CreateStatus.component";
import CreateBusinessUnit from "src/pages/ManageBusinessUnit/CreateBusinessUnit/CreateBusinessUnit.component";
import CreatePaymentTerms from "src/pages/ManagePaymentTerms/CreatePaymentTerms/CreatePaymentTerms.component";
import CreateFiles from "src/pages/ManageFiles/CreateFiles/CreateFiles.page";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  parseOutgoingJSON,
  parseIncomingJSON,
} from "src/helpers/manageRequestJSON.helper";
import useGetCustomForms from "src/hooks/useGetCustomForms.hooks";
import { getCustomFormById } from "src/services/customForms.services";
import CustomForm from "src/components/CustomForm/CustomForm.component";
import useGetCountryStateCity from "src/hooks/useGetCountryStateCity.hook";
import {
  createContract,
  getContractByID,
  updateContractById,
} from "src/services/contracts.services";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import { Country } from "country-state-city";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import useGetIntegrators from "src/hooks/useGetIntegrators.hook";

const CreateContract = () => {
  const navigate = useNavigate();
  const params = useParams();
  const contractId = params.id || null;

  const currentUser = useSelector((state) => state.auth.currentUser);
  const organizationId = currentUser?.organization_id || null;
  const myOrganization = organizationId
    ? {
        id: organizationId,
        label: organizationId,
        value: organizationId,
        name: "organization_id",
      }
    : null;

  // Flags:
  const [loadCustomForm, setLoadCustomForm] = useState(false); // Used in `handleAddCustomForms`
  const [loadContract, setLoadContract] = useState(false); // Used with `getContractByID`

  const [isSubmitting, setIsSubmitting] = useState(false); // Used in formik `onSubmit`

  const [searchTexts, setSearchTexts] = useState(null);
  const [attachments, setAttachments] = useState([]); // Attachment's state
  const [open, toggleOpen] = useState(false);
  const [modalValue, setModalValue] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [selectedCustomForm, setSelectedCustomForm] = useState(null);
  const [selectedCustomForms, setSelectedCustomForms] = useState([]);
  const [openCustomForm, toggleOpenCustomForm] = useState(false);
  const [incomingResponse, setIncomingResponse] = useState(null);

  const formik = useFormik({
    initialValues: { ...defaultInitialValues, organization_id: myOrganization },
    validationSchema: contractValidationSchema,
    onSubmit: async (values) => {
      const rawValues = values;
      const requestJSON = parseOutgoingJSON(rawValues);
      requestJSON.attachments = attachments;

      setIsSubmitting(true); // Set's `true` when onSubmit it triggered

      try {
        contractId
          ? await updateContractById(contractId, requestJSON)
          : await createContract(requestJSON);

        setIncomingResponse({
          severity: "success",
          message: `Contract ${
            contractId ? "Updated" : "created"
          } successfully!`,
        });

        if (!contractId) formik.handleReset();

        setIsSubmitting(false); // Set's `false` when request is done
      } catch (error) {
        setIsSubmitting(false); // Set's `false` when request is failed
        const message = error?.response?.data?.message || error.message;
        setIncomingResponse({
          severity: "error",
          message,
        });
        console.log(error.message);
      }
    },
    onReset: () => {
      if (contractId) navigate(-1);
    },
  });

  const constants = useConstants();
  const countryStateCity = useGetCountryStateCity();
  const [organizations, isOrganizationsLoading] = useGetOrganizations(
    searchTexts?.organization_id
  );
  const { statuses, isStatusesLoading, setStatuses } = useGetStatuses();
  const [categories, setCategories, isCategoriesLoading] = useGetCategories(
    formik?.values?.organization_id?.id
  );
  const [customers, isCustomersLoading] = useGetCustomers(
    searchTexts?.customer_id,
    formik?.values?.organization_id?.id
  );
  const [suppliers, isSuppliersLoading] = useGetSuppliers(
    searchTexts?.supplier_id,
    formik?.values?.organization_id?.id
  );
  const [integrators, isIntegratorsLoading] = useGetIntegrators(
    searchTexts?.integrator_id,
    formik?.values?.organization_id?.id
  );
  const [businessUnits, setBusinessUnits, isBusinessUnitsLoading] =
    useGetBusinessUnits(formik?.values?.organization_id?.id);
  const { paymentTerms, isPaymentTermsLoading, setPaymentTerms } =
    useGetPaymentTerms();
  const [customForms, setCustomForms, isCustomFormsLoading] = useGetCustomForms(
    "contract",
    formik?.values?.organization_id?.id
  );
  const [countries, setCountries] = countryStateCity.useGetCountries();

  // console.log(countries);

  useEffect(() => {
    if (selectedCustomForms.length && customForms.length) {
      const selectedCustomFormsIds = selectedCustomForms.map((form) => form.id);
      setCustomForms((prevState) => {
        return prevState.filter(
          (form) => !selectedCustomFormsIds.includes(form.id)
        );
      });
    }
  }, [selectedCustomForms, customForms]); // This removes selected forms from forms state.

  useEffect(() => {
    if (contractId) {
      (async () => {
        setLoadContract(true);
        try {
          const response = await getContractByID(contractId);
          const contract = response?.data || null;

          if (contract) {
            const rawValues = parseIncomingJSON(contract);
            rawValues.country = getCountry(rawValues);

            setAttachments(rawValues?.attachments || []);
            formik.setValues(rawValues);
            setSelectedCustomForms(rawValues.custom_forms);
          } else console.error("Contract not found.");
          setLoadContract(false);
        } catch (error) {
          setLoadContract(false);
          const message = error?.response?.data?.message || error.message;
          console.error("Error while fetching contract's details: ", message);

          throw error;
        }
      })();
    }
  }, [contractId]); // This `useEffect` will fetch contract's details if `contractId` is not null.

  // Functions:
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      const namesArr = value.split(" ");
      const newNameArr = namesArr.map((n) => firstLetterCaps(n));
      const newValue = newNameArr.join(" ");
      formik.setFieldValue(name, newValue);
    } else {
      formik.handleChange(e);
    }
  };

  const handleAutocompleteChange = (e, value) => {
    if (value) formik.handleChange({ target: { name: value.name, value } });
    else {
      const { name, value } = e.target;
      setSearchTexts((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDialogValue = (type, newValue) => {
    setModalType(type);
    setModalValue(newValue);
  }; // Helps to set dialog values

  const handleAddAttachments = () => {
    toggleOpen(true);
    handleDialogValue("upload_files", null);
  }; // Function to handle attachments

  const handleAddCustomForms = async () => {
    const customFormId = selectedCustomForm;
    setLoadCustomForm(true);
    try {
      const response = await getCustomFormById(customFormId);
      if (response?.data) {
        const customForm = [response?.data].map((cForm) => ({
          id: cForm.id,
          name: cForm.name,
          fields: cForm.fields,
        }))[0];

        const customForm_2 = [response?.data].map((cForm) => {
          const parsedObj = {};
          parsedObj.id = cForm.id;

          parsedObj.fields = cForm?.fields?.map((cField) => ({
            id: cField.id,
            value: "",
          }));

          return parsedObj;
        })[0];

        formik.setValues((values) => ({
          ...values,
          custom_forms: [...values.custom_forms, customForm_2],
        }));

        setSelectedCustomForms((prev) => [...prev, customForm]);
        setSelectedCustomForm(null);
        toggleOpenCustomForm(false);
        setLoadCustomForm(false);
      } else console.log("Invalid custom form!");
    } catch (error) {
      setLoadCustomForm(false);
      console.log("fetchCustomFormById: ", error.message);
    }
  };

  contractValidationSchema =
    formik.values.contract_type === "sell_side"
      ? schemaTypeSellSide
      : schemaTypeBuySide;

  const requiredFields = {};
  Object.keys(contractValidationSchema.fields).forEach((field) => {
    requiredFields[field] = formik.touched[field] && formik.errors[field];
  });

  // Configurations:
  const inputComponentProps = {
    values: {
      ...formik.values,
    },
    options: {
      organization_id: organizations,
      country: countries,
      category_id: categories,
      customer_id: customers,
      supplier_id: suppliers,
      integrator_id: integrators,
      business_unit_id: businessUnits,
      payment_terms_id: paymentTerms,
      status_id: statuses,
      financing: constants["financing"],
      end_of_term_action: constants["end_of_term_action"],
      end_of_term_clause: constants["end_of_term_clause"],
    },
    onChanges: {
      onInputChange: handleInputChange,
      onAutocompleteChange: handleAutocompleteChange,
    },
    errors: {
      ...requiredFields,
    },
    helperTexts: {
      ...requiredFields,
    },
    disabled: {},
    toggleOpen: {
      category_id: toggleOpen,
      status_id: toggleOpen,
      business_unit_id: toggleOpen,
      payment_terms_id: toggleOpen,
    },
    setDialogValue: {
      category_id: (e) => handleDialogValue("category", e),
      status_id: (e) => handleDialogValue("status", e),
      business_unit_id: (e) => handleDialogValue("business_unit", e),
      payment_terms_id: (e) => handleDialogValue("payment_terms", e),
    },
    loading: {
      organization_id: isOrganizationsLoading,
      category_id: isCategoriesLoading,
      customer_id: isCustomersLoading,
      supplier_id: isSuppliersLoading,
      integrator_id: isIntegratorsLoading,
      business_unit_id: isBusinessUnitsLoading,
      payment_terms_id: isPaymentTermsLoading,
      status_id: isStatusesLoading,
    },
    hides: {
      organization_id: organizationId ? true : false,
      customer_id: formik.values.contract_type === "buy_side" ? true : false,
      supplier_id: formik.values.contract_type === "sell_side" ? true : false,
    },
  };

  const modalOptions = {
    category: { categories, setCategories },
    status: { statuses, setStatuses },
    business_unit: { businessUnits, setBusinessUnits },
    payment_terms: { paymentTerms, setPaymentTerms },
    upload_files: { attachments, setAttachments },
  };

  if (loadContract) {
    return <PageLoader />;
  } else {
    return (
      <div>
        {incomingResponse && (
          <MUISnackbar
            open={true}
            onClose={() => setIncomingResponse(null)}
            severity={incomingResponse.severity}
            message={incomingResponse.message}
            vertical={"top"}
            horizontal={"right"}
          />
        )}
        <Grid spacing={2} container>
          <Breadcrumb contractId={contractId} />
          <Grid xs={12} spacing={2} item container>
            <Grid md={9} item spacing={2} container>
              <Grid xs={12} item spacing={2} container>
                {/* Register contract form */}
                {formConfig.map((section) => {
                  return (
                    <InputComponent data={section} {...inputComponentProps} />
                  );
                })}

                {/* Attachment section */}
                <Grid xs={12} item>
                  <AttachmentsComponent
                    onAdd={handleAddAttachments}
                    data={attachments}
                    setData={setAttachments}
                  />
                </Grid>

                {selectedCustomForms.length
                  ? selectedCustomForms.map((cform, index) => (
                      <Grid xs={12} key={cform.id} item>
                        <MUICard key={cform.id} title={cform.name}>
                          <CustomForm
                            index={index}
                            cForm={cform}
                            formik={formik}
                            handleInputChange={handleInputChange}
                            // flags={flags}
                          />
                        </MUICard>
                      </Grid>
                    ))
                  : null}

                {/* Custom form template section */}
                <Grid xs={12} item>
                  <CustomFormSection
                    open={openCustomForm}
                    toggleOpen={() => toggleOpenCustomForm((e) => !e)}
                    options={customForms}
                    value={selectedCustomForm}
                    onChange={(e) => setSelectedCustomForm(e.target.value)}
                    onAdd={handleAddCustomForms}
                    loading={loadCustomForm}
                  />
                </Grid>

                {/* Form's actions */}
                <Grid xs={12} item>
                  <FormActions formik={formik} isLoading={isSubmitting} />
                </Grid>
              </Grid>
            </Grid>
            <FeaturesComponent
              options={constants["contract_type"]}
              value={
                constants["contract_type"].length &&
                formik?.values?.contract_type
              }
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        {/* Modal/Dialog box */}
        <Modal open={open} onClose={() => toggleOpen((e) => !e)}>
          <div>
            <ModalsGroup
              organizationId={formik.values?.organization_id?.id}
              creatorId={currentUser?.id}
              type={modalType}
              value={modalValue}
              onAutocompleteChange={handleAutocompleteChange}
              toggleOpen={toggleOpen}
              setValue={setModalValue}
              setOptions={modalOptions}
            />
          </div>
        </Modal>
      </div>
    );
  }
};

export default CreateContract;

var contractValidationSchema = null;

const Breadcrumb = ({ contractId }) => (
  <Grid xs={12} item>
    <HeaderChip
      icon={
        <configs.icons.ShoppingCartIcon sx={{ color: "#fff" + "!important" }} />
      }
      label={`Contract > ${contractId ? "Edit" : "Create"}`}
    />
  </Grid>
);

const InputComponent = ({
  data,
  values,
  options,
  onChanges,
  errors,
  helperTexts,
  disabled,
  toggleOpen,
  setDialogValue,
  loading,
  hides,
}) => {
  return (
    <Grid xs={12} item>
      <MUICard title={data?.label}>
        <Grid spacing={2} container>
          {data?.fields?.map((field) => {
            const textfieldProps = {};

            if (field.type === "autocomplete") {
              textfieldProps.name = field?.id || field?.name || "default";
              textfieldProps.loading = loading && loading[field?.id];
              textfieldProps.value = values && values[field?.id];
              textfieldProps.options = options && options[field?.id];
              textfieldProps.onChange =
                onChanges && onChanges?.onAutocompleteChange;
              textfieldProps.error = errors && errors[field?.id];
              textfieldProps.helperText = helperTexts && helperTexts[field?.id];
              textfieldProps.disabled = disabled && disabled[field?.id];
              textfieldProps.creatable = field?.isCreatable;
              textfieldProps.toggleOpen = toggleOpen && toggleOpen[field?.id];
              textfieldProps.setDialogValue =
                setDialogValue && setDialogValue[field?.id];
            } else {
              textfieldProps.Type = field?.type || "text";
              textfieldProps.Name = field?.id || field?.name || "default";
              textfieldProps.Value = values && values[field?.id];
              textfieldProps.SelectOptions = options && options[field?.id];
              textfieldProps.OnChange = onChanges && onChanges?.onInputChange;
              textfieldProps.Error = errors && errors[field?.id];
              textfieldProps.helperText = helperTexts && helperTexts[field?.id];
              textfieldProps.Disabled = disabled && disabled[field?.id];
              textfieldProps.Select = field.type === "select" ? true : false;
            }

            return (
              <>
                {hides && hides[field?.id] ? null : (
                  <Grid sm={field?.col?.sm} md={field?.col?.md} item>
                    <MUIInputLabel>
                      {field.isRequired && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      {field?.label}
                    </MUIInputLabel>
                    {field?.type === "autocomplete" ? (
                      <MUIAutocomplete {...textfieldProps} />
                    ) : (
                      <MUITextField {...textfieldProps} />
                    )}
                  </Grid>
                )}
              </>
            );
          })}
        </Grid>
      </MUICard>
    </Grid>
  );
};

const FeaturesComponent = ({ options, value, onChange }) => {
  return (
    <Grid md={3} item>
      <MUICard title={"Features"}>
        <MUIInputLabel>Select Type</MUIInputLabel>
        <MUITextField
          Name={"contract_type"}
          Select
          SelectOptions={options}
          Value={value}
          OnChange={onChange}
        />
      </MUICard>
    </Grid>
  );
};

const ModalsGroup = ({
  organizationId,
  creatorId,
  type,
  value,
  onAutocompleteChange,
  toggleOpen,
  setValue,
  setOptions,
}) => {
  // Collecting common props:
  const props = {
    organizationId,
    creatorId,
    value: value,
    setSelectedOption: (selectedValue) =>
      onAutocompleteChange(null, selectedValue),
    toggleOpen: toggleOpen,
    setValue: setValue,
  };

  switch (type) {
    case "category":
      return (
        <CreateCategory
          {...props}
          setOptions={setOptions[type].setCategories}
        />
      );
    case "status":
      return (
        <CreateStatus {...props} setOptions={setOptions[type].setStatuses} />
      );
    case "business_unit":
      return (
        <CreateBusinessUnit
          {...props}
          setOptions={setOptions[type].setBusinessUnits}
        />
      );
    case "payment_terms":
      return (
        <CreatePaymentTerms
          {...props}
          setOptions={setOptions[type].setPaymentTerms}
        />
      );
    case "upload_files":
      return (
        <CreateFiles {...props} setOptions={setOptions[type].setAttachments} />
      );
    default:
      return null;
  }
};

const formConfig = [
  {
    id: "general_information",
    label: "General Information",
    fields: [
      {
        id: "organization_id",
        label: "Select Organization",
        name: "organization_id",
        type: "autocomplete",
        isRequired: true,
        isCreatable: false,
        col: {
          md: 12,
          sm: 12,
        },
      },
      {
        id: "name",
        label: "Name",
        name: "name",
        type: "text",
        isRequired: true,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "number",
        label: "Number",
        name: "number",
        type: "text",
        isRequired: true,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "description",
        label: "Description",
        name: "description",
        type: "text",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 12,
          sm: 6,
        },
      },
      {
        id: "country",
        label: "Country",
        name: "country",
        type: "autocomplete",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "status_id",
        label: "Status",
        name: "status_id",
        type: "autocomplete",
        isRequired: true,
        isCreatable: true,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "category_id",
        label: "Category",
        name: "category_id",
        type: "autocomplete",
        isRequired: true,
        isCreatable: true,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "customer_id",
        label: "Customer",
        name: "customer_id",
        type: "autocomplete",
        isRequired: true,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "supplier_id",
        label: "Supplier",
        name: "supplier_id",
        type: "autocomplete",
        isRequired: true,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "integrator_id",
        label: "Integrator",
        name: "integrator_id",
        type: "autocomplete",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
    ],
  },
  {
    id: "business_unit",
    label: "Business Unit",
    fields: [
      {
        id: "business_unit_id",
        label: "Business Unit",
        name: "business_unit_id",
        type: "autocomplete",
        isRequired: false,
        isCreatable: true,
        col: {
          md: 6,
          sm: 6,
        },
      },
    ],
  },
  {
    id: "timeline_terms",
    label: "Timeline/Terms",
    fields: [
      {
        id: "contract_start_date",
        label: "Contract Start Date",
        name: "contract_start_date",
        type: "date",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "contract_end_date",
        label: "Contract End Date",
        name: "contract_end_date",
        type: "date",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "notice_period",
        label: "Notice Period",
        name: "notice_period",
        type: "text",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "service_start_date",
        label: "Service Start Date",
        name: "service_start_date",
        type: "date",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "end_of_term_clause",
        label: "End Of Term Clause",
        name: "end_of_term_clause",
        type: "select",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "end_of_term_action",
        label: "End Of Term Action",
        name: "end_of_term_action",
        type: "select",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "timeline_notes",
        label: "Timeline Notes",
        name: "timeline_notes",
        type: "text",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
    ],
  },
  {
    id: "financials",
    label: "Financials",
    fields: [
      {
        id: "total_contract_spend_value",
        label: "Total Contract Spend Value",
        name: "total_contract_spend_value",
        type: "text",
        isRequired: true,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "non_recurring_cost",
        label: "Non Recurring Cost",
        name: "non_recurring_cost",
        type: "text",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "monthly_recurring_cost",
        label: "Monthly Recurring Cost",
        name: "monthly_recurring_cost",
        type: "text",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "payment_terms_id",
        label: "Payment Terms",
        name: "payment_terms_id",
        type: "autocomplete",
        isRequired: false,
        isCreatable: true,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "financing",
        label: "Financing",
        name: "financing",
        type: "select",
        isRequired: true,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
      {
        id: "financial_notes",
        label: "Financial Notes",
        name: "financial_notes",
        type: "text",
        isRequired: false,
        isCreatable: false,
        col: {
          md: 6,
          sm: 6,
        },
      },
    ],
  },
];

const defaultInitialValues = {
  organization_id: null,
  contract_type: "sell_side",

  name: "",
  number: "",
  country: null,
  description: "",
  category_id: null,
  customer_id: null,
  supplier_id: null,
  integrator_id: null,

  status_id: null,

  business_unit_id: null,

  contract_start_date: null,
  contract_end_date: null,
  notice_period: null,
  service_start_date: null,
  end_of_term_clause: null,
  end_of_term_action: null,
  timeline_notes: "",

  total_contract_spend_value: "",
  non_recurring_cost: "",
  monthly_recurring_cost: "",
  payment_terms_id: null,
  financing: null,
  financial_notes: "",
  custom_forms: [],
};

const schemaTypeSellSide = Yup.object().shape({
  organization_id: Yup.object().required("Organization is required!"),
  name: Yup.string().required("Name is required!"),
  number: Yup.string().required("Number is required!"),
  category_id: Yup.object().required("Category is required!"),
  customer_id: Yup.object().required("Customer is required!"),
  status_id: Yup.object().required("Status is required!"),
  contract_start_date: Yup.string().required("Start date is required!"),
  contract_end_date: Yup.string().required("End date is required!"),
  total_contract_spend_value: Yup.string().required(
    "Total contract spend value is required!"
  ),
  end_of_term_action: Yup.string().required("End of term action is required!"),
  financing: Yup.string().required("Financing is required!"),
});

const schemaTypeBuySide = Yup.object().shape({
  organization_id: Yup.object().required("Organization is required!"),
  name: Yup.string().required("Name is required!"),
  number: Yup.string().required("Number is required!"),
  category_id: Yup.object().required("Category is required!"),
  supplier_id: Yup.object().required("Supplier is required!"),
  status_id: Yup.object().required("Status is required!"),
  contract_start_date: Yup.string().required("Start date is required!"),
  contract_end_date: Yup.string().required("End date is required!"),
  total_contract_spend_value: Yup.string().required(
    "Total contract spend value is required!"
  ),
  end_of_term_action: Yup.string().required("End of term action is required!"),
  financing: Yup.string().required("Financing is required!"),
});

const getCountry = (data) => {
  const addressOptions = {};
  const getAllCountries = Country.getAllCountries();
  const matchedCountry = getAllCountries.filter(
    (country) =>
      (country?.name).toLowerCase() === (data?.country).toLowerCase() ||
      country?.isoCode === data?.country
  )[0];

  if (matchedCountry)
    return {
      id: matchedCountry.isoCode,
      label: matchedCountry.name,
      value: matchedCountry.name,
      name: "country",
    };
  else return null;
};
