import { useState, useEffect } from "react";
import { getContractByID } from "src/services/contracts.services";
import contractTypes from "src/data/contractType.data.json";

const useGetContractByID = (id) => {
  const [contract, setContract] = useState(null);

  useEffect(() => {
    getContractByID(id)
      .then((response) => {
        const { data } = response;
        const contractJSON = data;
        contractJSON.contractType = getContractType(contractJSON.contract_type);
        contractJSON.vendor = getVendor(contractJSON);
        setContract(contractJSON);
      })
      .catch((error) => console.log("useGetContractByID: ", error.message));
  }, [id]);

  return [contract, setContract];
};
export default useGetContractByID;

const getContractType = (id) => {
  const types = contractTypes?.data;
  const matchedType = types.filter((type) => type.id === id);
  if (matchedType && matchedType.length) return matchedType[0].label;
  else return "Not Available";
};

const getVendor = (data) => {
  if (data.contract_type === "sell_side") return data.customer;
  else return data.supplier;
};
