import configs from "src/configs/configs";

const sidebarItems = [
  {
    id: "dashboard",
    route: "/",
    icon: configs.icons.Dashboard,
    label: "Dashboard",
    key: "dashboard",
    sup: "Dev",
    enableDropdown: false,
  },
  {
    id: "manage",
    route: "/menu",
    label: "Manage",
    key: "manage",
    enableDropdown: true,
    menu: [
      {
        id: "manage_organizations",
        route: "/manage/organizations",
        icon: configs.icons.BusinessIcon,
        label: "Organizations",
        key: "organizations",
        enableDropdown: false,
      },
      {
        id: "manage_users",
        route: "/manage/users",
        icon: configs.icons.PersonIcon,
        label: "Users",
        key: "users",
        enableDropdown: false,
      },
      {
        id: "manage_customers",
        route: "/manage/customers",
        icon: configs.icons.PeopleIcon,
        label: "Customers",
        key: "customers",
        enableDropdown: false,
      },
      {
        id: "manage_suppliers",
        route: "/manage/suppliers",
        icon: configs.icons.ShoppingCartIcon,
        label: "Suppliers",
        key: "suppliers",
        enableDropdown: false,
      },
      {
        id: "manage_integrators",
        route: "/manage/integrators",
        icon: configs.icons.HandshakeRoundedIcon,
        label: "Integrators",
        key: "integrators",
        enableDropdown: false,
      },
      {
        id: "manage_contracts",
        route: "/manage/contracts",
        icon: configs.icons.DescriptionIcon,
        label: "Contracts",
        key: "contracts",
        enableDropdown: false,
      },
      {
        id: "manage_assets",
        route: "/manage/assets",
        icon: configs.icons.LayersIcon,
        label: "Assets & Services",
        key: "assets",
        enableDropdown: false,
      },
      {
        id: "manage_custom_forms",
        route: "/manage/custom_forms",
        icon: configs.icons.SummarizeIcon,
        label: "Custom Forms",
        key: "custom_forms",
        enableDropdown: false,
      },
      {
        id: "manage_embedded_analytics",
        route: "/manage/embedded_analytics",
        icon: configs.icons.AnalyticsIcon,
        label: "Embedded Analytics",
        sup: "Dev",
        key: "embedded_analytics",
        enableDropdown: false,
      },
    ],
  },
  {
    id: "configurations",
    route: "/configurations",
    icon: configs.icons.BuildSharpIcon,
    label: "Configurations",
    sup: "Dev",
    key: "configurations",
    enableDropdown: true,
    menu: [
      {
        id: "configurations_roles",
        route: "/configurations/roles",
        icon: configs.icons.SettingsAccessibilityIcon,
        label: "User Roles",
        key: "roles",
        enableDropdown: false,
      },
    ],
  },
  // {
  //   id: "playground",
  //   route: "/playground",
  //   icon: configs.icons.EditIcon,
  //   label: "Playground",
  //   key: "playground",
  //   enableDropdown: false,
  // },
];
export default sidebarItems;
