import { Grid } from "@mui/material";
import { useState } from "react";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUICard from "src/components/MUICard/MUICard.component";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import { createCategory } from "src/services/category.services";

const CreateCategory = ({
  organizationId,
  creatorId,
  value,
  setSelectedOption,
  toggleOpen,
  setOptions,
  setValue,
}) => {
  if (!organizationId) throw new Error("Props 'organizationId' is mandatory!");
  if (!creatorId) throw new Error("Props 'creatorId' is mandatory!");

  const [isCreating, setIsCreating] = useState(false);

  const handleSubmit = async () => {
    const requestBody = {
      organization_id: organizationId ? organizationId : "",
      name: value?.label,
      description: value?.description,
      created_by: creatorId ? creatorId : "",
    };

    try {
      setIsCreating(true);
      const response = await createCategory(requestBody);
      // const response = { data: { id: "1234" } };

      setOptions((prev) => [
        ...prev,
        {
          id: response?.data?.id,
          label: value?.label,
          value: response?.data?.id,
          name: "category_id",
        },
      ]);

      setSelectedOption({
        id: response?.data?.id,
        label: value?.label,
        value: response?.data?.id,
        name: "category_id",
      });

      toggleOpen((e) => !e);

      setValue({
        label: "",
        description: "",
      });

      setIsCreating(false);
    } catch (error) {
      setIsCreating(false);
      console.log("CreateCategory | handleSubmit: ", requestBody);
      console.log("handleSubmit", error.message);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MUICard title={"Create Category"} style={{ width: "500px" }}>
        <Grid spacing={2} container>
          <Grid sm={12} item>
            <MUIInputLabel>Category Name</MUIInputLabel>
            <MUITextField
              autoFocus
              Value={value?.label}
              OnChange={(e) =>
                setValue((prev) => ({ ...prev, label: e.target.value }))
              }
              Disabled={isCreating}
            />
          </Grid>
          <Grid sm={12} item>
            <MUIInputLabel>Description</MUIInputLabel>
            <MUITextField
              Value={value?.description}
              OnChange={(e) =>
                setValue((prev) => ({ ...prev, description: e.target.value }))
              }
              IsMultiLine
              Disabled={isCreating}
            />
          </Grid>
          <Grid sm={8} item></Grid>
          <Grid sm={4} item>
            <MUIButton
              onClick={handleSubmit}
              isLoading={isCreating}
              isLoadingButton
              primary
            >
              Create
            </MUIButton>
          </Grid>
        </Grid>
      </MUICard>
    </div>
  );
};
export default CreateCategory;
