import { Avatar, Grid, Modal } from "@mui/material";
import "./Playground.style.css";
import { useState } from "react";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import UploadFiles from "src/components/UploadFiles/UploadFiles.component";
import MUICard from "src/components/MUICard/MUICard.component";
import configs from "src/configs/configs";
import ImportDialog from "src/components/ImportDialog/ImportDialog.component";
import CreateContract from "../ManageContracts/CreateContract/CreateContract.page";

const PlayGround = () => {
  return <CreateContract />;
};

export default PlayGround;

const importBtn = {
  width: "200px",
  margin: "0 auto",
};

const containerStyle = {
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

// const PlayGround = () => {
//   const [uploadedFiles, setUploadedFiles] = useState([]);

//   const handleUpload = async (files) => {
//     try {
//       const response = await uploadFiles(files);
//       setUploadedFiles((prevState) => [...prevState, ...response.data]);
//     } catch (error) {
//       console.log("Error upload: ", error?.message);
//     }
//   };
//   return (
//     <div>
//       {uploadedFiles.length ? (
//         <div style={{ width: "800px", margin: "auto" }}>
//           <MUICard title={"Attached files"}>
//             {uploadedFiles.map((file, index) => (
//               <div>
//                 {index !== 0 ? <AppSpacer vertical={"12px"} /> : null}
//                 <Alert variant="outlined" severity={"success"}>
//                   {file?.name} {file?.size && `(${formatFileSize(file?.size)})`}
//                 </Alert>
//               </div>
//             ))}
//           </MUICard>
//         </div>
//       ) : null}
//       <UploadFiels onUpload={handleUpload} />
//     </div>
//   );
// };

// import MUICard from "src/components/MUICard/MUICard.component";
// import { useDropzone } from "react-dropzone";
// import { useCallback, useState } from "react";
// import configs from "src/configs/configs";
// import { Alert, AlertTitle, Grid, Typography } from "@mui/material";
// import MUIButton from "src/components/MUIButton/MUIButton.component";
// import AppSpacer from "src/components/AppSpace/AppSpace.component";
// import formatFileSize from "src/helpers/formatFileSize.helper";

// const PlayGround = () => {
//   const [file, setFile] = useState([]);
//   const [rejectedFiles, setRejectedFiles] = useState([]);

//   const onDrop = useCallback((acceptedFiles) => {
//     setRejectedFiles([]);
//     setFile(acceptedFiles);
//   }, []);

//   const onDropRejected = useCallback((rejected) => {
//     setRejectedFiles(rejected.map((f) => f.file));
//   }, []);

//   const { getRootProps, getInputProps, isDragAccept } = useDropzone({
//     onDrop,
//     onDropRejected,
//     multiple: true,
//     maxFiles: 10,
//     maxSize: 1 * 1024 * 1024, // 5 MB
//   });

//   const props_DragAndDropFiles = {};
//   props_DragAndDropFiles.isDragAccept = isDragAccept;
//   props_DragAndDropFiles.value = file && file.join(", ");

//   alertItems[0].data = file;
//   alertItems[1].data = rejectedFiles;

//   return (
//     <div>
//       <MUICard title={"Upload and attach file"}>
//         <div {...getRootProps({ className: "dropzone" })}>
//           <input {...getInputProps()} />
//           <DragAndDropFiles {...props_DragAndDropFiles} />
//         </div>
//         <AppSpacer vertical={"12px"} />
//         {rejectedFiles.length > 10 && (
//           <AlertComponent severity="error" title="Files limit exceeded 10" />
//         )}
//         {alertItems.map((item) => {
//           return item.data.map((i, index) => (
//             <AlertComponent
//               key={index}
//               filename={i.name}
//               fileSize={i.size}
//               severity={item.severity}
//               title={item?.title}
//             />
//           ));
//         })}

//         {file[0] || rejectedFiles[0] ? (
//           <Grid justifyContent={"center"} spacing={2} container>
//             <Grid sm={2} item>
//               <MUIButton
//                 variant={"outlined"}
//                 onClick={() => {
//                   setFile([]);
//                   setRejectedFiles([]);
//                 }}
//                 primary
//               >
//                 Cancel
//               </MUIButton>
//             </Grid>
//             <Grid sm={2} item>
//               <MUIButton primary>Upload</MUIButton>
//             </Grid>
//           </Grid>
//         ) : null}
//       </MUICard>
//     </div>
//   );
// };
// export default PlayGround;

// /**
//  *
//  * @param {Object} props - Component properties
//  * @param {string} props.value - Component name
//  * @param {Boolean} props.isDragAccept - Component props
//  * @returns
//  */
// const DragAndDropFiles = (props) => {
//   let class_dragContainer = "drag-container";
//   class_dragContainer += props?.isDragAccept ? " drag-accept" : "";

//   return (
//     <div className={class_dragContainer}>
//       <Grid spacing={1} container>
//         <Grid sm={12} item>
//           {props?.isDragAccept ? (
//             <configs.icons.NoteAddRoundedIcon
//               sx={{ fontSize: "68px" }}
//               color="success"
//             />
//           ) : (
//             <configs.icons.UploadFileRoundedIcon
//               sx={{ fontSize: "68px", color: configs.colors.primary }}
//             />
//           )}
//         </Grid>
//         <Grid sm={12} item>
//           {false ? (
//             <Typography variant="body1">
//               {/* <b>File: </b>
//               {props?.value?.name}
//               <br />
//               <b>Size:</b> {formatFileSize(props?.value?.size)} */}
//             </Typography>
//           ) : (
//             <span>
//               <Typography variant="body1">
//                 <u style={{ cursor: "pointer" }}>Click to upload</u> or drag and
//                 drop
//               </Typography>
//               <Typography color={"GrayText"} variant="caption">
//                 Maximum file size 5 MB.
//               </Typography>
//             </span>
//           )}
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// /**
//  *
//  * @param {Object} props - Component props
//  * @param {string} props.severity - success | error
//  * @param {string} props.title - Component title
//  * @param {string} props.filename - Filename
//  * @param {string} props.fileSize - File size
//  * @param {void} props.onClose - Component action
//  * @returns
//  */
// const AlertComponent = (props) => {
//   const { severity, title, filename, fileSize, onClose } = props;
//   return (
//     <>
//       <Alert variant="outlined" severity={severity} onClose={onClose}>
//         {title && <AlertTitle>{title}</AlertTitle>}
//         {filename} {fileSize && `(${formatFileSize(fileSize)})`}
//       </Alert>
//       <AppSpacer vertical={"12px"} />
//     </>
//   );
// };

// const alertItems = [
//   {
//     id: "success-upload",
//     data: [],
//     severity: "success",
//   },
//   {
//     id: "error-upload",
//     data: [],
//     severity: "error",
//     title: "File size exceeded 5 MB",
//   },
// ];
