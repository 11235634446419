import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUICard from "src/components/MUICard/MUICard.component";

const FormActions = ({
  formik,
  isLoading,
  submitLabel,
  onSubmit,
  onReset,
  disabledSubmit,
}) => {
  const params = useParams();
  const isIdAvail = params?.id;

  const handleOnSubmit = onSubmit || formik?.handleSubmit;
  const handleOnReset = onReset || formik?.handleReset;

  return (
    <MUICard>
      <Grid spacing={2} container>
        <Grid md={8} item></Grid>
        <Grid md={2} item>
          <MUIButton color={"error"} onClick={handleOnReset}>
            {isIdAvail ? "Cancel" : "Reset"}
          </MUIButton>
        </Grid>
        <Grid md={2} item>
          <MUIButton
            primary
            isLoading={isLoading}
            onClick={handleOnSubmit}
            isLoadingButton
            disabled={disabledSubmit}
          >
            {submitLabel ? submitLabel : isIdAvail ? "Update" : "Register"}
            {/* {isIdAvail ? "Update" : submitLabel ? submitLabel : "Register"} */}
          </MUIButton>
        </Grid>
      </Grid>
    </MUICard>
  );
};
export default FormActions;
