import {
  FormControlLabel,
  FormGroup,
  Grid,
  Checkbox,
  Typography,
  Modal,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUICard from "src/components/MUICard/MUICard.component";
import configs from "src/configs/configs";
import useApplicationRoles from "src/hooks/useApplicationRoles.hook";
import FormActions from "src/pages/ManageOrganizations/RegisterOrganization/components/FormActions/FormActions.component";
import CreateRole from "../CreateRole/CreateRole.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import { updateRealmsRoleByName } from "src/services/roles.service";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";

const ViewRoleDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  const applicationRoles = useApplicationRoles();
  const [attributes, setAttributes] = useState(null);
  const [open, toggleOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [incomingResponse, setIncomingResponse] = useState(null);
  const { role, isRoleLoading } = applicationRoles.useGetRoleByName(
    params?.id,
    isSaving
  );

  useEffect(() => {
    if (role) setAttributes(role?.attributes);
  }, [role]);

  const handleToggleOpen = () => toggleOpen((e) => !e);

  const handleOnChange = (mod, access) => {
    const modAttributes = attributes[mod?.id] || []; // Module's attributes

    if (modAttributes?.includes(access?.id)) {
      const newModAttr = modAttributes.filter((attr) => attr !== access?.id);
      setAttributes((prevState) => ({ ...prevState, [mod.id]: newModAttr }));
    } else {
      const newModAttr = [...modAttributes, access?.id];
      setAttributes((prevState) => ({ ...prevState, [mod.id]: newModAttr }));
    }
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    const requestBody = {
      name: params?.id,
      description: role?.description,
      attributes,
    };

    try {
      await updateRealmsRoleByName(params?.id, requestBody);
      setIncomingResponse({
        severity: "success",
        message: "Role is updated successfully",
      });
      setIsSaving(false);
    } catch (error) {
      setIncomingResponse({
        severity: "error",
        message: error?.message,
      });
      console.error("Error: ", error?.response?.data);
    }
  };

  if (isRoleLoading) return <PageLoader />;
  else
    return (
      <div>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <Grid spacing={2} container>
          <Grid xs={12} item container>
            <Grid sm={6} item>
              <HeaderChip
                label={"Roles > " + (role?.role_name || "")}
                icon={
                  <configs.icons.SettingsAccessibilityIcon
                    sx={{ color: "#fff" + "!important" }}
                  />
                }
              />
            </Grid>

            <Grid sm={4} item></Grid>
            <Grid sm={2} item>
              <MUIButton
                onClick={handleToggleOpen}
                startIcon={<configs.icons.AddIcon />}
                primary
              >
                Add Role
              </MUIButton>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <MUICard>
              <Typography variant="h4">{role?.role_name}</Typography>
              <Typography variant="body1">{role?.description}</Typography>
            </MUICard>
          </Grid>
          <Grid xs={12} item>
            <MUICard title={"Permissions"}>
              <Grid spacing={2} item container>
                {modules.map((module) => (
                  <ModuleComponent
                    key={module?.id}
                    data={module}
                    attributes={attributes}
                    onChange={handleOnChange}
                    isLoading={isSaving}
                  />
                ))}
              </Grid>
            </MUICard>
          </Grid>
          <Grid xs={12} item>
            <FormActions
              onReset={() => navigate("/configurations/roles")}
              submitLabel={"Save Changes"}
              onSubmit={handleSaveChanges}
              isLoading={isSaving}
            />
          </Grid>
        </Grid>

        <Modal open={open} onClose={handleToggleOpen}>
          <div>
            <CreateRole toggleOpen={toggleOpen} />
          </div>
        </Modal>
      </div>
    );
};

export default ViewRoleDetails;

const ModuleComponent = ({ data, attributes, onChange, isLoading }) => {
  return (
    <Grid sm={3} item>
      <MUICard silentHeader title={data?.label}>
        <FormGroup>
          {data?.access?.map((acc) => (
            <FormControlLabel
              key={acc.id}
              control={
                <Checkbox
                  disabled={isLoading}
                  checked={
                    attributes && attributes[data?.id]?.includes(acc?.id)
                      ? true
                      : false
                  }
                  onChange={() => onChange(data, acc)}
                  sx={{
                    color: configs?.colors.primary,
                    "&.Mui-checked": {
                      color: configs?.colors.primary,
                    },
                  }}
                />
              }
              label={acc?.label}
            />
          ))}
        </FormGroup>
      </MUICard>
    </Grid>
  );
};

const modules = [
  {
    id: "users",
    label: "Manage Users",
    access: [
      {
        id: "CREATE",
        label: "Create",
        value: "CREATE",
      },
      {
        id: "READ",
        label: "Read",
        value: "READ",
      },
      {
        id: "UPDATE",
        label: "Update",
        value: "UPDATE",
      },
      {
        id: "DELETE",
        label: "Delete",
        value: "DELETE",
      },
      {
        id: "EXPORT",
        label: "Export",
        value: "EXPORT",
      },
    ],
  },
  {
    id: "customers",
    label: "Manage Customers",
    access: [
      {
        id: "CREATE",
        label: "Create",
        value: "CREATE",
      },
      {
        id: "READ",
        label: "Read",
        value: "READ",
      },
      {
        id: "UPDATE",
        label: "Update",
        value: "UPDATE",
      },
      {
        id: "DELETE",
        label: "Delete",
        value: "DELETE",
      },
      {
        id: "EXPORT",
        label: "Export",
        value: "EXPORT",
      },
    ],
  },
  {
    id: "suppliers",
    label: "Manage Suppliers",
    access: [
      {
        id: "CREATE",
        label: "Create",
        value: "CREATE",
      },
      {
        id: "READ",
        label: "Read",
        value: "READ",
      },
      {
        id: "UPDATE",
        label: "Update",
        value: "UPDATE",
      },
      {
        id: "DELETE",
        label: "Delete",
        value: "DELETE",
      },
      {
        id: "EXPORT",
        label: "Export",
        value: "EXPORT",
      },
    ],
  },
  {
    id: "contracts",
    label: "Manage Contracts",
    access: [
      {
        id: "CREATE",
        label: "Create",
        value: "CREATE",
      },
      {
        id: "READ",
        label: "Read",
        value: "READ",
      },
      {
        id: "UPDATE",
        label: "Update",
        value: "UPDATE",
      },
      {
        id: "DELETE",
        label: "Delete",
        value: "DELETE",
      },
      {
        id: "IMPORT",
        label: "Import",
        value: "IMPORT",
      },
      {
        id: "EXPORT",
        label: "Export",
        value: "EXPORT",
      },
    ],
  },
  {
    id: "assets",
    label: "Manage Assets",
    access: [
      {
        id: "CREATE",
        label: "Create",
        value: "CREATE",
      },
      {
        id: "READ",
        label: "Read",
        value: "READ",
      },
      {
        id: "UPDATE",
        label: "Update",
        value: "UPDATE",
      },
      {
        id: "DELETE",
        label: "Delete",
        value: "DELETE",
      },
      {
        id: "IMPORT",
        label: "Import",
        value: "IMPORT",
      },
      {
        id: "EXPORT",
        label: "Export",
        value: "EXPORT",
      },
    ],
  },
  {
    id: "custom_forms",
    label: "Manage Custom Forms",
    access: [
      {
        id: "CREATE",
        label: "Create",
        value: "CREATE",
      },
      {
        id: "READ",
        label: "Read",
        value: "READ",
      },
      {
        id: "UPDATE",
        label: "Update",
        value: "UPDATE",
      },
      {
        id: "DELETE",
        label: "Delete",
        value: "DELETE",
      },
    ],
  },
  {
    id: "embedded_analytics",
    label: "Embedded Analytics",
    access: [
      {
        id: "CREATE",
        label: "Create",
        value: "CREATE",
      },
      {
        id: "READ",
        label: "Read",
        value: "READ",
      },
      {
        id: "UPDATE",
        label: "Update",
        value: "UPDATE",
      },
      {
        id: "DELETE",
        label: "Delete",
        value: "DELETE",
      },
    ],
  },
];
