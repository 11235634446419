import configs from "src/configs/configs";
import CardTitle from "src/components/CardTitle/CardTitle.component";
import MUICard from "src/components/MUICard/MUICard.component";
import registerOrganizationFormFields from "./registerOrganizationFormFields.config";
import { Grid, Switch, Tooltip } from "@mui/material";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import MUIAutocomplete from "src/components/MUIAutocomplete/MUIAutocomplete.component";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import { useParams } from "react-router-dom";
import MUISwitchButton from "src/components/MUISwitchButton/MUISwitchButton.component";

const RegisterOrganizationForm = ({
  formik,
  isRegistering,
  options,
  handleInputChange,
  handleAutocompleteChange,
  autocompleteValues,
  disabled,
}) => {
  const params = useParams();
  const organizationId = params?.id;

  return (
    <MUICard
      title={
        <CardTitle
          title={`${organizationId ? "Update" : "Register"} Organization`}
          icon={
            <configs.icons.BusinessIcon
              sx={{ color: configs.colors.primary }}
            />
          }
        />
      }
      action={
        organizationId ? (
          <MUISwitchButton
            name={`organization["is_active"]`}
            is_active={formik.values.organization.is_active || false}
            onChange={handleInputChange}
            disabled={disabled}
          />
        ) : null
      }
    >
      {/* {children} */}
      <Grid spacing={2} container>
        {registerOrganizationFormFields.map((field) => (
          <Grid md={field.col.md} key={field.id} item>
            <MUIInputLabel>
              {field.isRequired && <span style={{ color: "red" }}>*</span>}
              {field.label}
            </MUIInputLabel>

            {/*  */}
            {field.type === "text" ? (
              <MUITextField
                Name={`organization[${field.name}]`}
                Type={field.type}
                Value={formik?.values?.organization[field.id]}
                OnChange={handleInputChange}
                Error={
                  formik?.touched?.organization &&
                  formik?.errors?.organization &&
                  formik?.errors?.organization[field.id]
                }
                helperText={
                  formik?.touched?.organization &&
                  formik?.errors?.organization &&
                  formik?.errors?.organization[field.id]
                }
                Disabled={disabled || isRegistering}
              />
            ) : null}

            {/*  */}
            {field.type === "select" ? (
              <MUIAutocomplete
                value={autocompleteValues[field.id]}
                name={field.name}
                options={options[field.id]}
                onChange={handleAutocompleteChange}
                error={
                  formik?.touched?.organization &&
                  formik?.touched?.organization[field?.id] &&
                  formik?.errors?.organization &&
                  formik?.errors?.organization[field.id]
                }
                helperText={
                  formik?.touched?.organization &&
                  formik?.touched?.organization[field?.id] &&
                  formik?.errors?.organization &&
                  formik?.errors?.organization[field.id]
                }
                disabled={disabled || isRegistering}
              />
            ) : null}
          </Grid>
        ))}
      </Grid>
    </MUICard>
  );
};

export default RegisterOrganizationForm;
