import { get, post, put, remove } from "../utils/axios/axios.utils";
const path = "/dashboards";
export const getDashboards = async (params) => {
  try {
    const response = await get(`${path}`, {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// Service for fetching a dashboard by ID:
// export const getDashboardByOrgID = async ({ searchKeyword, orgId }) => {
//   try {
//     if (!orgId) throw new Error("Organization ID is missing.");

//     console.log("orgId", orgId);

//     const response = await get(`${path}`, {
//       params: {
//         searchKeyword,
//         organizationId: orgId,
//       },
//     });
//     return response;
//   } catch (error) {
//     console.error("Error:", error);
//     throw error;
//   }
// };

// Service for fetching a dashboard by ID:
export const getDashboardByID = async (dashboardId) => {
  try {
    if (!dashboardId) throw new Error("Dashboard ID is missing.");

    const response = await get(`${path}/${dashboardId}`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const createDashboard = async (data) => {
  try {
    const response = await post("/dashboards", data);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
/* 
// Updating organization details
export const updateOrganizationDetails = async (organizationId, data) => {
  try {
    const result = await put(path + `/${organizationId}`, data);
    return result;
  } catch (error) {
    throw error;
  }
};

// Deleting an Orgnization
export const deleteOrganization = async (orgId) => {
  try {
    const result = await remove(`${path}/${orgId}`);
    return result;
  } catch (error) {
    console.error("deleteOrganization:", error.message);
    throw error;
  }
};
 */
