import { useSelector } from "react-redux";

const { useState, useEffect } = require("react");
const { getOrganizations } = require("src/services/organizations.services");

const useGetOrganizations = (searchText) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userRole = currentUser?.role || null;
  // const organizationID = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [organizations, setOrganizations] = useState([]);
  const [isOrganizationsLoading, setIsOrganizationsLoading] = useState(false);

  const searchKeyword = searchText ? searchText : "";

  // Function to fetch organizations:
  const fetchOrganizations = async () => {
    try {
      setIsOrganizationsLoading(true);
      const resp = await getOrganizations({ searchKeyword });

      // Converting response into autocomplete options:
      const autocompleteOptions = resp?.data?.map((organization) => ({
        id: organization.id,
        label: organization.name,
        value: organization.id,
        name: "organization_id",
      }));

      setOrganizations(autocompleteOptions);
      setIsOrganizationsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsOrganizationsLoading(false);
    }
  };

  useEffect(() => {
    if (userRole === "asset_eye_owner") {
      if (!searchText) fetchOrganizations();
      if (searchText && searchText.length > 3) fetchOrganizations();
    }
  }, [searchText]);

  return [organizations, isOrganizationsLoading];
};

export default useGetOrganizations;
