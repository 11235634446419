import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCustomForms } from "src/services/customForms.services";

const useGetCustomForms = (type, organizationId) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationId = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [customForms, setCustomForms] = useState([]);
  const [isCustomFormsLoading, setIsCustomFormsLoading] = useState(false);

  useEffect(() => {
    if (organizationId) {
      setIsCustomFormsLoading(true);
      getCustomForms({ type, organizationId }).then((response) => {
        const parsedCustomFormsOptions = response?.data?.map((cForm) => {
          return {
            id: cForm?.id,
            label: cForm?.name,
            value: cForm?.id,
            name: "custom_form",
          };
        });
        setCustomForms(parsedCustomFormsOptions);
        setIsCustomFormsLoading(false);
      });
    }
  }, [organizationId]);

  return [customForms, setCustomForms, isCustomFormsLoading];
};

export default useGetCustomForms;
