import { get, post, put, remove } from "../utils/axios/axios.utils";
const path = "/assets";

export const getAssets = async (params) => {
  try {
    const response = await get("/assets", {
      params,
    });
    return response;
  } catch (error) {
    console.log("getAssets: ", error.message);
    throw error;
  }
};

// Service for fetching an Assets by ID:
export const getAssetByID = async (assetId) => {
  try {
    if (!assetId) throw new Error("Asset ID is missing.");

    const response = await get(`/assets/${assetId}`);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

/**
 *
 * @param {object} data
 * @returns
 */
export const createAsset = async (data) => {
  try {
    const result = await post(path, data);
    return result;
  } catch (error) {
    throw error;
  }
};

export const updateAssetById = async (id, data) => {
  try {
    const result = await put(`assets/${id}`, data);
    return result;
  } catch (error) {
    throw error;
  }
};

export const getAssetsMetaData = async (params) => {
  try {
    const response = await get(path + "/data/meta-data", { params });

    return response.data;
  } catch (error) {
    console.log("getAssetsMetaData: ", error.message);
    throw error;
  }
};

// Deleting an Assets
export const deleteAsset = async (assetId) => {
  try {
    const result = await remove(`${path}/${assetId}`);
    return result;
  } catch (error) {
    console.error("deleteAssets:", error.message);
    throw error;
  }
};

/**
 *
 * @param {File} data
 * @returns
 */
export const importAssets = async (data) => {
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("organization_id", data.organization_id);

  try {
    const reponse = await post(`${path}/bulk-create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return reponse;
  } catch (error) {
    throw error;
  }
};
