import {
  Alert,
  AlertTitle,
  Avatar,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import MUICard from "../MUICard/MUICard.component";
import configs from "src/configs/configs";
import "./UploadFiels.style.css";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import formatFileSize from "src/helpers/formatFileSize.helper";
import AppSpacer from "../AppSpace/AppSpace.component";
import MUIButton from "../MUIButton/MUIButton.component";

const UploadFiles = ({ onUpload }) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((accepted) => {
    setAcceptedFiles(accepted);
  }, []);

  const onDropRejected = useCallback((rejected) => {
    const files = rejected.map((f) => {
      const newObj = f.file;
      newObj.errors = f.errors;

      return newObj;
    });
    setRejectedFiles(files);
  }, []);

  const dropZone = useDropzone({
    onDrop,
    onDropRejected,
    multiple: true,
    maxFiles: 10,
    maxSize: 1 * 1024 * 1024, // 5 MB
  });

  const handleResetUpload = () => {
    setAcceptedFiles([]);
    setRejectedFiles([]);
  };

  const handleOnUpload = async () => {
    setLoading(true);
    try {
      await onUpload(acceptedFiles);
      handleResetUpload();
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className={"drag-drop-contrainer"}>
      <MUICard>
        <DropZone dropZone={dropZone} loading={loading} />
        {acceptedFiles[0] || rejectedFiles[0] ? (
          <AppSpacer vertical={"12px"} />
        ) : null}
        <AlertBox acceptedFiles={acceptedFiles} rejectedFiles={rejectedFiles} />
      </MUICard>
      {acceptedFiles[0] || rejectedFiles[0] ? (
        <ActionsGroup
          onCancel={handleResetUpload}
          onUpload={handleOnUpload}
          disabledUpload={rejectedFiles[0] && !acceptedFiles[0]}
          loading={loading}
        />
      ) : null}
    </div>
  );
};

export default UploadFiles;

/**
 *
 * @param {Object} props - Component props
 * @param {void} props.onCancel - Handles cancel event
 * @param {void} props.onUpload - Handles upload event
 * @param {boolean} props.disabledCancel - Boolean
 * @param {boolean} props.disabledUpload - Boolean
 * @param {boolean} props.loading - Boolean
 * @returns
 */
const ActionsGroup = (props) => {
  return (
    <MUICard>
      <Grid spacing={2} container>
        <Grid sm={8} item></Grid>
        <Grid sm={2} item>
          <MUIButton
            variant={"outlined"}
            disabled={props.disabledCancel || props.loading}
            onClick={props.onCancel}
            primary
          >
            Cancel
          </MUIButton>
        </Grid>
        <Grid sm={2} item>
          <MUIButton
            onClick={props.onUpload}
            disabled={props.disabledUpload || props.loading}
            primary
          >
            Upload
          </MUIButton>
        </Grid>
      </Grid>
    </MUICard>
  );
};
const AlertBox = ({ acceptedFiles, rejectedFiles }) => {
  return (
    <>
      {acceptedFiles.map((f, i) => {
        const alertProps = {};
        alertProps.severity = "success";
        alertProps.filename = f.name;
        alertProps.fileSize = f.size;
        alertProps.index = i;
        alertProps.icon = <configs.icons.CloudUploadIcon />;

        return <AlertComponent {...alertProps} key={i} />;
      })}
      {rejectedFiles.map((f, i) => {
        const alertProps = {};
        alertProps.severity = "error";
        alertProps.filename = f.name;
        alertProps.fileSize = f.size;
        alertProps.title = errorMessages[f.errors[0].code];
        alertProps.icon = null;

        return <AlertComponent {...alertProps} key={i} />;
      })}
    </>
  );
};

/**
 *
 * @param {Object} props - Component props
 * @param {string} props.severity - success | error
 * @param {string} props.title - Component title
 * @param {string} props.filename - Filename
 * @param {string} props.fileSize - File size
 * @param {boolean} props.icon - File size
 * @param {void} props.onClose - Component action
 * @returns
 */
const AlertComponent = (props) => {
  const { severity, title, filename, fileSize, onClose, index, icon } = props;
  return (
    <>
      {index !== 0 ? <AppSpacer vertical={"12px"} /> : null}
      <Alert
        icon={icon}
        variant="outlined"
        severity={severity}
        onClose={onClose}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {filename} {fileSize && `(${formatFileSize(fileSize)})`}
      </Alert>
    </>
  );
};

const DropZone = ({ dropZone, loading }) => (
  <div {...dropZone.getRootProps({ className: "drag-drop-content" })}>
    <input {...dropZone.getInputProps()} />
    <Grid spacing={2} justifyContent={"center"} container>
      <FileThumbnail />
      <FileDropper />
    </Grid>
    {dropZone.isDragAccept || loading ? (
      <div className="drag-drop-absolute-content">
        {loading ? (
          <CircularProgress
            sx={{ color: configs.colors.primary }}
            size={"40px"}
          />
        ) : (
          <img src={configs.images.uploadEffect} />
        )}
      </div>
    ) : null}
  </div>
);

const FileThumbnail = () => (
  <Grid sm={2} item>
    <div className="file-thumbnail">
      <Avatar className="icon-wrapper">
        <configs.icons.CloudUploadIcon className="file-icon" />
      </Avatar>
    </div>
  </Grid>
);

const FileDropper = () => (
  <Grid sm={12} item spacing={1} container>
    <Grid sm={12} item>
      <Typography textAlign={"center"} variant="body1">
        <u style={{ cursor: "pointer" }}>Click to upload</u> or drag and drop
      </Typography>
    </Grid>
    <Grid sm={12} item>
      <Typography textAlign={"center"} color={"GrayText"} variant="body2">
        Maximum file size 5 MB. | Maximum files limit 10.
      </Typography>
    </Grid>
  </Grid>
);

const errorMessages = {
  ["file-too-large"]: "File size exceeded 5 MB",
  ["too-many-files"]: "File limit exceeded 10",
};
