// All Imports
import React, { useState, useEffect } from "react";
import { Grid, IconButton, Modal } from "@mui/material";
import DashboardCard from "../../../components/DashboardCard/DashboardCard.component";
import { useNavigate } from "react-router-dom";
import MUIButton from "../../../components/MUIButton/MUIButton.component";
import configs from "../../../configs/configs";
import {
  deleteUser,
  fetchUsersData,
  getUsers,
} from "../../../services/users.services";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import MUIGridTable from "src/components/MUIGridTable/MUIGridTable.component";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import { useSelector } from "react-redux";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import Searchbar from "src/components/Searchbar/Searchbar.component";
import DialogsGroup from "src/components/DialogsGroup/DialogsGroup.component";
import { exportToCSV, exportToXLSX } from "src/helpers/exportData.helper";
import getRoleName from "src/helpers/getRoleName.helper";

// Styles
const iconStyle = {
  color: "#5452e0",
};

function UserList() {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const organizationId = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions["users"] || null;

  // State Variables
  const [metaData, setMetaData] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isUsersFetched, setIsUsersFetched] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [incomingResponse, setIncomingResponse] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [openDialog, toggleDialog] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [dialogType, setDialogType] = useState(null);

  // Redirection
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/manage/users/register`;
    navigate(path);
  };

  // Handling clicks on rows
  const handleRowClick = (params) => {
    if (params.row && params.row.id) {
      let path = `/manage/users/edit/${params.row.id}`;
      if (userPermissions.includes("READ")) navigate(path);
    } else {
      console.error("Invalid row data:", params.row);
    }
  };

  // Implement a function for fetching user's meta data:
  const fetchUsersMetaData = async () => {
    try {
      const response = await fetchUsersData({ organizationId });
      setMetaData({
        allCount: response.count,
        activeCount: response.active,
        inActiveCount: response.in_active,
        listedIn7DaysCount: response.registered_within_7_Days,
      });
    } catch (error) {
      console.log("fetchUsersMetaData: ", error.message);
    }
  };

  // Handle get user's meta data:
  useEffect(() => {
    if (!isDeleting) fetchUsersMetaData();
  }, [isDeleting]);

  // Implement a function to fetch all users with required params.
  const fetchUsers = async () => {
    try {
      setIsUsersFetched(true);

      const { data, meta_data } = await getUsers({
        limit: paginationModel.pageSize,
        pageNumber: paginationModel.page + 1,
        searchKeyword: searchText,
        organizationId,
      });
      setRows(data);
      setRowsCount(meta_data.count);
      setIsLoading(false);
      setIsUsersFetched(false);
    } catch (error) {
      console.log("GET Users: ", error.message);
    }
  };

  // Handle get users:
  useEffect(() => {
    if (!isDeleting) {
      if (!searchText) fetchUsers();
      if (searchText && searchText.length > 3) fetchUsers();
    }
  }, [paginationModel, searchText, isDeleting]);

  const onClickRowDelete = (e, data) => {
    e.stopPropagation(); // Remove row click effect
    setDialogType("DELETE");
    toggleDialog(true); // Open confirmation dialog
    setDeleteID(data?.id); // Set ID to delete
  };

  const handleOnDelete = async () => {
    setIsDeleting(true);

    try {
      await deleteUser(deleteID); // Call delete organization service

      setIncomingResponse({
        severity: "success",
        message: "User is deleted successfully",
      });

      setIsDeleting(false);
      toggleDialog(false);
      setDeleteID(null);
    } catch (error) {
      setIncomingResponse({
        severity: "error",
        message: error?.message,
      });

      setIsDeleting(false);
    }
  };

  // Handled export functionality
  const onClickExport = (e, data) => {
    setDialogType("EXPORT");
    toggleDialog(true);
  };

  const handleOnExport = (value) => {
    const type = value.id;
    const filename = `users-${new Date().toISOString()}`;
    setIsExporting(true);

    switch (type) {
      case "csv":
        exportToCSV(rows, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      case "xlsx":
        exportToXLSX(rows, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      default:
        console.log("Invalid export format/type");
        setIsExporting(false);
        break;
    }
  };

  const dialogOptions = {
    EXPORT: {
      onConfirm: handleOnExport,
      isLoading: isExporting,
    },
    DELETE: {
      onConfirm: handleOnDelete,
      isLoading: isDeleting,
    },
  };

  // Defining an array
  const columns = [
    {
      field: "is_active",
      headerName: "",
      width: 40,
      align: "center",
      renderCell: (params) => (
        <span>
          {params.value ? (
            <configs.icons.CheckCircleIcon color="success" />
          ) : (
            <configs.icons.UnpublishedIcon color="error" />
          )}
        </span>
      ),
    },
    { field: "firstname", headerName: "First Name", width: 150 },
    { field: "lastname", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      renderCell: (params) => {
        const string = params?.value || "user";
        return (
          <span>{string?.charAt(0)?.toUpperCase() + string?.slice(1)}</span>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      renderCell: (params) => {
        return <span>{getRoleName(params?.value)}</span>;
      },
    },
    {
      field: "organization",
      headerName: "Organization",
      width: 300,
      renderCell: (params) => {
        return <span>{params?.value?.name}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          disabled={!userPermissions?.includes("DELETE")}
          onClick={(e) => onClickRowDelete(e, params)}
        >
          <configs.icons.Trash
            color={userPermissions?.includes("DELETE") ? "error" : "disabled"}
          />
        </IconButton>
      ),
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <HeaderChip
              label={"Users | Summaries"}
              icon={
                <configs.icons.PersonIcon
                  sx={{ color: "#fff" + "!important" }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} spacing={2} container>
            {cardsList.map((card) => (
              <Grid sm={3} key={card.id} item>
                <DashboardCard
                  icon={card.icon}
                  label={card.label}
                  count={metaData && metaData[card.id]}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <AppSpacer vertical={"20px"} />
        <Grid container item spacing={2} alignItems="center">
          <Grid item xs={6}>
            <HeaderChip
              label={"Users | List"}
              icon={
                <configs.icons.List sx={{ color: "#fff" + "!important" }} />
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Searchbar
              value={searchText}
              placeholder={"Search..."}
              onChange={(e) => setSearchText(e.target.value)}
              onClear={() => setSearchText(null)}
            />
          </Grid>
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermissions?.includes("EXPORT")}
              onClick={onClickExport}
              primary
            >
              Export
            </MUIButton>
          </Grid>

          <Grid item xs={1}>
            <MUIButton
              onClick={routeChange}
              sx={{
                "&:hover": {
                  backgroundColor: configs.colors.primary_800,
                  borderColor: configs.colors.primary,
                },
                backgroundColor: configs.colors.primary,
                borderColor: configs.colors.primary,
              }}
              disabled={!userPermissions?.includes("CREATE")}
            >
              Create
            </MUIButton>
          </Grid>

          <Grid xs={12} item>
            <MUIGridTable
              loading={isUsersFetched}
              columns={columns}
              rows={rows}
              rowCount={rowsCount}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              onRowClick={
                userPermissions.includes("READ") ? handleRowClick : null
              }
            />
          </Grid>
        </Grid>

        {/* Confirmation Dialog */}
        <Modal
          open={openDialog}
          onClose={() => (isDeleting ? {} : toggleDialog((e) => !e))}
        >
          <div className="dialog-container">
            <DialogsGroup
              type={dialogType}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={dialogOptions[dialogType]?.onConfirm}
              isLoading={dialogOptions[dialogType]?.isLoading}
            />
            {/* <ConfirmationDialog
              primaryLabel={"Delete"}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={handleOnDelete}
              isLoading={isDeleting}
            /> */}
          </div>
        </Modal>
      </div>
    );
  }
}

export default UserList;

const cardsList = [
  {
    id: "allCount",
    icon: <configs.icons.PersonIcon />,
    count: 0,
    label: "Users",
  },
  {
    id: "activeCount",
    icon: <configs.icons.CheckCircleIcon />,
    count: 0,
    label: "Active Users",
  },
  {
    id: "inActiveCount",
    icon: <configs.icons.UnpublishedIcon />,
    count: 0,
    label: "In-Active Users",
  },
  {
    id: "listedIn7DaysCount",
    icon: <configs.icons.SyncIcon />,
    count: 0,
    label: "Listed In Last 7 Days",
  },
];
