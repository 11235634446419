import { IconButton, InputAdornment } from "@mui/material";
import MUITextField from "../MUITextField/MUITextField.component";
import "./Searchbar.style.css";
import configs from "src/configs/configs";

const Searchbar = ({ label, placeholder, value, style, onChange, onClear }) => {
  const textfieldProps = {};
  textfieldProps.Label = label;
  textfieldProps.PlaceHolder = placeholder;
  textfieldProps.Value = value;
  textfieldProps.OnChange = onChange;
  // textfieldProps.onClear = onClear;
  textfieldProps.InputProps = {
    endAdornment: <EndAdornment value={value} onClick={onClear} />,
  };

  textfieldProps.style = style
    ? { backgroundColor: "#ffffff", ...style }
    : { backgroundColor: "#ffffff" };

  return <MUITextField {...textfieldProps} />;
};

export default Searchbar;

const EndAdornment = ({ value, onClick }) => (
  <InputAdornment position="end">
    <IconButton style={{ color: configs.colors.primary }} onClick={onClick}>
      {value ? (
        <configs.icons.Cross
          sx={{
            "&:hover": { color: configs.colors.primary },
            color: configs.colors.secondary,
          }}
        />
      ) : (
        <configs.icons.SearchIcon
          sx={{
            "&:hover": { color: configs.colors.primary },
            color: configs.colors.secondary,
          }}
        />
      )}
    </IconButton>
  </InputAdornment>
);
