import { get, post, put, remove } from "src/utils/axios/axios.utils";
const path = "/customers";
export const getCustomers = async (params) => {
  try {
    const response = await get(`${path}`, {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getCustomersMetaData = async (params) => {
  try {
    const response = await get(path + "/data/meta-data", {
      params,
    });

    return response.data;
  } catch (error) {
    console.log("getCustomersMetaData: ", error.message);
    throw error;
  }
};

// Service for fetching an Customer by ID:
export const getCustomersByID = async (customerId) => {
  try {
    if (!customerId) throw new Error("Customer ID is missing.");

    const response = await get(`/customers/${customerId}`);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const createCustomer = async (data) => {
  try {
    const response = await post("/customers", data);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchCustomersData = async (data) => {
  try {
    const response = await get("/customers", data);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getCustomerDetails = async (userId) => {
  try {
    const response = await get(path + `/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCustomerDetails = async (userId, data) => {
  try {
    const result = await put(path + `/${userId}`, data);
    return result;
  } catch (error) {
    throw error;
  }
};
// export default { getOrganizations, postOrganizations }
export const fetchCustomerDataWithPagination = async (limit, pageNumber) => {
  try {
    const response = await get(
      `${path}?limit=${limit}&pageNumber=${pageNumber}`
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const updateCustomerById = async (id, data) => {
  try {
    const response = await put(`${path}/${id}`, data);

    return response;
  } catch (error) {
    console.log("getCustomerById: ", error.message);
    throw error;
  }
};

export const getCustomerById = async (id) => {
  try {
    const response = await get(`${path}/${id}`);

    return response;
  } catch (error) {
    console.log("getCustomerById: ", error.message);
    throw error;
  }
};

// Deleting an Customers
export const deleteCustomer = async (customerId) => {
  try {
    const result = await remove(`${path}/${customerId}`);
    return result;
  } catch (error) {
    console.error("deleteCustomers:", error.message);
    throw error;
  }
};
