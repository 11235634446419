import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBusinessUnits } from "src/services/businessUnit.services";

const useGetBusinessUnits = (organizationId) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationID = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [businessUnits, setBusinessUnits] = useState([]);
  const [isBusinessUnitsLoading, setIsBusinessUnitsLoading] = useState(false);

  useEffect(() => {
    if (organizationId) {
      setIsBusinessUnitsLoading(true);
      getBusinessUnits({ organizationId })
        .then((resp) => {
          const { data } = resp;
          // Converting response into autocomplete options:
          const autocompleteOptions = data?.map((businessUnit) => ({
            id: businessUnit.id,
            label: businessUnit.name,
            value: businessUnit.id,
            name: "business_unit_id",
          }));

          setBusinessUnits(autocompleteOptions);
          setIsBusinessUnitsLoading(false);
        })
        .catch((error) => {
          console.log("useGetBusinessUnits: ", error.message);
          setIsBusinessUnitsLoading(false);
        });
    }
  }, [organizationId]);

  return [businessUnits, setBusinessUnits, isBusinessUnitsLoading];
};
export default useGetBusinessUnits;
