import { Grid, Modal } from "@mui/material";
import MUICard from "src/components/MUICard/MUICard.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import { useEffect, useState } from "react";
import configs from "src/configs/configs";
import { useFormik } from "formik";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import FormActions from "src/pages/ManageOrganizations/RegisterOrganization/components/FormActions/FormActions.component";
import {
  createContract,
  getContractByID,
  updateContractById,
} from "src/services/contracts.services";
import firstLetterCaps from "src/helpers/firstLetterCaps.helper";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import endOfTermActionsData from "src/data/endOfTermAction.data.json";
import endOfTermClausesData from "src/data/endOfTermClause.data.json";
import financing from "src/data/financing.data.json";
import MUIAutocomplete from "src/components/MUIAutocomplete/MUIAutocomplete.component";
import { getCategory } from "src/services/category.services";
import { getStatuses } from "src/services/status.services";
import { getPaymentTerms } from "src/services/paymentTerms.services";
import { getBusinessUnits } from "src/services/businessUnit.services";
import contractType from "src/data/contractType.data.json";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import { Country } from "country-state-city";
import CreateCategory from "src/pages/ManageCategories/CreateCategory/CreateCategory.component";
import CreateStatus from "src/pages/ManageStatuses/CreateStatus/CreateStatus.component";
import CreateBusinessUnit from "src/pages/ManageBusinessUnit/CreateBusinessUnit/CreateBusinessUnit.component";
import useGetCustomers from "src/hooks/useGetCustomers.hook";
import useGetSuppliers from "src/hooks/useGetSuppliers.hook";
import CreatePaymentTerms from "src/pages/ManagePaymentTerms/CreatePaymentTerms/CreatePaymentTerms.component";
import useGetOrganizations from "src/hooks/useGetOrganizations.hook";
import CustomFormSection from "src/components/CustomFormSection/CustomFormSection.component";
import useGetCustomForms from "src/hooks/useGetCustomForms.hooks";
import CustomForm from "src/components/CustomForm/CustomForm.component";
import { getCustomFormById } from "src/services/customForms.services";
import moment from "moment";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import { useSelector } from "react-redux";
import CreateFiles from "src/pages/ManageFiles/CreateFiles/CreateFiles.page";
import AttachmentsComponent from "../../../components/AttachmentsComponent/AttachmentsComponent";
// main component:
const CreateContract = () => {
  const givenOrganization = {
    organization_id: null,
  }; // Provides given initial value

  const currentUser = useSelector((state) => state.auth.currentUser);
  const userRole = currentUser?.role || null;
  const organizationID = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions || [];

  if (organizationID) {
    givenOrganization.organization_id = {
      id: organizationID,
      label: "",
      value: organizationID,
    };
  } // Implementing pre-defined organization value

  const navigate = useNavigate();
  const params = useParams();
  const contractId = params.id;
  const [flags, setFlags] = useState({
    isFormSubmit: false,
    isPageLoading: contractId ? true : false,
    openCustomFormModal: false,
    isCustomFormFetched: false,
  }); // Flag for handling loading button on submit.

  const [incomingResponse, setIncomingResponse] = useState(null);
  const [endOfTermActionsOptions, setEndOfTermActionsOptions] = useState([]);
  const [endOfTermClausesOptions, setEndOfTermClausesOptions] = useState([]);
  const [financingOptions, setFinancingOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [statusId, setStatusId] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [selectedContractType, setSelectedContractType] = useState("");
  const [contractTypeOptions, setContractTypeOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [searchStrings, setSearchStrings] = useState({
    country: null,
  });

  // Attachmets states:
  const [attachments, setAttachments] = useState([]);

  const [open, toggleOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalValue, setModalValue] = useState({
    label: "",
    description: "",
  });
  const modalOptions = {
    category: { categories, setCategories },
    status: { statusId, setStatusId },
    business_unit: { businessUnit, setBusinessUnit },
    payment_terms: { paymentTerms, setPaymentTerms },
    upload_files: { attachments, setAttachments },
  };

  const [selectedCustomForm, setSelectedCustomForm] = useState(null);
  const [selectedCustomForms, setSelectedCustomForms] = useState([]);

  const [organizations, isOrganizationsLoading] = useGetOrganizations(
    searchStrings?.organizations
  );
  const [customers, isCustomersLoading] = useGetCustomers(
    searchStrings?.customers
  );
  const [suppliers, isSuppliersLoading] = useGetSuppliers(
    searchStrings?.suppliers
  );

  const [customForms, setCustomForms] = useGetCustomForms("contract");

  const formik = useFormik({
    initialValues: { ...createContractInitialValues, ...givenOrganization },
    validationSchema: createContractValidationSchema,

    onSubmit: async (values) => {
      try {
        setFlags((prev) => ({ ...prev, isFormSubmit: true }));

        const rawValues = values;
        rawValues.contract_type = selectedContractType;

        const requestJson = convertToRequestJson(rawValues);
        requestJson.attachments = attachments;

        contractId
          ? await updateContractById(contractId, requestJson)
          : await createContract(requestJson);

        setIncomingResponse({
          severity: "success",
          message: `Contract ${
            contractId ? "Updated" : "created"
          } successfully!`,
        });

        if (!contractId) formik.handleReset();

        setFlags((prev) => ({ ...prev, isFormSubmit: false }));
      } catch (error) {
        console.log("Contract Error: ", error.message);
        const message = error?.response?.data?.message || error.message;
        setIncomingResponse({
          severity: "error",
          message,
        });
        setFlags((prev) => ({ ...prev, isFormSubmit: false }));
      }
    },
    onReset: () => (contractId ? navigate(-1) : null),
  });

  const convertToRequestJson = (data) => {
    const incomingJson = data;
    const incomingJsonkeys = Object.keys(incomingJson);
    const incomingJsonValues = Object.values(incomingJson);
    const outgoingJSON = {};

    const parseIncomingJsonValues = incomingJsonValues.map((value) => {
      if (value && typeof value === "object" && !Array.isArray(value))
        return value.id;
      else return value;
    });

    incomingJsonkeys.forEach((key, index) => {
      outgoingJSON[key] = parseIncomingJsonValues[index];
    });

    return outgoingJSON;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      const namesArr = value.split(" ");
      const newNameArr = namesArr.map((n) => firstLetterCaps(n));
      const newValue = newNameArr.join(" ");
      formik.setFieldValue(name, newValue);
    } else {
      formik.handleChange(e);
    }
  };

  const handleGetContractById = async () => {
    try {
      setFlags((prev) => ({ ...prev, isPageLoading: true }));
      const response = await getContractByID(contractId);
      const incomingContractJSON = response?.data;
      const rawContractJSON = parseIncomingContractJSON(incomingContractJSON);
      const getAllCountries = Country.getAllCountries();
      const matchedCountry = getAllCountries.filter(
        (country) =>
          country.name === rawContractJSON.country ||
          country.isoCode === rawContractJSON.country
      )[0];
      // console.log(getAllCountries);
      rawContractJSON.country = {
        id: matchedCountry.isoCode,
        label: matchedCountry.name,
        value: matchedCountry.isoCode,
        name: "country",
      };

      setAttachments(rawContractJSON?.attachments);
      setSelectedContractType(rawContractJSON.contract_type);
      formik.setValues(rawContractJSON);
      setSelectedCustomForms(rawContractJSON.custom_forms);

      setFlags((prev) => ({ ...prev, isPageLoading: false }));
    } catch (error) {
      console.log("handleGetContractById: ", error.message);
    }
  };

  useEffect(() => {
    if (contractId) handleGetContractById();
  }, [contractId]);

  useEffect(() => {
    setEndOfTermActionsOptions(
      endOfTermActionsData.data.map((action) => ({
        id: action.id,
        label: action.label,
        value: action.id,
        name: "end_of_term_action",
      }))
    );

    setEndOfTermClausesOptions(
      endOfTermClausesData.data.map((clause) => ({
        id: clause.id,
        label: clause.label,
        value: clause.id,
        name: "end_of_term_clause",
      }))
    );

    setFinancingOptions(
      financing.data.map((finance) => ({
        id: finance.id,
        label: finance.label,
        value: finance.id,
        name: "financing",
      }))
    );
    setContractTypeOptions(
      contractType.data.map((contractType) => ({
        id: contractType.id,
        label: contractType.label,
        value: contractType.id,
      }))
    );
  }, []);

  useEffect(() => {
    if (contractTypeOptions.length) {
      setSelectedContractType(contractTypeOptions[0].id);
    }
  }, [contractTypeOptions]);

  // Function to fetch categories:
  const fetchCategories = async () => {
    try {
      setIsLoading(true);

      const resp = await getCategory({ organizationId: organizationID });
      const categoriesMap = resp?.data?.map((categories) => ({
        id: categories.id,
        label: categories.name,
        value: categories.name,
        name: "category_id",
      }));
      setIsLoading(false);
      setCategories(categoriesMap);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  // Function to fetch payment-terms:
  const fetchPaymentTerms = async () => {
    try {
      setIsLoading(true);
      const resp = await getPaymentTerms({ organizationId: organizationID });
      const paymentMap = resp?.data?.map((pay) => ({
        id: pay.id,
        label: pay.name,
        value: pay.name,
        name: "payment_terms_id",
      }));
      setPaymentTerms(paymentMap);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  // Function to fetch status:
  const fetchStatus = async () => {
    try {
      setIsLoading(true);
      const resp = await getStatuses({ organizationId: organizationID });
      const statusMap = resp?.data?.map((status) => ({
        id: status.id,
        label: status.name,
        value: status.name,
        name: "status_id",
      }));
      // console.log(statusId);
      setStatusId(statusMap);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  // Function to fetch BussinessUnits:
  const fetchBusinessUnits = async () => {
    try {
      setIsLoading(true);
      const resp = await getBusinessUnits({ organizationId: organizationID });
      const businessUnitsMap = resp?.data?.map((business) => ({
        id: business.id,
        label: business.name,
        value: business.name,
        name: "business_unit_id",
      }));

      setBusinessUnit(businessUnitsMap);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };
  //fetch countries
  const fetchCountries = async () => {
    try {
      const allCountries = Country.getAllCountries();
      const countryOptions = allCountries.map((country) => ({
        id: country.isoCode,
        label: country.name,
        value: country.name,
        name: "country",
      }));
      setCountries(countryOptions);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  // Trigger when user will search:
  useEffect(() => {
    fetchCategories();
    fetchPaymentTerms();
    fetchStatus();
    fetchBusinessUnits();
    fetchCountries();
  }, []);

  const handleAutocompleteChangeType = (event) => {
    setSelectedContractType(event.target.value);
    formik.setFieldValue("customer_id", null);
    formik.setFieldValue("supplier_id", null);
  };

  const handleAutocompleteChange = (e, value) => {
    if (value) formik.handleChange({ target: { name: value.name, value } });
    else {
      const { name, value } = e.target;
      setSearchStrings((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Function to handle dialog values
  const handleDialogValue = (type, newValue) => {
    setModalType(type);
    setModalValue(newValue);
  };

  const fetchCustomFormById = async (id) => {
    try {
      setFlags((prev) => ({
        ...prev,
        isCustomFormFetched: true,
      }));
      const response = await getCustomFormById(id);
      const { data } = response;
      const parsedCustomForm = [data].map((cForm) => ({
        id: cForm.id,
        name: cForm.name,
        fields: cForm.fields,
      }))[0];
      // console.log(data);

      const parsedCustomForm_2 = [data].map((cForm) => {
        const parsedObj = {};
        parsedObj.id = cForm.id;

        parsedObj.fields = cForm?.fields?.map((cField) => ({
          id: cField.id,
          value: "",
        }));

        return parsedObj;
      })[0];

      formik.setValues((values) => ({
        ...values,
        custom_forms: [...values.custom_forms, parsedCustomForm_2],
      }));
      setSelectedCustomForms((prev) => [...prev, parsedCustomForm]);
      setSelectedCustomForm(null);
      setFlags((prev) => ({
        ...prev,
        openCustomFormModal: !prev.openCustomFormModal,
        isCustomFormFetched: false,
      }));
    } catch (error) {
      setFlags((prev) => ({
        ...prev,
        isCustomFormFetched: false,
      }));
      console.log("fetchCustomFormById: ", error.message);
    }
  };

  const handleAddCustomForms = () => {
    const customFormId = selectedCustomForm;

    // console.log(customFormId);
    fetchCustomFormById(customFormId);
  };

  useEffect(() => {
    if (selectedCustomForms.length && customForms.length) {
      const selectedCustomFormsIds = selectedCustomForms.map((form) => form.id);
      setCustomForms((prevState) => {
        return prevState.filter(
          (form) => !selectedCustomFormsIds.includes(form.id)
        );
      });
    }
  }, [selectedCustomForms, customForms]);

  // Attachments functionality:
  const handleAddAttachments = () => {
    toggleOpen(true);
    handleDialogValue("upload_files", null);
  };

  if (flags.isPageLoading) {
    return <PageLoader />;
  } else {
    return (
      <>
        {incomingResponse && (
          <MUISnackbar
            open={true}
            onClose={() => setIncomingResponse(null)}
            severity={incomingResponse.severity}
            message={incomingResponse.message}
            vertical={"top"}
            horizontal={"right"}
          />
        )}
        <Grid spacing={2} container>
          <Grid sm={12} item>
            <HeaderChip
              icon={
                <configs.icons.ShoppingCartIcon
                  sx={{ color: "#fff" + "!important" }}
                />
              }
              label={`Contract > ${contractId ? "Edit" : "Create"}`}
            />
          </Grid>
          <Grid sm={12} spacing={2} container item>
            <Grid item xs={9}>
              {contractRegistrationForm.map((section) => (
                <div key={section.title}>
                  <MUICard title={<TitleComponent title={section.title} />}>
                    <Grid container spacing={2}>
                      {section.fields.map((field) => (
                        <Grid key={field.id} md={field.col.md} item>
                          {!organizationID && field.id === "organization_id" &&
                          field.type === "autocomplete" 
                          // &&
                          // userRole === "owner" 
                          ? 
                          (
                            <>
                              <MUIInputLabel>
                                {field.label}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </MUIInputLabel>
                              <MUIAutocomplete
                                loading={isOrganizationsLoading}
                                name={"organizations"}
                                value={formik.values[field.id]}
                                options={organizations}
                                onChange={handleAutocompleteChange}
                                error={
                                  formik?.errors && formik?.errors[field.id]
                                }
                                helperText={
                                  formik?.errors && formik?.errors[field.id]
                                }
                                disabled={flags.isFormSubmit}
                              />
                            </>
                          ) : null}

                          {field.id === "end_of_term_action" ? (
                            <>
                              <MUIInputLabel>
                                {field.label}{" "}
                                {field.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </MUIInputLabel>
                              <MUITextField
                                Name={"end_of_term_action"}
                                //value={selectedFinancingClause}
                                Value={formik.values[field.id]}
                                SelectOptions={endOfTermActionsOptions}
                                OnChange={handleInputChange}
                                Error={formik.errors["end_of_term_action"]}
                                helperText={formik.errors["end_of_term_action"]}
                                Disabled={flags.isFormSubmit}
                                Select
                              />
                            </>
                          ) : (
                            field.id === "customer_id" &&
                            selectedContractType === "sell_side" && (
                              <>
                                <MUIInputLabel>
                                  Customer{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </MUIInputLabel>
                                <MUIAutocomplete
                                  name="customers"
                                  value={formik.values[field.id]}
                                  options={customers}
                                  onChange={handleAutocompleteChange}
                                  loading={isCustomersLoading}
                                  error={formik.errors["customer_id"]}
                                  helperText={formik.errors["customer_id"]}
                                  disabled={flags.isFormSubmit}
                                />
                              </>
                            )
                          )}
                          {field.id === "customer_id" &&
                            selectedContractType === "buy_side" && (
                              <>
                                <MUIInputLabel>
                                  Supplier{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </MUIInputLabel>
                                <MUIAutocomplete
                                  name="suppliers"
                                  value={formik.values["supplier_id"]}
                                  options={suppliers}
                                  onChange={handleAutocompleteChange}
                                  loading={isSuppliersLoading}
                                  error={formik.errors["supplier_id"]}
                                  helperText={formik.errors["supplier_id"]}
                                  disabled={flags.isFormSubmit}
                                />
                              </>
                            )}

                          {field.id === "end_of_term_clause" ? (
                            <>
                              <MUIInputLabel>
                                {field.label}{" "}
                                {field.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </MUIInputLabel>
                              <MUITextField
                                Name={"end_of_term_clause"}
                                //value={selectedFinancingClause}
                                Value={formik.values[field.id]}
                                SelectOptions={endOfTermClausesOptions}
                                OnChange={handleInputChange}
                                Error={formik.errors["end_of_term_clause"]}
                                helperText={formik.errors["end_of_term_clause"]}
                                Disabled={flags.isFormSubmit}
                                Select
                              />
                            </>
                          ) : field.id === "financing" ? (
                            <>
                              <MUIInputLabel>
                                {field.label}{" "}
                                {field.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </MUIInputLabel>
                              <MUITextField
                                Name={"financing"}
                                //value={selectedFinancingClause}
                                Value={formik.values[field.id]}
                                SelectOptions={financingOptions}
                                OnChange={handleInputChange}
                                Error={formik.errors["financing"]}
                                helperText={formik.errors["financing"]}
                                Disabled={flags.isFormSubmit}
                                Select
                              />
                            </>
                          ) : field.id === "category_id" ? (
                            <>
                              <MUIInputLabel>
                                {field.label}
                                {field.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </MUIInputLabel>
                              <MUIAutocomplete
                                name={"category_id"}
                                // value={selectedCategory}
                                value={formik.values[field.id]}
                                options={categories}
                                onChange={handleAutocompleteChange}
                                loading={isLoading}
                                error={formik.errors["category_id"]}
                                helperText={formik.errors["category_id"]}
                                disabled={flags.isFormSubmit}
                                creatable={formik.values.organization_id}
                                toggleOpen={toggleOpen}
                                setDialogValue={(e) =>
                                  handleDialogValue("category", e)
                                }
                              />
                            </>
                          ) : field.id === "status_id" ? (
                            <>
                              <MUIInputLabel>
                                {field.label}{" "}
                                {field.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </MUIInputLabel>
                              <MUIAutocomplete
                                name={"status_id"}
                                //value={selectedStatusId}
                                value={formik.values[field.id]}
                                options={statusId}
                                onChange={handleAutocompleteChange}
                                loading={isLoading}
                                error={formik.errors["status_id"]}
                                helperText={formik.errors["status_id"]}
                                disabled={flags.isFormSubmit}
                                creatable
                                toggleOpen={toggleOpen}
                                setDialogValue={(e) =>
                                  handleDialogValue("status", e)
                                }
                              />
                            </>
                          ) : field.id === "payment_terms_id" ? (
                            <>
                              <MUIInputLabel>
                                {field.label}{" "}
                                {field.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </MUIInputLabel>
                              <MUIAutocomplete
                                name={"payment_terms_id"}
                                //value={selectedPaymentTerms}
                                value={formik.values[field.id]}
                                options={paymentTerms}
                                onChange={handleAutocompleteChange}
                                loading={isLoading}
                                error={formik.errors["payment_terms_id"]}
                                helperText={formik.errors["payment_terms_id"]}
                                disabled={flags.isFormSubmit}
                                creatable
                                toggleOpen={toggleOpen}
                                setDialogValue={(e) =>
                                  handleDialogValue("payment_terms", e)
                                }
                              />
                            </>
                          ) : field.id === "business_unit_id" ? (
                            <>
                              <MUIInputLabel>
                                {field.label}
                                {""}
                                {field.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </MUIInputLabel>
                              <MUIAutocomplete
                                name={"business_unit_id"}
                                //value={selectedBusinessUnits}
                                value={formik.values[field.id]}
                                options={businessUnit}
                                onChange={handleAutocompleteChange}
                                loding={isLoading}
                                error={formik.errors["business_unit_id"]}
                                helperText={formik.errors["business_unit_id"]}
                                disabled={flags.isFormSubmit}
                                creatable
                                toggleOpen={toggleOpen}
                                setDialogValue={(e) =>
                                  handleDialogValue("business_unit", e)
                                }
                              />
                            </>
                          ) : field.id === "country" ? (
                            <>
                              <MUIInputLabel>{field.label}</MUIInputLabel>
                              <MUIAutocomplete
                                name={"country"}
                                //value={selectedBusinessUnits}
                                value={formik.values[field.id]}
                                options={countries}
                                onChange={handleAutocompleteChange}
                                loding={isLoading}
                                error={formik.errors["country"]}
                                helperText={formik.errors["country"]}
                                disabled={flags.isFormSubmit}
                              />
                            </>
                          ) : (
                            <>
                              {field.type !== "select" &&
                              field.type !== "autocomplete" ? (
                                <>
                                  <MUIInputLabel>
                                    {field.label}{" "}
                                    {field.required && (
                                      <span style={{ color: "red" }}>*</span>
                                    )}
                                  </MUIInputLabel>
                                  <MUITextField
                                    Name={field.id}
                                    Value={formik.values[field.id]}
                                    OnChange={handleInputChange}
                                    Type={field.type}
                                    Error={
                                      formik?.errors && formik?.errors[field.id]
                                    }
                                    helperText={
                                      formik?.errors && formik?.errors[field.id]
                                    }
                                    disabled={flags.isFormSubmit}
                                  />
                                </>
                              ) : null}
                            </>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </MUICard>
                  <AppSpacer vertical={"12px"} />
                </div>
              ))}

              {/* Attachments component */}
              <AttachmentsComponent
                onAdd={handleAddAttachments}
                data={attachments}
                setData={setAttachments}
              />

              {selectedCustomForms.length
                ? selectedCustomForms.map((cform, index) => (
                    <>
                      <MUICard key={cform.id} title={cform.name}>
                        <CustomForm
                          index={index}
                          cForm={cform}
                          formik={formik}
                          handleInputChange={handleInputChange}
                          flags={flags}
                        />
                      </MUICard>
                      <AppSpacer vertical={"12px"} />
                    </>
                  ))
                : null}

              <CustomFormSection
                open={flags.openCustomFormModal}
                toggleOpen={() =>
                  setFlags((prev) => ({
                    ...prev,
                    openCustomFormModal: !prev.openCustomFormModal,
                  }))
                }
                options={customForms}
                value={selectedCustomForm}
                onChange={(e) => setSelectedCustomForm(e.target.value)}
                onAdd={handleAddCustomForms}
                loading={flags.isCustomFormFetched}
              />
              <FormActions formik={formik} isLoading={flags.isFormSubmit} />
            </Grid>
            <Grid item xs={3}>
              <MUICard title={<TitleComponent title="Contract Types" />}>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <MUIInputLabel>Contract Types</MUIInputLabel>
                    <>
                      <MUITextField
                        Name={"customer_types"}
                        Value={selectedContractType}
                        SelectOptions={contractTypeOptions}
                        OnChange={handleAutocompleteChangeType}
                        Select
                      />
                    </>
                  </Grid>
                </Grid>
              </MUICard>
              <AppSpacer vertical={"12px"} />
            </Grid>
          </Grid>
        </Grid>

        <Modal open={open} onClose={() => toggleOpen((e) => !e)}>
          <div>
            <ModalsGroup
              organizationId={formik.values?.organization_id?.id}
              creatorId={currentUser?.id}
              type={modalType}
              value={modalValue}
              onAutocompleteChange={handleAutocompleteChange}
              toggleOpen={toggleOpen}
              setValue={setModalValue}
              setOptions={modalOptions}
            />
          </div>
        </Modal>
      </>
    );
  }
};
export default CreateContract;

// Title Component:
function TitleComponent({ title }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* <configs.icons.HandshakeIcon sx={{ color: configs.colors.primary }} /> */}
      {/* &nbsp; */}
      {title}
    </div>
  );
}

const ModalsGroup = ({
  organizationId,
  creatorId,
  type,
  value,
  onAutocompleteChange,
  toggleOpen,
  setValue,
  setOptions,
}) => {
  // Collecting common props:
  const props = {
    organizationId,
    creatorId,
    value: value,
    setSelectedOption: (selectedValue) =>
      onAutocompleteChange(null, selectedValue),
    toggleOpen: toggleOpen,
    setValue: setValue,
  };

  switch (type) {
    case "category":
      return (
        <CreateCategory
          {...props}
          setOptions={setOptions[type].setCategories}
        />
      );
    case "status":
      return (
        <CreateStatus {...props} setOptions={setOptions[type].setStatusId} />
      );
    case "business_unit":
      return (
        <CreateBusinessUnit
          {...props}
          setOptions={setOptions[type].setBusinessUnit}
        />
      );
    case "payment_terms":
      return (
        <CreatePaymentTerms
          {...props}
          setOptions={setOptions[type].setPaymentTerms}
        />
      );
    case "upload_files":
      return (
        <CreateFiles {...props} setOptions={setOptions[type].setAttachments} />
      );
    default:
      return null;
  }
};

// Configurations:
const contractRegistrationForm = [
  //General Information
  {
    title: "General Information ",
    fields: [
      {
        id: "organization_id",
        name: "organization_id",
        label: "Select Organization",
        type: "autocomplete",
        col: {
          md: 12,
        },
      },
      {
        id: "name",
        label: "Name",
        type: "text",
        col: {
          md: 6,
        },
        required: true,
      },
      {
        id: "number",
        label: "Number",
        type: "text",
        col: {
          md: 6,
        },
        required: true,
      },
      {
        id: "country",
        label: "Country",
        type: "autocomplete",
        col: {
          md: 6,
        },
      },
      {
        id: "description",
        label: "Description",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "category_id",
        label: "Category",
        type: "text",
        col: {
          md: 6,
        },
        required: true,
      },

      {
        id: "status_id",
        label: "Status",
        type: "text",
        col: {
          md: 6,
        },
        required: true,
      },
      {
        id: "customer_id",
        label: "customer",
        type: "select",
        col: {
          md: 6,
        },
        required: true,
      },
      // {
      //   id: "supplier_id",
      //   label: "supplier",
      //   type: "select",
      //   col: {
      //     md: 6,
      //   },
      //   required: true,
      // },
    ],
  },

  //Business Unit
  {
    title: "Business Unit",
    fields: [
      {
        id: "business_unit_id",
        label: "Business Unit",
        type: "text",
        col: {
          md: 6,
        },
      },
    ],
  },

  //Timeline / Terms
  {
    title: "Timeline / Terms",
    fields: [
      {
        id: "contract_start_date",
        label: "Contract Start Date",
        type: "date",
        col: {
          md: 6,
        },
        required: true,
      },
      {
        id: "contract_end_date",
        label: "Contract End Date",
        type: "date",
        col: {
          md: 6,
        },
        required: true,
      },
      {
        id: "notice_period",
        label: "Notice Period",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "service_start_date",
        label: "Service Start Date",
        type: "date",
        col: {
          md: 6,
        },
      },
      {
        id: "end_of_term_clause",
        label: "End Of Term Clause",
        type: "select",
        col: {
          md: 6,
        },
      },
      {
        id: "end_of_term_action",
        label: "End Of Term Action",
        type: "select",
        col: {
          md: 6,
        },
        required: true,
      },
      {
        id: "timeline_notes",
        label: "Timeline Notes",
        type: "text",
        col: {
          md: 6,
        },
      },
    ],
  },

  //Financials
  {
    title: "Financials",
    fields: [
      {
        id: "total_contract_spend_value",
        label: "Total Contract Spend Value",
        type: "text",
        col: {
          md: 6,
        },
        required: true,
      },
      {
        id: "non_recurring_cost",
        label: "Non Recurring Cost",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "monthly_recurring_cost",
        label: "Monthly Recurring Cost",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "payment_terms_id",
        label: "Payment Terms",
        type: "text",
        col: {
          md: 6,
        },
      },
      {
        id: "financing",
        label: "Financing",
        type: "text",
        col: {
          md: 6,
        },
        required: true,
      },
      {
        id: "financial_notes",
        label: "Financial Notes",
        type: "text",
        col: {
          md: 6,
        },
      },
    ],
  },
];

const createContractInitialValues = {
  organization_id: null,
  name: "",
  number: "",
  country: null,
  description: "",
  category_id: null,
  customer_id: null,
  supplier_id: null,
  status_id: null,

  business_unit_id: null,

  contract_start_date: null,
  contract_end_date: null,
  notice_period: null,
  service_start_date: null,
  end_of_term_clause: null,
  end_of_term_action: null,
  timeline_notes: "",

  total_contract_spend_value: "",
  non_recurring_cost: "",
  monthly_recurring_cost: "",
  payment_terms_id: null,
  financing: null,
  financial_notes: "",
  custom_forms: [],
};

const createContractValidationSchema = Yup.object().shape({
  organization_id: Yup.object().required("Organization is required!"),
  name: Yup.string().required("Name is required!"),
  number: Yup.string().required("Number is required!"),
  category_id: Yup.object().required("Category is required!"),
  status_id: Yup.object().required("Status is required!"),
  contract_start_date: Yup.string().required("Start date is required!"),
  contract_end_date: Yup.string().required("End date is required!"),
  total_contract_spend_value: Yup.string().required(
    "Total contract spend value is required!"
  ),
  end_of_term_action: Yup.string().required("End of term action is required!"),
  financing: Yup.string().required("Financing is required!"),
});

const parseIncomingContractJSON = (contractJSON) => {
  const incomingJSON = contractJSON;
  const incomingJSONkeys = Object.keys(incomingJSON);
  const incomingJSONValues = Object.values(incomingJSON);
  const outgoingJSON = {};

  const parseIncomingJsonValues = incomingJSONValues.map((value, index) => {
    if (value && typeof value === "object" && !Array.isArray(value))
      return {
        id: value.id,
        label: value.name,
        value: value.name,
        name: `${incomingJSONkeys[index]}_id`,
      };
    else return value;
  });

  incomingJSONkeys.forEach((key, index) => {
    let value = parseIncomingJsonValues[index];

    if (value && typeof value === "object" && !Array.isArray(value))
      outgoingJSON[value?.name] = value;
    else {
      if (value && isIsoDate(value)) {
        outgoingJSON[key] = moment(value).format("YYYY-MM-DD");
      } else outgoingJSON[key] = value;
    }
  });

  // return outgoingJSON;
  // console.log(outgoingJSON);

  return outgoingJSON;
};

function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
}
