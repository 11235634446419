import { useSelector } from "react-redux";

const { useState, useEffect } = require("react");
const { getDashboards } = require("src/services/dashboards.services");

const useGetDashboards = (searchText, organizationId) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationID = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [dashboards, setDashboards] = useState([]);
  const [isDashboardsLoading, setIsDashboardsLoading] = useState(false);

  const searchKeyword = searchText ? searchText : "";

  // Function to fetch dashboards:
  const fetchDashboards = async () => {
    try {
      setIsDashboardsLoading(true);
      const resp = await getDashboards({
        searchKeyword,
        organizationId,
      });

      // Converting response into autocomplete options:
      const autocompleteOptions = resp?.data?.map((dashboard) => ({
        id: dashboard.id,
        label: dashboard.name,
        value: dashboard.id,
        name: "dashboard_id",
      }));

      setDashboards(autocompleteOptions);
      setIsDashboardsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsDashboardsLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      if (!searchText) fetchDashboards();
      if (searchText && searchText.length > 3) fetchDashboards();
    }
  }, [searchText, organizationId]);

  return [dashboards, setDashboards, isDashboardsLoading];
};

export default useGetDashboards;
