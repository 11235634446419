import "./App.css";
import { Route, Routes } from "react-router-dom";
import useAuthentication from "./hooks/useAuthentication.hook";
import secureRoutes from "./routes/secure.routes";
import publicRoutes from "./routes/public.routes";

function App() {
  const [isLoggedIn] = useAuthentication();

  return (
    <div>
      <Routes>
        {isLoggedIn
          ? secureRoutes.map((route) => (
              <Route path={route.path} element={route.element} key={route.id} />
            ))
          : publicRoutes.map((route) => (
              <Route path={route.path} element={route.element} key={route.id} />
            ))}
      </Routes>
    </div>
  );
}

export default App;
