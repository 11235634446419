import { get, post } from "src/utils/axios/axios.utils";
const path = "/asset-owners";

export const getAssetOwners = async (params) => {
  try {
    const response = await get(`${path}`, {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

/**
 *
 * @param {Object} data - Params for creating asset owner
 * @returns
 */
export const createAssetOwner = async (data) => {
  try {
    const response = await post(`${path}`, data);
    return response;
  } catch (error) {
    console.error("createAssetOwner:", error);
    throw error;
  }
};
