import { useEffect, useState } from "react";
import usageStatus from "src/data/usageStatus.data.json";
import supportStatus from "src/data/supportStatus.data.json";
import supportTypes from "src/data/supportTypes.data.json";
import warrantyStatus from "src/data/warrantyStatus.data.json";
import warrantyTypes from "src/data/warrantyTypes.data.json";
import assetTypes from "src/data/assetTypes.data.json";
import customFormTypes from "src/data/customFormTypes.data.json";
import inputTypes from "src/data/inputTypes.data.json";
import contractTypes from "src/data/contractType.data.json";
import financing from "src/data/financing.data.json";
import endOfTermClause from "src/data/endOfTermClause.data.json";
import endOfTermAction from "src/data/endOfTermAction.data.json";

const useConstants = () => {
  const [usage_status] = useGetUsageStatus();
  const [support_status] = useGetSupportStatus();
  const [support_type] = useGetSupportTypes();
  const [warranty_status] = useGetWarrantyStatus();
  const [warranty_type] = useGetWarrantyTypes();
  const [asset_type] = useGetAssetTypes();
  const [form_type] = useGetCustomFormTypes();
  const [input_type] = useGetInputTypes();
  const [contract_type] = useGetContractTypes();
  const [financing] = useGetFinancing();
  const [end_of_term_action] = useGetEndOfTermAction();
  const [end_of_term_clause] = useGetEndOfTermClause();

  return {
    usage_status,
    support_status,
    warranty_status,
    warranty_type,
    support_type,
    asset_type,
    form_type,
    input_type,
    contract_type,
    financing,
    end_of_term_action,
    end_of_term_clause,
  };
};
export default useConstants;

const useGetUsageStatus = () => {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    setStatus(
      usageStatus.data.map((status) => ({
        id: status.id,
        label: status.label,
        value: status.id,
        name: "usage_status",
      }))
    );
  }, []);

  return [status, setStatus];
};

const useGetSupportTypes = () => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(
      supportTypes.data.map((type) => ({
        id: type.id,
        label: type.label,
        value: type.id,
        name: "support_type",
      }))
    );
  }, []);

  return [types, setTypes];
};

const useGetSupportStatus = () => {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    setStatus(
      supportStatus.data.map((status) => ({
        id: status.id,
        label: status.label,
        value: status.id,
        name: "support_status",
      }))
    );
  }, []);

  return [status, setStatus];
};

const useGetWarrantyStatus = () => {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    setStatus(
      warrantyStatus.data.map((status) => ({
        id: status.id,
        label: status.label,
        value: status.id,
        name: "warranty_status",
      }))
    );
  }, []);

  return [status, setStatus];
};

const useGetWarrantyTypes = () => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(
      warrantyTypes.data.map((type) => ({
        id: type.id,
        label: type.label,
        value: type.id,
        name: "warranty_type",
      }))
    );
  }, []);

  return [types, setTypes];
};

const useGetAssetTypes = () => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(
      assetTypes.data.map((type) => ({
        id: type.id,
        label: type.label,
        value: type.id,
        name: "asset_type",
      }))
    );
  }, []);

  return [types, setTypes];
};

const useGetCustomFormTypes = () => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(
      customFormTypes.data.map((type) => ({
        id: type.id,
        label: type.label,
        value: type.id,
        name: "form_type",
      }))
    );
  }, []);

  return [types, setTypes];
};

const useGetInputTypes = () => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(
      inputTypes.data.map((type) => ({
        id: type.id,
        label: type.label,
        value: type.id,
        name: "input_type",
      }))
    );
  }, []);

  return [types, setTypes];
};

const useGetContractTypes = () => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(
      contractTypes.data.map((type) => ({
        id: type.id,
        label: type.label,
        value: type.id,
        name: "contract_type",
      }))
    );
  }, []);

  return [types, setTypes];
};

const useGetFinancing = () => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(
      financing.data.map((type) => ({
        id: type.id,
        label: type.label,
        value: type.id,
        name: "financing",
      }))
    );
  }, []);

  return [types, setTypes];
};

const useGetEndOfTermClause = () => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(
      endOfTermClause.data.map((type) => ({
        id: type.id,
        label: type.label,
        value: type.id,
        name: "end_of_term_clause",
      }))
    );
  }, []);

  return [types, setTypes];
};

const useGetEndOfTermAction = () => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(
      endOfTermAction.data.map((type) => ({
        id: type.id,
        label: type.label,
        value: type.id,
        name: "end_of_term_action",
      }))
    );
  }, []);

  return [types, setTypes];
};

// usage_status: [],
//     support_type: [],
//     support_status: [],
//     warranty_status: [],
//     warranty_type: [],
