import { useEffect, useState } from "react";
import {
  getRealmsRoleByName,
  getRealmsRoles,
} from "src/services/roles.service";

const useApplicationRoles = () => {
  return { useGetRoles, useGetRoleByName };
};
export default useApplicationRoles;

const useGetRoles = (isDeleting) => {
  const [roles, setRoles] = useState([]);
  const [isRolesLoading, setIsRolesLoading] = useState(false);

  useEffect(() => {
    if (!isDeleting) {
      (async () => {
        setIsRolesLoading(true);
        try {
          const response = await getRealmsRoles();
          setRoles(response);
        } catch (error) {
          console.log("Error: ", error.message);
        }
        setIsRolesLoading(false);
      })();
    }
  }, [isDeleting]);

  return { roles, isRolesLoading };
};

const useGetRoleByName = (roleName, loading) => {
  const [role, setRole] = useState(null);
  const [isRoleLoading, setIsRoleLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      (async () => {
        setIsRoleLoading(true);
        try {
          const response = await getRealmsRoleByName(roleName);
          setRole(response);
        } catch (error) {
          console.log("Error: ", error.message);
        }
        setIsRoleLoading(false);
      })();
    }
  }, [roleName, loading]);

  return { role, isRoleLoading };
};
