//
//
import Keycloak from "keycloak-js";

const keycloakSetting = {
  url: process.env.REACT_APP_SERVER_URL,
  realm: process.env.REACT_APP_REALM,
  clientId: process.env.REACT_APP_CLIENT_ID,
};

const authInstance = new Keycloak(keycloakSetting);

export default authInstance;
