import { Grid } from "@mui/material";
import { LineChart, PieChart } from "@mui/x-charts";
import DashboardCardV2 from "src/components/DashboardCard/DashboardCardV2.component";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import MUICard from "src/components/MUICard/MUICard.component";
import configs from "src/configs/configs";

const HomePage = () => {
  return (
    <div>
      <Grid columnSpacing={4} rowSpacing={1} container>
        {gridItems.map((item) => (
          <DashboardGrids {...item} key={item.id} />
        ))}

        <SummaryComponent
          title={"Organizations > Summary"}
          items={organizationItems}
        />
        <SummaryComponent title={"Users > Summary"} items={userItems} />
      </Grid>
    </div>
  );
};

export default HomePage;

const DashboardGrids = ({ title, items }) => {
  return (
    <Grid sm={6} columnSpacing={1} item container>
      <Grid sm={12} item>
        <HeaderChip label={title} />
      </Grid>
      {items.map((item) => (
        <Grid sm={4} key={item.id} item>
          <DashboardCardV2 {...item} />
        </Grid>
      ))}
    </Grid>
  );
};

const gridItems = [
  {
    id: "organizations",
    title: "Organizations",
    items: [
      {
        id: "total",
        label: "Total",
        value: 100,
        icon: <configs.icons.BusinessIcon />,
      },
      {
        id: "active",
        label: "Active",
        value: 80,
        icon: <configs.icons.CheckCircleIcon />,
      },
      {
        id: "inactive",
        label: "Inactive",
        value: 20,
        icon: <configs.icons.Cross />,
      },
    ],
  },
  {
    id: "users",
    title: "Users",
    items: [
      {
        id: "total",
        label: "Total",
        value: 50,
        icon: <configs.icons.PersonIcon />,
      },
      {
        id: "active",
        label: "Active",
        value: 44,
        icon: <configs.icons.CheckCircleIcon />,
      },
      {
        id: "inactive",
        label: "Inactive",
        value: 6,
        icon: <configs.icons.Cross />,
      },
    ],
  },
  {
    id: "customers",
    title: "Customers",
    items: [
      {
        id: "total",
        label: "Total",
        value: 50,
        icon: <configs.icons.CustomersIcon />,
      },
      {
        id: "active",
        label: "Active",
        value: 44,
        icon: <configs.icons.CheckCircleIcon />,
      },
      {
        id: "inactive",
        label: "Inactive",
        value: 6,
        icon: <configs.icons.Cross />,
      },
    ],
  },
  {
    id: "suppliers",
    title: "Suppliers",
    items: [
      {
        id: "total",
        label: "Total",
        value: 50,
        icon: <configs.icons.ShoppingCartIcon />,
      },
      {
        id: "active",
        label: "Active",
        value: 44,
        icon: <configs.icons.CheckCircleIcon />,
      },
      {
        id: "inactive",
        label: "Inactive",
        value: 6,
        icon: <configs.icons.Cross />,
      },
    ],
  },
  {
    id: "contracts",
    title: "Contracts",
    items: [
      {
        id: "total",
        label: "Total",
        value: 50,
        icon: <configs.icons.DescriptionIcon />,
      },
      {
        id: "active",
        label: "Active",
        value: 44,
        icon: <configs.icons.CheckCircleIcon />,
      },
      {
        id: "inactive",
        label: "Inactive",
        value: 6,
        icon: <configs.icons.Cross />,
      },
    ],
  },
  {
    id: "assets",
    title: "Assets",
    items: [
      {
        id: "total",
        label: "Total",
        value: 50,
        icon: <configs.icons.LayersIcon />,
      },
      {
        id: "active",
        label: "Active",
        value: 44,
        icon: <configs.icons.CheckCircleIcon />,
      },
      {
        id: "inactive",
        label: "Inactive",
        value: 6,
        icon: <configs.icons.Cross />,
      },
    ],
  },
];

const SummaryComponent = ({ title, items }) => {
  return (
    <>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <HeaderChip label={title} />
      </Grid>
      {/* {items.map((item) => {
        return (
          <Grid sm={3} key={item.id} item>
            <DashboardCard {...item} />
          </Grid>
        );
      })} */}

      <Grid sm={6} item>
        <MUICard>
          <LineChart
            colors={[configs.colors.primary]}
            xAxis={[
              {
                scaleType: "point",
                data: ["Apr", "May", "Jun", "July"],
              },
            ]}
            series={[
              {
                data: [4, 6, 10, 11],
                label: "Organizations Onboard",
              },
            ]}
            height={300}
          />
        </MUICard>
      </Grid>
      <Grid sm={6} item>
        <MUICard>
          <PieChart
            colors={[configs.colors.primary, configs.colors.secondary]}
            series={[
              {
                data: [
                  { id: 0, value: 79, label: "Active" },
                  { id: 1, value: 21, label: "Inactive" },
                ],
                innerRadius: 30,
                outerRadius: 100,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: -90,
              },
            ]}
            height={300}
          />
        </MUICard>
      </Grid>
    </>
  );
};

const organizationItems = [
  {
    id: "total-organizations",
    label: "Organizations",
    count: 100,
    icon: <configs.icons.BusinessIcon />,
  },
  {
    id: "active-organizations",
    label: "Active",
    count: 79,
    icon: <configs.icons.CheckCircleIcon />,
  },
  {
    id: "inactive-organizations",
    label: "Inactive",
    count: 21,
    icon: <configs.icons.Cross />,
  },
  {
    id: "recent-organizations",
    label: "Recently Onboard",
    count: 11,
    icon: <configs.icons.ScheduleIcon />,
  },
];

const userItems = [
  {
    id: "total-users",
    label: "Users",
    count: 100,
    icon: <configs.icons.CustomersIcon />,
  },
  {
    id: "active-users",
    label: "Active",
    count: 79,
    icon: <configs.icons.CheckCircleIcon />,
  },
  {
    id: "inactive-users",
    label: "Inactive",
    count: 21,
    icon: <configs.icons.Cross />,
  },
  {
    id: "recent-users",
    label: "Recently Onboard",
    count: 11,
    icon: <configs.icons.ScheduleIcon />,
  },
];
