//
const colors = {
  primary: "rgba(84, 82, 224, 1)",
  secondary: "rgba(23, 41, 53, 1)",
  remove: "rgba(211, 47, 47, 1)",

  // Opacity:
  primary_100: "rgba(84, 82, 224, 0.1)",
  primary_200: "rgba(84, 82, 224, 0.2)",
  primary_300: "rgba(84, 82, 224, 0.3)",
  primary_400: "rgba(84, 82, 224, 0.4)",
  primary_500: "rgba(84, 82, 224, 0.5)",
  primary_600: "rgba(84, 82, 224, 0.6)",
  primary_700: "rgba(84, 82, 224, 0.7)",
  primary_800: "rgba(84, 82, 224, 0.8)",
  primary_900: "rgba(84, 82, 224, 0.9)",

  // rgb(84 82 224);
  // rgb(23 41 53)
};

export default colors;
