import { useEffect, useState } from "react";
import { getPaymentTerms } from "src/services/paymentTerms.services";

const useGetPaymentTerms = () => {
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [isPaymentTermsLoading, setIsPaymentTermsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsPaymentTermsLoading(true);
      try {
        const response = await getPaymentTerms();
        const autocompleteOptions = response?.data?.map((payment_terms) => ({
          id: payment_terms.id,
          label: payment_terms.name,
          value: payment_terms.id,
          name: "payment_terms_id",
        }));
        setPaymentTerms(autocompleteOptions);
        setIsPaymentTermsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, []);

  return { paymentTerms, setPaymentTerms, isPaymentTermsLoading };
};

export default useGetPaymentTerms;
