import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSuppliers } from "src/services/suppliers.services";

const useGetWarrantyProviders = (searchText, organizationId, options) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationID = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [isInitializing, setIsInitializing] = useState(true);
  const [warrantyProviders, setWarrantyProviders] = useState([]);
  const [isWarrantyProvidersLoading, setIsWarrantyProvidersLoading] =
    useState(false);

  const searchKeyword = searchText ? searchText : "";

  // Function to fetch customer:
  const fetchWarrantyProviders = async () => {
    try {
      setIsWarrantyProvidersLoading(true);
      const resp = await getSuppliers({
        searchKeyword,
        organizationId,
      });

      // Converting response into autocomplete options:
      const autocompleteOptions = resp?.data?.map((warrantyProvider) => ({
        id: warrantyProvider.id,
        label: warrantyProvider.name,
        value: warrantyProvider.id,
        name: "warranty_provider_id",
      }));

      setWarrantyProviders(autocompleteOptions);
      setIsWarrantyProvidersLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsWarrantyProvidersLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      if (!searchText && (!isInitializing || !options?.data))
        fetchWarrantyProviders();
      if (searchText && searchText.length > 3) fetchWarrantyProviders();
    }
  }, [searchText, organizationId]);

  useEffect(() => {
    if (options?.data && options?.data[0] && isInitializing) {
      const suppliers = options?.data;
      const autocompleteOptions = getAutocompleteOptions(suppliers);
      setWarrantyProviders(autocompleteOptions);
      setIsInitializing(false);
    }
  }, [options?.data]);

  return [warrantyProviders, isWarrantyProvidersLoading];
};

export default useGetWarrantyProviders;

const getAutocompleteOptions = (data) => {
  const autocompleteOptions = data?.map((warrantyProvider) => ({
    ...warrantyProvider,
    name: "warranty_provider_id",
  }));

  return autocompleteOptions;
};
