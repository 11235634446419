import { useEffect, useState } from "react";
import { getStatuses } from "src/services/status.services";

const useGetStatuses = () => {
  const [statuses, setStatuses] = useState([]);
  const [isStatusesLoading, setIsStatusesLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsStatusesLoading(true);
      try {
        const response = await getStatuses();
        const autocompleteOptions = response?.data?.map((status) => ({
          id: status.id,
          label: status.name,
          value: status.id,
          name: "status_id",
        }));
        setStatuses(autocompleteOptions);
        setIsStatusesLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, []);

  return { statuses, setStatuses, isStatusesLoading };
};

export default useGetStatuses;
