import { get, post, put, remove } from "src/utils/axios/axios.utils";

export const getRealmsRoles = async (params) => {
  try {
    const response = await get(`/keycloak/realms/roles`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createRealmsRole = async (data) => {
  try {
    const response = await post(`/keycloak/realms/roles`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRealmsRoleByName = async (roleName, params) => {
  try {
    const response = await get(`/keycloak/realms/roles/${roleName}`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateRealmsRoleByName = async (roleName, data) => {
  try {
    const response = await put(`/keycloak/realms/roles/${roleName}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRealmsRoleByName = async (roleName) => {
  try {
    const response = await remove(`/keycloak/realms/roles/${roleName}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
