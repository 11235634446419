import { Grid, IconButton, Modal } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import DashboardCard from "src/components/DashboardCard/DashboardCard.component";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUIGridTable from "src/components/MUIGridTable/MUIGridTable.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import configs from "src/configs/configs";
import {
  getContracts,
  getContractsMetaData,
  deleteContract,
  importContracts,
} from "src/services/contracts.services";
import ExportToolbar from "src/components/MUIExport/ExportToolbar";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import { useSelector } from "react-redux";
import ConfirmationDialog from "src/components/ConfirmationDialog/ConfirmationDialog.component";
import Searchbar from "src/components/Searchbar/Searchbar.component";
import DialogsGroup from "src/components/DialogsGroup/DialogsGroup.component";
import { exportToCSV, exportToXLSX } from "src/helpers/exportData.helper";
import contractTypes from "src/data/contractType.data.json";

const ContractsDashboard = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const organizationId = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions["contracts"] || [];

  const [isPageLoading, setIsPageLoading] = useState({
    meta_data: true,
    list: true,
  });
  const [isListFetched, setIsListFetched] = useState(false);
  const [summaries, setSummaries] = useState({
    all_count: 0,
    active_count: 0,
    in_active_count: 0,
    listed_in_7_days: 0,
  });
  const [contracts, setContracts] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [incomingResponse, setIncomingResponse] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [searchString, setSearchString] = useState(null);

  const [openDialog, toggleDialog] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const [dialogType, setDialogType] = useState(null);

  // get customers meta data:
  const fetchContractsMetaData = async () => {
    try {
      const response = await getContractsMetaData({ organizationId });
      if (response && response.count !== undefined) {
        setSummaries({
          all_count: response.count,
          active_count: response.active,
          in_active_count: response.in_active,
          listed_in_7_days: response.registered_within_7_Days,
        });
        setIsPageLoading((prev) => ({ ...prev, meta_data: false }));
      } else {
        // Handle unexpected response format
        console.log("Invalid metadata response:", response);
      }
    } catch (error) {
      console.log("fetchContractsMetaData error: ", error.message);
    }
  };

  useEffect(() => {
    if (!isDeleting || !isImporting) fetchContractsMetaData();
  }, [isDeleting, isImporting]);

  useEffect(() => {
    if (!isDeleting && !isImporting) {
      if (!searchString) fetchContracts();
      if (searchString && searchString.length > 3) fetchContracts();
    }
  }, [paginationModel, searchString, isDeleting, isImporting]);

  //
  const fetchContracts = async () => {
    try {
      setIsListFetched(true);
      const response = await getContracts({
        pageNumber: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        searchKeyword: searchString,
        organizationId,
      });

      const { data, meta_data } = response;

      setContracts(data);
      setTotalRows(meta_data.count);
      setIsPageLoading((prev) => ({ ...prev, list: false }));
      setIsListFetched(false);
    } catch (error) {
      console.log("fetchContracts error: ", error.message);
    }
  };

  // A function to handle table row click:
  const handleOnRowClick = (params) =>
    navigate(`/manage/contracts/view/${params.id}`);

  const onClickRowDelete = (e, data) => {
    e.stopPropagation(); // Remove row click effect
    setDialogType("DELETE");
    toggleDialog(true); // Open confirmation dialog
    setDeleteID(data?.id); // Set ID to delete
  };

  /* Implement a function to handle delete */
  const handleOnDelete = async () => {
    setIsDeleting(true);

    try {
      await deleteContract(deleteID); // Call delete organization service

      setIncomingResponse({
        severity: "success",
        message: "Contract is deleted successfully",
      });

      setIsDeleting(false);
      toggleDialog(false);
      setDeleteID(null);
    } catch (error) {
      setIncomingResponse({
        severity: "error",
        message: error?.message,
      });

      setIsDeleting(false);
    }
  };

  // Handled export functionality
  const onClickExport = (e, data) => {
    setDialogType("EXPORT");
    toggleDialog(true);
  };

  const handleOnExport = (value) => {
    const type = value.id;
    const filename = `contracts-${new Date().toISOString()}`;
    setIsExporting(true);

    switch (type) {
      case "csv":
        exportToCSV(contracts, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      case "xlsx":
        exportToXLSX(contracts, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      default:
        console.log("Invalid export format/type");
        setIsExporting(false);
        break;
    }
  };

  // Import contracts functionality:
  const onClickImport = () => {
    setDialogType("IMPORT_CONTRACT");
    toggleDialog(true);
  }; // function to handle event trigger.

  /* Implement a function to handle import */
  const handleOnImport = async (value) => {
    setIsImporting(true);

    try {
      await importContracts(value); // Call import contracts service

      setIncomingResponse({
        severity: "success",
        message: "Contracts are uploaded successfully",
      });

      setIsImporting(false);
      toggleDialog(false);
      // setDeleteID(null);
    } catch (error) {
      setIncomingResponse({
        severity: "error",
        message: error?.message,
      });

      setIsImporting(false);
    }
  }; // Import contracts function

  const dialogOptions = {
    IMPORT_CONTRACT: {
      onConfirm: handleOnImport,
      isLoading: isImporting,
    },
    EXPORT: {
      onConfirm: handleOnExport,
      isLoading: isExporting,
    },
    DELETE: {
      onConfirm: handleOnDelete,
      isLoading: isDeleting,
    },
  };

  //defining columns
  const columns = [
    {
      field: "is_active",
      headerName: "",
      width: 40,
      align: "center",
      renderCell: (params) => (
        <span>
          {params.value ? (
            <configs.icons.CheckCircleIcon color="success" />
          ) : (
            <configs.icons.UnpublishedIcon color="error" />
          )}
        </span>
      ),
    },
    { field: "name", headerName: "Name", width: 190 },
    {
      field: "organization",
      headerName: "Organization",
      width: 300,
      renderCell: (params) => {
        return <span>{params.value.name}</span>;
      },
    },
    {
      field: "contract_type",
      headerName: "Type",
      width: 200,
      renderCell: (params) => {
        const c_type = contractTypes?.data?.filter(
          (c) => c.id === params?.value
        );
        return <span>{c_type[0].label}</span>;
      },
    },
    { field: "number", headerName: "Number", width: 200 },
    {
      field: "contract_start_date",
      headerName: "Start Date ",
      width: 200,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: "contract_end_date",
      headerName: "End Date",
      width: 200,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: "total_contract_spend_value",
      headerName: "Total Value",
      width: 130,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return <span>{params.value.name}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          disabled={!userPermissions?.includes("DELETE")}
          onClick={(e) => onClickRowDelete(e, params)}
        >
          <configs.icons.Trash
            color={userPermissions?.includes("DELETE") ? "error" : "disabled"}
          />
        </IconButton>
      ),
    },
  ];

  const formatDate = (date) => {
    if (!date) return "N.A.";
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime())
      ? "N.A."
      : moment(parsedDate.toISOString()).format("DD/MMM/YYYY");
  };

  if (isPageLoading.meta_data || isPageLoading.list) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <HeaderChip
              label={"Contracts | Summaries"}
              icon={
                <configs.icons.DescriptionIcon
                  sx={{ color: "#fff" + "!important" }}
                />
              }
            />
          </Grid>

          <Grid container item xs={12} spacing={2}>
            {cardsList.map((card) => (
              <Grid sm={3} key={card.id} item>
                <DashboardCard
                  icon={card.icon}
                  label={card.label}
                  count={summaries && summaries[card.id]}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <AppSpacer vertical={"20px"} />

        <Grid container item spacing={2} alignItems="center">
          <Grid item xs={6}>
            <HeaderChip
              label={"Contracts | List"}
              icon={
                <configs.icons.List sx={{ color: "#fff" + "!important" }} />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Searchbar
              placeholder={"Search..."}
              value={searchString}
              onChange={({ target: { value } }) => setSearchString(value)}
              onClear={() => setSearchString(null)}
            />
          </Grid>
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermissions.includes("IMPORT")}
              onClick={onClickImport}
              primary
            >
              Import
            </MUIButton>
          </Grid>
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermissions.includes("EXPORT")}
              onClick={onClickExport}
              primary
            >
              Export
            </MUIButton>
          </Grid>
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermissions.includes("CREATE")}
              onClick={() => navigate("/manage/contracts/register")}
              primary
            >
              Create
            </MUIButton>
          </Grid>
          <Grid xs={12} item>
            <MUIGridTable
              loading={isListFetched}
              columns={columns}
              rows={contracts}
              rowCount={totalRows}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              onRowClick={
                userPermissions.includes("READ") ? handleOnRowClick : null
              }
            />
          </Grid>
        </Grid>

        {/* Confirmation Dialog */}
        <Modal
          open={openDialog}
          onClose={() => (isDeleting ? {} : toggleDialog((e) => !e))}
        >
          <div className="dialog-container">
            <DialogsGroup
              type={dialogType}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={dialogOptions[dialogType]?.onConfirm}
              isLoading={dialogOptions[dialogType]?.isLoading}
            />
            {/* <ConfirmationDialog
              primaryLabel={"Delete"}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={handleOnDelete}
              isLoading={isDeleting}
            /> */}
          </div>
        </Modal>
      </div>
    );
  }
};
//}
export default ContractsDashboard;

const cardsList = [
  {
    id: "all_count",
    icon: <configs.icons.DescriptionIcon />,
    count: 0,
    label: "Contracts",
  },
  {
    id: "active_count",
    icon: <configs.icons.CheckCircleIcon />,
    count: 0,
    label: "Active Contracts",
  },
  {
    id: "in_active_count",
    icon: <configs.icons.UnpublishedIcon />,
    count: 0,
    label: "In-Active Contracts",
  },
  {
    id: "listed_in_7_days",
    icon: <configs.icons.SyncIcon />,
    count: 0,
    label: "Listed In Last 7 Days",
  },
];
