/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/

import { Avatar, ButtonBase, Grid, Typography } from "@mui/material";
import configs from "../../../configs/configs";
import MUICard from "../../MUICard/MUICard.component";
import { useState } from "react";

//
function ProfileThumbnail({ displayName, role, handleMenuOpen }) {
  //
  const [isActive, setIsActive] = useState(false);

  //
  const activeColor = configs.colors.primary;
  const textColor = isActive ? activeColor : configs.colors.secondary;

  //
  const toggleIsActive = () => setIsActive((e) => !e);

  return (
    <div
      style={{ cursor: "pointer" }}
      onMouseEnter={toggleIsActive}
      onMouseLeave={toggleIsActive}
    >
      <Grid alignItems={"center"} columnSpacing={2} container>
        <Grid xs={8} item>
          <ProfileDetails
            textColor={textColor}
            displayName={displayName}
            role={role}
          />
        </Grid>
        <Grid xs={2} item>
          <ButtonBase
            id="user_menu"
            aria-expanded="true"
            onClick={handleMenuOpen}
            sx={{ borderRadius: "50%" }}
          >
            <ProfilePicture
              src=""
              isActive={isActive}
              activeColor={activeColor}
              displayName={displayName}
            />
          </ButtonBase>
        </Grid>
      </Grid>
    </div>
  );
}
export default ProfileThumbnail;

//
function ProfileDetails({ textColor, displayName, role }) {
  return (
    <Grid container>
      <Grid xs={12} item>
        <Typography
          textAlign={"right"}
          color={textColor}
          fontWeight={"bold"}
          variant="body2"
          textTransform={"capitalize"}
        >
          {displayName}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography
          textAlign={"right"}
          color={textColor}
          fontWeight={"bold"}
          variant="body2"
          fontSize={"12px"}
          textTransform={"capitalize"}
        >
          ({role})
        </Typography>
      </Grid>
    </Grid>
  );
}

//
function ProfilePicture({ src, isActive, activeColor, displayName }) {
  const profileImage = src ? src : configs.images.default_profile_photo;
  const userNames = displayName && displayName?.split(" ");
  const avatarName = displayName && userNames[0][0] + userNames[1][0];
  return (
    <MUICard
      style={{
        width: "50px",
        aspectRatio: 1,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isActive ? activeColor : "#000",
      }}
      padding={0}
      removeDefaultPadding
    >
      <Avatar sx={{ bgcolor: "#fff", color: "#000" }}>
        <b>{avatarName}</b>
      </Avatar>
    </MUICard>
  );
}
