import "./ImportDialog.style.css";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog.component";
import configs from "src/configs/configs";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import { useRef, useState } from "react";
import { Alert, ButtonBase, Link, TextField, Typography } from "@mui/material";
import MUIButton from "../MUIButton/MUIButton.component";
import formatFileSize from "src/helpers/formatFileSize.helper";
import AppSpacer from "../AppSpace/AppSpace.component";
import downloadContractsTemplate from "src/helpers/downloadContractsTemplate.helper";
import downloadAssetsTemplate from "src/helpers/downloadAssetsTemplate.helper";
import MUIAutocomplete from "../MUIAutocomplete/MUIAutocomplete.component";
import MUIInputLabel from "../MUIInputLabel/MUIInputLabel.component";
import { useSelector } from "react-redux";
import useGetOrganizations from "src/hooks/useGetOrganizations.hook";
import { useFormik } from "formik";
import * as Yup from "yup";

const ImportDialog = ({ onCancel, onConfirm, isLoading, type }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const organizationId = currentUser?.organization_id || null;

  const [file, setFile] = useState(null);
  const [searchStrings, setSearchStrings] = useState({
    organization_id: null,
  });
  const inputRef = useRef(null);

  const [organizations, isOrganizationsLoading] = useGetOrganizations(
    searchStrings?.organization_id
  );

  const formik = useFormik({
    initialValues: {
      organization_id: organizationId
        ? {
            id: organizationId,
            label: "",
            value: organizationId,
          }
        : null,
    },
    validationSchema: Yup.object().shape({
      organization_id: Yup.object().required("Organization is required!"),
    }),

    onSubmit: (values) => {
      const value = {
        file,
        organization_id: values?.organization_id?.id,
      };

      onConfirm(value);
    },
  });
  // const [selectedOption, setSelectedOption] = useState({
  //   id: "csv",
  //   label: ".CSV",
  //   value: "csv",
  // });

  const handleAutocompleteChange = (e, value) => {
    if (value) formik.handleChange({ target: { name: value.name, value } });
    else {
      const { name, value } = e.target;
      setSearchStrings((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleChooseFile = () => {
    inputRef.current.click();
  };

  const handleResetFile = () => {
    inputRef.current.value = null;
    setFile(null);
  };

  const handleOnChange = (e) => {
    const { files } = e.target;
    setFile(files[0]);
  };

  const handleDownloadTemplate = () =>
    type === "CONTRACT"
      ? downloadContractsTemplate()
      : downloadAssetsTemplate();

  const handleDisabledConfirm = () => {
    const isFile = file ? false : true;
    return isFile;
  };

  return (
    <ConfirmationDialog
      icon={
        <configs.icons.ExportRoundedIcon
          className="default-size-icon"
          sx={{ color: configs.colors.primary }}
        />
      }
      headerText={"Select a file to import"}
      captionText={"Import .csv/.xlsx"}
      primaryLabel={"Import"}
      onCancel={onCancel}
      onConfirm={formik.handleSubmit}
      isLoading={isLoading}
      disabledConfirm={handleDisabledConfirm()}
    >
      {organizationId ? null : (
        <div style={{ width: "100%", textAlign: "left" }}>
          <AppSpacer vertical={"12px"} />
          <MUIInputLabel>Select organization</MUIInputLabel>
          <MUIAutocomplete
            loading={isOrganizationsLoading}
            name={"organization_id"}
            value={formik.values.organization_id}
            options={organizations}
            onChange={handleAutocompleteChange}
            error={formik?.errors && formik?.errors.organization_id}
            helperText={formik?.errors && formik?.errors.organization_id}
          />
        </div>
      )}

      <AppSpacer vertical={"12px"} />

      {file ? (
        <Alert
          icon={<configs.icons.CloudUploadIcon />}
          variant="outlined"
          onClose={handleResetFile}
        >
          {file?.name} ({formatFileSize(file?.size)}){" "}
        </Alert>
      ) : (
        <MUIButton onClick={handleChooseFile} primary>
          Choose a file
        </MUIButton>
      )}
      <input
        ref={inputRef}
        type="file"
        onChange={handleOnChange}
        style={{ display: "none" }}
        accept=".csv, .xlsx"
      />
      <AppSpacer vertical={"12px"} />
      <Typography variant="caption">
        <ButtonBase onClick={handleDownloadTemplate}>
          <u>Download sample template.</u>
        </ButtonBase>
      </Typography>
    </ConfirmationDialog>
  );
};

export default ImportDialog;

// const exportOptions = [
//   {
//     id: "csv",
//     label: ".CSV",
//     value: "csv",
//   },
//   {
//     id: "xlsx",
//     label: ".XLSX",
//     value: "xlsx",
//   },
// ];
