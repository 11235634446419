import { Avatar, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MUICard from "src/components/MUICard/MUICard.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import configs from "src/configs/configs";
import { getOrganizationByID } from "src/services/organizations.services";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";

const ViewOrganizationDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const organizationId = params?.id;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [organization, setOrganization] = useState(null);
  const [primaryUser, setPrimaryUser] = useState(null);

  useEffect(() => {
    getOrganizationByID(organizationId).then((response) => {
      setOrganization(response);
      setPrimaryUser(response.primaryUser);
      setIsPageLoading(false);
    });
  }, [organizationId]);

  if (isPageLoading) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <HeaderChip
          icon={
            <configs.icons.BusinessIcon
              sx={{ color: "#fff" + "!important" }}
            />
          }
          label={`Organization > View > ${organization?.name}`}
        />
      
        <div style={{ width: "500px", margin: "auto" }}>
          <MUICard style={{ position: "relative" }}>
            <Grid alignItems={"center"} container>
              <Grid sm={3} item>
                <Avatar
                  sx={{
                    bgcolor: organization.is_active ? configs.colors.primary : "",
                    width: "80px",
                    height: "80px",
                  }}
                >
                  {organization.is_active ? (
                    <configs.icons.BusinessIcon sx={{ fontSize: "42px" }} />
                  ) : (
                    <configs.icons.DomainDisabledIcon sx={{ fontSize: "42px" }} />
                  )}
                </Avatar>
              </Grid>
              <Grid sm={8} item>
                <Typography variant="h5">{organization?.name}</Typography>
                <Typography variant="caption">
                  {organization?.description}
                </Typography>
              </Grid>
              <Grid sm={1} item>
                <div style={{ position: "absolute", top: 10, right: 10 }}>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() =>
                        navigate(`/manage/organizations/edit/${organization.id}`)
                      }
                    >
                      <configs.icons.EditIcon
                        sx={{
                          color: configs.colors.primary,
                          fontSize: 18,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </MUICard>
          <MUICard>
            <Grid alignItems={"center"} container>
              <Grid sm={1} style={{ textAlign: "center" }} item>
                <configs.icons.LocationOnIcon />
              </Grid>
              <Grid sm={8} item>
                <Typography variant="body2">
                  {organization?.city}, {organization?.state},{" "}
                  {organization?.country} - {organization?.zipcode}
                </Typography>
              </Grid>
            </Grid>
          </MUICard>
          <MUICard
            style={{ position: "relative" }}
            title={"Primary Contact"}
            silentHeader
          >
            <Grid alignItems={"center"} container>
              <Grid sm={3} item>
                <Avatar
                  sx={{
                    bgcolor: primaryUser.is_active ? configs.colors.primary : "",
                    width: "80px",
                    height: "80px",
                  }}
                >
                  {primaryUser.is_active ? (
                    <configs.icons.PersonIcon sx={{ fontSize: "42px" }} />
                  ) : (
                    <configs.icons.PersonOffIcon sx={{ fontSize: "42px" }} />
                  )}
                </Avatar>
              </Grid>
              <Grid sm={8} item>
                <Typography variant="h5">
                  {primaryUser?.firstname} {primaryUser?.lastname},{" "}
                  {primaryUser?.designation}
                </Typography>
                <Typography textTransform={"capitalize"} variant="body2">
                  {primaryUser?.department} Department
                </Typography>
                <Typography variant="caption">
                  {primaryUser?.email} | {primaryUser?.contact_number}
                </Typography>
              </Grid>
              <Grid sm={1} item>
                <div style={{ position: "absolute", top: 10, right: 10 }}>
                  <Tooltip title="View">
                    <IconButton
                      onClick={() =>
                        navigate(`/manage/users/view/${primaryUser.id}`)
                      }
                    >
                      <configs.icons.ViewActivities
                        sx={{
                          color: configs.colors.primary,
                          fontSize: 18,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </MUICard>
        </div>
      </div>
    );
  }
};

export default ViewOrganizationDetails;
