import { City, Country, State } from "country-state-city";
import { useEffect, useState } from "react";

const useGetCountryStateCity = () => {
  return {
    useGetCountries,
    useGetStatesByCountryCode,
    useGetCityByStateCountryCode,
  };
};
export default useGetCountryStateCity;

const useGetCountries = () => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const rawCountries = Country.getAllCountries();
    const parseCountries = rawCountries.map((cntry) => ({
      id: cntry?.isoCode,
      label: cntry?.name,
      value: cntry?.name,
      name: "country",
    }));
    setCountries(parseCountries);
  }, []);

  return [countries, setCountries];
};

const useGetStatesByCountryCode = (countryCode) => {
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (countryCode) {
      const states = State.getStatesOfCountry(countryCode);
      const parseStates = states.map((state) => ({
        id: state?.isoCode,
        label: state?.name,
        value: state?.name,
        name: "state",
      }));

      setStates(parseStates);
    }
  }, [countryCode]);

  return [states, setStates];
};

const useGetCityByStateCountryCode = (countryCode, stateCode) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const cities = City.getCitiesOfState(countryCode, stateCode);

    const parseCities = cities.map((city) => ({
      id: `${city?.countryCode}_${city?.stateCode}`,
      label: city?.name,
      value: city?.name,
      name: "city",
    }));

    setCities(parseCities);
  }, [stateCode]);

  return [cities, setCities];
};
