import configs from "src/configs/configs";
import CardTitle from "src/components/CardTitle/CardTitle.component";
import MUICard from "src/components/MUICard/MUICard.component";
import { Grid } from "@mui/material";
import registerAdminFormFields from "./registerAdminFormFields.config";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";

const RegisterAdminForm = ({ formik, handleInputChange, isRegistering }) => {
  return (
    <MUICard
      title={
        <CardTitle
          title="Register Admin"
          icon={
            <configs.icons.AddCustomer sx={{ color: configs.colors.primary }} />
          }
        />
      }
    >
      <Grid spacing={2} container>
        {registerAdminFormFields.map((field) => (
          <Grid md={6} key={field.id} item>
            <MUIInputLabel>
              {field.isRequired && <span style={{ color: "red" }}>*</span>}
              {field.label}
            </MUIInputLabel>
            <MUITextField
              Name={`user[${field.id}]`}
              Type={field.type}
              Value={formik.values.user[field.id]}
              OnChange={handleInputChange}
              Error={
                formik.touched.user &&
                formik.errors.user &&
                formik.errors.user[field.id]
              }
              helperText={
                formik.touched.user &&
                formik.errors.user &&
                formik.errors?.user[field.id]
              }
              Disabled={isRegistering}
            />
          </Grid>
        ))}
      </Grid>
    </MUICard>
  );
};

export default RegisterAdminForm;
