import { Button } from "@mui/material";
import configs from "../../configs/configs";
import { LoadingButton } from "@mui/lab";

const MUIButton = ({
  children,
  sx = {},
  color,
  onClick,
  primary,
  isLoadingButton,
  isLoading,
  disabled,
  variant,
  size,
  startIcon,
}) => {
  const isOutlined = variant === "outlined" ? true : false;
  // Default primary styling
  const primarySx = primary
    ? {
        "&:hover": {
          backgroundColor: isOutlined ? null : configs.colors.primary,
          borderColor: configs.colors.primary,
        },
        backgroundColor: isOutlined ? null : configs.colors.primary_900,
        color: !isOutlined ? null : configs.colors.primary,
        borderColor: configs.colors.primary,

        "&.Mui-disabled": {
          backgroundColor: isOutlined ? null : configs.colors.primary_200,
          borderColor: isOutlined ? null : configs.colors.primary,
        },
      }
    : {};

  // Default styling
  const defaultSx = {
    borderRadius: 2,
    ...primarySx,
  };

  return (
    <>
      {isLoadingButton ? (
        <LoadingButton
          loading={isLoading}
          sx={{ ...defaultSx, ...sx }}
          color={color}
          variant={variant || "contained"}
          onClick={onClick}
          disabled={disabled}
          size={size}
          fullWidth
        >
          {children}
        </LoadingButton>
      ) : (
        <Button
          sx={{ ...defaultSx, ...sx }}
          color={color}
          variant={variant || "contained"}
          onClick={onClick}
          disabled={disabled}
          size={size}
          startIcon={startIcon}
          fullWidth
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default MUIButton;
