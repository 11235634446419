import { exportToXLSX } from "./exportData.helper";

const templateName = new Date().getTime() + "_assets_template";
const downloadAssetsTemplate = () => exportToXLSX([templateJSON], templateName);

export default downloadAssetsTemplate;

const templateJSON = {
  "Organization Id": "",
  Name: "",
  "Asset Type": "",
  "Contract Id": "",
  "Category Id": "",
  "Manufacturer Id": "",
  "Serial Number": "",
  "Supplier Id": "",
  "Seller Id": "",
  "Reseller Id": "",
  Address: "",
  State: "",
  City: "",
  Country: "",
  "Asset Location Address": "",
  "Location Description": "",
  "Asset Owner Id": "",
  "Business Unit Id": "",
  "Warranty Status": "",
  "Warranty Type": "",
  "Warranty Provider Id": "",
  "End Of Life Date": "",
  "Warranty Start Date": "",
  "Warranty End Date": "",
  "Warranty Contact Phone": "",
  "Warranty Contact Email": "",
  "Warranty Notes": "",
  "Support Status": "",
  "Support Type": "",
  "Support Provider Id": "",
  "End Of Support Date": "",
  "Support Start Date": "",
  "Support End Date": "",
  "Support Contact Phone": "",
  "Support Contact Email": "",
  "Support Notes": "",
  "Usage Status": "",
  "Asset Tag": "",
  "Asset Notes": "",
  "Customer Part Number": "",
  "Manufacturer Part Number": "",
  "Hardware Model Id": "",
  "Sales Manager Name": "",
  "Sales Manager Email": "",
  "Additional Sales Email": "",
};

// const templateJSON = {
//   contract_type: "",
//   name: "",
//   number: "",
//   customer_id: "",
//   supplier_id: "",
//   country: "",
//   status_id: "",
//   category_id: "",
//   description: "",
//   business_unit_id: "",
//   contract_start_date: "",
//   contract_end_date: "",
//   notice_period: 0,
//   service_start_date: "",
//   end_of_term_clause: "",
//   end_of_term_action: "",
//   timeline_notes: "",
//   total_contract_spend_value: "",
//   monthly_recurring_cost: "",
//   non_recurring_cost: "",
//   payment_terms_id: "",
//   financing: "",
//   financial_notes: "",
// };
