import { get, post, put } from "../utils/axios/axios.utils";
const path = "/payment-terms";

export const getPaymentTerms = async (params) => {
  try {
    const response = await get(`${path}`, {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

/**
 *
 * @param {Object} data - Params for creating payment terms
 * @returns
 */
export const createPaymentTerms = async (data) => {
  try {
    const response = await post(`${path}`, data);
    return response;
  } catch (error) {
    console.error("createPaymentTerms:", error.message);
    throw error;
  }
};
