/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/
//
const fonts = {
  size: {
    small: "16px",
    medium: "20px",
    large: "20px",
  },
  mui_size: {
    small: "small",
    medium: "medium",
    large: "large",
  },
};

export default fonts;
