// 
const crypto = require("crypto-js");

const encryptData = (data) => {
  return crypto.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
};

const decryptData = (data) => {
  console.log("CALLING DECRYPT DATA...");
  return crypto.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY).toString(
    crypto.enc.Utf8
  );
};

module.exports = {
  encryptData,
  decryptData,
};
