// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent-card-container-v2 {
  cursor: pointer;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}
.parent-card-container-v2:hover {
  transform: scale(1.08);
  transition: all 0.2s ease-in-out;
}
.card-container-v2 {
  width: 100%;
  height: 100px;
}

.value-container-v2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.icon-container-v2 {
  width: 100%;
  height: 100%;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/DashboardCard/DashboardCardV2.style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,gCAAgC;AAClC;AACA;EACE,sBAAsB;EACtB,gCAAgC;AAClC;AACA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;AACf","sourcesContent":[".parent-card-container-v2 {\n  cursor: pointer;\n  transform: scale(1);\n  transition: all 0.2s ease-in-out;\n}\n.parent-card-container-v2:hover {\n  transform: scale(1.08);\n  transition: all 0.2s ease-in-out;\n}\n.card-container-v2 {\n  width: 100%;\n  height: 100px;\n}\n\n.value-container-v2 {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n}\n\n.icon-container-v2 {\n  width: 100%;\n  height: 100%;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
