
// initial values for register organization.
export const initializeOrganizationValues = () => ({
  name: "",
  zipcode: "",
  description: "",
  country: "",
  city: "",
  state: "",
});

// initial values for register user.
export const initializeUserValues = () => ({
  firstname: "",
  lastname: "",
  email: "",
  contact_number: "",
  designation: "",
  department: "",
});
