// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .drag-container {
  padding: 12px;
  border: 2px dashed rgb(203, 203, 203);
  border-radius: 8px;
  text-align: center;
}

.drag-accept {
  border-color: #388e3c !important;
} */
`, "",{"version":3,"sources":["webpack://./src/pages/PlayGrounds/Playground.style.css"],"names":[],"mappings":"AAAA;;;;;;;;;GASG","sourcesContent":["/* .drag-container {\n  padding: 12px;\n  border: 2px dashed rgb(203, 203, 203);\n  border-radius: 8px;\n  text-align: center;\n}\n\n.drag-accept {\n  border-color: #388e3c !important;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
