import { Avatar, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MUICard from "src/components/MUICard/MUICard.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import configs from "src/configs/configs";
import { getOrganizationByID } from "src/services/organizations.services";
import { getUserById } from "src/services/users.services";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";

const ViewUserDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const userId = params.id;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [organization, setOrganization] = useState(null);
  const [user, setUser] = useState(null);

  const fetchOrganization = async (organizationId) => {
    try {
      const response = await getOrganizationByID(organizationId);
      setOrganization(response);
    } catch (error) {
      console.log("fetchOrganization: ", error.message);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await getUserById(userId);
      await fetchOrganization(response.organization_id);
      setUser(response);
      setIsPageLoading(false);
    } catch (error) {
      console.log("fetchUser: ", error.message);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [userId]);
  if (isPageLoading) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <HeaderChip
          icon={
            <configs.icons.PersonIcon
              sx={{ color: "#fff" + "!important" }}
            />
          }
          label={`Users > View > ${user?.firstname} ${user?.lastname}`}
        />
      
        <div style={{ width: "500px", margin: "auto" }}>
          <Grid container>
            <Grid sm={12} item>
              <MUICard style={{ position: "relative" }}>
                <Grid alignItems={"center"} container>
                  <Grid sm={3} item>
                    <Avatar
                      sx={{
                        bgcolor: user?.is_active ? configs.colors.primary : "",
                        width: "80px",
                        height: "80px",
                      }}
                    >
                      {user?.is_active ? (
                        <configs.icons.PersonIcon sx={{ fontSize: "42px" }} />
                      ) : (
                        <configs.icons.PersonOffIcon sx={{ fontSize: "42px" }} />
                      )}
                    </Avatar>
                  </Grid>
                  <Grid sm={8} item>
                    <Typography variant="h5">
                      {user?.firstname} {user?.lastname}, {user?.designation}
                    </Typography>
                    <Typography textTransform={"capitalize"} variant="body2">
                      {user?.department} Department
                    </Typography>
                    <Typography variant="caption">
                      {user?.email} | {user?.contact_number}
                    </Typography>
                  </Grid>
                  <Grid sm={1} item>
                    <div style={{ position: "absolute", top: 10, right: 10 }}>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() =>
                            navigate(
                              `/manage/users/edit/${user?.id}`
                            )
                          }
                        >
                          <configs.icons.EditIcon
                            sx={{
                              color: configs.colors.primary,
                              fontSize: 18,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              </MUICard>
            </Grid>
            <Grid sm={12} item>
              <MUICard style={{ position: "relative" }}>
                <Grid alignItems={"center"} container>
                  <Grid sm={3} item>
                    <Avatar
                      sx={{
                        bgcolor: organization?.is_active
                          ? configs.colors.primary
                          : "",
                        width: "80px",
                        height: "80px",
                      }}
                    >
                      {organization?.is_active ? (
                        <configs.icons.BusinessIcon sx={{ fontSize: "42px" }} />
                      ) : (
                        <configs.icons.DomainDisabledIcon
                          sx={{ fontSize: "42px" }}
                        />
                      )}
                    </Avatar>
                  </Grid>
                  <Grid sm={8} item>
                    <Typography variant="h5">{organization?.name}</Typography>
                    <Typography variant="caption">
                      {organization?.description}
                    </Typography>
                  </Grid>
                  <Grid sm={1} item>
                    <div style={{ position: "absolute", top: 10, right: 10 }}>
                      <Tooltip title="View">
                        <IconButton
                          onClick={() =>
                            navigate(
                              `/manage/organizations/view/${organization?.id}`
                            )
                          }
                        >
                          <configs.icons.ViewActivities
                            sx={{
                              color: configs.colors.primary,
                              fontSize: 18,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              </MUICard>
              <MUICard>
                <Grid alignItems={"center"} container>
                  <Grid sm={1} style={{ textAlign: "center" }} item>
                    <configs.icons.LocationOnIcon />
                  </Grid>
                  <Grid sm={8} item>
                    <Typography variant="body2">
                      {organization?.city}, {organization?.state},{" "}
                      {organization?.country} - {organization?.zipcode}
                    </Typography>
                  </Grid>
                </Grid>
              </MUICard>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
};

export default ViewUserDetails;
