import { Grid, IconButton, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import DashboardCard from "src/components/DashboardCard/DashboardCard.component";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUIGridTable from "src/components/MUIGridTable/MUIGridTable.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import configs from "src/configs/configs";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import { useSelector } from "react-redux";
import Searchbar from "src/components/Searchbar/Searchbar.component";
import { exportToCSV, exportToXLSX } from "src/helpers/exportData.helper";
import DialogsGroup from "src/components/DialogsGroup/DialogsGroup.component";
import {
  deleteIntegratorsById,
  getInegrators,
  getInegratorsMetaData,
} from "src/services/integrators.service";

const IntegratorsDashboard = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const organizationId = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions["integrators"] || [];

  const [isPageLoading, setIsPageLoading] = useState({
    meta_data: true,
    list: true,
  });
  const [isListFetched, setIsListFetched] = useState(false);
  const [summaries, setSummaries] = useState(null);
  const [integrators, setIntegrators] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [incomingResponse, setIncomingResponse] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [searchString, setSearchString] = useState(null);
  const [openDialog, toggleDialog] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [dialogType, setDialogType] = useState(null);

  // get integrators meta data:
  const fetchIntegratorsMetaData = async () => {
    try {
      const response = await getInegratorsMetaData({ organizationId });

      setSummaries({
        all_count: response?.data?.count,
        active_count: response?.data?.active,
        in_active_count: response?.data?.in_active,
        listed_in_7_days: response?.data?.registered_within_7_Days,
      });

      setIsPageLoading((prev) => ({ ...prev, meta_data: false }));
    } catch (error) {
      console.log("fetchIntegratorsMetaData: ", error.message);
    }
  };

  useEffect(() => {
    if (!isDeleting) fetchIntegratorsMetaData();
  }, [isDeleting]);

  const fetchIntegrators = async () => {
    try {
      setIsListFetched(true);

      const response = await getInegrators({
        pageNumber: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        searchKeyword: searchString,
        organizationId,
      });

      const { data, meta_data } = response;

      setIntegrators(data);
      setTotalRows(meta_data.count);

      setIsPageLoading((prev) => ({ ...prev, list: false }));
      setIsListFetched(false);
    } catch (error) {
      console.log("fetchIntegrators: ", error.message);
    }
  };

  useEffect(() => {
    if (!isDeleting) {
      if (!searchString) fetchIntegrators();
      if (searchString && searchString.length > 3) fetchIntegrators();
    }
  }, [paginationModel, searchString, isDeleting]);

  // A function to handle table row click:
  const handleOnRowClick = (params) =>
    navigate(`/manage/integrators/edit/${params.id}`);

  const onClickRowDelete = (e, data) => {
    e.stopPropagation(); // Remove row click effect
    setDialogType("DELETE");
    toggleDialog(true); // Open confirmation dialog
    setDeleteID(data?.id); // Set ID to delete
  };

  const handleOnDelete = async () => {
    setIsDeleting(true);

    try {
      await deleteIntegratorsById(deleteID); // Call delete service

      setIncomingResponse({
        severity: "success",
        message: "Integrator is deleted successfully",
      });

      setIsDeleting(false);
      toggleDialog(false);
      setDeleteID(null);
    } catch (error) {
      const message = error?.response?.data?.message || error.message;
      setIncomingResponse({
        severity: "error",
        message,
      });

      setIsDeleting(false);
    }
  };

  // Handled export functionality
  const onClickExport = (e, data) => {
    setDialogType("EXPORT");
    toggleDialog(true);
  };

  const handleOnExport = (value) => {
    const type = value.id;
    const filename = `integrators-${new Date().toISOString()}`;
    setIsExporting(true);

    switch (type) {
      case "csv":
        exportToCSV(integrators, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      case "xlsx":
        exportToXLSX(integrators, filename);
        setIsExporting(false);
        toggleDialog(false);
        break;
      default:
        console.log("Invalid export format/type");
        setIsExporting(false);
        break;
    }
  };

  const dialogOptions = {
    EXPORT: {
      onConfirm: handleOnExport,
      isLoading: isExporting,
    },
    DELETE: {
      onConfirm: handleOnDelete,
      isLoading: isDeleting,
    },
  };

  //Defining Columns
  const columns = [
    {
      field: "is_active",
      headerName: "",
      width: 40,
      align: "center",
      renderCell: (params) => (
        <span>
          {params.value ? (
            <configs.icons.CheckCircleIcon color="success" />
          ) : (
            <configs.icons.UnpublishedIcon color="error" />
          )}
        </span>
      ),
    },
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "organization",
      headerName: "Organization",
      width: 300,
      renderCell: (params) => {
        return <span>{params.value.name}</span>;
      },
    },
    { field: "description", headerName: "Description", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "address",
      headerName: "Address",
      width: 300,

      renderCell: (params) => (
        <span style={{ whiteSpace: "pre-wrap" }}>
          {params.row.street_address_1}, {params.row.street_address_2},{" "}
          {params.row.city}, {params.row.state}, {params.row.country},{" "}
          {params.row.zipcode}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          disabled={!userPermissions?.includes("DELETE")}
          onClick={(e) => onClickRowDelete(e, params)}
        >
          <configs.icons.Trash
            color={userPermissions?.includes("DELETE") ? "error" : "disabled"}
          />
        </IconButton>
      ),
    },
  ];

  if (isPageLoading.meta_data || isPageLoading.list) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <Grid alignItems={"center"} spacing={2} container>
          <Grid xs={12} item>
            <HeaderChip
              label={"Integrators | Summaries"}
              icon={
                <configs.icons.HandshakeRoundedIcon
                  sx={{ color: "#fff" + "!important" }}
                />
              }
            />
          </Grid>
          <Grid xs={12} item>
            <Grid spacing={2} container>
              {cardsList.map((card) => (
                <Grid sm={3} key={card.id} item>
                  <DashboardCard
                    icon={card.icon}
                    label={card.label}
                    count={summaries && summaries[card.id]}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <AppSpacer vertical={"20px"} />

        <Grid alignItems={"center"} spacing={2} container>
          <Grid xs={6} item>
            <HeaderChip
              label={"Integrators | List"}
              icon={
                <configs.icons.List sx={{ color: "#fff" + "!important" }} />
              }
            />
          </Grid>
          <Grid xs={4} item>
            <Searchbar
              placeholder={"Search..."}
              value={searchString}
              onChange={({ target: { value } }) => setSearchString(value)}
              onClear={() => setSearchString(null)}
            />
          </Grid>
          <Grid item xs={1}>
            <MUIButton
              disabled={!userPermissions?.includes("EXPORT")}
              onClick={onClickExport}
              primary
            >
              Export
            </MUIButton>
          </Grid>
          <Grid xs={1} item>
            <MUIButton
              onClick={() => navigate("/manage/integrators/create")}
              primary
              disabled={!userPermissions?.includes("CREATE")}
            >
              Create
            </MUIButton>
          </Grid>
          <Grid xs={12} item>
            <MUIGridTable
              loading={isListFetched}
              columns={columns}
              rows={integrators}
              rowCount={totalRows}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              onRowClick={
                userPermissions?.includes("READ") ? handleOnRowClick : null
              }
            />
          </Grid>
        </Grid>

        {/* Confirmation Dialog */}
        <Modal
          open={openDialog}
          onClose={() => (isDeleting ? {} : toggleDialog((e) => !e))}
        >
          <div className="dialog-container">
            <DialogsGroup
              type={dialogType}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={dialogOptions[dialogType]?.onConfirm}
              isLoading={dialogOptions[dialogType]?.isLoading}
            />
            {/* <ConfirmationDialog
              primaryLabel={"Delete"}
              onCancel={() => toggleDialog((e) => !e)}
              onConfirm={handleOnDelete}
              isLoading={isDeleting}
            /> */}
          </div>
        </Modal>
      </div>
    );
  }
};

export default IntegratorsDashboard;

const cardsList = [
  {
    id: "all_count",
    icon: <configs.icons.HandshakeRoundedIcon />,
    count: 0,
    label: "Integrators",
  },
  {
    id: "active_count",
    icon: <configs.icons.CheckCircleIcon />,
    count: 0,
    label: "Active",
  },
  {
    id: "in_active_count",
    icon: <configs.icons.UnpublishedIcon />,
    count: 0,
    label: "Inactive",
  },
  {
    id: "listed_in_7_days",
    icon: <configs.icons.SyncIcon />,
    count: 0,
    label: "Listed In Last 7 Days",
  },
];
