// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/
.mui-card-header {
  background-color: rgba(84, 82, 224, 0.2);
  /* background-image: url("../../assets/images/card-background-004.jpg"); */
  background-size: cover;
  background-position: center;
  color: rgba(21, 47, 85, 1) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/MUICard/MUICard.style.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;EACE,wCAAwC;EACxC,0EAA0E;EAC1E,sBAAsB;EACtB,2BAA2B;EAC3B,qCAAqC;AACvC","sourcesContent":["/* \n    Copyright 2023-24 Rahi Systems Inc\n    Author: Pratik Sonar <pratik.sonar@rahisystems.com>\n*/\n.mui-card-header {\n  background-color: rgba(84, 82, 224, 0.2);\n  /* background-image: url(\"../../assets/images/card-background-004.jpg\"); */\n  background-size: cover;\n  background-position: center;\n  color: rgba(21, 47, 85, 1) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
