import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog.component";
import ExportDialog from "../ExportDialog/ExportDialog.component";
import ImportDialog from "../ImportDialog/ImportDialog.component";

const DialogsGroup = ({ type, ...props }) => {
  switch (type) {
    case "IMPORT_CONTRACT":
      return <ImportDialog {...props} type={"CONTRACT"} />;
    case "IMPORT_ASSET":
      return <ImportDialog {...props} type={"ASSET"} />;
    case "EXPORT":
      return <ExportDialog {...props} />;
    case "DELETE":
      return <ConfirmationDialog {...props} primaryLabel={"Delete"} />;
    default:
      return null;
  }
};

export default DialogsGroup;
