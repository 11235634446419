import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    currentUser: {},
    error: null,
  },
  reducers: {
    addAuthUser: (state, authUser) => {
      state.currentUser = authUser.payload;
    },
    addAuthUserError: (state, authUser) => {
      state.error = authUser.payload;
    },
  },
});

export const { addAuthUser, addAuthUserError } = authSlice.actions;

export default authSlice.reducer;
