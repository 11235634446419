import { exportToXLSX } from "./exportData.helper";

const templateName = new Date().getTime() + "_contracts_template";
const downloadContractsTemplate = () =>
  exportToXLSX([templateJSON], templateName);

export default downloadContractsTemplate;

const templateJSON = {
  Name: "",
  Number: "",
  Country: "",
  Type: "",
  Customer: "",
  Supplier: "",
  Status: "",
  Category: "",
  Description: "",
  "Business Unit": "",
  "Contract Start Date": "",
  "Contract End Date": "",
  "Notice Period": "",
  "Service Start Date": "",
  "End Of Term Clause": "",
  "End Of Term Action": "",
  "Timeline Notes": "",
  "Total Contract Spend Value": "",
  "Monthly Recurring Cost": "",
  "Non Recurring Cost": "",
  "Payment Terms": "",
  Financing: "",
  "Financial Notes": "",
};
// const templateJSON = {
//   contract_type: "",
//   name: "",
//   number: "",
//   customer_id: "",
//   supplier_id: "",
//   country: "",
//   status_id: "",
//   category_id: "",
//   description: "",
//   business_unit_id: "",
//   contract_start_date: "",
//   contract_end_date: "",
//   notice_period: 0,
//   service_start_date: "",
//   end_of_term_clause: "",
//   end_of_term_action: "",
//   timeline_notes: "",
//   total_contract_spend_value: "",
//   monthly_recurring_cost: "",
//   non_recurring_cost: "",
//   payment_terms_id: "",
//   financing: "",
//   financial_notes: "",
// };
