import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSuppliers } from "src/services/suppliers.services";

const useGetSupportProviders = (searchText, organizationId, options) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationID = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [isInitializing, setIsInitializing] = useState(true);
  const [supportProviders, setSupportProviders] = useState([]);
  const [isSupportProvidersLoading, setIsSupportProvidersLoading] =
    useState(false);

  const searchKeyword = searchText ? searchText : "";

  // Function to fetch customer:
  const fetchSupportProviders = async () => {
    try {
      setIsSupportProvidersLoading(true);
      const resp = await getSuppliers({
        searchKeyword,
        organizationId,
      });

      // Converting response into autocomplete options:
      const autocompleteOptions = resp?.data?.map((supportProvider) => ({
        id: supportProvider.id,
        label: supportProvider.name,
        value: supportProvider.id,
        name: "support_provider_id",
      }));

      setSupportProviders(autocompleteOptions);
      setIsSupportProvidersLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsSupportProvidersLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      if (!searchText && (!isInitializing || !options?.data))
        fetchSupportProviders();
      if (searchText && searchText.length > 3) fetchSupportProviders();
    }
  }, [searchText, organizationId]);

  useEffect(() => {
    if (options?.data && options?.data[0] && isInitializing) {
      const suppliers = options?.data;
      const autocompleteOptions = getAutocompleteOptions(suppliers);
      setSupportProviders(autocompleteOptions);
      setIsInitializing(false);
    }
  }, [options?.data]);

  return [supportProviders, isSupportProvidersLoading];
};

export default useGetSupportProviders;

const getAutocompleteOptions = (data) => {
  const autocompleteOptions = data?.map((supportProvider) => ({
    ...supportProvider,
    name: "support_provider_id",
  }));

  return autocompleteOptions;
};
