// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-item-btn {
  border: 2px solid #fff !important;
}

.list-item-btn:hover {
  background-color: rgba(84, 82, 224, 0.2);
}

.sidebar-menu-item-container {
  width: 100%;
  overflow: hidden;
  padding: 4px 12px;
}

.expand-menu {
  transform: rotate(0deg);
  transition: all 1s ease-in;
}

.expand-menu-active {
  transform: rotate(-180deg) !important;
  transition: all 1s ease-in;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.style.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,qCAAqC;EACrC,0BAA0B;AAC5B","sourcesContent":[".list-item-btn {\n  border: 2px solid #fff !important;\n}\n\n.list-item-btn:hover {\n  background-color: rgba(84, 82, 224, 0.2);\n}\n\n.sidebar-menu-item-container {\n  width: 100%;\n  overflow: hidden;\n  padding: 4px 12px;\n}\n\n.expand-menu {\n  transform: rotate(0deg);\n  transition: all 1s ease-in;\n}\n\n.expand-menu-active {\n  transform: rotate(-180deg) !important;\n  transition: all 1s ease-in;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
