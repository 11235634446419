import { CircularProgress, Typography } from "@mui/material";
import configs from "src/configs/configs";

const PageLoader = () => {
  return (
    <div className="login-container">
      <CircularProgress sx={{ color: configs.colors.primary }} size={"60px"} />
      <br />
      <Typography variant="h6">Wait A Moment...</Typography>
    </div>
  );
};

export default PageLoader;
