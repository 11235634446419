
const AppSpacer = ({ horizontal, vertical }) => {
  return (
    <div
      style={{
        width: horizontal ? horizontal : "",
        height: vertical ? vertical : "",
      }}
    ></div>
  );
};

export default AppSpacer;
