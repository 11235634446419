import { Avatar, Chip } from "@mui/material";
import configs from "src/configs/configs";

function HeaderChip({ label, icon }) {
  return (
    <Chip
      sx={{
        fontWeight: "bold",
        bgcolor: "transparent",
        // border: "1px solid rgba(0, 0, 0, 0.2)",
        // padding: 2,
        borderRadius: 2,
      }}
      size="medium"
      avatar={
        icon ? (
          <Avatar
            sx={{ padding: 2, bgcolor: configs.colors.primary }}
            variant="rounded"
          >
            {icon}
          </Avatar>
        ) : null
      }
      // icon={icon}
      label={label}
      // variant="outlined"
    />
  );
}
export default HeaderChip;
