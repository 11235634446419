import "./Sidebar.style.css";
import React from "react";
import { MenuList, Typography } from "@mui/material";
import SidebarMenuItem from "./components/SidebarMenuItem.component";
import { useSelector } from "react-redux";
import sidebarItems from "./sidebarItems.config";

const Sidebar = () => {
  const activePath = window.location.pathname;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userPermissions = currentUser?.permissions;

  const sidebarRoutes = sidebarItems.map((item) => {
    if (item.id === "manage") {
      let newMenu = item.menu.filter((menuItem) => {
        const permissionKey =
          menuItem.key === "embedded_analytics" ? "CREATE" : "READ";
        return userPermissions[menuItem.key]?.includes(permissionKey);
      });
      item.menu = newMenu;
    }

    if (item.id === "configurations") {
      let newMenu = item.menu.filter((menuItem) =>
        // userPermissions[menuItem.key]?.includes("READ")
        ["owner", "asset_eye_owner"].includes(currentUser?.role)
      );
      item.menu = newMenu;
    }

    return item;
  });

  return (
    <div
      className="sp-sidebar"
      style={{
        backgroundColor: "#fff",
        borderRight: ".5px solid rgba(0,0,0,0.1)",
      }}
    >
      <MenuList style={{ height: "84vh" }}>
        <Typography color={"GrayText"} variant="caption" padding={2}>
          Menu
        </Typography>

        {/* Implement Dropdown Menu Item */}
        <div className="sidebar-menu-item-container">
          {sidebarRoutes
            // ?.sort((a, b) => a.route.localeCompare(b.route))
            ?.map((route, index) => (
              <SidebarMenuItem
                key={index}
                StartIcon={route.icon}
                route={route.route}
                label={route.label}
                sup={route?.sup} //For superscript
                isActive={
                  activePath === route.route
                  // || activePath.includes(route.key)
                }
                enableDropdown={route.enableDropdown}
                subMenu={route.menu}
                activePath={activePath}
              />
            ))}
        </div>
        {/*  */}
      </MenuList>

      <div
        style={{
          position: "sticky",
          bottom: 0,
          width: "100%",
          padding: "12px",
          fontSize: "10px",
          textAlign: "center",
          background: "#fff",
        }}
      >
        {/* Designed and developed by <br />
        Pratik Sonar {`<pratik.sonar@rahisystems.com>`} */}
      </div>
    </div>
  );
};

export default Sidebar;
