// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mui-input-label {
  text-transform: uppercase !important;
  font-size: 12px !important;
  margin-bottom: 4px !important;
}

.mui-input-label-dark {
  text-transform: uppercase !important;
  font-size: 12px !important;
  margin-bottom: 4px !important;
  color: #000 !important;
  font-weight: 600 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/MUIInputLabel/MUIInputLabel.style.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,6BAA6B;EAC7B,sBAAsB;EACtB,2BAA2B;AAC7B","sourcesContent":[".mui-input-label {\n  text-transform: uppercase !important;\n  font-size: 12px !important;\n  margin-bottom: 4px !important;\n}\n\n.mui-input-label-dark {\n  text-transform: uppercase !important;\n  font-size: 12px !important;\n  margin-bottom: 4px !important;\n  color: #000 !important;\n  font-weight: 600 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
