import { get, post, put } from "../utils/axios/axios.utils";
const path = "/categories";

export const getCategory = async (params) => {
  try {
    const response = await get(`${path}`, {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getCategories = async (params) => {
  try {
    const response = await get(`${path}`, {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

/**
 *
 * @param {Object} data - Params for creating category
 * @returns
 */
export const createCategory = async (data) => {
  try {
    const response = await post(`${path}`, data);
    return response;
  } catch (error) {
    console.error("createCategory:", error);
    throw error;
  }
};
