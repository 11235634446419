import { Switch, Tooltip } from "@mui/material";
import configs from "src/configs/configs";

function MUISwitchButton({ is_active, onChange, name, disabled }) {
  return (
    <Tooltip title={is_active ? "Active" : "In-Active"}>
      <Switch
        name={name}
        onChange={onChange}
        checked={is_active}
        sx={{
          "&.MuiSwitch-root .MuiSwitch-track": {
            bgcolor: configs.colors.primary + "!important",
          },

          "&.MuiSwitch-root .Mui-checked": {
            color: configs.colors.primary,
          },
        }}
        disabled={disabled}
      />
    </Tooltip>
  );
}

export default MUISwitchButton;
