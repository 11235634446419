import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getHardwareModels } from "src/services/hardwareModel.services";

const useGetHardwareModels = (searchText, organizationId) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationID = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [hardwareModels, setHardwareModels] = useState([]);
  const [isHardwareModelsLoading, setIsHardwareModelsLoading] = useState(false);

  const searchKeyword = searchText ? searchText : "";

  // Function to fetch customer:
  const fetchHardwareModels = async () => {
    try {
      setIsHardwareModelsLoading(true);
      const resp = await getHardwareModels({
        searchKeyword,
        organizationId,
        // organizationId: organizationID,
      });

      // Converting response into autocomplete options:
      const autocompleteOptions = resp?.data?.map((hardware_model) => ({
        id: hardware_model.id,
        label: hardware_model.name,
        value: hardware_model.id,
        name: "hardware_model_id",
      }));

      setHardwareModels(autocompleteOptions);
      setIsHardwareModelsLoading(false);
    } catch (error) {
      console.log("fetchHardwareModels: ", error.message);
      setIsHardwareModelsLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      if (!searchText) fetchHardwareModels();
      if (searchText && searchText.length > 3) fetchHardwareModels();
    }
  }, [searchText, organizationId]);

  return [hardwareModels, setHardwareModels, isHardwareModelsLoading];
};
export default useGetHardwareModels;
