// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drag-drop-contrainer {
  width: 800px;
  margin: auto;
}

.drag-drop-content {
  width: 100%;
  border: 2px dashed rgba(84, 82, 224, 0.8);
  padding: 16px;
  border-radius: 8px;
  position: relative;
}

.drag-drop-absolute-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.8);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-thumbnail {
  width: 100%;
  border-radius: 8px;
  background-color: rgba(84, 82, 224, 0.2);
  aspect-ratio: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-thumbnail .icon-wrapper {
  height: 80px;
  width: 80px;
  background-color: rgba(84, 82, 224, 0.8);
}

.file-thumbnail .file-icon {
  font-size: 40px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/UploadFiles/UploadFiels.style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yCAAyC;EACzC,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,yCAAyC;EACzC,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,wCAAwC;EACxC,0BAA0B;EAC1B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".drag-drop-contrainer {\n  width: 800px;\n  margin: auto;\n}\n\n.drag-drop-content {\n  width: 100%;\n  border: 2px dashed rgba(84, 82, 224, 0.8);\n  padding: 16px;\n  border-radius: 8px;\n  position: relative;\n}\n\n.drag-drop-absolute-content {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgb(255, 255, 255, 0.8);\n  border-radius: 8px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.file-thumbnail {\n  width: 100%;\n  border-radius: 8px;\n  background-color: rgba(84, 82, 224, 0.2);\n  aspect-ratio: 1 !important;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.file-thumbnail .icon-wrapper {\n  height: 80px;\n  width: 80px;\n  background-color: rgba(84, 82, 224, 0.8);\n}\n\n.file-thumbnail .file-icon {\n  font-size: 40px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
