import UploadFiles from "src/components/UploadFiles/UploadFiles.component";
import "./CreateFiles.style.css";
import { uploadFiles } from "src/services/files.services";

/**
 *
 * @param {object} props - Component props
 * @param {string} props.organizationId - Organization's UUID
 * @param {string} props.creatorId - User's UUID
 * @param {string} props.value - Component's value
 * @param {string} props.setValue - Set's component value
 * @param {string} props.setOptions - Modal toggle state
 * @param {string} props.toggleOpen - Modal toggle state
 * @returns
 */
const CreateFiles = (props) => {
  const handleUpload = async (files) => {
    try {
      const response = await uploadFiles(files);
      props.setOptions((prevState) => [...prevState, ...response.data]);
      props.toggleOpen(false);
    } catch (error) {
      console.log("Error upload: ", error?.message);
    }
  };
  return (
    <div style={containerStyle}>
      <UploadFiles onUpload={handleUpload} />
    </div>
  );
};

export default CreateFiles;

const containerStyle = {
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
