import rahi_logo from "../assets/images/rahi_logo_001.png";
import wesco_logo from "../assets/images/wesco_logo_1.svg";
import default_profile_photo from "../assets/images/alphabet-P.png";
import card_background from "../assets/images/card-background-003.jpg";
import uploadEffect from "src/assets/images/upload-effect.gif";
//
const images = {
  app_logo: wesco_logo,
  app_logo_alt_text: "app_logo",
  rahi_logo,
  rahi_logo_alt_text: "rahi_log",
  default_profile_photo,
  default_profile_photo_alt_text: "default_profile",
  card_background,
  card_background_alt_text: "background_image",
  uploadEffect,
};

export default images;
