/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/
//
import "./DashboardCard.style.css";
import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import configs from "../../configs/configs";
import MUICard from "../MUICard/MUICard.component";

function DashboardCard({ icon, count, label }) {
  return (
    <MUICard className="card-container">
      {/* <div
        className="card-container-vector"
        style={{
          backgroundImage: `url(${configs.images.card_background})`,
        }}
      ></div>
      <div
        className="card-container-vector-1"
        style={{
          backgroundImage: `url(${configs.images.card_background})`,
        }}
      ></div> */}
      <div>
        <Grid spacing={2} alignItems={"center"} container>
          <Grid xs={6} item>
            <div
              style={{
                width: "100%",

                textAlign: "left",
              }}
            >
              {/* <IconButton> */}
              <Avatar
                variant="rounded"
                style={{ backgroundColor: configs.colors.primary }}
              >
                {icon ? icon : <configs.icons.Dashboard />}
              </Avatar>
              {/* </IconButton> */}
            </div>
          </Grid>
          <Grid xs={6} item>
            <div
              style={{
                width: "100%",
                textAlign: "right",
              }}
            >
              <IconButton style={{ color: configs.colors.primary }}>
                <configs.icons.BulletMenuIcon
                  sx={{
                    "&:hover": { color: configs.colors.primary },
                    color: configs.colors.secondary,
                  }}
                />
              </IconButton>
            </div>
          </Grid>

          <Grid xs={6} item>
            <div
              style={{
                width: "100%",
                textAlign: "left",
              }}
            >
              <Typography
                fontWeight={"bold"}
                color={configs.colors.secondary}
                variant="body1"
              >
                {label ? label : "Default Count"}
              </Typography>
            </div>
          </Grid>

          <Grid xs={6} item>
            <div
              style={{
                width: "100%",
                textAlign: "right",
              }}
            >
              <Typography
                fontWeight={"bold"}
                color={configs.colors.secondary}
                variant="h3"
              >
                {count ? count : 0}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </MUICard>
  );
}

export default DashboardCard;
