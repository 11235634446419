/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/
//
import images from "./images.config";
import colors from "./colors.config";
import icons from "./icons.config";
import fonts from "./fonts.config";
//
const configs = {
  images,
  colors,
  icons,
  fonts,
};

export default configs;
