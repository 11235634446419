import gql from "graphql-tag";

export const GET_DASHBOARD_ITEMS = gql`
  query GetDashboardItems($dashboardId: String!) {
    dashboardItems(dashboardId: $dashboardId) {
      id
      layout
      vizState
      name
      dashboard_id
    }
  }
`;

export const GET_DASHBOARD_ITEM = gql`
  query GetDashboardItem($id: String!) {
    dashboardItem(id: $id) {
      id
      layout
      vizState
      name
      dashboard_id
    }
  }
`;
