import {
  Avatar,
  Grid,
  Typography,
  IconButton,
  Alert,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
//import EditIcon from "@mui/icons-material/Edit";
import { useParams, Link } from "react-router-dom";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import MUICard from "src/components/MUICard/MUICard.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import configs from "src/configs/configs";
import useGetContractByID from "src/hooks/manageContracts/getContractByID.hook";
import React, { useState } from "react";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import CustomFields from "src/components/CustomField/CustomFields.component";
import formatFileSize from "src/helpers/formatFileSize.helper";
import { downloadFile } from "src/services/files.services";
import { useSelector } from "react-redux";

const ViewContractDetails = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userPermissions = currentUser?.permissions["contracts"] || [];

  const params = useParams();
  const contractId = params.id;
  const [contract] = useGetContractByID(contractId);

  const formatDate = (date) => {
    if (!date) return "N.A.";
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime())
      ? "N.A."
      : moment(parsedDate.toISOString()).format("DD/MMM/YYYY");
  };

  if (!contract) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <Grid spacing={1} container>
          <Grid sm={12} item>
            <MUICard>
              <Grid alignItems={"center"} container>
                <Grid sm={9} container item>
                  <Grid sm={12} item>
                    <ContractName name={contract?.name} />
                  </Grid>
                  <Grid sm={12} item>
                    <SubHeaders data={contract} />
                  </Grid>
                </Grid>
                <Grid sm={2} item>
                  <VendorProfile
                    name={contract?.vendor?.name}
                    type={contract?.contract_type}
                  />
                </Grid>
                <Grid sm={1} container item justifyContent="flex-end">
                  <IconButton
                    component={Link}
                    to={`/manage/contracts/edit/${contractId}`}
                    aria-label="edit"
                    disabled={!userPermissions.includes("UPDATE")}
                  >
                    <configs.icons.EditIcon
                      sx={{
                        color: userPermissions.includes("UPDATE")
                          ? configs.colors.primary
                          : "GrayText",
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </MUICard>
            {/* <AppSpacer vertical={"12px"} /> */}
          </Grid>
          <Grid sm={12} spacing={1} container item>
            {finacialCardsConfig.map((item) => (
              <Grid sm={3} item key={item.id}>
                <MUICard>
                  <Typography align="center" variant="h4" fontWeight={"bold"}>
                    <span style={{ color: "green" }}>
                      $
                      {contract[item.id] && contract[item.id] !== ""
                        ? parseFloat(contract[item.id]).toFixed(2)
                        : "0.00"}
                    </span>
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    fontWeight={"bold"}
                  >
                    {item.label}
                  </Typography>
                </MUICard>
                {/* <AppSpacer vertical={"12px"} /> */}
              </Grid>
            ))}
            <Grid sm={3} item>
              <MUICard>
                <Typography align="center" variant="h4" fontWeight={"bold"}>
                  {contract.payment_terms
                    ? contract.payment_terms.name
                    : "N.A."}
                </Typography>
                <Typography align="center" variant="body2" fontWeight={"bold"}>
                  Payment Terms
                </Typography>
              </MUICard>
              {/* <AppSpacer vertical={"12px"} /> */}
            </Grid>
          </Grid>
          <Grid sm={12} item>
            <MUICard title={<TitleComponent title="General Information" />}>
              <Grid alignItems={"center"} container>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">
                      <MUIInputLabel>Contract Name</MUIInputLabel>{" "}
                      {contract.name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">
                      <MUIInputLabel>Contract Number</MUIInputLabel>{" "}
                      {contract.number || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Contract Type</MUIInputLabel>{" "}
                      {contract.contract_type || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Customer</MUIInputLabel>{" "}
                      {contract.customer?.name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Integrator</MUIInputLabel>{" "}
                      {contract?.integrator?.name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Status</MUIInputLabel>{" "}
                      {contract.status?.name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>End of Term Clause</MUIInputLabel>{" "}
                      {contract.end_of_term_clause?.name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>End of Term Action</MUIInputLabel>{" "}
                      {contract.end_of_term_action || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Category</MUIInputLabel>{" "}
                      {contract.category?.name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Country</MUIInputLabel>{" "}
                      {contract.country || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Description</MUIInputLabel>{" "}
                      {contract.description || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Organization</MUIInputLabel>{" "}
                      {contract.organization?.name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Supplier</MUIInputLabel>{" "}
                      {contract.supplier?.name || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </MUICard>
            <AppSpacer vertical={"12px"} />
            <MUICard title={<TitleComponent title="Warranty Information" />}>
              <Grid alignItems={"center"} container>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Start Date</MUIInputLabel>{" "}
                      {formatDate(contract.contract_start_date || "-")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>End Date</MUIInputLabel>{" "}
                      {formatDate(contract.contract_end_date || "-")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Notice Period</MUIInputLabel>{" "}
                      {contract.notice_period || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Service Start Date</MUIInputLabel>{" "}
                      {formatDate(contract.service_start_date || "-")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Timeline Notes</MUIInputLabel>{" "}
                      {contract.timeline_notes || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </MUICard>
            <AppSpacer vertical={"12px"} />
            <MUICard title={<TitleComponent title="Business Units" />}>
              <Grid alignItems={"center"} container>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Business Unit</MUIInputLabel>{" "}
                      {contract.business_unit?.name || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </MUICard>
            <AppSpacer vertical={"12px"} />
            <MUICard title={<TitleComponent title="Financial Information" />}>
              <Grid alignItems={"center"} container>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Payment Terms</MUIInputLabel>{" "}
                      {contract.payment_terms?.name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Financing</MUIInputLabel>{" "}
                      {contract.financing || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Financial Notes</MUIInputLabel>{" "}
                      {contract.financial_notes || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Total Contract Spend Value</MUIInputLabel>{" "}
                      {contract.total_contract_spend_value || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Monthly Recurring Cost</MUIInputLabel>{" "}
                      {contract.monthly_recurring_cost || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <Typography variant="body1">
                      <MUIInputLabel>Non Recurring Cost</MUIInputLabel>{" "}
                      {contract.non_recurring_cost || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </MUICard>
            <AppSpacer vertical={"12px"} />
            <MUICard title={"Attachments"}>
              <Grid container>
                <Grid sm={12} item>
                  {contract?.attachments.length ? (
                    <AttachmentsList data={contract?.attachments} /> // <AttachmentsList />
                  ) : (
                    <Typography color={"GrayText"}>No Attachments</Typography>
                  )}
                </Grid>
              </Grid>
            </MUICard>
            <AppSpacer vertical={"12px"} />
            <CustomFields customForms={contract.custom_forms} />
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default ViewContractDetails;

const FileActions = ({ file }) => {
  const [isDownload, setIsDownload] = useState(false);

  const handleDownload = async () => {
    setIsDownload(true);
    try {
      await downloadFile(file.id, file.name);
      setIsDownload(false);
    } catch (error) {
      setIsDownload(false);
      console.log("Error download: ", error.message);
    }
  };

  return (
    <Grid alignItems={"center"} container>
      <Grid sm={6} item>
        <IconButton onClick={handleDownload} disabled={isDownload}>
          {isDownload ? (
            <CircularProgress
              sx={{ color: configs.colors.primary }}
              size={"18px"}
            />
          ) : (
            <configs.icons.CloudDownloadIcon
              sx={{ color: configs.colors.primary }}
            />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
};
const AttachmentIcon = () => <configs.icons.AttachFileRoundedIcon />;
const AttachmentsList = ({ data = [] }) => (
  <div>
    {data.map((file, index) => (
      <div key={file.id}>
        {index !== 0 ? <AppSpacer vertical={"12px"} /> : null}
        <Alert
          icon={<AttachmentIcon />}
          variant="outlined"
          action={<FileActions file={file} />}
        >
          {file?.name} {file?.size && `(${formatFileSize(file?.size)})`}
        </Alert>
      </div>
    ))}
  </div>
);

const ContractName = ({ name }) => (
  <Typography variant="h4" fontWeight={"bold"}>
    {name}
  </Typography>
);

const SubHeaders = ({ data }) => (
  <div>
    {data &&
      subHeadersConfig.map((header, i) => {
        if (header.type === "date" && data[header.id])
          data[header.id] = moment(
            new Date(data[header.id]).toISOString()
          ).format("DD/MMM/YYYY");
        return (
          <Typography
            key={header.id}
            sx={{
              fontSize: "12px",
            }}
            fontWeight={"bold"}
            color={"GrayText"}
            variant="caption"
          >
            {i !== 0 ? " | " : null}
            {header.label}:{" "}
            <span style={{ color: "rgba(0,0,0,0.8)" }}>
              {data && data[header.id]}
            </span>
          </Typography>
        );
      })}
  </div>
);

const VendorProfile = ({ name, type }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <Avatar
      sx={{
        backgroundColor: configs.colors["primary_800"],
      }}
    >
      {name && name[0]}
    </Avatar>
    <AppSpacer horizontal={"8px"} />
    <Typography variant="h6" fontWeight={"bold"} lineHeight={"1rem"}>
      {name}
      <br />
      <span style={{ fontSize: "12px", marginTop: "-120px" }}>
        ({type === "sell_side" ? "Customer" : "Supplier"})
      </span>
    </Typography>
  </div>
);

const subHeadersConfig = [
  {
    id: "number",
    label: "Number",
    type: "text",
  },
  {
    id: "contractType",
    label: "Type",
    type: "text",
  },
  {
    id: "createdAt",
    label: "Added On",
    type: "date",
  },
  {
    id: "updatedAt",
    label: "Updated On",
    type: "date",
  },
];

const finacialCardsConfig = [
  {
    id: "total_contract_spend_value",
    label: "Total Contract Value",
  },
  {
    id: "monthly_recurring_cost",
    label: "Monthly Recurring Cost (MRC)",
  },
  {
    id: "non_recurring_cost",
    label: "Non Recurring Cost (NRC)",
  },
];

function TitleComponent({ title }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {title}
    </div>
  );
}
