// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/
.card-container {
  position: relative;
  height: 150px;
  width: 100%;
  margin-left: 20px;
  /* background-color: rgba(239, 130, 33, 0.1) !important; */
}

.card-container-vector {
  position: absolute;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  top: -40px;
  right: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card-container-vector-1 {
  position: absolute;
  width: 120px;
  aspect-ratio: 1;
  border-radius: 50%;
  top: -56px;
  right: -40px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card-container:first-child {
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/DashboardCard/DashboardCard.style.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,0DAA0D;AAC5D;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,2BAA2B;EAC3B,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,2BAA2B;EAC3B,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["/* \n    Copyright 2023-24 Rahi Systems Inc\n    Author: Pratik Sonar <pratik.sonar@rahisystems.com>\n*/\n.card-container {\n  position: relative;\n  height: 150px;\n  width: 100%;\n  margin-left: 20px;\n  /* background-color: rgba(239, 130, 33, 0.1) !important; */\n}\n\n.card-container-vector {\n  position: absolute;\n  width: 80px;\n  aspect-ratio: 1;\n  border-radius: 50%;\n  top: -40px;\n  right: 20px;\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n\n.card-container-vector-1 {\n  position: absolute;\n  width: 120px;\n  aspect-ratio: 1;\n  border-radius: 50%;\n  top: -56px;\n  right: -40px;\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n\n.card-container:first-child {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
