import { get, put, remove, post } from "src/utils/axios/axios.utils";

export const createInegrator = async (data) => {
  try {
    const response = await post("/integrators", data);
    return response;
  } catch (error) {
    console.log("Error while creating integrator: ", error?.response?.data);
    throw error;
  }
};

export const getInegrators = async (params) => {
  try {
    const response = await get("/integrators", { params });
    return response;
  } catch (error) {
    console.log("Error while fetching integrators: ", error?.response?.data);
    throw error;
  }
};

export const getInegratorsMetaData = async (params) => {
  try {
    const response = await get("/integrators/data/meta-data", { params });
    return response;
  } catch (error) {
    console.log(
      "Error while fetching integrators meta-data: ",
      error?.response?.data
    );
    throw error;
  }
};

export const getInegratorById = async (id) => {
  try {
    const response = await get(`/integrators/${id}`);
    return response;
  } catch (error) {
    console.log(
      "Error while fetching integrator by id: ",
      error?.response?.data
    );
    throw error;
  }
};

export const updateInegratorById = async (id, data) => {
  try {
    const response = await put(`/integrators/${id}`, data);
    return response;
  } catch (error) {
    console.log(
      "Error while fetching integrator by id: ",
      error?.response?.data
    );
    throw error;
  }
};

export const deleteIntegratorsById = async (id) => {
  try {
    const response = await remove(`/integrators/${id}`);
    return response;
  } catch (error) {
    console.log(
      "Error while deleting integrator by id: ",
      error?.response?.data
    );
    throw error;
  }
};
