import { useEffect, useState } from "react";
import { getCustomFormById } from "src/services/customForms.services";

/**
 *
 * @param {string} id - Unique Identifier (UUID4)
 * @returns
 */
const useGetCustomFormByID = (id) => {
  const [form, setForm] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const response = await getCustomFormById(id);
          setForm(response?.data);
        } catch (error) {
          console.log("Get custom form: ", error.message);
        }
      })();
    }
  }, [id]); // fetching custom form by its ID:

  return [form, setForm];
};

export default useGetCustomFormByID;
