import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CardTitle from "src/components/CardTitle/CardTitle.component";
import MUICard from "src/components/MUICard/MUICard.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import { getCustomFormById } from "src/services/customForms.services";
import configs from "src/configs/configs";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";

const ViewCustomFormDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const formId = params.id;

  const [customForm, setCustomForm] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const getCustomForm = async () => {
    try {
      const response = await getCustomFormById(formId);
      const { data } = response;

      setCustomForm(data);
      setIsPageLoading(false);
    } catch (error) {
      console.log("getCustomForm: ", error.message);
    }
  };

  useEffect(() => {
    getCustomForm();
  }, []);

  if (isPageLoading) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <MUICard
          title={<TitleComponent title={customForm?.name} />}
          style={{ width: "800px", margin: "auto" }}
        >
          <Grid spacing={2} container>
            {customForm?.fields?.map((field) => (
              <Grid sm={6} item>
                <MUIInputLabel>{field.label}</MUIInputLabel>
                <MUITextField />
              </Grid>
            ))}
          </Grid>
        </MUICard>
      </div>
    );
  }
};

export default ViewCustomFormDetails;

const TitleComponent = ({ title }) => (
  <CardTitle
    title={title}
    icon={
      <configs.icons.PhotoFilterIcon sx={{ color: configs.colors.primary }} />
    }
  />
);
