import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSuppliers } from "src/services/suppliers.services";

const useGetSuppliers = (searchText, organizationId) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationId = currentUser?.organization_id || organizationId;
  // const userPermissions = currentUser?.permissions || [];

  const [suppliers, setSuppliers] = useState([]);
  const [isSuppliersLoading, setIsSuppliersLoading] = useState(false);

  const searchKeyword = searchText ? searchText : "";

  // Function to fetch customer:
  const fetchSuppliers = async () => {
    try {
      setIsSuppliersLoading(true);
      const resp = await getSuppliers({ searchKeyword, organizationId });

      // Converting response into autocomplete options:
      const autocompleteOptions = resp?.data?.map((supplier) => ({
        id: supplier.id,
        label: supplier.name,
        value: supplier.name,
        name: "supplier_id",
      }));

      setSuppliers(autocompleteOptions);
      setIsSuppliersLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsSuppliersLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      if (!searchText) fetchSuppliers();
      if (searchText && searchText.length > 3) fetchSuppliers();
    }
  }, [searchText, organizationId]);

  return [suppliers, isSuppliersLoading];
};

export default useGetSuppliers;
