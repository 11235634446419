import { Grid } from "@mui/material";
import ViewRoles from "./ManageRoles/ViewRoles/ViewRoles.page";

const Configurations = () => {
  return (
    <div>
      <Grid spacing={2} container>
        <Grid sm={12} item>
          <ViewRoles />
        </Grid>
      </Grid>
    </div>
  );
};

export default Configurations;
