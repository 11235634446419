import "./CreateHardwareModel.style.css";
import MUICard from "src/components/MUICard/MUICard.component";
import { Grid } from "@mui/material";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import { useState } from "react";
import { createHardwareModel } from "src/services/hardwareModel.services";

/**
 *
 * @param {object} props - Component properties
 * @param {string} props.organizationId
 * @param {string} props.creatorId
 * @param {object} props.value
 * @param {void} props.setSelectedOption
 * @param {void} props.toggleOpen
 * @param {void} props.setOptions
 * @param {void} props.setValue
 * @returns
 */
const CreateHardwareModel = (props) => {
  const [isCreating, setIsCreating] = useState(false);
  const [handleSubmit] = useCreateHardwareModel(props, setIsCreating);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    props.setValue((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="hardware-model-container">
      <MUICard title={"Create Hardware Model"} style={{ width: "500px" }}>
        <FormComponent
          handleSubmit={handleSubmit}
          values={props.value}
          onChange={handleInputChange}
          isCreating={isCreating}
        />
      </MUICard>
    </div>
  );
};

export default CreateHardwareModel;

const FormComponent = ({ values, onChange, handleSubmit, isCreating }) => (
  <Grid spacing={2} container>
    {fields.map((field) => (
      <Grid sm={12} key={field?.id} item>
        <MUIInputLabel>{field?.label}</MUIInputLabel>
        <MUITextField
          Name={field.name}
          autoFocus
          Value={values && values[field?.id]}
          OnChange={onChange}
          Disabled={isCreating}
        />
      </Grid>
    ))}

    <Grid sm={8} item></Grid>
    <Grid sm={4} item>
      <MUIButton
        onClick={handleSubmit}
        isLoading={isCreating}
        isLoadingButton
        primary
      >
        Create
      </MUIButton>
    </Grid>
  </Grid>
);

const fields = [
  {
    id: "label",
    name: "label",
    label: "Name",
    type: "text",
  },
  {
    id: "description",
    name: "description",
    label: "Description",
    type: "text",
  },
];

const useCreateHardwareModel = (data, setIsCreating) => {
  const {
    organizationId,
    creatorId,
    value,
    setOptions,
    setSelectedOption,
    toggleOpen,
    setValue,
  } = data;

  const handleSubmit = async () => {
    const requestBody = {
      organization_id: organizationId ? organizationId : "",
      name: value?.label,
      email: value?.email,
      contact_number: value?.contact_number,
      created_by: creatorId ? creatorId : "",
    };

    try {
      setIsCreating(true);
      const response = await createHardwareModel(requestBody);
      // const response = { data: { id: "1234" } };

      setOptions((prev) => [
        ...prev,
        {
          id: response?.data?.id,
          label: value?.label,
          value: response?.data?.id,
          name: "hardware_model_id",
        },
      ]);

      setSelectedOption({
        id: response?.data?.id,
        label: value?.label,
        value: response?.data?.id,
        name: "hardware_model_id",
      });

      toggleOpen((e) => !e);

      setValue(null);

      setIsCreating(false);
    } catch (error) {
      setIsCreating(false);
      console.log("CreateBusinessUnit | handleSubmit: ", requestBody);
      console.log("handleSubmit", error.message);
    }
  };
  return [handleSubmit];
};
