import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import configs from "src/configs/configs";

/**
 *
 * @param {Object} props
 * @param {Boolean} props.loading
 * @param {Array} props.columns
 * @param {Array} props.rows
 * @param {number} props.rowCount
 * @param {void} props.paginationModel
 * @param {void} props.onRowClick
 * @param {void} props.onPaginationModelChange
 * @param {Boolean} props.disableColumnFilter
 * @param {Boolean} props.disableColumnMenu
 * @returns
 */
function MUIGridTable(props) {
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <DataGrid
        disableColumnMenu={props.disableColumnMenu}
        disableColumnFilter={props.disableColumnFilter}
        sx={{
          border: 1,
          borderColor: "rgba(0, 0, 0, 0.12)",
          backgroundColor: "#fff",
          borderRadius: 2,
          minHeight: "420px",
          maxHeight: "840px",
          "& .MuiCircularProgress-root": {
            color: configs.colors.primary + "!important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
        pagination
        paginationMode="server"
        pageSizeOptions={[10, 15, 25, 100]}
        // components={{ Toolbar: CustomToolbar }}
        {...props}
        disableRowSelectionOnClick
      />
    </div>
  );
}

export default MUIGridTable;
