
function CardTitle({ title, icon }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {icon} &nbsp; {title}
    </div>
  );
}

export default CardTitle;
