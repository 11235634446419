//

import { Navigate } from "react-router-dom";

//
const commonRoutes = [
  {
    id: "default-1",
    path: "/*",
    element: <Navigate to="/" replace />,
  },
];

export default commonRoutes;
