/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/

import { Grid, IconButton, Tooltip } from "@mui/material";
import configs from "../../../configs/configs";

const ActionsGroup = ({ actions }) => {
  // Config for icons
  const sxConfig = {
    "&:hover": { color: configs.colors.primary },
    color: configs.colors.primary_900,
  };

  return (
    <div>
      <Grid container>
        <Grid lg={4} item></Grid>
        <Grid lg={2} item>
          <Tooltip title="Quick Adds">
            <IconButton
              style={{ color: configs.colors.primary }}
              className="icon-button"
              onClick={actions?.quickAdds}
            >
              {/* <configs.icons.AddCircleIcon aria-label="add" sx={sxConfig} on /> */}
              <configs.icons.AddCircleIcon sx={sxConfig} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid lg={2} item>
          <Tooltip title="Disabled">
            <IconButton
              style={{ color: configs.colors.primary }}
              className="icon-button"
            >
              <configs.icons.MoveToInboxIcon sx={sxConfig} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid lg={2} item>
          <Tooltip title="Notifications">
            <IconButton
              style={{ color: configs.colors.primary }}
              className="icon-button"
            >
              <configs.icons.NotificationsIcon sx={sxConfig} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid lg={2} item>
          <Tooltip title="Settings">
            <IconButton
              style={{ color: configs.colors.primary }}
              className="icon-button"
            >
              <configs.icons.SettingsIcon sx={sxConfig} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};

export default ActionsGroup;
