const getRoleName = (roleId) => {
  if (roleId) {
    const label = removeInitials(roleId);
    const strings = label.split("_").map((n) => firstLetterCaps(n));

    const defaultString = strings.join(" ");

    if (defaultString === "Owner") return "Super Admin";
    else return defaultString;
  }
};
export default getRoleName;

const removeInitials = (string) => {
  return string.split("asset_eye_")[1];
};

function firstLetterCaps(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
