const registerAdminFormFields = [
  {
    id: "firstname",
    label: "Firstname",
    type: "text",
    isRequired: true,
    order: 1,
  },
  {
    id: "lastname",
    label: "Lastname",
    type: "text",
    isRequired: true,
    order: 2,
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    isRequired: true,
    order: 3,
  },
  {
    id: "contact_number",
    label: "Contact Number",
    type: "text",
    order: 4,
  },
  {
    id: "designation",
    label: "Designation",
    type: "text",
    order: 6,
  },
  {
    id: "department",
    label: "Department",
    type: "text",
    order: 7,
  },
];

export default registerAdminFormFields;
