import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSuppliers } from "src/services/suppliers.services";

const useGetManufacturers = (searchText, organizationId, options) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationID = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [isInitializing, setIsInitializing] = useState(true);
  const [manufacturers, setManufacturers] = useState([]);
  const [isManufacturersLoading, setIsManufacturersLoading] = useState(false);

  const searchKeyword = searchText ? searchText : "";

  // Function to fetch customer:
  const fetchManufacturers = async () => {
    try {
      setIsManufacturersLoading(true);
      const resp = await getSuppliers({
        searchKeyword,
        organizationId,
      });

      // Converting response into autocomplete options:
      const autocompleteOptions = resp?.data?.map((manufacturer) => ({
        id: manufacturer.id,
        label: manufacturer.name,
        value: manufacturer.id,
        name: "manufacturer_id",
      }));

      setManufacturers(autocompleteOptions);
      setIsManufacturersLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsManufacturersLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      if (!searchText && (!isInitializing || !options?.data))
        fetchManufacturers();
      if (searchText && searchText.length > 3) fetchManufacturers();
    }
  }, [searchText, organizationId]);

  useEffect(() => {
    if (options?.data && options?.data[0] && isInitializing) {
      const suppliers = options?.data;
      const autocompleteOptions = getAutocompleteOptions(suppliers);
      setManufacturers(autocompleteOptions);
      setIsInitializing(false);
    }
  }, [options?.data]);

  return [manufacturers, isManufacturersLoading];
};

export default useGetManufacturers;

const getAutocompleteOptions = (data) => {
  const autocompleteOptions = data?.map((manufacturer) => ({
    ...manufacturer,
    name: "manufacturer_id",
  }));

  return autocompleteOptions;
};
