import { writeFile, utils } from "xlsx";

/* Implement functionality to convert data[] to excel */
const convertToXLSX = (data, filename) => {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFile(workbook, filename + ".xlsx");
};

/* Implement functionality to convert data[] to csv */
function convertToCSV(objArray) {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  const headers = Object.keys(array[0]);
  const csvRows = [];

  csvRows.push(headers.join(","));

  for (const obj of array) {
    const values = headers.map((header) => {
      const escaped = ("" + obj[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(","));
  }

  return csvRows.join("\n");
}

function downloadCSV(csv, filename) {
  const csvFile = new Blob([csv], { type: "text/csv" });
  const downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

/**
 *
 * @param {Array<object>} data - Data to export
 * @param {string} filename - Filename to export
 * @returns
 */
export const exportToXLSX = (data, filename) => {
  convertToXLSX(data, filename);
};

/**
 *
 * @param {Array<object>} data - Data to export
 * @param {string} filename - Filename to export
 * @returns
 */
export const exportToCSV = (data, filename) => {
  const defaultName = filename + ".csv" || "default.csv";
  const csv = convertToCSV(data);
  downloadCSV(csv, defaultName);
};
