import { get, post, put, remove } from "../utils/axios/axios.utils";
const path = "/organizations";
export const getOrganizations = async (params) => {
  try {
    const response = await get(`${path}`, {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// Service for fetching an Organization by ID:
export const getOrganizationByID = async (orgId) => {
  try {
    if (!orgId) throw new Error("Organization ID is missing.");

    const response = await get(`/organizations/${orgId}`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const postOrganization = async (data) => {
  try {
    const response = await post("/organizations", data);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchOrganizationData = async () => {
  try {
    const response = await get("/organizations/data/meta-data");
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// Organization Details
export const getOrganizationDetails = async (organizationId) => {
  try {
    const response = await get(path + `/${organizationId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Updating organization details
export const updateOrganizationDetails = async (organizationId, data) => {
  try {
    const result = await put(path + `/${organizationId}`, data);
    return result;
  } catch (error) {
    throw error;
  }
};

// export default { getOrganizations, postOrganizations }
export const fetchOrganizationDataWithPagination = async (
  limit,
  pageNumber
) => {
  try {
    const response = await get(
      `${path}?limit=${limit}&pageNumber=${pageNumber}`
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const getOrgnizationsMetaData = async () => {
  try {
    const response = await get(path + "/data/meta-data");

    return response.data;
  } catch (error) {
    console.log("getOrgnizationsMetaData: ", error.message);
    throw error;
  }
};

export const deleteOrganizationByID = async (id) => {
  try {
    await remove(`${path}/${id}`);

    return {
      status: 200,
      message: "success",
      data: "A record having ID ${id} is deleted",
    };
  } catch (error) {
    throw error;
  }
};

// Deleting an Orgnization
export const deleteOrganization = async (orgId) => {
  try {
    const result = await remove(`${path}/${orgId}`);
    return result;
  } catch (error) {
    console.error("deleteOrganization:", error.message);
    throw error;
  }
};
