import "./CustomFormSection.style.css";
import { Grid, Modal } from "@mui/material";
import MUIButton from "../MUIButton/MUIButton.component";
import MUICard from "../MUICard/MUICard.component";
// import { useState } from "react";
import MUITextField from "../MUITextField/MUITextField.component";

const CustomFormSection = ({
  open,
  toggleOpen,
  options,
  value,
  onChange,
  name,
  onAdd,
  loading,
  disabled,
}) => {
  //   const [open, toggleOpen] = useState(false);
  return (
    <div>
      <MUICard className="custom-form-card">
        <Grid container>
          <Grid sm={4} item></Grid>
          <Grid sm={4} item>
            <MUIButton
              onClick={toggleOpen}
              disabled={disabled || !options?.length ? true : false}
              primary
            >
              {!options?.length ? "No more forms available" : "Add Custom Form"}
            </MUIButton>
          </Grid>
          <Grid sm={4} item></Grid>
        </Grid>
      </MUICard>
      <Modal open={open} onClose={toggleOpen}>
        <div className="custom-form-modal-container">
          <MUICard title={"Add Custom Form"} style={{ width: "500px" }}>
            <Grid alignItems={"center"} spacing={2} container>
              <Grid sm={9} item>
                <MUITextField
                  Name={name}
                  Value={value}
                  OnChange={onChange}
                  SelectOptions={options}
                  Disabled={loading || disabled}
                  Select
                />
              </Grid>
              <Grid sm={3} item>
                <MUIButton
                  onClick={onAdd}
                  isLoadingButton
                  isLoading={loading}
                  disabled={disabled}
                  primary
                >
                  Add
                </MUIButton>
              </Grid>
            </Grid>
          </MUICard>
        </div>
      </Modal>
    </div>
  );
};

export default CustomFormSection;
