import { useEffect, useRef, useState } from "react";
import authInstance from "../utils/keycloak/keycloak.utils";
import { getCurrentUser } from "../services/users.services";
import { useDispatch } from "react-redux";
import {
  addAuthUser,
  addAuthUserError,
} from "../utils/react-redux/slices/auth.slice";
import { decryptData } from "../utils/crypto-js/crypto";

const useAuthentication = () => {
  const isInitialize = useRef(false);
  const dispatch = useDispatch(); // Initialize dispatch
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [userPermissions, setPermissions] = useState([]);
  const [error, setError] = useState(null);

  // function to store encrypted user details:
  const fetchCurrentUser = async () => {
    try {
      const response = await getCurrentUser(authInstance.token);
      const { data } = response; // incoming response from API
      const currentUser = JSON.parse(decryptData(data));

      dispatch(addAuthUser(currentUser));
      setPermissions(currentUser?.permissions);
      setAccessToken(authInstance.token);
      setIsLoggedIn(true);
      startRefreshTokenInterval();
    } catch (error) {
      setError(error);
      dispatch(addAuthUserError(error));
      let message = error?.message;
      console.error("User login: ", message);
    }
  };

  const initializeKeycloak = async () => {
    try {
      const isAuthenticate = await authInstance.init({
        onLoad: "login-required",
        checkLoginIframe: false,
      }); // Initializing keycloak instance

      if (isAuthenticate) fetchCurrentUser();
      else console.log("User is unauthenticated.");
    } catch (error) {
      console.log("Keycloak init error: ", error.message || error);
    }
  };

  useEffect(() => {
    if (isInitialize.current) return; // Avoiding second 'useEffect' initialization
    isInitialize.current = true;

    initializeKeycloak();
  }, []);

  return [isLoggedIn, error];
};

export default useAuthentication;

const startRefreshTokenInterval = () => {
  setInterval(async () => {
    if (authInstance.isTokenExpired(30)) {
      console.log("Token is expired!");
      console.log("Refreshing token ...");
      await authInstance.updateToken(30);
      console.log("Token is refreshed.");
    } else console.log("Token is still valid.");
  }, 1000 * 60);
};
