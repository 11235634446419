// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-form-card {
  margin: 12px 0px !important;
  border: 2px dashed rgba(0, 0, 0, 0.2) !important;
  background: transparent !important;
}

.custom-form-modal-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomFormSection/CustomFormSection.style.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,gDAAgD;EAChD,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".custom-form-card {\n  margin: 12px 0px !important;\n  border: 2px dashed rgba(0, 0, 0, 0.2) !important;\n  background: transparent !important;\n}\n\n.custom-form-modal-container {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
