import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// react-redux imports:
import { Provider } from "react-redux";
import store from "./utils/react-redux/store.utils";

// React Router DOM Imports:
import { BrowserRouter } from "react-router-dom";

// Material UI Fonts:
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { ApolloProvider } from '@apollo/react-hooks';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import client from './graphql/client';


const API_URL = 'http://localhost:4000';
const CUBEJS_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjE3ODI1ODYsImV4cCI6MTcyNDM3NDU4Nn0.qm93ZikbmVIFBtmjY4n6Cj7xfLe1gCmlOT8jfGSxyUE';
const cubeApi = cubejs(CUBEJS_TOKEN, { apiUrl: `${API_URL}/cubejs-api/v1` });


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CubeProvider cubeApi={cubeApi}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </CubeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
