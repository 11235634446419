// New imports:
import DefaultLayout from "src/components/layouts/default.layout";
import commonRoutes from "./common.routes";

import HomePage from "src/pages/Home/Home.page";
import OrgnizationDashboardPage from "src/pages/ManageOrganizations/OrganizationDashboard/OrgnizationDashboardPage";
import RegisterOrganization from "src/pages/ManageOrganizations/RegisterOrganization/RegisterOrganization.page";
import ViewOrganizationDetails from "src/pages/ManageOrganizations/ViewOrganizationDetails/ViewOrganizationDetails.page";

import UserDashboardPage from "src/pages/ManageUsers/UserDashboard/UserDashboardPage";
import RegisterUser from "src/pages/ManageUsers/RegisterUser/RegisterUser.page";
// import RegisterCustomer from "src/pages/ManageCustomers/RegisterCustomer/RegisterCustomer.page";
// import UpadteOrgnizationPage from "src/pages/ManageOrganizations/UpdateOrgnization/UpdateOrgnizationPage-depricated";
import CustomerDashboard from "src/pages/ManageCustomers/CustomerDashboard/CustomerDashboard.page";
//import UpdateCustomerPage from "src/pages/ManageCustomers/UpdateCustomer/UpdateCustomerPage";
import RegisterContracts from "src/pages/ManageContracts/RegisterContract/RegisterContractPage";
import ContractsDashboard from "src/pages/ManageContracts/ContractDashboard/ContractDashboardPage";
import RegisterAssets from "src/pages/ManageAssets/RegisterAsset/RegisterAssetPage";
import ViewUserDetails from "src/pages/ManageUsers/ViewUserDetails/ViewUserDetails.page";
import SuppliersDashboard from "src/pages/ManageSuppliers/SuppliersDashboard/SuppliersDashboard.page";
import CreateSupplier from "src/pages/ManageSuppliers/CreateSupplier/CreateSupplier.page";
import ViewSupplierDetails from "src/pages/ManageSuppliers/ViewSupplierDetails/ViewSupplierDetails.page";
import CustomersDashboard from "src/pages/ManageCustomers/CustomerDashboard/CustomerDashboard.page";
import ViewCustomerDetails from "src/pages/ManageCustomers/ViewCustomerDetails/ViewCustomerDetailsPage";
import CreateCustomer from "src/pages/ManageCustomers/CreateCustomer/CreateCustomerPage";
import PlayGround from "src/pages/PlayGrounds/PlayGround.page";
import CustomFormsDashboard from "src/pages/ManageCustomForms/CustomFormsDashboard/CustomFormsDashboard.page";
import ViewCustomFormDetails from "src/pages/ManageCustomForms/ViewCustomFormDetails/ViewCustomFormDetails.page";
import AssetsDashboard from "src/pages/ManageAssets/AssetDashboard/AssetDashboardPage";
import ViewContractDetails from "src/pages/ManageContracts/ViewContractDetails/ViewContractDetails.page";
import ViewAssetDetails from "src/pages/ManageAssets/ViewAssetDetails/ViewAssetDetails.page";
import CreateCustomForm from "src/pages/ManageCustomForms/CreateCustomForm/CreateCustomForm.page";
import DashboardPage from "src/pages/EmbeddedAnalytics/DashboardPage";
import ReportBuilderPage from "src/pages/EmbeddedAnalytics/ReportBuilderPage";
import CreateContract from "src/pages/ManageContracts/CreateContract/CreateContract.page";
import Configurations from "src/pages/Configurations/Configurations.page";
import ViewRoleDetails from "src/pages/Configurations/ManageRoles/ViewRoleDetails/ViewRoleDetails.page";
import ViewRoles from "src/pages/Configurations/ManageRoles/ViewRoles/ViewRoles.page";
import IntegratorsDashboard from "src/pages/ManageIntegrators/IntegratorsDashboard/IntegratorsDashboard.page";
import CreateIntegrator from "src/pages/ManageIntegrators/CreateIntegrator/CreateIntegrator.page";

const secureRoutes = [
  {
    id: "home-page",
    path: "/",
    element: (
      <DefaultLayout>
        {/* <HomePage /> */}
        <DashboardPage />
      </DefaultLayout>
    ),
  },
  {
    id: "table-2",
    path: "/manage/organizations",
    element: (
      <DefaultLayout>
        <OrgnizationDashboardPage />
      </DefaultLayout>
    ),
  },

  {
    id: "form-2",
    path: "/manage/organizations/register",
    element: (
      <DefaultLayout>
        <RegisterOrganization />
      </DefaultLayout>
    ),
  },
  {
    id: "organization-details",
    path: "/manage/organizations/view/:id", // Unique ID for the Org Details Page
    element: (
      <DefaultLayout>
        <ViewOrganizationDetails />
      </DefaultLayout>
    ),
  },
  {
    id: "organization-update",
    path: "/manage/organizations/edit/:id", // Unique ID for the Org Details Page
    element: (
      <DefaultLayout>
        <RegisterOrganization />
      </DefaultLayout>
    ),
  },
  // Manage Users Routes:
  {
    id: "register-user",
    path: "/manage/users/register",
    element: (
      <DefaultLayout>
        <RegisterUser />
      </DefaultLayout>
    ),
  },
  {
    id: "user-dashboard",
    path: "/manage/users",
    element: (
      <DefaultLayout>
        <UserDashboardPage />
      </DefaultLayout>
    ),
  },
  // Manage Users Routes:

  // User Details Route
  {
    id: "user-details", // Unique ID for the User Details Page
    path: "/manage/users/view/:id",
    element: (
      <DefaultLayout>
        <ViewUserDetails />
      </DefaultLayout>
    ),
  },
  {
    id: "user-details", // Unique ID for the User Details Page
    path: "/manage/users/edit/:id",
    element: (
      <DefaultLayout>
        <RegisterUser />
      </DefaultLayout>
    ),
  },
  // {
  //   id: "customers_dashboard",
  //   path: "/manage/customers",
  //   element: (
  //     <DefaultLayout>
  //       <CustomerDashboard />
  //     </DefaultLayout>
  //   ),
  // },
  // Manage Customers Routes:
  {
    id: "customer_dashboard",
    path: "/manage/customers",
    element: (
      <DefaultLayout>
        <CustomersDashboard />
      </DefaultLayout>
    ),
  },
  {
    id: "customer_create",
    path: "/manage/customers/create",
    element: (
      <DefaultLayout>
        <CreateCustomer />
      </DefaultLayout>
    ),
  },
  {
    id: "customer_view_details",
    path: "/manage/customers/view/:id",
    element: (
      <DefaultLayout>
        <ViewCustomerDetails />
      </DefaultLayout>
    ),
  },
  {
    id: "customer_edit",
    path: "/manage/customers/edit/:id",
    element: (
      <DefaultLayout>
        <CreateCustomer />
      </DefaultLayout>
    ),
  },
  // Endl;

  // Manage Suppliers Routes:
  {
    id: "supplier_dashboard",
    path: "/manage/suppliers",
    element: (
      <DefaultLayout>
        <SuppliersDashboard />
      </DefaultLayout>
    ),
  },
  {
    id: "supplier_create",
    path: "/manage/suppliers/create",
    element: (
      <DefaultLayout>
        <CreateSupplier />
      </DefaultLayout>
    ),
  },
  {
    id: "supplier_view_details",
    path: "/manage/suppliers/view/:id",
    element: (
      <DefaultLayout>
        <ViewSupplierDetails />
      </DefaultLayout>
    ),
  },
  {
    id: "supplier_edit",
    path: "/manage/suppliers/edit/:id",
    element: (
      <DefaultLayout>
        <CreateSupplier />
      </DefaultLayout>
    ),
  },
  // Endl;
  {
    id: "interators",
    path: "/manage/integrators",
    element: (
      <DefaultLayout>
        <IntegratorsDashboard />
      </DefaultLayout>
    ),
    // Dashboard
  },

  {
    id: "interators_create",
    path: "/manage/integrators/create",
    element: (
      <DefaultLayout>
        <CreateIntegrator />
      </DefaultLayout>
    ),
  },

  {
    id: "interators_edit",
    path: "/manage/integrators/edit/:id",
    element: (
      <DefaultLayout>
        <CreateIntegrator />
      </DefaultLayout>
    ),
  },

  // Manage Contracts:
  {
    id: "user-dashboard",
    path: "/manage/contracts",
    element: (
      <DefaultLayout>
        <ContractsDashboard />
      </DefaultLayout>
    ),
  },
  // Contracts management
  {
    id: "register-contracts",
    path: "/manage/contracts/register",
    element: (
      <DefaultLayout>
        <CreateContract />
      </DefaultLayout>
    ),
  },
  {
    id: "contract-details", // Unique ID for the Customer Details Page
    path: "/manage/contracts/view/:id",
    element: (
      <DefaultLayout>
        <ViewContractDetails />
      </DefaultLayout>
    ),
  },
  {
    id: "contract-details", // Unique ID for the Customer Details Page
    path: "/manage/contracts/edit/:id",
    element: (
      <DefaultLayout>
        <CreateContract />
      </DefaultLayout>
    ),
  },
  // aAssets management
  {
    id: "assets-dashboard",
    path: "/manage/assets",
    element: (
      <DefaultLayout>
        <AssetsDashboard />
      </DefaultLayout>
    ),
  },
  {
    id: "assets-register",
    path: "/manage/assets/register",
    element: (
      <DefaultLayout>
        <RegisterAssets />
      </DefaultLayout>
    ),
  },
  {
    id: "asset-details", // Unique ID for the Customer Details Page
    path: "/manage/assets/view/:id",
    element: (
      <DefaultLayout>
        <ViewAssetDetails />
      </DefaultLayout>
    ),
  },
  {
    id: "assets-edit",
    path: "/manage/assets/edit/:id",
    element: (
      <DefaultLayout>
        <RegisterAssets />
      </DefaultLayout>
    ),
  },
  {
    id: "custom_forms",
    path: "/manage/custom_forms",
    element: (
      <DefaultLayout>
        <CustomFormsDashboard />
      </DefaultLayout>
    ),
  },
  {
    id: "create_custom_form",
    path: "/manage/custom_forms/create",
    element: (
      <DefaultLayout>
        <CreateCustomForm />
      </DefaultLayout>
    ),
  },
  {
    id: "update_custom_form",
    path: "/manage/custom_forms/edit/:id",
    element: (
      <DefaultLayout>
        <CreateCustomForm />
      </DefaultLayout>
    ),
  },
  {
    id: "embedded_analytics",
    path: "/manage/embedded_analytics",
    element: (
      <DefaultLayout>
        <ReportBuilderPage />
      </DefaultLayout>
    ),
  },
  {
    id: "configurations",
    path: "/configurations/roles",
    element: (
      <DefaultLayout>
        <ViewRoles />
      </DefaultLayout>
    ),
  },
  {
    id: "configurations_roles_details",
    path: "/configurations/roles/:id",
    element: (
      <DefaultLayout>
        <ViewRoleDetails />
      </DefaultLayout>
    ),
  },
  {
    id: "playground",
    path: "/playground",
    element: (
      <DefaultLayout>
        <PlayGround />
      </DefaultLayout>
    ),
  },
  ...commonRoutes,
];

export default secureRoutes;
