import React from "react";
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid";
import colors from "src/configs/colors.config";
import MUIButton from "../MUIButton/MUIButton.component";

const ExportToolbar = () => {
  const handleExport = () => {};

  return (
    <MUIButton onClick={handleExport} primary>
      Export
    </MUIButton>
  );
};

export default ExportToolbar;
