/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/
//
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import "./MUICard.style.css";

const MUICardHeader = ({ title, action }) => (
  <CardHeader
    className="mui-card-header"
    title={
      <Typography fontWeight={"bold"} variant="button">
        {title}
      </Typography>
    }
    action={action}
  />
);

const MainCard = ({
  children,
  padding,
  removeDefaultPadding,
  title,
  className,
  removeBorder,
  style,
  action,
}) => {
  const paddingBottom = padding ? padding * 8 : removeDefaultPadding ? 0 : 16;

  return (
    <Card
      style={style}
      className={`${className}`}
      variant="outlined"
      sx={{ borderRadius: 2, border: removeBorder ? "none" : "" }}
    >
      {title ? <MUICardHeader title={title} action={action} /> : null}
      <CardContent sx={{ padding }} style={{ paddingBottom }}>
        {children}
      </CardContent>
    </Card>
  );
};

const SilentHeader = ({
  children,
  title,
  action,
  padding,
  removeDefaultPadding,
  style,
  className,
}) => (
  <MainCard style={style} padding={0} removeDefaultPadding>
    <MainCard
      style={{
        borderRadius: 0,
      }}
      removeBorder
      // className={className}
    >
      <Grid alignItems={"center"} container>
        <Grid sm={8} item>
          <Typography
            variant="caption"
            textTransform={"uppercase"}
            fontWeight={"bold"}
          >
            {title}
          </Typography>
        </Grid>
        <Grid sm={4} style={{ textAlign: "right" }} item>
          {action}
        </Grid>
      </Grid>
    </MainCard>

    <MainCard
      style={{
        borderRadius: 0,
      }}
      removeBorder
      padding={padding}
      removeDefaultPadding={removeDefaultPadding}
      className={className}
    >
      {children}
    </MainCard>
  </MainCard>
);

const MUICard = ({
  children,
  padding,
  removeDefaultPadding,
  title,
  className,
  removeBorder,
  style,
  action,
  silentHeader,
}) => {
  const mainCardProps = {
    children,
    padding,
    removeDefaultPadding,
    title,
    className,
    removeBorder,
    style,
    action,
  };
  return silentHeader ? (
    <SilentHeader {...mainCardProps} />
  ) : (
    <MainCard {...mainCardProps} />
  );
};

export default MUICard;
