import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getInegrators } from "src/services/integrators.service";

const useGetIntegrators = (searchText, organizationId) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationId = currentUser?.organization_id || organizationId;
  // const userPermissions = currentUser?.permissions || [];

  const [integrators, setIntegrators] = useState([]);
  const [isIntegratorsLoading, setIsIntegratorsLoading] = useState(false);

  const searchKeyword = searchText ? searchText : "";

  // Function to fetch customer:
  const fetchIntegrators = async () => {
    try {
      setIsIntegratorsLoading(true);
      const resp = await getInegrators({ searchKeyword, organizationId });

      // Converting response into autocomplete options:
      const autocompleteOptions = resp?.data?.map((integrator) => ({
        id: integrator.id,
        label: integrator.name,
        value: integrator.name,
        name: "integrator_id",
      }));

      setIntegrators(autocompleteOptions);
      setIsIntegratorsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsIntegratorsLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      if (!searchText) fetchIntegrators();
      if (searchText && searchText.length > 3) fetchIntegrators();
    }
  }, [searchText, organizationId]);

  return [integrators, isIntegratorsLoading];
};

export default useGetIntegrators;
