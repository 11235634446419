import { Grid } from "@mui/material";
import MUIInputLabel from "../MUIInputLabel/MUIInputLabel.component";
import MUITextField from "../MUITextField/MUITextField.component";

const CustomForm = ({
  index,
  cForm,
  formik,
  handleInputChange,
  flags,
  disabled,
}) => (
  <Grid spacing={2} container>
    {cForm?.fields?.map((cField, index_2) => (
      <Grid sm={6} key={cField.id} item>
        <MUIInputLabel>{cField.label}</MUIInputLabel>
        <MUITextField
          Type={cField.type}
          Id={`custom_forms[${index}]["fields"][${index_2}]["value"]`}
          Name={`custom_forms[${index}]["fields"][${index_2}]["value"]`}
          Value={formik?.values?.custom_forms[index]?.fields[index_2]["value"]}
          OnChange={handleInputChange}
          Error={formik?.errors && formik?.errors[cField.id]}
          helperText={formik?.errors && formik?.errors[cField.id]}
          Disabled={
            flags?.isFormSubmit ||
            (disabled &&
              disabled[`custom_forms[${index}]["fields"][${index_2}]["value"]`])
          }
        />
      </Grid>
    ))}
  </Grid>
);

export default CustomForm;
