import { get, post, put, remove } from "src/utils/axios/axios.utils";

export const createDashboardItem = async (data) => {
  try {
    const response = await post("/dashboards/items", data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateDashboardItem = async (id, data) => {
  try {
    const response = await put(`/dashboards/items/${id}`, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteDashboardItem = async (id) => {
  try {
    const response = await remove(`/dashboards/items/${id}`);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getDashboardItemById = async (id) => {
  try {
    const response = await get(`/dashboards/items/${id}`);

    return response;
  } catch (error) {
    throw error;
  }
};
