import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCategories } from "src/services/category.services";

const useGetCategories = (organizationId) => {
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const userRole = currentUser?.role || null;
  // const organizationID = currentUser?.organization_id || null;
  // const userPermissions = currentUser?.permissions || [];

  const [categories, setCategories] = useState([]);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);

  useEffect(() => {
    if (organizationId) {
      setIsCategoriesLoading(true);
      getCategories({ organizationId })
        .then((resp) => {
          const { data } = resp;
          // Converting response into autocomplete options:
          const autocompleteOptions = data?.map((category) => ({
            id: category.id,
            label: category.name,
            value: category.id,
            name: "category_id",
          }));

          setCategories(autocompleteOptions);
          setIsCategoriesLoading(false);
        })
        .catch((error) => {
          console.log("useGetCategories: ", error.message);
          setIsCategoriesLoading(false);
        });
    }
  }, [organizationId]);

  return [categories, setCategories, isCategoriesLoading];
};
export default useGetCategories;
