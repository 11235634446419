import { get, post, put, remove } from "../utils/axios/axios.utils";
const path = "/contracts";

export const getContracts = async (params) => {
  try {
    const response = await get(`${path}`, {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// Service for fetching an Contracts by ID:
export const getContractByID = async (contractId) => {
  try {
    if (!contractId) throw new Error("Contract ID is missing.");

    const response = await get(`/contracts/${contractId}`);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchContractsDataWithPagination = async (limit, pageNumber) => {
  try {
    const response = await get(
      `${path}?limit=${limit}&pageNumber=${pageNumber}`
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateContractById = async (id, data) => {
  try {
    const response = await put(`${path}/${id}`, data);

    return response;
  } catch (error) {
    console.log("getContractById: ", error.message);
    throw error;
  }
};
export const createContract = async (requestBody) => {
  try {
    const response = await post(path, requestBody);
    return response;
  } catch (error) {
    console.log("createContract: ", error.message);
    throw error;
  }
};
// export const getContractDetails = async (contractId) => {
//   try {
//     const response = await get(path + `/${contractId}`);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };
export const getContractsMetaData = async (params) => {
  try {
    const response = await get(path + "/data/meta-data", { params });

    return response.data;
  } catch (error) {
    console.log("getContractsMetaData: ", error.message);
    throw error;
  }
};

// Deleting an Contracts
export const deleteContract = async (contractId) => {
  try {
    const result = await remove(`${path}/${contractId}`);
    return result;
  } catch (error) {
    console.error("deleteOrganization:", error.message);
    throw error;
  }
};

export const importContracts = async (data) => {
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("organization_id", data.organization_id);

  try {
    const reponse = await post(`${path}/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return reponse;
  } catch (error) {
    throw error;
  }
};
