/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/
//
import { ButtonBase, Collapse, Grid, Typography } from "@mui/material";
import MUICard from "../../MUICard/MUICard.component";
import configs from "../../../configs/configs";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 *
 * @param {object} props - Component properties.
 * @param {string} props.label - Menu Item Label.
 * @param {boolean | undefined} props.isActive - Active Menu Item.
 * @param {React.JSX.Element | undefined} props.StartIcon - Menu Item Icon
 * @param {boolean | undefined} props.enableDropdown - Enable Dropdown Menu Feature
 * @param {object []} props.subMenu - Contains submenus.
 * @param {string} props.route - Enable Dropdown Menu Feature
 *
 * @returns
 */
const SidebarMenuItem = ({
  label,
  StartIcon,
  enableDropdown,
  isActive,
  route,
  subMenu,
  activePath,
  sup, //superscript
}) => {
  const [expandMenu, setExpandMenu] = useState(true);
  const navigation = useNavigate();
  const handleNavigation = () => {
    navigation(route);
  };

  if (!label) {
    throw new Error("Please provide label.");
  }

  if (!route) {
    throw new Error("Please provide route.");
  }

  // if (!enableDropdown || !subMenu) {
  //   throw new Error(
  //     "(enableDropdown, subMenu) both are required if any one is provided."
  //   );
  // }

  // Handle toggling of expand menu:
  const toggleExpandMenu = () => setExpandMenu((e) => !e);

  return (
    <div>
      <ButtonBase
        style={{ width: "100%", borderRadius: "8px", textAlign: "inherit" }}
        sx={{
          color: configs.colors.primary,
        }}
        onClick={() =>
          enableDropdown ? toggleExpandMenu() : handleNavigation()
        }
      >
        <MUICard
          padding={1}
          removeDefaultPadding
          style={{ width: "100%" }}
          removeBorder
          className={
            isActive
              ? // || (enableDropdown && expandMenu)
                "list-item-btn sidebar-item-active"
              : "list-item-btn"
          }
          sx={{ "&:hover": { backgroundColor: configs.colors.primary_100 } }}
        >
          <Grid alignItems={"center"} container>
            {StartIcon ? (
              <Grid md={2} style={{ textAlign: "left", height: "20px" }} item>
                {StartIcon ? <StartIcon style={{ fontSize: "20px" }} /> : null}
              </Grid>
            ) : null}
            <Grid
              md={StartIcon ? 8 : 10}
              style={{ textAlign: "left", height: "20px" }}
              item
            >
              <Typography variant="body2" fontWeight={"bold"} fontSize="small">
                {label} <sup>{sup}</sup>
              </Typography>
            </Grid>
            <Grid md={2} style={{ textAlign: "center", height: "20px" }} item>
              {enableDropdown ? (
                <configs.icons.ExpandMoreIcon
                  style={{
                    fontSize: "20px",
                    transform: expandMenu ? "rotate(-180deg)" : "rotate(0deg)",
                    transition: "all 0.14s ease-in",
                  }}
                />
              ) : null}
            </Grid>
          </Grid>
        </MUICard>
      </ButtonBase>

      {/* Submenu section: */}
      {enableDropdown ? (
        <Collapse in={expandMenu}>
          {subMenu.map((subItem, index) => (
            <Submenu
              key={index}
              label={subItem.label}
              StartIcon={subItem.icon}
              route={subItem.route}
              sup={subItem.sup}
              isActive={
                activePath === subItem.route || activePath.includes(subItem.key)
              }
            />
          ))}
        </Collapse>
      ) : null}
    </div>
  );
};

export default SidebarMenuItem;

// submenu:
function Submenu({ isActive, StartIcon, label, route, sup }) {
  const navigation = useNavigate();

  return (
    <ButtonBase
      style={{
        width: "96%",
        borderRadius: "8px",
        textAlign: "inherit",
        float: "right",
      }}
      sx={{
        color: configs.colors.primary,
      }}
      onClick={() => navigation(route)}
    >
      <MUICard
        padding={1}
        removeDefaultPadding
        style={{ width: "100%" }}
        removeBorder
        className={
          isActive ? "list-item-btn sidebar-item-active" : "list-item-btn"
        }
        sx={{
          "&:hover": { backgroundColor: configs.colors.primary_100 },
        }}
      >
        <Grid alignItems={"center"} container>
          <Grid md={2} style={{ textAlign: "left", height: "20px" }} item>
            {StartIcon ? <StartIcon style={{ fontSize: "20px" }} /> : null}
          </Grid>
          <Grid md={10} style={{ textAlign: "left", height: "20px" }} item>
            <Typography variant="body2" fontWeight={"bold"} fontSize="small">
              {label} <sup>{sup}</sup>
            </Typography>
          </Grid>
        </Grid>
      </MUICard>
    </ButtonBase>
  );
}
