import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import MUIGridTable from "src/components/MUIGridTable/MUIGridTable.component";
import configs from "src/configs/configs";
import { getCustomForms } from "src/services/customForms.services";

const CustomFormsDashboard = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const organizationId = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions["custom_forms"] || [];

  const [forms, setForms] = useState([]);
  const [isListFetched, setIsListFetched] = useState(false);
  const [rowsCount, setRowsCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const handleRowClick = (params) => {
    if (params.row && params.row.id) {
      let path = `/manage/custom_forms/edit/${params.row.id}`;
      navigate(path);
    } else {
      console.error("Invalid row data:", params.row);
    }
  };

  useEffect(() => {
    fetchForms({ setIsListFetched, setForms, setRowsCount, organizationId });
  }, []);

  return (
    <div>
      <Grid spacing={2} container>
        <Grid xs={10} item>
          <Header />
        </Grid>
        <Grid xs={2} item>
          <MUIButton
            onClick={() => navigate("/manage/custom_forms/create")}
            primary
          >
            Create
          </MUIButton>
        </Grid>
        <Grid xs={12} item>
          <MUIGridTable
            columns={columns}
            rows={forms}
            loading={isListFetched}
            rowCount={rowsCount}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            onRowClick={handleRowClick}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomFormsDashboard;

const Header = () => (
  <HeaderChip
    label={"Custom Forms | List"}
    icon={<configs.icons.List sx={{ color: "#fff" + "!important" }} />}
  />
);

const columns = [
  {
    field: "is_active",
    headerName: "",
    width: 40,
    align: "center",
    renderCell: (params) => (
      <span>
        {params.value ? (
          <configs.icons.CheckCircleIcon color="success" />
        ) : (
          <configs.icons.UnpublishedIcon color="error" />
        )}
      </span>
    ),
  },
  { field: "name", headerName: "Name", width: 260 },
  {
    field: "organization",
    headerName: "Organization",
    width: 260,
    renderCell: (params) => {
      return <span>{params.value.name}</span>;
    },
  },
  { field: "type", headerName: "Type", width: 260 },
  { field: "description", headerName: "Description", width: 260 },
];

async function fetchForms({
  setIsListFetched,
  setForms,
  setRowsCount,
  organizationId,
}) {
  setIsListFetched(true);

  try {
    const response = await getCustomForms({
      type: "all",
      organizationId,
    });
    const { data } = response;

    setForms(data);
    setRowsCount(data.length);
    setIsListFetched(false);
  } catch (error) {
    console.log("Fetch Forms: ", error.message);
    setIsListFetched(false);
  }
}
