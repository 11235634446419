import * as Yup from "yup";
import { Grid } from "@mui/material";
import MUICard from "src/components/MUICard/MUICard.component";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import FormActions from "src/pages/ManageOrganizations/RegisterOrganization/components/FormActions/FormActions.component";
import configs from "src/configs/configs";
import CardTitle from "src/components/CardTitle/CardTitle.component";
import MUIAutocomplete from "src/components/MUIAutocomplete/MUIAutocomplete.component";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getOrganizations } from "src/services/organizations.services";
import { City, Country, State } from "country-state-city";
import {
  createSupplier,
  getSupplierById,
  updateSupplierById,
} from "src/services/suppliers.services";
import MUISnackbar from "src/components/MUISnackbar/MUISnackbar.component";
import firstLetterCaps from "src/helpers/firstLetterCaps.helper";
import HeaderChip from "src/components/HeaderChip/HeaderChip.component";
import { useNavigate, useParams } from "react-router-dom";
import MUISwitchButton from "src/components/MUISwitchButton/MUISwitchButton.component";
import PageLoader from "src/components/PageLoader/PageLoader.component";
import { getCustomFormById } from "src/services/customForms.services";
import CustomFormSection from "src/components/CustomFormSection/CustomFormSection.component";
import useGetCustomForms from "src/hooks/useGetCustomForms.hooks";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import { useSelector } from "react-redux";

// main component:
const CreateSupplier = () => {
  const givenOrganization = {
    organization: null,
  };
  const navigate = useNavigate();
  const params = useParams();
  const supplierId = params.id;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userRole = currentUser?.role || null;
  const organizationID = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions["suppliers"] || [];

  if (organizationID) {
    givenOrganization.organization = {
      id: organizationID,
      label: "",
      value: organizationID,
    };
  }

  const [flags, setFlags] = useState({
    isFormSubmit: false,
    organization: false,
    isPageLoading: supplierId ? true : false,
    openCustomFormModal: false,
  }); // Flag for handling loading button on submit.
  const [searchStrings, setSearchStrings] = useState({
    organization: null,
  });
  const [optionsList, setOptionsList] = useState({
    organization: [],
    city: [],
    state: [],
    country: [],
    // customForms: [],
  }); // Autocomplete options
  const [incomingResponse, setIncomingResponse] = useState(null);
  const [customForms, setCustomForms] = useGetCustomForms("supplier");
  const [selectedCustomForm, setSelectedCustomForm] = useState(null);
  const [selectedCustomForms, setSelectedCustomForms] = useState([]);

  // Formik integration:
  const formik = useFormik({
    initialValues: { ...createSupplierInitialValues, ...givenOrganization },
    validationSchema: createSupplierValidationSchema,
    onSubmit: async (values) => {
      try {
        setFlags((prev) => ({ ...prev, isFormSubmit: true }));
        const rawValues = values;
        const requestBodyJson = convertToRequestJson(rawValues);

        supplierId
          ? await updateSupplierById(supplierId, requestBodyJson)
          : await createSupplier(requestBodyJson);

        setIncomingResponse({
          severity: "success",
          message: `Supplier ${
            supplierId ? "Updated" : "created"
          } successfully!`,
        });

        if (!supplierId) formik.handleReset();

        setFlags((prev) => ({ ...prev, isFormSubmit: false }));
      } catch (error) {
        const message = error?.response?.data?.message || error.message;

        setIncomingResponse({
          severity: "error",
          message,
        });

        setFlags((prev) => ({ ...prev, isFormSubmit: false }));
      }
    },
    onReset: () => (supplierId ? navigate(-1) : null),
  });

  // get orgnaizations:
  useEffect(() => {
    if (userRole === "asset_eye_owner") {
      if (!searchStrings.organization)
        handleGetOrganizations(setFlags, searchStrings, setOptionsList);

      if (searchStrings.organization && searchStrings.organization.length > 3)
        handleGetOrganizations(setFlags, searchStrings, setOptionsList);
    }
  }, [searchStrings.organization]);

  // Get countries:
  useEffect(() => {
    const countries = Country.getAllCountries();
    const parseCountries = countries.map((cntry) => ({
      id: cntry?.isoCode,
      label: cntry?.name,
      value: cntry?.name,
      name: "country",
    }));

    setOptionsList((prev) => ({ ...prev, country: parseCountries }));
  }, []);

  // Get states:
  useEffect(() => {
    if (formik.values.country) {
      if (!supplierId) {
        formik.setFieldValue("city", null);
        formik.setFieldValue("state", null);
      }

      const states = State.getStatesOfCountry(formik?.values?.country?.id);
      const parseStates = states.map((state) => ({
        id: state?.isoCode,
        label: state?.name,
        value: state?.name,
        name: "state",
      }));

      setOptionsList((prev) => ({ ...prev, state: parseStates }));
    }
  }, [formik.values.country]);

  // Get city:
  useEffect(() => {
    if (formik.values.state) {
      if (!supplierId) formik.setFieldValue("city", null);

      const countryId = formik?.values?.country?.id;
      const stateId = formik?.values?.state?.id;

      const cities = City.getCitiesOfState(countryId, stateId);

      const parseCities = cities.map((city) => ({
        id: `${city?.countryCode}_${city?.stateCode}`,
        label: city?.name,
        value: city?.name,
        name: "city",
      }));

      setOptionsList((prev) => ({ ...prev, city: parseCities }));
    }
  }, [formik.values.state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      const namesArr = value.split(" ");
      const newNameArr = namesArr.map((n) => firstLetterCaps(n));

      e.target.value = newNameArr.join(" ");
    }

    formik.handleChange(e);
  };

  const handleAutocompleteChange = (e, value) => {
    if (value) formik.handleChange({ target: { name: value.name, value } });
    else {
      const { name, value } = e.target;
      setSearchStrings((prev) => ({ ...prev, [name]: value }));
    }
  };

  // fetching supplier's details:
  const handleGetSupplierById = async () => {
    try {
      setFlags((prev) => ({ ...prev, isPageLoading: true }));
      const response = await getSupplierById(supplierId);
      const supplier = response.data;
      const organization = supplier?.organization;
      const organizationObj = {
        id: organization?.id,
        label: organization?.name,
        value: organization?.id,
        name: "organization",
      };
      const addressOptions = getCountryStateAndCity(supplier);

      formik.setValues({
        organization: organizationObj,
        name: supplier?.name,
        description: supplier?.description,
        email: supplier?.email,
        street_address_1: supplier?.street_address_1,
        street_address_2: supplier?.street_address_2,
        city: supplier?.city,
        state: addressOptions?.state,
        country: addressOptions?.country,
        zipcode: supplier?.zipcode,
        is_active: supplier?.is_active,
        custom_forms: supplier?.custom_forms,
      });
      setSelectedCustomForms(supplier?.custom_forms);
      setFlags((prev) => ({ ...prev, isPageLoading: false }));
    } catch (error) {
      console.log("handleGetSupplierById: ", error.message);
    }
  };
  useEffect(() => {
    if (supplierId) handleGetSupplierById();
  }, [supplierId]);

  useEffect(() => {
    if (selectedCustomForms.length && customForms.length) {
      const selectedCustomFormsIds = selectedCustomForms.map((form) => form.id);
      setCustomForms((prevState) => {
        return prevState.filter(
          (form) => !selectedCustomFormsIds.includes(form.id)
        );
      });
    }
  }, [selectedCustomForms, customForms]);

  const fetchCustomFormById = async (id) => {
    try {
      setFlags((prev) => ({
        ...prev,
        isCustomFormFetched: true,
      }));
      const response = await getCustomFormById(id);
      const { data } = response;
      const parsedCustomForm = [data].map((cForm) => ({
        id: cForm.id,
        name: cForm.name,
        fields: cForm.fields,
      }))[0];

      const parsedCustomForm_2 = [data].map((cForm) => {
        const parsedObj = {};
        parsedObj.id = cForm.id;

        parsedObj.fields = cForm?.fields?.map((cField) => ({
          id: cField.id,
          value: "",
        }));

        return parsedObj;
      })[0];

      formik.setValues((values) => ({
        ...values,
        custom_forms: [...values.custom_forms, parsedCustomForm_2],
      }));

      setSelectedCustomForms((prev) => [...prev, parsedCustomForm]);
      setSelectedCustomForm(null);
      setFlags((prev) => ({
        ...prev,
        openCustomFormModal: !prev.openCustomFormModal,
        isCustomFormFetched: false,
      }));
    } catch (error) {
      setFlags((prev) => ({
        ...prev,
        isCustomFormFetched: false,
      }));
      console.log("fetchCustomFormById: ", error.message);
    }
  };

  const handleAddCustomForms = () => {
    const customFormId = selectedCustomForm;
    fetchCustomFormById(customFormId);
  };

  // Set a variable for handling an update permission:
  const haveUpdatePermission =
    params?.id && !userPermissions.includes("UPDATE");

  if (flags.isPageLoading) {
    return <PageLoader />;
  } else {
    return (
      <div>
        <MUISnackbar
          severity={incomingResponse?.severity}
          message={incomingResponse?.message}
          open={incomingResponse ? true : false}
          onClose={() => setIncomingResponse(null)}
          vertical={"top"}
          horizontal={"right"}
        />
        <Grid spacing={2} container>
          <Grid sm={12} item>
            <HeaderChip
              icon={
                <configs.icons.ShoppingCartIcon
                  sx={{ color: "#fff" + "!important" }}
                />
              }
              label={`Suppliers > ${supplierId ? "Edit" : "Create"}`}
            />
          </Grid>
          <Grid sm={12} item>
            <div
              style={{
                width: "800px",
                margin: "auto",
              }}
            >
              <MUICard
                title={<TitleComponent isUpdate={supplierId} />}
                action={
                  supplierId ? (
                    <MUISwitchButton
                      name={"is_active"}
                      is_active={formik.values.is_active}
                      onChange={handleInputChange}
                      disabled={haveUpdatePermission}
                    />
                  ) : null
                }
              >
                <DefaultFormComponent
                  organizationID={organizationID}
                  formik={formik}
                  handleInputChange={handleInputChange}
                  flags={flags}
                  optionsList={optionsList}
                  handleAutocompleteChange={handleAutocompleteChange}
                  disabled={haveUpdatePermission}
                />
              </MUICard>
              <AppSpacer vertical={"12px"} />

              {selectedCustomForms.length
                ? selectedCustomForms.map((cform, index) => (
                    <>
                      <MUICard key={cform.id} title={cform.name}>
                        <CustomFormComponent
                          index={index}
                          cForm={cform}
                          formik={formik}
                          handleInputChange={handleInputChange}
                          flags={flags}
                        />
                      </MUICard>
                      <AppSpacer vertical={"12px"} />
                    </>
                  ))
                : null}

              <CustomFormSection
                open={flags.openCustomFormModal}
                toggleOpen={() =>
                  setFlags((prev) => ({
                    ...prev,
                    openCustomFormModal: !prev.openCustomFormModal,
                  }))
                }
                options={customForms}
                value={selectedCustomForm}
                onChange={(e) => setSelectedCustomForm(e.target.value)}
                onAdd={handleAddCustomForms}
                loading={flags.isCustomFormFetched}
                disabled={haveUpdatePermission}
              />

              <FormActions
                formik={formik}
                isLoading={flags.isFormSubmit}
                disabledSubmit={haveUpdatePermission}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
};
export default CreateSupplier;

// Components:
const TitleComponent = ({ isUpdate }) => (
  <CardTitle
    title={`${isUpdate ? "Edit" : "Create"}  Supplier`}
    icon={
      <configs.icons.AddShoppingCartIcon
        sx={{ color: configs.colors.primary }}
      />
    }
  />
);

// Default form component:
const DefaultFormComponent = ({
  formik,
  handleInputChange,
  flags,
  optionsList,
  handleAutocompleteChange,
  organizationID,
  disabled,
}) => (
  <Grid spacing={2} container>
    {createSupplierFormFields.map((field) =>
      field.type !== "autocomplete" ? (
        <Grid md={field.col.md} key={field.id} item>
          <MUIInputLabel>
            {field.isRequired && <span style={{ color: "red" }}>*</span>}
            {field.label}
          </MUIInputLabel>
          <MUITextField
            Name={field.name}
            Value={formik.values[field.name]}
            OnChange={handleInputChange}
            Error={
              formik?.touched &&
              formik?.touched[field?.id] &&
              formik?.errors &&
              formik?.errors[field.id]
            }
            helperText={
              formik?.touched &&
              formik?.touched[field?.id] &&
              formik?.errors &&
              formik?.errors[field.id]
            }
            Disabled={disabled || flags.isFormSubmit}
          />
        </Grid>
      ) : field.id === "organization" && organizationID ? null : (
        <Grid md={field.col.md} key={field.id} item>
          <MUIInputLabel>
            {field.isRequired && <span style={{ color: "red" }}>*</span>}
            {field.label}
          </MUIInputLabel>
          <MUIAutocomplete
            loading={flags[field.id]}
            name={field.name}
            value={formik.values[field.id]}
            options={optionsList[field.id]}
            onChange={handleAutocompleteChange}
            error={
              formik?.touched &&
              formik?.touched[field?.id] &&
              formik?.errors &&
              formik?.errors[field.id]
            }
            helperText={
              formik?.touched &&
              formik?.touched[field?.id] &&
              formik?.errors &&
              formik?.errors[field.id]
            }
            disabled={disabled || flags.isFormSubmit}
            // creatable
          />
        </Grid>
      )
    )}
  </Grid>
);

// Custom form component:
const CustomFormComponent = ({
  index,
  cForm,
  formik,
  handleInputChange,
  flags,
  disabled,
}) => (
  <Grid spacing={2} container>
    {cForm?.fields?.map((cField, index_2) => (
      <Grid sm={6} key={cField.id} item>
        <MUIInputLabel>{cField.label}</MUIInputLabel>
        <MUITextField
          Name={`custom_forms[${index}]["fields"][${index_2}]["value"]`}
          Value={formik?.values?.custom_forms[index]?.fields[index_2]["value"]}
          OnChange={handleInputChange}
          Error={formik?.errors && formik?.errors[cField.id]}
          helperText={formik?.errors && formik?.errors[cField.id]}
          Disabled={disabled || flags.isFormSubmit}
        />
      </Grid>
    ))}
  </Grid>
);

// helpers:

// Configurations:
const createSupplierFormFields = [
  {
    id: "organization",
    name: "organization",
    label: "Select Organization",
    isRequired: true,
    type: "autocomplete",
    col: {
      md: 12,
    },
  },
  {
    id: "name",
    name: "name",
    label: "Name",
    isRequired: true,
    type: "text",
    col: {
      md: 6,
    },
  },
  {
    id: "email",
    name: "email",
    label: "Email",
    isRequired: true,
    type: "text",
    col: {
      md: 6,
    },
  },
  {
    id: "description",
    name: "description",
    isRequired: true,
    label: "Description",
    type: "text",
    col: {
      md: 12,
    },
  },
  {
    id: "street_address_1",
    name: "street_address_1",
    isRequired: true,
    label: "Street Address 1",
    type: "text",
    col: {
      md: 6,
    },
  },
  {
    id: "street_address_2",
    name: "street_address_2",
    label: "Street Address 2",
    type: "text",
    col: {
      md: 6,
    },
  },
  {
    id: "country",
    name: "country",
    label: "Country",
    isRequired: true,
    type: "autocomplete",
    col: {
      md: 3,
    },
  },
  {
    id: "state",
    name: "state",
    label: "State",
    isRequired: true,
    type: "autocomplete",
    col: {
      md: 3,
    },
  },
  {
    id: "city",
    name: "city",
    label: "City",
    isRequired: true,
    type: "text",
    col: {
      md: 3,
    },
  },
  {
    id: "zipcode",
    name: "zipcode",
    label: "Zipcode",
    isRequired: true,
    type: "text",
    col: {
      md: 3,
    },
  },
];

const createSupplierInitialValues = {
  organization: null,
  name: "",
  description: "",
  email: "",
  street_address_1: "",
  street_address_2: "",
  city: "",
  state: null,
  country: null,
  zipcode: "",
  is_active: true,
  custom_forms: [],
};

const createSupplierValidationSchema = Yup.object().shape({
  organization: Yup.object().required("Organization is required"),
  name: Yup.string().required("Customer Name is required"),
  description: Yup.string().required("Decription is required!"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    ),
  street_address_1: Yup.string().required("Street Address 1 is required"),
  country: Yup.object().required("Select your country"),
  state: Yup.object().required("Select your state"),
  city: Yup.string().required("City is required!"),
  zipcode: Yup.number("Integer value is required")
    .integer("Integer value is required")
    .required("Zipcode is required"),
});

// Helpers:
const handleGetOrganizations = async (
  setFlags,
  searchStrings,
  setOptionsList
) => {
  try {
    setFlags((prev) => ({ ...prev, organization: true }));

    const response = await getOrganizations({
      searchKeyword: searchStrings.organization,
    });

    const { data } = response;
    const parseOrganizations = data?.map((org) => ({
      id: org?.id,
      label: org?.name,
      value: org?.id,
      name: "organization",
    }));

    setOptionsList((prevState) => ({
      ...prevState,
      organization: parseOrganizations,
    }));
    setFlags((prev) => ({ ...prev, organization: false }));
  } catch (error) {
    console.log("handleGetOrganizations: ", error.message);
  }
};

const convertToRequestJson = (data) =>
  [data].map(({ organization, country, state, ...value }) => {
    value.organization_id = organization.id;
    value.country = country.label;
    value.state = state.label;
    // value.city = city.label;

    return value;
  })[0];

const getCountryStateAndCity = (data) => {
  const addressOptions = {};
  const getAllCountries = Country.getAllCountries();
  const matchedCountry = getAllCountries.filter(
    (country) => country.name === data.country
  )[0];

  addressOptions.country = {
    id: matchedCountry.isoCode,
    label: matchedCountry.name,
    value: matchedCountry.isoCode,
    name: "country",
  };

  const allStates = State.getStatesOfCountry(matchedCountry.isoCode);
  const matchedState = allStates.filter(
    (state) => state.name === data.state
  )[0];

  addressOptions.state = {
    id: matchedState.isoCode,
    label: matchedState.name,
    value: matchedState.isoCode,
    name: "state",
  };

  const allCities = City.getCitiesOfState(
    matchedCountry.isoCode,
    matchedState.isoCode
  );
  const matchedCity = allCities.filter((city) => city?.name === data?.city)[0];

  addressOptions.city = {
    id: matchedCity?.isoCode,
    label: matchedCity?.name,
    value: matchedCity?.isoCode,
    name: "city",
  };

  return addressOptions;
};
