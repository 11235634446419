import React, { useState, useEffect } from "react";
import { Grid, Modal } from "@mui/material";
import { Alert, Button, Spin } from "antd";
import { useQuery } from "@apollo/react-hooks";
//import { withRouter } from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";

import ExploreQueryBuilder from "src/components/QueryBuilder/ExploreQueryBuilder.js";
import { GET_DASHBOARD_ITEM } from "src/graphql/queries.js";
import TitleModal from "src/components/TitleModal.js";
import { CubeContext } from "@cubejs-client/react";
import MUIAutocomplete from "src/components/MUIAutocomplete/MUIAutocomplete.component.jsx";

import useGetOrganizations from "src/hooks/useGetOrganizations.hook";

import CreateDashboard from "src/pages/EmbeddedAnalytics/CreateDashboard/CreateDashboard.component";
import { useSelector } from "react-redux";
import useGetDashboards from "src/hooks/useGetDashboards.hook";
import MUICard from "src/components/MUICard/MUICard.component";
import MUIInputLabel from "src/components/MUIInputLabel/MUIInputLabel.component";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import { getDashboardItemById } from "src/services/dashboardItems.service";

//const ReportBuilderPage = withRouter(({ history, location }) => {
const ReportBuilderPage = () => {
  const { cubeApi } = React.useContext(CubeContext);

  const navigate = useNavigate();
  //const params = useParams();
  const location = useLocation();

  const [searchStrings, setSearchStrings] = useState({
    organization_id: "",
    dashboard_id: "",
  });

  const currentUser = useSelector((state) => state.auth.currentUser);
  const userRole = currentUser?.role || null;
  const organizationID = currentUser?.organization_id || null;
  const userPermissions = currentUser?.permissions || [];

  const myOrganization = organizationID
    ? {
        id: organizationID,
        label: "",
        value: organizationID,
      }
    : null;

  const [organization, setOrganization] = useState(myOrganization);
  const [dashboard, setDashboard] = useState(null);

  // useEffect(() => {
  //   if (organizationID !== null && organizationID !== undefined) {
  //     setSearchStrings((prev) => ({
  //       ...prev,
  //       organization_id: organizationID,
  //     }));
  //   }
  // }, [organizationID]);

  const [organizations, isOrganizationsLoading] = useGetOrganizations(
    searchStrings?.organization_id
  );

  const [dashboards, setDashboards, isDashboardsLoading] = useGetDashboards(
    searchStrings["dashboard_id"],
    organization?.id
  );

  const [dashboardItem, setDashboardItem] = useState(null); // Storing fetched dashboard item

  const [addingToDashboard, setAddingToDashboard] = useState(false);
  const params = new URLSearchParams(location.search);
  const itemId = params.get("itemId");
  //console.log('itemId', itemId)
  const { loading, error, data } = useQuery(GET_DASHBOARD_ITEM, {
    fetchPolicy: "cache-and-network", // Set a valid fetchPolicy
    variables: {
      id: itemId,
    },
    skip: !itemId,
  });
  /* 
        console.log('loading', loading)
        console.log('error', error)
        console.log('data', data) */

  const [open, toggleOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalValue, setModalValue] = useState(null);

  const customHookConfigs = {
    organization_id: {
      options: organizations,
      flag: isOrganizationsLoading,
    },
    dashboard_id: {
      options: dashboards,
      flag: isDashboardsLoading,
    },
  };

  const modalOptions = {
    dashboard_id: { dashboards, setDashboards },
  };

  const [vizState, setVizState] = useState(null);
  const finalVizState =
    vizState ||
    (itemId && !loading && data && JSON.parse(data.dashboardItem.vizState)) ||
    {};
  const [titleModalVisible, setTitleModalVisible] = useState(false);
  const [title, setTitle] = useState(null);
  const finalTitle =
    title != null
      ? title
      : (itemId && !loading && data && data.dashboardItem.name) || "New Chart";

  useEffect(() => {
    if (!organizationID && organization) {
      setDashboard(null);
    }
  }, [organization]);

  useEffect(() => {
    if (itemId) {
      (async () => {
        try {
          const response = await getDashboardItemById(itemId);
          setDashboardItem(response?.data);
          if (!organizationID) {
            setOrganization({
              id: response?.data?.dashboard?.organization_id,
              label: response?.data?.dashboard?.organization.name,
              value: response?.data?.dashboard?.organization_id,
              name: "organization_id",
            });
          }
          // setDashboard({
          //   id: response?.data?.dashboard_id,
          //   label: response?.data?.dashboard?.name,
          //   value: response?.data?.dashboard_id,
          //   name: "dashboard_id",
          // });
        } catch (error) {
          throw error;
        }
      })();
      // const currentDashboard = JSON.parse(
      //   localStorage.getItem("dashboardItems")
      // ).filter((d) => d.id === itemId);

      // if (currentDashboard?.length) {
      // setOrganization({
      //   id: currentDashboard[0].organization_id,
      //   label: "",
      //   value: currentDashboard[0].organization_id,
      // });
      // setDashboard({
      //   id: currentDashboard[0].dashboard_id,
      //   label: "",
      //   value: currentDashboard[0].dashboard_id,
      // });
      // }
    }
  }, [itemId]);

  useEffect(() => {
    if (itemId && dashboards?.length && dashboardItem) {
      const sDashboard = dashboards.filter(
        (d) => d.id === dashboardItem?.dashboard_id
      );
      if (sDashboard?.length) {
        setDashboard(sDashboard[0]);
      }
    }
  }, [itemId, dashboards, dashboardItem]);

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert type="error" message={error.toString()} />;
  }

  const handleAutocompleteChange = (e, value) => {
    console.log("value", e);
    if (value && value.name == "organization_id") {
      if (value?.value) setOrganization(value);
      else {
        const { name, value } = e.target;
        setSearchStrings((prev) => ({ ...prev, [name]: value }));
      }
    } else if (value && value.name == "dashboard_id") {
      if (value?.value) setDashboard(value);
      else {
        const { name, value } = e.target;
        setSearchStrings((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      const { name, value } = e.target;
      setSearchStrings((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Function to handle dialog values
  const handleDialogValue = (type, newValue) => {
    setModalType(type);
    setModalValue(newValue);
  };

  const ModalsGroup = ({
    organizationId,
    creatorId,
    type,
    value,
    onAutocompleteChange,
    toggleOpen,
    setValue,
    setOptions,
  }) => {
    // Collecting common props:
    const props = {
      organizationId,
      creatorId,
      value: value,
      setSelectedOption: (selectedValue) =>
        onAutocompleteChange(null, selectedValue),
      toggleOpen: toggleOpen,
      setValue: setValue,
    };

    switch (type) {
      case "dashboard_id":
        return (
          <CreateDashboard
            {...props}
            setOptions={setOptions[type].setDashboards}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <MUICard>
        <Grid spacing={2} container>
          {organizationID ? null : (
            <Grid sm={6} item>
              <MUIInputLabel>Select Organization</MUIInputLabel>
              <MUIAutocomplete
                loading={customHookConfigs["organization_id"]?.flag}
                name={"organization_id"}
                key={"organization_id"}
                value={organization}
                options={customHookConfigs["organization_id"]?.options}
                onChange={handleAutocompleteChange}
                creatable={false}
              />
            </Grid>
          )}
          <Grid sm={organizationID ? 4 : 6} item>
            <MUIInputLabel>Select Dashboard</MUIInputLabel>
            <MUIAutocomplete
              loading={customHookConfigs["dashboard_id"]?.flag}
              name={"dashboard_id"}
              key={"dashboard_id"}
              value={dashboard}
              options={customHookConfigs["dashboard_id"]?.options}
              onChange={handleAutocompleteChange}
              creatable={true}
              toggleOpen={toggleOpen}
              setDialogValue={(e) => handleDialogValue("dashboard_id", e)}
              disabled={organization?.id ? false : true}
            />
          </Grid>
        </Grid>
      </MUICard>

      <AppSpacer vertical={"12px"} />

      <TitleModal
        organization_id={organization?.id}
        dashboard_id={dashboard?.id}
        //history={history}
        history={navigate}
        itemId={itemId}
        titleModalVisible={titleModalVisible}
        setTitleModalVisible={setTitleModalVisible}
        setAddingToDashboard={setAddingToDashboard}
        finalVizState={finalVizState}
        setTitle={setTitle}
        finalTitle={finalTitle}
      />
      <ExploreQueryBuilder
        vizState={finalVizState}
        cubeApi={cubeApi}
        chartExtra={[
          <Button
            key="button"
            type="primary"
            loading={addingToDashboard}
            onClick={() => setTitleModalVisible(true)}
          >
            {itemId ? "Update" : "Add to Dashboard"}
          </Button>,
        ]}
        onVizStateChanged={setVizState}
      />

      <Modal open={open} onClose={() => toggleOpen((e) => !e)}>
        <div>
          <ModalsGroup
            organizationId={organization?.id}
            creatorId={currentUser?.id}
            type={modalType}
            value={modalValue}
            onAutocompleteChange={handleAutocompleteChange}
            toggleOpen={toggleOpen}
            setValue={setModalValue}
            setOptions={modalOptions}
          />
        </div>
      </Modal>
    </div>
  );
};
export default ReportBuilderPage;
