/* 
    Copyright 2023-24 Rahi Systems Inc
    Author: Pratik Sonar <pratik.sonar@rahisystems.com>
*/
//
import { Grid } from "@mui/material";
import Navbar from "../Navbar/Navbar.component";
import Sidebar from "../Sidebar/Sidebar.component";

//
const DefaultLayout = ({ children }) => {
  return (
    <div>
      <Grid container>
        {/* Navbar */}
        <Grid xs={12} item>
          <Navbar />
        </Grid>

        {/* Sidebar */}
        <Grid md={3} lg={2} display={{ xs: "none", md: "block" }} item>
          <Sidebar />
        </Grid>

        {/* Body */}
        <Grid xs={12} md={9} lg={10} item>
          <div className="main-page">{children}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default DefaultLayout;
