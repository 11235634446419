import { get, post, put } from "src/utils/axios/axios.utils";

export const getCustomForms = async (config) => {
  try {
    const response = await get(`/custom-forms`, {
      params: {
        ...config,
      },
    });

    return response;
  } catch (error) {
    console.log("getCustomForms: ", error.message);
    throw error;
  }
};

/**
 *
 * @param {string} id - Custom form ID (UID4)
 * @returns
 */
export const getCustomFormById = async (id) => {
  try {
    const response = await get(`/custom-forms/${id}`);

    return response;
  } catch (error) {
    console.log("getCustomFormById: ", error.message);
    throw error;
  }
};

/**
 *
 * @param {object} data - Create custom from request JSON
 * @returns
 */
export const createCustomForm = async (data) => {
  try {
    const response = await post(`/custom-forms`, data);

    return response;
  } catch (error) {
    console.log("createCustomForm: ", error.message);
    throw error;
  }
};

/**
 *
 * @param {string} id - Unique identifier (UUID4)
 * @param {object} data - Create custom from request JSON
 * @returns
 */
export const updateCustomForm = async (id, data) => {
  try {
    const response = await put(`/custom-forms/${id}`, data);

    return response;
  } catch (error) {
    console.log("updateCustomForm: ", error.message);
    throw error;
  }
};
