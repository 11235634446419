import "./ExportDialog.style.css";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog.component";
import configs from "src/configs/configs";
import MUITextField from "src/components/MUITextField/MUITextField.component";
import { useEffect, useState } from "react";

const ExportDialog = ({ onCancel, onConfirm, isLoading }) => {
  const [selectedOption, setSelectedOption] = useState({
    id: "csv",
    label: ".CSV",
    value: "csv",
  });

  const handleOnChange = (e) => {
    const matchedOption = exportOptions.filter(
      (opt) => opt.id === e.target.value
    );

    setSelectedOption(matchedOption[0]);
  };

  return (
    <ConfirmationDialog
      icon={
        <configs.icons.ExportRoundedIcon
          className="default-size-icon"
          sx={{ color: configs.colors.primary, transform: "rotate(180deg)" }}
        />
      }
      headerText={"Select file type to export"}
      captionText={"Export to .csv/.xlsx"}
      primaryLabel={"Export"}
      onCancel={onCancel}
      onConfirm={() => onConfirm(selectedOption)}
      isLoading={isLoading}
    >
      <MUITextField
        Value={selectedOption?.value}
        Select
        SelectOptions={exportOptions}
        OnChange={handleOnChange}
      />
    </ConfirmationDialog>
  );
};

export default ExportDialog;

const exportOptions = [
  {
    id: "csv",
    label: ".CSV",
    value: "csv",
  },
  {
    id: "xlsx",
    label: ".XLSX",
    value: "xlsx",
  },
];
