import {
  Alert,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import MUICard from "src/components/MUICard/MUICard.component";
import AppSpacer from "src/components/AppSpace/AppSpace.component";
import MUIButton from "src/components/MUIButton/MUIButton.component";
import formatFileSize from "src/helpers/formatFileSize.helper";
import configs from "src/configs/configs";
import { deleteFile, downloadFile } from "src/services/files.services";
import { useState } from "react";

/**
 *
 * @param {object} props - Component props
 * @param {Array} props.data - List of attachments
 * @param {void} props.setData - Set attachments
 * @param {Boolean} props.disabled - set disabled
 * @param {VoidFunction} props.onAdd - Void function
 * @returns
 */
const AttachmentsComponent = (props) => {
  const attachments = props.data || [];
  const cardTitle = "Attachments";

  // Helper components:
  const Action = () => (
    <MUIButton size={"small"} onClick={props.onAdd} disabled={props.disabled} primary>
      Add
    </MUIButton>
  );

  const FileActions = ({ file }) => {
    const [isDownload, setIsDownload] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDownload = async () => {
      setIsDownload(true);
      try {
        await downloadFile(file.id, file.name);
        setIsDownload(false);
      } catch (error) {
        setIsDownload(false);
        console.log("Error download: ", error.message);
      }
    };
    const handleDelete = async () => {
      setIsDelete(true);
      try {
        await deleteFile(file.id, file.name);
        props.setData((prev) => prev.filter((f) => f.id !== file.id));
        setIsDelete(false);
      } catch (error) {
        setIsDelete(false);
        console.log("Error delete: ", error.message);
      }
    };

    return (
      <Grid alignItems={"center"} container>
        <Grid sm={6} item>
          <IconButton onClick={handleDownload} disabled={isDownload}>
            {isDownload ? (
              <CircularProgress
                sx={{ color: configs.colors.primary }}
                size={"18px"}
              />
            ) : (
              <configs.icons.CloudDownloadIcon
                sx={{ color: configs.colors.primary }}
              />
            )}
          </IconButton>
        </Grid>
        <Grid sm={6} item>
          <IconButton onClick={handleDelete} disabled={isDelete}>
            {isDelete ? (
              <CircularProgress color="error" size={"18px"} />
            ) : (
              <configs.icons.Trash color="error" />
            )}
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const AttachmentsList = () => (
    <div>
      {attachments.map((file, index) => (
        <div key={file.id}>
          {index !== 0 ? <AppSpacer vertical={"12px"} /> : null}
          <Alert
            variant="outlined"
            action={<FileActions file={file} />}
            severity={"success"}
          >
            {file?.name} {file?.size && `(${formatFileSize(file?.size)})`}
          </Alert>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <MUICard title={cardTitle} action={<Action />}>
        <Grid container>
          <Grid sm={12} item>
            {attachments.length ? (
              <AttachmentsList />
            ) : (
              <Typography color={"GrayText"}>No Attachments</Typography>
            )}
          </Grid>
        </Grid>
      </MUICard>
    </div>
  );
};
export default AttachmentsComponent;
