import {
  Autocomplete,
  CircularProgress,
  TextField,
  createFilterOptions,
} from "@mui/material";
import configs from "src/configs/configs";

const filter = createFilterOptions();

// //
// /**
//  *
//  * @param {Object} props React props
//  * @param {Array<Object>} props.options - Array of objects containing options.
//  * @param {string} props.options[].id - Id property of each object in the options array.
//  * @param {string} props.options[].label - Label property of each object in the options array.
//  * @param {string} props.options[].value - Value property of each object in the options array.
//  * @param {string} props.options[].name - Name property of each object in the options array.
//  * @param {void} props.onChange -
//  * @returns
//  */
const MUIAutocomplete = ({
  value,
  // setValue,
  options = [],
  onChange,
  name,
  error,
  helperText,
  disabled,
  loading,
  creatable,
  toggleOpen,
  setDialogValue,
}) => {
  const defaultInputProps = {
    sx: {
      borderRadius: 2,
      "&.MuiOutlinedInput-root": {
        " &.Mui-focused fieldset": {
          borderColor: error ? "red" : configs.colors.primary,
        },
      },
    },
  };

  return (
    <>
      {creatable ? (
        <Autocomplete
          size="small"
          value={value}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              // timeout to avoid instant validation of the dialog's form.
              setTimeout(() => {
                toggleOpen(true);
                setDialogValue((prev) => ({
                  ...prev,
                  label: newValue.inputValue,
                }));
              });
            } else if (newValue && newValue.inputValue) {
              toggleOpen(true);
              setDialogValue((prev) => ({
                ...prev,
                label: newValue.inputValue,
              }));
            } else {
              onChange(event, newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                label: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          id={"autocomplete-" + name}
          options={options}
          getOptionLabel={(option) => {
            // for example value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.label;
          }}
          isOptionEqualToValue={(option) => option.label === value.label}
          disableClearable
          handleHomeEndKeys
          selectOnFocus
          clearOnBlur
          // freeSolo
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          renderInput={(params) => (
            <TextField
              name={name}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
                type: "search",
                ...defaultInputProps,
              }}
              onChange={(e) => onChange(e, null)}
              error={error ? true : false}
              helperText={helperText}
            />
          )}
          disabled={disabled}
        />
      ) : (
        <Autocomplete
          value={value}
          size="small"
          id={"autocomplete-" + name}
          disableClearable
          handleHomeEndKeys
          selectOnFocus
          clearOnBlur
          // freeSolo
          options={options}
          isOptionEqualToValue={(option) => option.label === value.label}
          getOptionLabel={(option) => option.label}
          loading={loading}
          renderInput={(params) => (
            <TextField
              name={name}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
                type: "search",
                ...defaultInputProps,
              }}
              onChange={(e) => onChange(e, null)}
              error={error ? true : false}
              helperText={helperText}
            />
          )}
          onChange={(e, newValue) => onChange(e, newValue)}
          disabled={disabled}
          // onInputChange={(e, value, reason) => {
          //   if (setValue && reason === "reset") setValue(null);
          // }}
        />
      )}
    </>
  );
};

export default MUIAutocomplete;
