import { get, post, put } from "../utils/axios/axios.utils";
const path = "/statuses?type=contracts";

export const getStatuses = async (params) => {
  try {
    const response = await get(`${path}`, {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

/**
 *
 * @param {Object} data - Params for creating status
 * @returns
 */
export const createStatus = async (data) => {
  try {
    const response = await post(`${path}`, data);
    return response;
  } catch (error) {
    console.error("createStatus:", error.message);
    throw error;
  }
};
