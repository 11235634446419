// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page {
  height: calc(100vh - 60px);
  width: 100%;
  padding: 20px;
  /* background-color: #f8f8f8 !important; */
  background-color: rgba(84, 82, 224, 0.04) !important;
  overflow: scroll;
}

.card-container {
  position: relative;
  height: 100%;
}

.sidebar-item-active {
  background-color: rgba(84, 82, 224, 0.9) !important;
  color: #ffffff !important;
  border: none !important;
}

.sidebar-item-active:hover {
  background-color: rgba(84, 82, 224, 1) !important;
}

.mui-card-header {
  color: rgba(23, 41, 53, 1) !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,WAAW;EACX,aAAa;EACb,0CAA0C;EAC1C,oDAAoD;EACpD,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,mDAAmD;EACnD,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".main-page {\n  height: calc(100vh - 60px);\n  width: 100%;\n  padding: 20px;\n  /* background-color: #f8f8f8 !important; */\n  background-color: rgba(84, 82, 224, 0.04) !important;\n  overflow: scroll;\n}\n\n.card-container {\n  position: relative;\n  height: 100%;\n}\n\n.sidebar-item-active {\n  background-color: rgba(84, 82, 224, 0.9) !important;\n  color: #ffffff !important;\n  border: none !important;\n}\n\n.sidebar-item-active:hover {\n  background-color: rgba(84, 82, 224, 1) !important;\n}\n\n.mui-card-header {\n  color: rgba(23, 41, 53, 1) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
