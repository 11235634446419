import {
  deleteOrganizationByID,
  postOrganization,
} from "src/services/organizations.services";
import { createUser } from "src/services/users.services";

const handleRegisterOrganization = async (values) => {
  // var organizationID = "";
  const organizationJSON = values?.organization;
  // const adminJSON = values?.user;
  // adminJSON.role = "admin";
  organizationJSON.admin = values?.user;

  try {
    const organizationResponse = await postOrganization(organizationJSON);
    // organizationID = organizationResponse?.data?.id;

    // if (!organizationID) throw new Error("Organization ID is missing!");

    // adminJSON.organization_id = organizationID;
    // adminJSON.role = "admin";
    // const adminResponse = await createUser(adminJSON);

    // return { organization: organizationResponse, admin: adminResponse };

    return organizationResponse;
  } catch (error) {
    // if (organizationID) await deleteOrganizationByID(organizationID);

    throw error;
  }
};

export default handleRegisterOrganization;
