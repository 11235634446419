
import * as Yup from "yup";

const organizationValidationsSchema = {
  name: Yup.string().required("Organization Name is required"),
  country: Yup.string().required("Select your country"),
  state: Yup.string().required("Select your state"),
  city: Yup.string().required("City is required!"),
  zipcode: Yup.number("Integer value is required")
    .integer("Integer value is required")
    .required("Zipcode is required"),
};

export default organizationValidationsSchema;
