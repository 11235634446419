//

import Login from "../pages/Login/Login.page";
import commonRoutes from "./common.routes";

//
export const publicRoutes = [
  {
    id: "login-1",
    path: "/",
    element: <Login />,
  },

  ...commonRoutes,
];

export default publicRoutes;
